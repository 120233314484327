import { Box, Flex, Text, Popover } from "@mantine/core";

import TitleWithTooltipDashboard from "./TitleWithTooltipDashboard";
import { getNestedValue } from "../../../../ideaNiv/Funcs";
import { formatBigMonetaryValueIdeas } from "../../../../ideas/ideas-table-helpers/Helpers";

interface ImpactItemProps {
  aggregationProperty: any;
  impactData: any;
  idea: any;
  /* ideaDomain?: string; */
  ideaRanks: any;
}

const DashboardImpact = ({
  aggregationProperty,
  impactData,
  idea,
  ideaRanks,
}: ImpactItemProps) => {
  
  const getMatchedDomain = (
    objectName: string | undefined,
    matchedObject: any
  ) => {
    if (!objectName || !matchedObject)
      return { domainKey: null, matchedDomain: matchedObject };

    if (
      objectName.startsWith("domains") ||
      objectName.startsWith("components")
    ) {
      const domainKey = Object.keys(matchedObject)
        .filter((key) => key !== "index" && key !== "total")
        .pop();
      return {
        domainKey,
        matchedDomain: domainKey ? matchedObject[domainKey] : null,
      };
    }

    return { domainKey: null, matchedDomain: matchedObject };
  };

  const isCompanies = aggregationProperty?.fieldPath === "orgId";
  const path = `aggregations.${aggregationProperty?.propertyName}.total`;
  const relevantValue = getNestedValue(idea, path);
  const currentImpact = impactData[aggregationProperty.propertyName];
  const ranksData = currentImpact?.ranksData

  console.log({ranksData: currentImpact?.ranksData})

  return (
    <Box
      style={{
        flex: "1 1 auto",
        minWidth: "127px",
        borderRadius: 4,
        padding: "12px 16px 12px 16px",
        backgroundColor: "#F8F8FB",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <TitleWithTooltipDashboard
        label={aggregationProperty.label}
        tooltip={aggregationProperty.impact.tooltip}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Text weight={600} color="#212529">
          <span style={{ fontSize: "24px" }}>
            {isCompanies
              ? relevantValue
              : relevantValue
                ? formatBigMonetaryValueIdeas(relevantValue,  aggregationProperty?.impact?.prefix)
                : 0}
          </span>
        </Text>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
          gap: "8px",
          width: "100%",
        }}
      >
        {ranksData.map((rank: any, index: number) => {
          const objectName = rank?.objectName;
          const matchedObject = ideaRanks?.[objectName];
          const { domainKey, matchedDomain } = getMatchedDomain(
            objectName,
            matchedObject
          );

          const label = objectName
            ? objectName
              .split("By")[0]
              .replace(/components/gi, "ideas")
              .replace(/domains/gi, "ideas")
              .trim()
            : "Items";

          const domainKeyLabel =
            domainKey && objectName.startsWith("domains") ? domainKey : null;
          const componentKey =
            domainKey && objectName.startsWith("components") ? domainKey : null;

          return (
            <Flex
              key={objectName}
              align="center"
              justify="center"
              style={{
                display: "flex",
                justifyContent: "left",
                gap: "8px",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Box
                style={{
                  backgroundColor: "rgb(217 217 248)",
                  borderRadius: 4,
                  padding: "2px 6px",
                  textAlign: "center",
                }}
              >
                <Text size="10px" weight={600} color="#5C5CEB">
                  #{matchedDomain?.index || rank?.index}
                </Text>
              </Box>
              <Text size="xs" color="#585C68" style={{ textAlign: "left" }}>
                of {matchedDomain?.total || rank?.total} {label.trim()}{" "}
                {(componentKey || domainKeyLabel) && (
                  <>
                    <Text span>in </Text>
                    <Text color="#6060eb" span>
                      '{componentKey || domainKeyLabel}'
                    </Text>
                  </>
                )}
              </Text>
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
};

export default DashboardImpact;
