import {
  ActionIcon,
  Box,
  Flex,
  Stack,
  Text,
  Tooltip
} from "@mantine/core";
import { IconCheck, IconMenu2, IconX } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import posthog from "posthog-js";
import IdeaContext from "../../../IdeaContext";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";
import { isBagelAdmin } from "../../../../../utils";

import BagelUsersActions from "./MatchActions/BagelUsersActions";
import { labelMatch } from "../../../../../Api";

  
const MatchActions = ({ navToPerspective, componentId }: { navToPerspective: any, componentId: string, }) => {
  const auth0 = useAuth0();
  
  const {
    loadEvidence,
    chosenEvidence,
    ideaId,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,   
  } = useContext(IdeaContext);
  
  
  const { dismiss, isLoading } = useEvidenceActions({ evidenceId: chosenEvidence._id });
  
  const isBagelUser = isBagelAdmin(auth0?.user);
  // const [loadingAssign, setLoadingAssign] = useState(false);
  // const [loadingDismissed, setLoadingDismissed] = useState(false);
  const [labelingDisabaled, setLabelingDisabaled] = useState(
    chosenEvidence?.manualLabel === "sameRequest"
  );
  const [isBagelUsersActionsOpened, setIsBagelUsersActionsOpened] = useState(false);
  const dismissMatch = (matchId: string) => {
    // setLoadingDismissed(true);
  
  
    // dismissSuggested(auth0, matchId, ideaId || "", chosenEvidence?._id, true)
    dismiss({
      ideaId,
      matchId, 
      isGap: true
    })
      .then((res) => {
        setAddEvidenceFormScreen(false);
        setCardView(false);
        reCalculatPerspectiveForRequest({ _id: matchId }, "remove");
        showNotification({
          title: "This evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });
  
        console.log(res);
        loadEvidence();
      })
      .then(() => {
        setChosenEvidence(null);
        // setLoadingDismissed(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  
  const labelMatchBagelUser = (sameRequest: boolean) => {
    setLabelingDisabaled(true);
    labelMatch(
      chosenEvidence?.match?._id,
      ideaId || "",
      chosenEvidence?._id,
      sameRequest,
      auth0
    )
      .then((res) => {
        console.log(res);
        showNotification({
          title: "This evidence has been added",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  
  useEffect(() => {
    setLabelingDisabaled(chosenEvidence?.manualLabel === "sameRequest");
  }, [chosenEvidence]);
  
  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      
     
      {isBagelUser && (
        <Stack align="center" justify='left' style={{ position: "relative", display: "inline-block" }} mr={2}>
          {/* Target Button */}
          <ActionIcon 
            variant="transparent" 
            onClick={()=> setIsBagelUsersActionsOpened(!isBagelUsersActionsOpened)} 
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconMenu2/>
          </ActionIcon>
    
          {/* Dropdown */}
          {isBagelUsersActionsOpened && (
            <Stack
              justify="center"
              align="center"
              style={{
                position: "absolute",
                bottom: "100%", // Position the dropdown above the button
                /* left: 0, */
                marginBottom: 8, // Spacing between target and dropdown
                 
                left: "50%", // Start at the middle of the parent
                transform: "translateX(-50%)", // Adjust to center
                zIndex: 1000,
                background: "white",
                border: "1px solid #ddd",
                borderRadius: 4,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "8px 12px",
                /* minWidth: 200, */
              }}
                
            >
              <BagelUsersActions
                labelMatchBagelUser={labelMatchBagelUser}
                labelingDisabaled={labelingDisabaled}
              />
            </Stack>
          )}
        </Stack>
          
      )}
    </Flex>
  );
};
  
export default MatchActions;