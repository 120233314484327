import { Tooltip, Text, TextProps } from "@mantine/core";
import { debounce } from "lodash";
import { useRef, useState, useLayoutEffect } from "react";

interface OverflownTextProps extends TextProps {
  overTooltip?: string;
}

export function OverflownText({
  children,
  overTooltip,
  ...props
}: OverflownTextProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useLayoutEffect(() => {
    const measure = debounce(() => {
      const element = ref.current;
      if (element) {
        setIsOverflown(
          element.clientWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
        );
      }
    }, 100);

    measure();
    return () => measure.cancel();
  }, [children]);

  return (
    <Tooltip
      sx={{ whiteSpace: "break-spaces" }}
      label={overTooltip || children}
      disabled={!isOverflown && !overTooltip}
      maw="50vw"
      multiline
      withinPortal
    >
      <Text
        color="#212529"
        ref={ref}
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          ...props.sx,
        }}
        {...props}
        fz={props.fz || 13.2}
      >
        {children}
      </Text>
    </Tooltip>
  );
}
