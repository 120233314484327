import { Box, Button, Grid, Group, ScrollArea, Text, Tooltip } from "@mantine/core";
import DiscoveryTextArea from "./DiscoveryTextArea";
import DiscoveryTabs from "./DiscoveryTabs";
import { useEffect, useState, useContext } from "react";
import NewIdeaForm from "./NewIdeaForm";
import CustomDrawer from "./evidenceCard/CustomDrawer";
import { BgColorRouteContext } from "../../context/BgColorContext";
import { useSearchParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconLink } from "@tabler/icons";

const Discovery = () => {
  const [newIdeaFormView, setNewIdeaFormView] = useState<boolean>(false);
  const { setBgColor, setPageName } = useContext(BgColorRouteContext);
  const [copied, setCopied] = useState(false);

  const [searchParams] = useSearchParams();
  const ideaId = searchParams.get("ideaId");


  const handleCopy = async () => {
    const url = window.location.href;
    await navigator.clipboard.writeText(url);

    showNotification({
      title: "Copied!",
      message: "URL has been copied to clipboard.",
      color: "green",
    });

    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  useEffect(() => {
    setBgColor("#F8F9FA");
    setPageName("discovery");
  }, [setBgColor, setPageName]);

  return (
    <Box>
      <Box h={52} px={16} sx={{ borderBottom: "1px solid #D8D8DB" }}>
        <Group position="apart">
          <Text color="#212529" fz={20} fw={600} py={12}>
        Idea Validation
          </Text>
          { ideaId && <Tooltip label={copied ? "Copied!" : "Copy URL"}>
            <Button
              sx={{ borderColor: "#5B60E3", color: "#5B60E3" }}
              variant="outline"
              color="violet"
              size="xs"
              leftIcon={copied ? <IconCheck size={18} /> : <IconLink size={18} />}
              onClick={handleCopy}
            >
              {copied ? "Copied" : "Share"}
            </Button>
          </Tooltip>}
        </Group>
      </Box>
      <ScrollArea h="calc(100vh - 115px)" mah={"calc(100vh - 63px)"}>
        <CustomDrawer />

        <Grid gutter={0} columns={120}>
          <Grid.Col span={38}>
            {newIdeaFormView ? (
              <NewIdeaForm setNewIdeaFormView={setNewIdeaFormView} />
            ) : (
              <DiscoveryTextArea
                setNewIdeaFormView={setNewIdeaFormView}
                urlIdeaId={ideaId}
              />
            )}
          </Grid.Col>
          <Grid.Col span={82} bg="#F8F9FA">
            <DiscoveryTabs />
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </Box>
  );
};

export default Discovery;
