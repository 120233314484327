import { Flex } from "@mantine/core"
import { IdeaBadge } from "./IdeaBadge"


const Atrributes = ( { ideaItem } : { ideaItem: any } ) => {
  return (
    <Flex>
      <IdeaBadge 
        type='product area'
        label={ideaItem?.componentObj?.name || 'Unassigned'}
      />
      <IdeaBadge 
        type='status'
        label={ideaItem?.status || 'Unassigned'}
      />
      <IdeaBadge 
        type='owner'
        label={ideaItem?.owner?.name || 'Unassigned'}
      />
    </Flex>
  )
}

export default Atrributes