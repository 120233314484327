import {
  Box,
  Button,
  Checkbox,
  ColorInput,
  Flex,
  Loader,
  Modal,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRef, useState } from "react";
import { updateObjectsStatusFields } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import { SourceIcon } from "../../../utils/SourceIcon";

interface FormModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  currentDeliveryStatus: any;
  addValueLoader?: boolean;
  setAddValueLoader?: (addValueLoader: boolean) => void;
  getField: any;
  allDeliveryStatuses: any[]; 
}

function EditStatusFormModal({
  opened,
  setOpened,
  addValueLoader,
  setAddValueLoader,
  currentDeliveryStatus,
  getField,
  allDeliveryStatuses,
}: FormModalProps) {
  const auth0 = useAuth0();
  const colorRef = useRef<HTMLInputElement>(null);
  const theme = useMantineTheme();
  const [formLoader, setFormLoader] = useState(false);

  if (setAddValueLoader) {
    setAddValueLoader(false);
  }

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200,
    });
  };

  const form = useForm({
    initialValues: currentDeliveryStatus,
  });

  const editObject = async (newValue: any, id: string) => {
    try {
      await updateObjectsStatusFields(auth0, newValue, id);
      setFormLoader(false);
      setOpened(false);
      saveNotification();
    } catch (error) {
      console.log(error);
    }
    setFormLoader(false);
    setOpened(false);
    getField();
  };

  const handleSaveValues = async (formValues: any) => {
    setFormLoader(true);
    const newValues = {
      name: formValues.name,
      label: formValues.label,
      color: formValues.color,
      id: formValues.id,
      marked: formValues.marked,
    };
    editObject(newValues, formValues.id);
    form.reset();
  };

  const markedCount = allDeliveryStatuses.filter((status:any) => status.marked).length;
  const isCheckboxDisabled = !currentDeliveryStatus.marked && markedCount >= 2; 

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => setOpened(false)}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <Box>
        <TextInput
          label={<Text fw={600}>Delivery Status Name</Text>}
          placeholder="Add a value..."
          {...form.getInputProps("name")}
          disabled
        />
        <TextInput
          label={<Text fw={600}>Display Name in GTM apps</Text>}
          placeholder="Add a value..."
          {...form.getInputProps("label")}
        />
        <ColorInput
          my={"md"}
          label={
            <Text>
              Choose a color{" "}
              <Text span c="red">
                *
              </Text>
            </Text>
          }
          ref={colorRef}
          format="hex"
          swatches={[
            "#FC0A0A",
            "#F26786",
            "#FDAE16",
            "#1DB547",
            "#4194F9",
            "#6B7280",
          ]}
          {...form.getInputProps("color")}
          sx={{ width: "130px" }}
        />
        <Tooltip
          label="You can only mark max two delivery status"
          disabled={!isCheckboxDisabled}
          withArrow
        >
          <Box>
            <Checkbox
              fw={600}
              label="Marked in Timeline Graphs"
              {...form.getInputProps("marked", { type: "checkbox" })}
              disabled={isCheckboxDisabled}
            />
          </Box>
        </Tooltip>
        <Flex
          mih={50}
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            bg={"#5C5CEA"}
            disabled={
              !form.values.label ||
                form.values.color?.length < 3 ||
                !form.values.name
            }
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
              "&[disabled]": {
                pointerEvents: "all",
                backgroundColor: "#fff",
                cursor: "not-allowed",
                ":hover": {
                  backgroundColor: "#fff",
                },
              },
            }}
            color={"#fff"}
            onClick={() => handleSaveValues(form.values)}
          >
            {formLoader === false ? (
              "Save"
            ) : (
              <Loader color="violet" variant="dots" />
            )}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

export default EditStatusFormModal;
