import { Box, Flex, Group, Tooltip } from "@mantine/core";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { OverflownText } from "../../../OverflownText";
import {
  GeneratedChosenIcon,
  ProductAreaIcon,
} from "../../../../icons/x-symbol-svgrepo-com";

export const TextCell: React.FC<any> = ({ data, col, renderParams }) => {
  const value = getNestedFieldValue(data, col.accessor) || renderParams.default;

  // console.log('value')
  // console.log(value)

  // console.log('col.accessor')
  // console.log(col.accessor)
  // console.log('data')

  const addGeneratedIcon =
    col.accessor === "title" && data.state === "generated";

  const isStatusCell = col.accessor === "status"
  const addStatusGenerated: boolean = isStatusCell && data.state === "generated"

  return (
    <Box
      sx={{
        maxWidth: renderParams.maxWidth || "350px",
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <Flex align="center">
        {col.accessor === "componentObj.name" && (
          <Box mr={4.2}>
            <ProductAreaIcon color="#212529" />
          </Box>
        )}


        {addGeneratedIcon && (
          <Tooltip
            position="top"
            label={"Automatically generated by Bagel AI"}
            withinPortal
          >
            <Box
              w={22}
              pr={3}
              mr={6}
              m={0}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <GeneratedChosenIcon />
            </Box>
          </Tooltip>
        )}


        <OverflownText
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginLeft: "0px",
            width: "100%",
          }}
          overTooltip={renderParams.tooltip}
          {...renderParams}
        >
          {addStatusGenerated ? 'AI Generated' : value}
        </OverflownText>
      </Flex>
    </Box>
  );
};
