import { FC } from "react";
import { Box, Group, Paper, Text, Tooltip } from "@mantine/core";
import {
  PlungingIcon,
  SurgingIcon,
} from "../../../../icons/x-symbol-svgrepo-com";
import { useHover } from "@mantine/hooks";

interface HeaderTabProps {
  title: string;
  count: number;
  surging: boolean;
  active?: boolean;
  primary?: boolean;
}

export const HeaderTab: FC<HeaderTabProps> = ({
  title,
  count,
  active,
  primary,
  surging,
}) => {
  // Adjust component
  const { hovered, ref } = useHover();

  return (
    <Tooltip
      withinPortal
      multiline={true}
      width={220}
      label={
        surging
          ? 
          <Box>
            <Text>
            Ideas with Significant 
            </Text>
            <Text>
           Increase in Customer Demand
            </Text>
          </Box>
          
          :
          <Box>
            <Text>
            Ideas with Significant 
            </Text>
            <Text>
           Decrease in Customer Demand
            </Text>
          </Box>
      }
    >
      <Box
        ref={ref}
        h={106}
        /*  h='100%' */
        sx={{
          width: 134,
          borderTop: active
            ? "4px solid #5C5CEB"
            : hovered
              ? "4px solid #5C5CEB"
              : "4px solid #E0E7FF",
          background: !active /* || hovered */
            ? "linear-gradient(180deg, #EFEFFD 0%, rgba(255,255,255,0.00) 100%)"
            : "transparent",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          /* '&:hover': {
          borderTop: '4px solid #5C5CEB'
        } */
        }}
      >
        <Box>
          <Text size="sm" weight={600} color={!active ? "#5C5CEB" : "#212529"}>
            {title}
          </Text>
          <Group mt={12}>
            <Paper
              p="xs"
              radius="sm"
              sx={{
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Paper
                p="xs"
                /* style={{ backgroundColor: iconBgColor }} */
                sx={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>{surging ? <SurgingIcon /> : <PlungingIcon />}</Box>
              </Paper>

              {/* <img
              src={icon}
              style={{ width: 20, height: 20, objectFit: 'contain' }}
              alt=""
            /> */}
            </Paper>
            <Text
              size={28}
              weight={600}
              color={!active ? "#5C5CEB" : "#212529"}
            >
              {count}
            </Text>
          </Group>
        </Box>
      </Box>
    </Tooltip>
  );
};

/* export const IdeaHeader: FC = () => {
  return (
    <Box sx={{ backgroundColor: 'rgba(248,248,251,1)', display: 'flex', gap: -1, flexWrap: 'wrap' }}>
      <HeaderTab
        title="Surging Ideas"
        count={5}
        active={true}
      />
      <HeaderTab
        title="Plunging Ideas"
        count={3}
        primary={true}
      />
      <Box 
        sx={{ 
          flex: 1,
          minWidth: 240,
          width: 590,
          height: 106,
          background: 'linear-gradient(180deg, #EFEFFD 0%, rgba(255,255,255,0.00) 100%)',
          padding: '36px 12px 12px'
        }} 
      />
    </Box>
  );
}; */
