import {
  Box,
  Group,
  TextInput,
  ActionIcon,
  UnstyledButton,
  Text,
  Flex,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useState, useEffect } from "react";
import TabMenu from "./TabMenu";
import { showNotification } from "@mantine/notifications";
import {
  GeneratedChosenIcon,
  GeneratedIcon,
} from "../../icons/x-symbol-svgrepo-com";

const IdeasTab = ({
  view,
  isActive,
  onTabChange,
  upsertViews,
  isGenerated,
}: {
  view: any;
  isActive: boolean;
  onTabChange(value: string): void;
  upsertViews: (body: any, func: (res: any) => void) => void;
  isGenerated: boolean;
}) => {
  const { hovered, ref } = useHover();
  const [onEdit, setOnEdit] = useState(false);
  const [viewName, setViewName] = useState(view.name);

  /* console.log('isGenerated')

  console.log(isGenerated) */

  useEffect(() => {
    setOnEdit(false);
  }, [isActive]);

  return (
    <Box
      className="ideas-tab"
      ref={ref}
      sx={{
        borderBottom: `${isActive ? "2px" : "1.5px"} solid`,
        borderColor: isActive ? "#5b60e3" : "#f1f1f1",
        ":hover": {
          backgroundColor: "#f9fafb",
          borderBottom: "2px solid #5b60e3",
        },
        marginBottom: "-2px",
      }}
    >
      {onEdit && isActive ? (
        <Group spacing={0}>
          <TextInput
            h={35}
            pl="sm"
            pr={3}
            onChange={(event) => {
              setViewName(event.currentTarget.value);
            }}
            variant="unstyled"
            value={viewName}
            withAsterisk
            w={100}
          />
          <ActionIcon
            color="#5b60e3"
            size="xs"
            mr={5}
            onClick={() => {
              upsertViews({ name: viewName, _id: view._id }, (res: any) => {
                console.log(res);
                setOnEdit(false);
                showNotification({
                  title: "Saved successfully!",
                  color: "teal",
                  message: "view name saved successfully",
                  icon: <IconCheck size="1.1rem" />,
                  autoClose: 1200,
                });
              });
            }}
          >
            <IconCheck size={16} />
          </ActionIcon>
        </Group>
      ) : (
        <Tooltip
          disabled={!isGenerated}
          label={"Automatically generated by Bagel AI"}
        >
          <UnstyledButton
            h={31}
            py={isGenerated ? 0 : 6}
            px={isGenerated ? 0 : 8}
            key={view._id}
            value={`${view._id}`}
            onClick={() => onTabChange(view._id)}
          >
            <Group
              spacing={0}
              bg={
                isGenerated && !isActive
                  ? "#FF8700"
                  : isGenerated && isActive
                    ? "rgba(92, 92, 235, 0.14)"
                    : ""
              }
              sx={{
                width: isGenerated ? "86px" : "auto",
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
              }}
              align="center"
              px={isGenerated ? 6 : 0}
            >
              {isGenerated && (
                <Box mr={3} pt={3} pb={2}>
                  <GeneratedChosenIcon fill={!isActive ? "#fff" :"#FF8700"}/>
                </Box>
              )}
              <Text
                span
                size={12}
                fw={500}
                sx={{
                  maxWidth: hovered ? "18ch" : "14ch",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color:
                    isGenerated && !isActive
                      ? "white"
                      : isGenerated && isActive
                        ? "black"
                        : isActive
                          ? "#212529"
                          : "#5C5CEB",
                }}
              >
                {view?.name}
              </Text>
              {hovered && (
                <TabMenu
                  item={view}
                  hovered={hovered}
                  setOnEdit={setOnEdit}
                  upsertViews={upsertViews}
                />
              )}
            </Group>
          </UnstyledButton>
        </Tooltip>
      )}
    </Box>
  );
};
export default IdeasTab;
