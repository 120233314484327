
export type RankData = {
  index: number;
  total: number;
  objectName: string;
  childName: string;
};

export const calculateRanks = (
  impactAggregationProperties: any[],
  idea: any
) => {

  const impactResults: Record<string, any> = {};

  impactAggregationProperties.forEach((property: any) => {
    const {
      propertyName,
      impact,
    } = property;

    const displayRanks = impact?.displayRanks || [];
    let ranksData: RankData[] | null = null;
 
    ranksData = displayRanks?.map((rankPropertyName: string) => {
      const objectName = rankPropertyName;
      let childName = null;
      let rankData = null;
      if (["domains", "components"].includes(objectName)) {
        if (objectName == "components") {
          childName = idea?.componentObj?.name;
          rankData = idea.ranks?.components?.[childName?.toLowerCase()];
        } else {
          childName = idea.componentObj?.domain;
          rankData = idea.ranks?.domains?.[childName?.toLowerCase()];
        }
      } else if (idea.ranks?.[objectName]) {
        rankData = idea?.ranks?.[objectName];
      } else {
        return undefined;
      }

      return {
        index: rankData?.index,
        total: rankData?.total,
        objectName,
        childName,
      };
    });

    impactResults[propertyName] = {

      ranksData: ranksData?.filter((rank) => rank !== undefined),
    };
  });

  return impactResults;
};