import { ActionIcon, Indicator, Tooltip } from "@mantine/core";
import FiltersMenu, { ActiveFilter } from "../../../ideas/filters/FiltersMenu";
import { IconFilter } from "@tabler/icons";
import FilterBadge from "../../../ideas/filters/FilterBadge";
import { findFilterByName } from "../../../../context/helpers";
import SyncContext from "../../../../context/SyncContext";
import { useContext } from "react";

// Passing all states and handlers as parameters to the FiltersBar
const FiltersBar = ({
  activeFilters,
  setActiveFilters,
}: {
  activeFilters: any;
  setActiveFilters: React.Dispatch<React.SetStateAction<ActiveFilter[]>>;
}) => {
  const {
    ideasFiltersConfig
  } = useContext(SyncContext);

  const MenuType = "evidence"

  const currentTypeActiveFilters = activeFilters
    .filter( (filter:any) => findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig))
    .map((filter:any) => ({...filter, ...findFilterByName(filter.parent?.split(".").pop(), MenuType, ideasFiltersConfig)}));
  
  return (
    <>
      <FiltersMenu
        MenuType={MenuType}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        componentType="idea-page"
        Target={
          <Tooltip label={`Filter by attributes`} withArrow>
            <ActionIcon
              sx={{
                height: 32,
                width: 32,
                borderColor: "#D8D8DB",
                color: "black",
                fontSize: 12,
                fontWeight: 500,
                ":hover": {
                  backgroundColor: "#5C5CEB1A",
                },
              }}
              variant="outline"
              radius="sm"
            >
              <Indicator
                mt={2}
                zIndex={5}
                disabled={currentTypeActiveFilters?.length == 0}
                inline
                label=""
                size={8}
                offset={3}
                color="#0BAC4D"
              >
                <IconFilter strokeWidth={1} size={18} />
              </Indicator>
            </ActionIcon>
          </Tooltip>
        }
      />
      {currentTypeActiveFilters.map((filter:any, index: number) => (
        <FilterBadge
          key={index}
          filter={filter}
          MenuType={MenuType}
          // ownerOptions={findFilterByName('Owner', 'idea', ideasFiltersConfig)?.options}
          onRemoveClick={() =>
            setActiveFilters(
              activeFilters.filter((item:any) => item.parent !== filter.parent)
            )
          }
        />
      ))}
    </>
  );
};

export default FiltersBar;
