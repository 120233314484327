// src/components/RightSide.tsx

import React, { FC, useState } from "react";
import { Stack } from "@mantine/core";
import Activities from "./Activities";
import AssignIdeaSearch from "./AssignIdea";
import { EvidenceModalType } from "..";

const RightSide: FC<EvidenceModalType> = ({
  onAction,
  evidence,
  setEvidence
}) => (
  <Stack
    justify="space-between"
    // h="100%"
    mih="80vh"
    pb={0}
    sx={{
      borderLeft: "1px solid #E5E7EB",
    }}
    spacing={0}
  >
    {/* Search Section */}
    <AssignIdeaSearch
      onAction={onAction}
      evidence={evidence}
      setEvidence={setEvidence}
    />

    {/* Activities Section */}
    <Activities evidence={evidence} />
  </Stack>
);

export default RightSide;
