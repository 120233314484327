import { createContext, useContext, ReactNode } from "react";

// Define types for the functions to update bgColor and pageRoute
type BgColorRouteContextType = {
  setBgColor: (color: string) => void;
  setPageName: (route: string) => void;
};

// Default values for both functions
const defaultContextValue: BgColorRouteContextType = {
  setBgColor: () => {
    // Intentionally empty 
  },
  setPageName: () => {
    // Intentionally empty
  },
};

export const BgColorRouteContext = createContext<BgColorRouteContextType>(defaultContextValue);

export const useBgColorRouteContext = () => useContext(BgColorRouteContext);
