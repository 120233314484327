import { Flex, Menu, Stack, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy, IconX } from "@tabler/icons";
import { useContext, useState, useEffect } from "react";
import IdeaContext from "../../IdeaContext";
import { useNavigate } from "react-router-dom";
import { updateValidation } from '../../../../Api';
import { useAuth0 } from "@auth0/auth0-react";

const CardHeader = ({ validationCount, navToPerspective, setValidateModalOpened }: { validationCount : any, navToPerspective: any, setValidateModalOpened?: (opened: boolean) => void; }) => {
  
  const { setChosenEvidence, chosenEvidence, idea } = useContext(IdeaContext);
 
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isShown, setIsShown] = useState(true);

  const requestId = chosenEvidence?._id;
  useEffect(() => {
    if (chosenEvidence?.ideaId && chosenEvidence?.sourceOfAssignment == 'suggestion') {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }, [chosenEvidence]);

  const auth0 = useAuth0();

  useEffect(() => {
    if(chosenEvidence?.ideaId){
      setShowTooltip(true)
    } else {
      setShowTooltip(false)
    }
  }, [chosenEvidence]);

  const handleCopy = async () => {
    try {
      const newPath = new URL(window.location.href);
      const originStr = newPath?.origin?.toString()

      await navigator.clipboard.writeText(
        `${originStr}/evidence/${requestId}`
      );

      setTimeout(() => setOpened(false), 1500);

    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const state = chosenEvidence?.state;

  const isRequest: boolean = state === "inReview" || state === "validated";

  return (
    <Flex justify="space-between" w="100%" h={52} align="center">
      <Stack fz="20px" fw={600} h="20px" ta="center" justify="center">
        Evidence details
      </Stack>

      <Flex>
        {(isRequest || showTooltip) && (
          <Menu opened={opened} onChange={setOpened} position="top">
            <Tooltip
              label="The Evidence is already assigned to a Idea. Click “Copy Link” to review this Evidence inside it’s assigned Idea"
              position="bottom"
              withArrow
              disabled={!showTooltip}
            >
              <Menu.Target>
                <Flex
                  mr="lg"
                  p={6}
                  bg="#F0F0FA"
                  sx={{ cursor: "pointer", borderRadius: 6 }}
                  onClick={() => {
                    handleCopy();
                  }}
                  w={96}
                >
                  <IconCopy size={16} color="#212529" />
                  <Text ml="3px" color="#212529" fz={12} lineClamp={1}>
                Copy link
                  </Text>
                </Flex>
              </Menu.Target>
            </Tooltip>
            <Menu.Dropdown>
              <Flex p={6} bg="#212529" sx={{borderRadius: 4}}>
                <IconCheck size={14} color="#F9F7F5"/>
                <Text color="#F9F7F5" fz={13} ml={4}>
                Link copied to clipboard
                </Text> 
              </Flex>
            
            </Menu.Dropdown>
          </Menu>

        )}

        <Flex
          mb={2}
          mr="8px"
          sx={{ cursor: "pointer" }}
          align="center"
          onClick={() => {
            navToPerspective();
            setChosenEvidence(null);
            const newPath = new URL(window.location.href);
            newPath.searchParams.delete("requestId");
            console.log(newPath.toString().substring(newPath.origin.length), {
              replace: true,
            });
            navigate(newPath.toString().substring(newPath.origin.length), {
              replace: true,
            });
            setValidateModalOpened?.(false);
            if(validationCount){
              updateValidation(
                auth0, 
                {id:idea._id, verifyState:'continue', validationCount: validationCount}
              ).catch((e:any) => {
                console.log(e);
              })      
            }   
          }}
        >
          <IconX size={14} color="#5C5CEB" />
          <Text ml="4px" color="#5C5CEB" fz={12} py={6} >
            Close
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardHeader;
