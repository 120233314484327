import { useContext } from 'react';
import { Box, Text, Flex, Menu, Stack } from '@mantine/core';
import { IconCaretDown } from '@tabler/icons';
import DashboardContext from '../../../DashboardContext';
import PerspectiveData from './PerspectiveData';

const PerspectivesMenu = () => {
  const {
    perspectiveConfigurations,
    perspective,
    setPerspective
  } = useContext(DashboardContext);

  return (
    <Stack align='stretch' pt={8}>
      <Box mt={26} py={2}>
        <Flex>
          <Text fz="14px" fw={600} mr='4px'>
            Perspective:
          </Text>
          <Menu position='bottom-start' withinPortal>
            <Menu.Target>
              <Flex sx={{ cursor: 'pointer', zIndex: 10 }} align="center">
                <Text fz="14px" mr='4px'>
                  {perspectiveConfigurations?.find((i: any) => i.label === perspective?.label)?.label || 'Select'}
                </Text>
                <IconCaretDown fill='black' size={14} />
              </Flex>
            </Menu.Target>
            <Menu.Dropdown>
              {perspectiveConfigurations.map((perspectiveItem: any) => (
                <Menu.Item
                  key={perspectiveItem.key}
                  onClick={() => {
                    setPerspective(perspectiveItem);
                  }}
                >
                  {perspectiveItem.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Box>
      <PerspectiveData />
    </Stack>
  );
};

export default PerspectivesMenu;