import { FC } from "react";
import { Box, Group, Text, Tooltip } from "@mantine/core";
import {
  OwnerHeaderIcon,
  ProductAreaHeaderIcon,
  StatusHeaderIcon,
} from "../../../../../../icons/x-symbol-svgrepo-com";
import { capitalizeFirstLetter } from "../../../../../../utils/capitalizeFirstLetter";

interface IdeaBadgeProps {
  type: string;
  label: string;
}

export const IdeaBadge: FC<IdeaBadgeProps> = ({ type, label }) => {
  return (
    
    <Tooltip label={<Box p={0.186}>
      <Text fz={11.4} mb={1.4}>{capitalizeFirstLetter(type)}:</Text>
      <Text fz={11.4}>{label}</Text>
    </Box>}>
      <Group spacing={4} py={4} mr={8} align="center">
        {type === "product area" ? (
          <ProductAreaHeaderIcon />
        ) : type === "status" ? (
          <StatusHeaderIcon />
        ) : (
          <OwnerHeaderIcon />
        )}
        <Text size="xs">{label}</Text>
      </Group>
    </Tooltip>
    
  
    
  );
};
