import { useContext } from "react";
import { Flex, Text } from "@mantine/core";
import DashboardContext from "../../../DashboardContext";
import BagelMultiSelect, { Option } from "../../../../general/BagelMultiSelect";
import { ProductAreaHeaderIcon } from "../../../../../icons/x-symbol-svgrepo-com";

const ProductAreaSelect = () => {

  const {
    components,
    componentsByDomain,
    trendsActiveFilters,
    setTrendsActiveFilters,
    displayState,
  } = useContext(DashboardContext);

  const optionsToRender: Option[] = (
    displayState?.domain ? componentsByDomain : components
  ).map((comp: any) => ({
    value: comp._id,
    label: comp.name,
    childComponents:
      comp.childComponents.map((child: any) => ({
        value: child._id,
        label: child.name,
      })) || [],
  }));

  const currentSelection =
    (trendsActiveFilters.find((f: any) => f.key === "componentId")
      ?.value as string[]) || [];

  const getSelectedLabels = (opts: Option[], selection: string[]): string[] => {
    const labels: string[] = [];
    opts.forEach((option) => {
      if (selection.includes(option.value.toLowerCase())) {
        labels.push(option.label);
      }
      // Check child options first (ignore parent's label if children exist)
      if (option.childComponents && option.childComponents.length > 0) {
        option.childComponents.forEach((child) => {
          if (selection.includes(child.value.toLowerCase())) {
            labels.push(child.label);
          }
        });
      }
    });
    return labels;
  };

  const selectedLabels = getSelectedLabels(optionsToRender, currentSelection);
  const selectedLabelDisplay =
    selectedLabels.length === 1
      ? selectedLabels[0]
      : selectedLabels.length > 1
        ? `${selectedLabels.length} selected`
        : "All";

  const handleSelectionChange = (selected: string[]) => {
    setTrendsActiveFilters(
      (prev: { key: string; value: string | string[] }[]) => {
        const otherFilters = prev.filter((f) => f.key !== "componentId");
        return [...otherFilters, { key: "componentId", value: selected }];
      }
    );
  };

  return (
    <>
      {displayState?.component && (
        <Flex
          h={32}
          align="center"
          sx={{
            borderRadius: "4px",
            border: "0.8px solid #F8e9FB",
            cursor: "pointer",
          }}
        >
          <BagelMultiSelect
            parent='componentId'
            options={optionsToRender}
            value={currentSelection}
            onChange={handleSelectionChange}
            searchable
          >
            <Flex mx="4px" align="center">
              <ProductAreaHeaderIcon />
              <Text
                fz="12px"
                color="#585C68"
                mx="6px"
                sx={{ lineHeight: "16px" }}
              >
                Product area:
              </Text>

              <Text
                fz="12px"
                lineClamp={1}
                maw="100px"
                tt="capitalize"
                miw="65px"
                sx={() => ({
                  "@keyframes blinking": {
                    "0%": { opacity: 1 },
                    "50%": { opacity: 0 },
                    "100%": { opacity: 1 },
                  },
                })}
              >
                {selectedLabelDisplay}
              </Text>
            </Flex>
          </BagelMultiSelect>
        </Flex>
      )}
    </>
  );
};

export default ProductAreaSelect;
