import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { addViews, getViews } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { IdeasContextType, initialState } from "../../types/types";
import FiltersContext from "./filters/context/FiltersContext";
import { getFilteredRequests as fetchFilteredRequests, parseFiltersFromQuery } from "./ideasHelpers";

const IdeasContext = createContext<IdeasContextType>(initialState);

const IdeasContextProvider = ({ children }: { children: React.ReactNode }) => {

  const { ideaColumns, ideaDefaultSortColumn, ideasFiltersConfig, impactAggregationProperties } = useContext(SyncContext);

  const { activeFilters } = useContext(FiltersContext);

  const location = useLocation();
  const auth0 = useAuth0();
  const queryParams = new URLSearchParams(location.search);
  const _page = queryParams.get("page");
  const _selectedView = queryParams.get("selectedView");
  const [ideas, setIdeas] = useState<any[]>([]);

  const [page, setPage] = useState<string | null>(_page || null);
  const [requestsTotalCount, setRequestsTotalCount] = useState(0);
  const [sortState, setSortState] = useState<string>("total");
  const [filters, setFilters] = useState<any>(() => {
    parseFiltersFromQuery(location.search, ideasFiltersConfig) ?? []
  });
  const [sortStatus, setSortStatus] = useState<any>({});
  const [isTagsHovered, setIsTagsHovered] = useState<boolean>(false);
  const [hoveredId, setHoveredId] = useState("");
  const [savedViews, setSavedViews] = useState<any[]>([]);
  const [selectedView, setSelectedView] = useState<any>();
  const [search, setSearch] = useState("");
  const [, setLastFilterParams] = useState<any>({});
  const [loading, setLoading] = useState(false);
  
  



  const getFilteredIdeas = (tabName: string) => {
    console.log("getFilteredIdeas",{tabName, selectedView: selectedView?.name,})
  
    fetchFilteredRequests(
      sortStatus,
      setLastFilterParams,
      page,
      search,
      activeFilters,
      setLoading,
      setIdeas,
      setRequestsTotalCount, 
      setPage,
      ideasFiltersConfig,
      selectedView?.name,
      auth0
    );
  };

  const loadViews = async (deleted = false) => {
    try {
      const { views } = await getViews(auth0);
      setSavedViews(views);
      if(_selectedView){
        setSelectedView(views.find((sv:any) => sv.name.toLowerCase() == _selectedView.toLowerCase()))
      }else if (!_selectedView || deleted) {
        setSelectedView(views[0]);
      }
    } catch (error) {
      console.error("Failed to load views:", error);
    }
  };

  const upsertView = async (body: any, callback: (res: any) => void) => {
    try {
      const res = await addViews(body, auth0);
      callback(res);
      await loadViews(body?.deleted);
    } catch (error) {
      console.error("Failed to upsert view:", error);
    }
  };

  const hoverTags = (e: any, id: string) => {
    if (e.type === "mouseleave") {
      setIsTagsHovered(false);
      setHoveredId(id);
    } else if (e.type === "mouseenter") {
      setIsTagsHovered(true);
      setHoveredId(id);
    }
  };

  useEffect(() => {
    loadViews();
  }, []);

  useEffect(() => {
    const _sortStatus = queryParams.get("sortStatus");
    let _sortStatusParams;

    try {
      _sortStatusParams = _sortStatus ? JSON.parse(_sortStatus) : null;
    } catch (error) {
      console.error("Invalid JSON in sortStatus:", error);
      _sortStatusParams = null;
    }

    console.log({ _sortStatus, ideaDefaultSortColumn });

    if (_sortStatusParams?.columnAccessor && _sortStatusParams.columnAccessor !== "null" && _sortStatusParams.columnAccessor !== null) {
      setSortStatus(_sortStatusParams);
    } else if (ideaDefaultSortColumn) {
      setSortStatus({ columnAccessor: ideaDefaultSortColumn, direction: "asc" });
    }
  }, [impactAggregationProperties, ideaDefaultSortColumn, ideaColumns]);
  

  const tableColoumns = useMemo(() => {

    if (selectedView?.name === 'Bagel AI') {

      const relevantColoumns = ideaColumns.filter((column: any) => column.generatedEnabled !== false)
      return relevantColoumns

    }

    else {

      return ideaColumns

    }
    
  }, [ideaColumns, selectedView]);

  


  return (
    <IdeasContext.Provider
      value={{
        location,
        page,
        filters,
        sortStatus,
        savedViews,
        selectedView,
        requestsTotalCount,
        setRequestsTotalCount,
        setPage,
        setFilters,
        setSortStatus,
        setSelectedView,
        loadViews,
        upsertView,
        sortState,
        setSortState,
        isTagsHovered,
        hoverTags,
        hoveredId,
        search,
        setSearch,
        loading,
        setLoading,
        ideas,
        setIdeas,
        getFilteredIdeas,
        tableColoumns
      }}
    >
      {children}
    </IdeasContext.Provider>
  );
};

export { IdeasContext, IdeasContextProvider };
export default IdeasContext;
