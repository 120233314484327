import { Button, Group, Text } from "@mantine/core"
import { ConvertIcon } from "../../../icons/x-symbol-svgrepo-com"
import { useContext } from "react";
import IdeaContext from "../IdeaContext";
import { saveIdea } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { LIGHT_GREY_BG } from "../../../constants/colors";

const GeneratedBar = () => {
  

  const auth0 = useAuth0()

  const {
    idea, fetchData
  } = useContext(IdeaContext);


  const onSelectSave = async (type: string, value: string, ownerId = null ) => {

    setTimeout(() => {
      showNotification({
        title: "This is now an active idea",
        color: "teal",
        message: ``,
        icon: <IconCheck size="1.1rem" />,
        autoClose: 1400,
      });
    }, 2000);
    
    if (idea) {
      const ideaObj = {
        id: idea._id,
        [type]: value,
      };
      if(ownerId){
        ideaObj['ownerId'] = ownerId
      }
      const response = await saveIdea(ideaObj, auth0)
        .then(async ()=>{
          await fetchData()
          
        })
            
      return response
    }
  };

  return (
    <Group mb={20} position='apart' p="5px" mx="20px" style={{borderRadius: 4, backgroundColor: "#9CEAC2"}}>
      <Group spacing={8}>

        <Text fz={12} lineClamp={2} w={700}>
        This idea was automatically generated by Bagel AI and is currently in read-only mode.
         To save and edit it, click the “Convert to idea” button.
        </Text>

      </Group>
      <Button
        ml={6}
        h={32}
        w={160}
        variant="outline" 

        leftIcon={<ConvertIcon />}
        
        onClick={() => {
          onSelectSave('state', 'active',idea?.componentObj?.ownerId  )
        }}

        styles={(theme) => ({
          root: {
            height: "32px",
            backgroundColor: "white",
            border: `1px solid #B0B0B0`,
            fontWeight: 400,
            padding: "0px 5px",
            margin: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "&:hover": {
              backgroundColor: theme.fn.darken(LIGHT_GREY_BG, 0.05),
            },
          },

          loader: {
            position: "absolute",
            color: theme.white,
          },

          leftIcon: {
            marginRight: 15,
          },
        })}
      >
        <Text color="#5C5CEB" fz={12}>
                                    Convert to idea
        </Text>
      </Button>
    </Group>
  )
}

export default GeneratedBar