import { Anchor, Box, Button, Flex, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { IconBell, IconCheck, IconCircleCheck, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { showNotification } from "@mantine/notifications";

import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { addRequestsToIdea, assignRequestToNewIdea, reassignRequest } from "../../../../../Api";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";
import AssignActionsMenu from "./AssignActionsMenu";
import { MatchReAssignment } from "./MatchReAssignment";
import { validateComponentId, buildNewIdeaObject } from "../../../../ideas/ideasHelpers";


const SearchActions = ({
  navToPerspective,
  setActiveSearch,
  chosenEvidence,
  validation,
  componentId, 
}: {
  navToPerspective: any;
  setActiveSearch: any;
  chosenEvidence: any;
  validation?: boolean;
  componentId: string,
}) => {
  // const auth0 = useAuth0();

  // const { defaultRequestPriority } = useContext(SyncContext);

  const {
    idea: _idea,
  } = useContext(IdeaContext);

  const {
    loadEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,
    triggerImpact,
    setTriggerImpact
  } = useContext(IdeaContext);

  const { validate, isLoading } = useEvidenceActions({evidenceId: chosenEvidence._id})
  
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [verifyOpened, setVerifyOpened] = useState(false);
  const [opened, setOpened] = useState(false);
  const { defaultPriority, defaultStatus,allComponents } = useContext(SyncContext);
  const assignedRequest = (chosenEvidence?.requests && chosenEvidence?.requests?.length > 0) ? chosenEvidence?.requests[0] : null
  const assignedIdea = (chosenEvidence?.ideas && chosenEvidence?.ideas?.length > 0) ? chosenEvidence?.ideas[0] : null
  const SALESFORCE = "salesforce";
  const auth0 = useAuth0();

  const handleReassignRequestWithTextItem = () => {
    setLoadingVerify(true)
    validate({ideaId:_idea._id, sourceOfAssignment:"search"})
      .then(({ success, evidence }) => {
        reCalculatPerspectiveForRequest( evidence, "add");
        setAddEvidenceFormScreen(false);
        setCardView(false);
        setChosenEvidence({});
        loadEvidence();
        setTriggerImpact(!triggerImpact)

        showNotification({
          title: "Eviedence has been re-assigned successfuly",
          color: "teal",
          message: "",
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        setLoadingVerify(false)
        setVerifyOpened(false);
        // loadEvidence();
        // navToPerspective();
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          title: "Error re-assigning request",
          color: "red",
          message: "please try again...",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200
        });
      });
  }

  const handleTextItemAssignment = (chosenEvidence: any)=>{

    // setLoading(true);

    if (chosenEvidence?.cxItem?.itemType === "account") {
      if (!chosenEvidence?.cxItem.origin) {
        //if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = chosenEvidence?.company?.sfId;
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          chosenEvidence?.company?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true;
            }
          });
        }
        if (hasSalesforce) {
          chosenEvidence.cxItem.origin = SALESFORCE;
        } else {
          chosenEvidence.cxItem.origin =
          chosenEvidence.company.sources?.length > 0
            ? chosenEvidence.company?.sources[0]?.origin
            : undefined;
        }
      }
    }

    // const newEvidence: any = {
    //   title: chosenEvidence?.title ,
    //   text: chosenEvidence?.text,
    //   type: chosenEvidence?.type,
    //   componentId: idea?.componentId,
    //   cxOrigin: chosenEvidence.cxItem?.origin,
    //   originId: chosenEvidence.cxItem?.originId,
    //   orgId: chosenEvidence.cxItem?.orgId,
    //   reporterId: chosenEvidence.cxItem?.contactId,
    //   priority: defaultRequestPriority, 
    //   // committedDate: textItem.committed,
    //   textId: textItem?._id,
    //   businessName: textItem?.businessName,
    //   itemId: chosenEvidence?.parentId,
    //   ownerId: idea?.ownerId,
    //   sourceOfAssignment: "search",
    // };
    ideaId &&
    validate({ideaId, sourceOfAssignment:'search'})
      .then(({ success, evidence }) => {
        console.log("validate evidence ToIdea", {evidence});
        reCalculatPerspectiveForRequest( evidence, "add");
        setAddEvidenceFormScreen(false);
        setCardView(false);
        setChosenEvidence({});
        loadEvidence();
        setTriggerImpact(!triggerImpact)
      })
      .catch((e) => console.log(e))
      .finally(() => {
        navToPerspective();
        setActiveSearch(false);
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        // setLoading(false);
      });

  }

  const handleAttachFromSearch = (chosenEvidence: any) => {

    
    if (
      // Checking if this text item is already assigned to a request and idea for verification step
      // assignedRequest && 
      // assignedIdea &&
      // assignedRequest?.state === "validated"   
      chosenEvidence.ideaId
    ) 
    {  // verification step
      setVerifyOpened(true)
    }
    else{
      // assign directly if not already assigned
      handleTextItemAssignment(chosenEvidence)
    }
  };
  
  const submitNewIdea = (body: any) => {
    assignRequestToNewIdea(body, auth0)
      .then((response) => {
        const ideaId = response.createNewIdeaRes?.idea?._id;
  
        showNotification({
          title: "Assigned Successfully!",
          color: "teal",
          message: (
            <Box>
              <Text>New idea has been created.</Text>
              {ideaId && (
                <Anchor href={`/idea/${ideaId}`} target="_blank">
                  Go to the new idea
                </Anchor>
              )}
            </Box>
          ),
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });
  
        if (ideaId) {
          setTimeout(() => {
            window.open(`/idea/${ideaId}`);
          }, 1300);
        } else {
          console.log("Did not receive an idea ID for the new idea", response);
        }
      })
      .catch((error) => {
        console.error("Error creating idea:", error);
        showNotification({
          title: "Error Creating Idea",
          color: "red",
          message: "An error occurred while creating the idea. Please try again.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
      });
  };
  
  const createNewIdea = () => {
    const icon = <IconX size="1.1rem" />
    const foundComponent = validateComponentId(componentId, allComponents, icon);
    if (!foundComponent) return;
  
    const newIdea = buildNewIdeaObject( foundComponent.ownerId, chosenEvidence, componentId, defaultStatus, defaultPriority );
    submitNewIdea(newIdea);
  };
  
  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      {opened && (
        <Modal
          ml="30vw"
          opened={opened}
          onClose={() => setOpened(false)}
          yOffset={123}
          size="34vw"
          title={
            <Text fz={20} fw={600}>
              Assign to another idea
            </Text>
          }
        >
          <Box h={440}>
            <MatchReAssignment
              idea={idea}
              item={chosenEvidence}
              setOpened={setOpened}
              navToPerspective={navToPerspective}
              TextInputW="100%"
              BoxW="38vw"
            />
          </Box>
        </Modal>
      )}
      {/* Verifcation modal that handles reassignment of text item to current idea */}
      {verifyOpened && <Modal
        yOffset={150}
        opened={verifyOpened}
        onClose={() => setVerifyOpened(false)}
        h='40vw'
        size='32vw'
      >
        <Stack mx='md' mb={5}>
          <Stack  mb={5}> 
            <Text fz={14} mr={5} lineClamp={1}>
                        This Evidence is already assigned to another Idea:
            </Text>
            <Anchor  href={`/idea/${ chosenEvidence?.idea?._id}`} target="_blank">
              <Tooltip disabled={!chosenEvidence?.idea?.title} label={chosenEvidence?.idea?.title}>
                <Text fz={14} color="indigo" weight={600} lineClamp={1}>
                  {chosenEvidence?.idea?.title}
                </Text>
              </Tooltip> 
            </Anchor>
          </Stack>
          <Text fz={14}>
                Would you like to reassign this Evidence to the current Idea?
          </Text>
                
          <Flex justify="space-around" style={{ width: '100%' }} mt={13}>
      
            <Button
              mx="md" 
              onClick={()=> setVerifyOpened(false)}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#DB2525",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#DB2525", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
            >
                    Cancel
            </Button>
      
            <Button
              mx="md" 
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={handleReassignRequestWithTextItem}
              loading={isLoading.validate} 
            >
                    Reassign
            </Button>
          </Flex>
        </Stack>
      
      </Modal>}


      {!validation && (<Flex align="center" w="107px" mr="12px" sx={{ cursor: "default" }}>
        <Box>
          <IconBell size={15} color="#D8D8DB" />
        </Box>

        <Text ml="4px" color="#D8D8DB" fz={12} lineClamp={1}>
          Activity
        </Text>
      </Flex>)}
      <Button.Group>
        <Tooltip
          label="Assign to this Idea"
          multiline
          maw={200}
          offset={12}
          position="left"
        >
          <Button
            fz="12px"
            color="indigo"
            w="140px"
            loading={isLoading.validate}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: "0px 5px",
                margin: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5353D3", 0.05),
                },
              },

              loader: {
                position: "absolute",
                color: theme.white,
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => {
              handleAttachFromSearch(chosenEvidence);
            }}
          >
            {!isLoading.validate && (
              <>
                <IconCircleCheck size={16} fill="white" color="#5C5CEB" />
                <Text ml={5} fw={500}>
                  Assign to this idea
                </Text>
              </>
            )}
          </Button>
        </Tooltip>
        {!validation && (
          <AssignActionsMenu
            actions={[
              {
                label: "Assign to another idea",
                onClick: () => setOpened(true),
              },
              {
                label: "Assign to a new idea",
                tooltip: "Assign to a new idea",
                onClick: createNewIdea,
              },
            ]}
          />
        )}
      </Button.Group>
    </Flex>
  );
};

export default SearchActions;