// src/components/AssignIdeaSearch.tsx

import React, { FC, useState, useEffect, useContext } from "react";
import {
  TextInput,
  ActionIcon,
  Box,
  Title,
  Text,
  Stack,
  Button,
  Card,
  Group,
  Skeleton,
  Anchor,
  createStyles,
  Checkbox,
} from "@mantine/core";
import { IconCheck, IconSearch, IconX } from "@tabler/icons";
import { useSuggestionMatches } from "../../../../hooks/useSuggestionMatches";
import { IdeaCard } from "../Content/IdeaCard";
import { SparklesIcon } from "../../../../icons/x-symbol-svgrepo-com";
import useApiSearch from "../../../../hooks/useApiSearch";
import { closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { request } from "http";
import { assignRequestToNewIdea } from "../../../../Api";
import SyncContext from "../../../../context/SyncContext";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { EvidenceModalType } from "..";
import { getBagelId } from "../../../../utils";

export const useStyles = createStyles(() => ({
  textBWAnimation: {
    // Create a gradient that includes black, gray, your brand color (#5C5CEA), then gray, then black
    backgroundImage:
      "linear-gradient(to right, #000, #666,#c8c8ff, #666, #000)",
    backgroundSize: "400% 100%",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",

    animation: "bwBrandAnimation 15s linear infinite",

    "@keyframes bwBrandAnimation": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "100%": {
        backgroundPosition: "-800% 50%",
      },
    },
  },
}));

const AssignIdeaSearch: FC<EvidenceModalType> = ({
  onAction,
  evidence,
  setEvidence,
  evidencesHandlers,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingNew, setLoadingNew] = useState(false);
  const [onlyMyIdeas, setOnlyMyIdeas] = useState(false);
  const auth0 = useAuth0();
  const { classes } = useStyles();
  const userId = getBagelId(auth0.user);
  console.log("userIddd", userId);

  console.log({ evidenceUp: evidence });

  const { itemMatches, loading: matchesLoading } = useSuggestionMatches({
    evidence,
    evidenceId: evidence?._id,
  });
  const { defaultPriority, defaultStatus, allComponents } =
    useContext(SyncContext);
  const { searchIdeas, data, loading, resetResults } = useApiSearch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery !== "") {
      searchIdeas(searchQuery, onlyMyIdeas ? userId : undefined);

    }
  }, [searchQuery, onlyMyIdeas]);

  const handleClear = () => {
    setSearchQuery("");
    setOnlyMyIdeas(false)
    resetResults("ideas");
  };

  const createNew = () => {
    const newIdeaObj = {
      title: evidence?.title,
      description: evidence?.text,
      componentId: evidence?.componentId,
      ownerId: null,
      rolloutDate: null,
      status: defaultStatus,
      priority: defaultPriority,
    };

    const _body = {
      idea: newIdeaObj,
      evidence: { ...evidence },
    };

    // Check if a product area has been selected
    if (!evidence.componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message: "Please select a product area before adding evidence.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // Find the product area by componentId
    const foundComponent = allComponents.find(
      (c) => c._id === evidence.componentId
    );

    // If no matching component is found, show an error
    if (!foundComponent) {
      showNotification({
        title: "Invalid Product Area",
        color: "red",
        message: "Please select a valid product area before adding evidence.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // If a valid product area is found, assign its ownerId to the idea and evidence objects
    _body.idea.ownerId = foundComponent.ownerId;
    _body.evidence.ownerId = foundComponent.ownerId;

    setLoadingNew(true);

    // Submit the new idea request
    assignRequestToNewIdea(_body, auth0)
      .then((response) => {
        const ideaId = response.createNewIdeaRes?.idea?._id;
        evidencesHandlers?.filter((evi: any) => evi._id !== evidence._id);

        showNotification({
          title: "Idea Created Successfully!",
          color: "teal",
          message: (
            <Box>
              <Text>A new idea has been created.</Text>
              {ideaId && (
                <Anchor href={`/idea/${ideaId}`} target="_blank">
                  View the Idea
                </Anchor>
              )}
            </Box>
          ),
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });

        if (ideaId) {
          setTimeout(() => {
            window.open(`/idea/${ideaId}`);
          }, 1300);
        } else {
          console.log("Did not receive an idea ID for the new idea", response);
        }
      })
      .catch((error) => {
        console.error("Error creating idea:", error);
        showNotification({
          title: "Error Creating Idea",
          color: "red",
          message:
            "An error occurred while creating the idea. Please try again.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
      })
      .finally(() => {
        onAction()
        setLoadingNew(false);
        navigate("/inbox");
        setTimeout(() => {
          closeAllModals();
        }, 1300);
      });
  };

  return (
    <Box p="sm">
      <Box
        sx={{
          background: "#F5F6FE",
          padding: "1rem",
          paddingBottom: 0,
          borderRadius: "8px",
          overflowY: "scroll",
          overflowX:'unset'
        }}
        mah={"60vh"}
      >
        <Title order={5} mb="xs">
          Assign Idea
        </Title>
        <TextInput
          placeholder="Search idea"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          icon={<IconSearch size={18} />}
          rightSection={
            searchQuery.trim() !== "" ? (
              <ActionIcon onClick={handleClear} aria-label="Clear search">
                <IconX size={18} />
              </ActionIcon>
            ) : null
          }
          mb="xs"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // Optionally handle enter key
            }
          }}
        />
        <Checkbox
          label="Only search Ideas I own"
          color="indigo"
          checked={onlyMyIdeas}
          onChange={(event) => setOnlyMyIdeas (event.currentTarget.checked)}
          mb="xs"
        />

        {loading.ideas &&
          Array.from({ length: 2 }).map((_, index) => (
            <Skeleton
              sx={{ border: "1px solid #dee2e6" }}
              mb="sm"
              mt={8}
              height={120}
              width={"100%"}
              key={index}
            />
          ))}
        {/* Search Results */}
        {data.ideas.length > 0 && (
          <Stack spacing="sm" mb="sm">
            {data.ideas.map((idea, index) => (
              <IdeaCard
                key={idea.id || index}
                onAction={onAction}
                evidence={evidence}
                setEvidence={setEvidence}
                evidencesHandlers={evidencesHandlers}
                idea={idea}
                showRolloutDate
              />
            ))}
          </Stack>
        )}

        {evidence?.gtmIdea?._id && (
          <Box my="sm">
            <Text size="xs" mb="xs" fw={500}>
              Suggested by GTM
            </Text>

            <IdeaCard
              key={evidence.gtmIdea._id}
              onAction={onAction}
              evidence={evidence}
              setEvidence={setEvidence}
              evidencesHandlers={evidencesHandlers}
              idea={evidence.gtmIdea}
              showRolloutDate
            />
          </Box>
        )}
        <Group spacing={8} mb="xs">
          <SparklesIcon color={"#5B60E3"} />
          <Text
            size="xs"
            fw={500}
            pt={5}
            className={matchesLoading ? classes.textBWAnimation : undefined}
          >
            {matchesLoading
              ? 'Finding ideas that match this evidence...'
              : 'Suggested by Bagel'}          
          </Text>
        </Group>

        {matchesLoading &&
          Array.from({ length: 1 }).map((_, index) => (
            <Skeleton
              sx={{
                border: "1px solid #dee2e6",
                "::after": {
                  background: "#5c5ceb30",
                },
              }}
              mt={8}
              mb="sm"
              height={120}
              width={"100%"}
              color="#5C5CEB"
              key={index}
            />
          ))}
        {/* Suggestions Dropdown */}
        {itemMatches.length > 0 ? (
          <Stack spacing="sm" mb="sm" mt={8}>
            {itemMatches.map((matche: any) => (
              <IdeaCard
                key={matche.ideaId}
                onAction={onAction}
                evidence={evidence}
                setEvidence={setEvidence}
                evidencesHandlers={evidencesHandlers}
                idea={matche.idea}
                matchId={matche._id}
                showRolloutDate
              />
            ))}
          </Stack>
        ) : (
          !matchesLoading && (
            <Card
              withBorder
              radius="sm"
              sx={{ borderColor: "#EAEAFE" }}
              mb="sm"
            >
              <Text size="sm">No suggestions found</Text>
            </Card>
          )
        )}
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#F5F6FE",
            paddingBottom: "1rem",
          }}
        >
          <Button
            fullWidth
            radius="md"
            onClick={createNew} //createNew
            loading={loadingNew}
            styles={(theme) => ({
              root: {
                backgroundColor: "#EAEAFE",
                border: 0,
                height: 35,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#EAEAFE", 0.05),
                },
              },
              leftIcon: {
                marginRight: 15,
              },
            })}
            sx={{
              border: "1px solid #5C5CEB",
              color: "#5C5CEB",
            }}
          >
            + Assign to new idea
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AssignIdeaSearch;
