import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  ScrollArea,
  Table,
  Text,
  createStyles,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { IconGripVertical, IconPencil, IconTrash } from "@tabler/icons";
import "./CheckboxStyles.css";
import FormModal from "./FormModal";
import ReassignValueModal from "./ReassignValueModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { listObjectsFields } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../context/SyncContext";
import EditStatusFormModal from "./EditStatusFormModal";

const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    width: "rem(40)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
  },
}));

function Status() {
  const auth0 = useAuth0();
  const { getFields } = useContext(SyncContext);
  const [field, setField] = useState<any | null>(null);
  const fieldId = field?._id;
  const { height } = useElementSize();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [objectCount, setObjectCount] = useState(field?.options?.length);
  const [addValueLoader, setAddValueLoader] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [opened, setOpened] = useState(false);
  const [optionToEdit, setOptionToEdit] = useState(null);
  const [currentKey, setCurrentKey] = useState<null | string>(null);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const { classes } = useStyles();
  const [dragOptions, setDragOptions] = useState(field?.options);
  const [dragDeliveryStatus, setDragDeliveryStatus] = useState(field?.deliveryStatus);
  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState(null);
  const [editStatusForm, setEditStatusForm] = useState(false);
  const getField = async () => {
    await listObjectsFields("status", auth0).then((res) => {
      const sorted = res?.options.sort((a: any, b: any) => a.index - b.index);
      res.options = sorted;
      setField(res);
    });
  };

  useEffect(() => {
    getField();
  }, []);

  const handleAddValue = () => {
    setAddValueLoader(true);
    setEditForm(false);
    setOpened(true);
    // axios dealt with in the FormModal.tsx
  };

  const handleEditValue = async (key: string) => {
    console.log({ handleEditValue: key });
    setOptionToEdit(field?.options?.find((o: any) => o.key === key));
    setEditForm(true);
    setCurrentKey(key);
    setOpened(true);
    getFields?.();
    // axios dealt with in the FormModal.tsx
  };

  const handleEditStatusValue = async (option: any) => {
    setEditStatusForm(true);
    setCurrentDeliveryStatus(option);
  };

  const handleRemoveValue = async (fieldId: string, key: string) => {
    setCurrentKey(key);
    setOpenReassignModal(true);
    // axios dealt with in the ReassignValueModal.tsx
  };

  const rows =
    field?.options &&
    dragOptions &&
    dragOptions.map((option: any, index: number) => (
      <Draggable key={option.key} index={index} draggableId={option.key}>
        {(provided: any) => (
          <tr
            className={classes.item}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <td width={"30px"}>
              <div className={classes.dragHandle} {...provided.dragHandleProps}>
                <IconGripVertical size="1.05rem" stroke={1.5} />
              </div>
            </td>
            <td width={"150px"} style={{ textAlign: "left" }}>
              {option.label}
            </td>
            <td width={"60px"}>
              {option.isDefault === true ? (
                <SourceIcon sourceName="tick" />
              ) : null}
            </td>
            <td width={"60px"}>
              {option.isDefaultDelivery === true ? (
                <SourceIcon sourceName="tick" />
              ) : null}
            </td>
            <td width={"120px"}>{option.category}</td>
            <td width={"150px"}>
              <Text
                sx={{
                  backgroundColor: option.color,
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid grey",
                }}
                mx={"auto"}
              ></Text>
            </td>
            <td width={"120px"} style={{ textAlign: "left" }}>
              <Group noWrap position="right">
                <Button
                  variant="subtle"
                  color="dark"
                  aria-label="Edit"
                  leftIcon={<IconPencil size={16} />}
                  onClick={() => handleEditValue(option.key)}
                >
                  Edit
                </Button>
                {/* {option.key !== "New" && option.key !== "In review" && option.key !== "Backlog" && ( */}
                <Button
                  title={
                    (option?.isDefault || option?.isDefaultDelivery)
                      ? "default cannot be deleted"
                      : "Edit status"
                  }
                  variant="subtle"
                  color="dark"
                  disabled={(option?.isDefault || option?.isDefaultDelivery)}
                  leftIcon={<IconTrash size={16} />}
                  aria-label="Remove"
                  onClick={() => handleRemoveValue(fieldId, option.key)}
                >
                  Remove
                </Button>
              </Group>
              {/* )} */}
            </td>
          </tr>
        )}
      </Draggable>
    ));

  const rowsForStatus =
    field?.deliveryStatus &&
    dragDeliveryStatus &&
    dragDeliveryStatus.map((option: any, index: number) => (
      <Draggable key={option.key} index={index} draggableId={option.key}>
        {(provided: any) => (
          <tr
            className={classes.item}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            {/* <th style={{ width: "30px", padding: "12px" }}></th> */}
            <td width={"150px"} style={{ textAlign: "center" }}>
              {option.name}
            </td>
            <td width={"120px"} style={{ textAlign: "center" }}>{option.label}</td>
            <td width={"60px"} style={{ textAlign: "center" }}>
              {option.marked === true ? (
                <SourceIcon sourceName="tick" />
              ) : null}
            </td>
            <td width={"150px"} style={{ textAlign: "center" }}>
              <Text
                sx={{
                  backgroundColor: option.color,
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid grey",
                }}
                mx={"auto"}
              ></Text>
            </td>
            <td width={"120px"} style={{ textAlign: "center" }}>
              <Group noWrap >
                <Button
                  variant="subtle"
                  color="dark"
                  aria-label="Edit"
                  leftIcon={<IconPencil size={16} />}
                  onClick={() => handleEditStatusValue(option)}
                >
                  Edit
                </Button>
              </Group>
            </td>
          </tr>
        )}
      </Draggable>
    ));

  useEffect(() => {
    setLoading(true);
    if (field && field.options) {
      setLoading(false);
    }
  }, [field]);

  useEffect(() => {
    setObjectCount(field?.options?.length);
    setDragOptions(field?.options);
    setDragDeliveryStatus(field?.deliveryStatus);
  }, [field?.options, field?.deliveryStatus]);

  if (loading) {
    return <Loader variant="dots" mt={"lg"} ml={"lg"} />;
  }

  return (
    <Box
      p={"xl"}
      mt={7}
      bg={"#F1F3F5"}
    >
      {currentKey && (
        <ReassignValueModal
          openReassignModal={openReassignModal}
          setOpenReassignModal={setOpenReassignModal}
          options={field.options}
          currentOption={
            currentKey && field.options?.find((o: any) => o.key === currentKey)
          }
          currentKey={currentKey}
          fieldId={fieldId}
          key={fieldId}
          type="status"
        />
      )}

      {editForm ? (
        currentKey &&
        optionToEdit && (
          <Modal
            centered
            opened={opened}
            onClose={() => setOpened(false)}
            overlayOpacity={0.55}
            overlayBlur={3}
          >
            <FormModal
              dragOptions={dragOptions}
              editForm={editForm}
              opened={opened}
              setOpened={setOpened}
              options={field?.options}
              currentKey={currentKey}
              optionToEdit={optionToEdit}
              fieldId={fieldId}
              type="status"
            />
          </Modal>
        )
      ) : (
        <Modal
          centered
          opened={opened}
          onClose={() => setOpened(false)}
          overlayOpacity={0.55}
          overlayBlur={3}
        >
          <FormModal
            dragOptions={dragOptions}
            editForm={editForm}
            opened={opened}
            setOpened={setOpened}
            options={field?.options}
            fieldId={fieldId}
            objectCount={objectCount}
            setObjectCount={setObjectCount}
            setAddValueLoader={setAddValueLoader}
            addValueLoader={addValueLoader}
            type="status"
          />
        </Modal>
      )}
      {
        editStatusForm && (
          <EditStatusFormModal
            opened={editStatusForm}
            setOpened={setEditStatusForm}
            currentDeliveryStatus={currentDeliveryStatus}
            setAddValueLoader={setAddValueLoader}
            addValueLoader={addValueLoader}
            getField={getField}
            allDeliveryStatuses={dragDeliveryStatus}
          />
        )
      }
      <Paper mt={"lg"} shadow={"sm"} withBorder>
        <Grid p={0} m={0}>
          <Grid.Col span={2} p={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"}>
              General
            </Text>
            <Text size={"sm"} mt={"sm"} mb={"sm"} c="dimmed">
              Field level settings
            </Text>
          </Grid.Col>
          <Divider size="xs" orientation="vertical" />
          <Grid.Col span={"auto"} p={"xl"}>
            <Grid>
              <Grid.Col span={2}>
                <Text color={"#161832"} weight={"500"}>
                  Display name
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text color={"#161832"} weight={"500"}>
                  Status
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={2}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Type
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Single selection
                </Text>{" "}
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={2}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Description
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Idea status for workflow management
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col
                span={4}
                mt={"md"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={isChecked}
                  // onChange={handleCheckboxChange}
                  disabled
                />
                <Text ml={"md"}>Mandatory field</Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Paper>
      <Paper mt={"lg"} withBorder>
        <Grid align="flex-start" m={0}>
          <Grid.Col span={2} px={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"} mt={"md"}>
              Field values
            </Text>
          </Grid.Col>
        </Grid>

        <ScrollArea>
          <DragDropContext
            onDragEnd={({ destination, source }: any) =>
              field?.options.reorder({
                from: source.index,
                to: destination?.index || 0,
              })
            }
          >
            <Table
              highlightOnHover
              sx={{ "& tbody tr td": { borderBottom: 0 } }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f3f3f3" }}>
                  <th style={{ width: "30px", padding: "12px" }}></th>
                  <th style={{ width: "150px", padding: "12px" }}>
                    Display value
                  </th>
                  <th
                    style={{
                      width: "60px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Default (New)
                  </th>
                  <th
                    style={{
                      width: "60px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Default (Delivery)
                  </th>
                  <th
                    style={{
                      width: "120px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Category
                  </th>
                  <th
                    style={{
                      width: "150px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Color
                  </th>
                  <th style={{ width: "120px", padding: "12px" }}></th>
                </tr>
              </thead>
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided: any) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {rows}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </ScrollArea>

        <Box
          px="xl"
          py="md"
          sx={{
            borderTop: "1px solid #dee2e6",
          }}
        >
          <Button
            bg={"#5C5CEA"}
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
              "&[disabled]": {
                pointerEvents: "all",
                backgroundColor: "#fff",
                cursor: "not-allowed",
                ":hover": {
                  backgroundColor: "#fff",
                },
              },
            }}
            color={"#fff"}
            onClick={handleAddValue}
            disabled={objectCount >= 10}
          >
            {!addValueLoader ? (
              "+ Add Value"
            ) : (
              <Loader color="violet" variant="dots" />
            )}
          </Button>
        </Box>
      </Paper>
      <Box mt={"lg"}>
        <Text size={"xl"} fw={600}>Delivery Statuses</Text>
        <Text>
          Delivery statuses are retrieved automatically from the product delivery system ( Jira, Clickup, Monday etc.) .You can define here how they will show up in Bagel's applications 
        </Text>
      </Box>
      <Paper mt={"lg"} withBorder>
        <Grid align="flex-start" m={0}>
          <Grid.Col span={2} px={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"} mt={"md"}>
              Field values
            </Text>
          </Grid.Col>
        </Grid>

        <ScrollArea>
          <DragDropContext
            onDragEnd={({ destination, source }: any) =>
              field?.deliveryStatus.reorder({
                from: source.index,
                to: destination?.index || 0,
              })
            }
          >
            <Table
              highlightOnHover
              sx={{ "& tbody tr td": { borderBottom: 0 } }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f3f3f3" }}>
                  {/* <th style={{ width: "30px", padding: "12px" }}></th> */}
                  <th style={{ width: "150px", padding: "12px",  textAlign: "center"}}>
                    Delivery Status Name
                  </th>
                  <th
                    style={{
                      width: "60px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Display Name in GTM apps
                  </th>
                  <th
                    style={{
                      width: "150px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Marked in Timeline Graphs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      margin: "auto",
                      textAlign: "center",
                      padding: "12px",
                    }}
                  >
                    Color
                  </th>
                  <th style={{ width: "120px", padding: "12px" }}></th>
                </tr>
              </thead>
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided: any) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {rowsForStatus}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </ScrollArea>
      </Paper>
    </Box>
  );
}

export default Status;
