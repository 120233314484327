import React, { useState, useEffect } from "react";
import { Box, Group } from "@mantine/core";
import { ViewsMenu } from "./filters/ViewsMenu";
import IdeasTab from "./IdeasTab";
import { useLocation } from "react-router-dom";

const TAB_WIDTH = 80;

const IdeasTabs = ({
  savedViews,
  selectedView,
  setSelectedView,
  upsertViews,
  containerRef,
}: {
  savedViews: any;
  selectedView: any;
  setSelectedView: (value: any) => void;
  upsertViews: (body: any, func: (res: any) => void) => void;
  SplitOn: number;
  containerRef: React.MutableRefObject<null>;
}) => {
  const [splitOn, setSplitOn] = useState(savedViews.length);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _selectedView = queryParams.get("selectedView");

  const [bagelAiView, setBagelAiView] = useState<any>({});
  const [views, setViews] = useState([]);

  useEffect(() => {
    const filteredViews = savedViews.filter(
      (savedView: any) => savedView.name?.toLowerCase() !== "Bagel AI".toLowerCase()
    );
    setViews(filteredViews);
    const bagelAi = savedViews.find(
      (savedView: any) => savedView.name?.toLowerCase() === "Bagel AI".toLowerCase()
    );
    setBagelAiView(bagelAi);
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const containerWidth = entry.contentRect.width;
        const maxTabs = Math.floor(containerWidth / TAB_WIDTH);
        setSplitOn(Math.max(1, Math.min(maxTabs, savedViews.length)));
      }
    });
    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, [savedViews]);

  return (
    <Box>
      <Group spacing={0}>
        {views.slice(0, splitOn).map((view: any) => (
          <IdeasTab
            key={view._id}
            view={view}
            isActive={view.name === _selectedView}
            onTabChange={(item_id) => {
              const newView = savedViews.find(
                (view: any) => view._id === item_id
              );
              setSelectedView(newView);
            }}
            upsertViews={upsertViews}
            isGenerated={false}
          />
        ))}
        {savedViews.length > splitOn && (
          <ViewsMenu
            savedViews={savedViews.slice(splitOn)}
            selectedView={savedViews.find(
              (sv: any) => sv?.name?.toLowerCase() == _selectedView?.toLowerCase()
            )}
            setSelectedView={setSelectedView}
            upsertViews={upsertViews}
          />
        )}
        {bagelAiView && (
          <Box ml={70}>
            <IdeasTab
              key={bagelAiView._id}
              view={bagelAiView}
              isActive={bagelAiView?.name?.toLowerCase() === _selectedView?.toLowerCase()}
              onTabChange={(item_id) => {
                const newView = savedViews.find(
                  (view: any) => view._id === item_id
                );
                setSelectedView(newView);
              }}
              upsertViews={upsertViews}
              isGenerated={true}
            />
          </Box>
        )}
      </Group>
    </Box>
  );
};

export default IdeasTabs;
