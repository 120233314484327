import {
  Box,
  Button,
  Flex,
  Menu,
  ScrollArea,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import IdeaContext from "../../IdeaContext";
import BagelAutocomplete from "../../../general/BagelAutocomplete";
import { useAuth0 } from "@auth0/auth0-react";
import { saveRequest } from "../../../../Api";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import React from "react";
import { formatNumberValue } from "../../Funcs";
import { getNestedFieldValue } from "../../helpers/calculateImpactResults";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";
import { updateEvidence } from "../../../../api/evidences";

// Function to evaluate displayIf condition
const evaluateDisplayIf = (data: any, displayIf: any): boolean => {
  if (!displayIf) return true;

  const value = getNestedFieldValue(data, displayIf.fieldPath);
  switch (displayIf.operator) {
  case "exists":
    return (
      value !== null && value !== undefined && value !== "" && value !== "N/A"
    );
  case "equals":
    return value === displayIf.value;
  case "notEquals":
    return value !== displayIf.value;
  default:
    return true;
  }
};

const CardInfo = ({ item,
  ideaPage,
  isInAccordian,
  activeTab, 
  setActiveTab,
  setDetailsOpened, 
  componentId,
  setComponentId
}
       :
  { item: any;
    ideaPage: boolean,
    isInAccordian: boolean,
    activeTab: any,
    setActiveTab: any,
    setDetailsOpened: (isOpen: boolean) => void,
    componentId: string,
    setComponentId: React.Dispatch<React.SetStateAction<any>>,
            }
) => {
  // console.log("CardInfo",{item})
  const auth0 = useAuth0();
  const {
    components,
    evidenceDomain,
    domains,
    allComponents,
    owners,
    opportunityAdditionalInfo,
    accountAdditionalInfo,
    ideaInfoTabsConfig,
  } = useContext(SyncContext);

  const ContextToUse: React.Context<any> = ideaPage
    ? IdeaContext
    : DiscoveryContext;

  const { idea, loadEvidence } = useContext(ContextToUse);
  const [domain, setDomain] = useState<any>(
    evidenceDomain ? item?.componentObj?.domain : null
  );
  const [productAreaName, setProductAreaName] = useState(
    item?.componentObj?.name
  );
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(
    evidenceDomain ? [] : components
  );
  const [owner, setOwner] = useState(item?.owner);
  const [ownerName, setOwnerName] = useState(item?.owner?.name);
  const [ownerId, setOwnerId] = useState(item?.owner?.id);
  const domainsNames = domains.map((d: any) => d.name);
  const isMatch: boolean = item?.match;
  const company = item?.company;

  const existingAdditionalFieldsAccount = accountAdditionalInfo
    ? Object.keys(accountAdditionalInfo).filter((key) => company?.[key])
    : [];
  const existingAdditionalFieldsOpportunity = opportunityAdditionalInfo
    ? Object.keys(opportunityAdditionalInfo).filter((key) => company?.[key])
    : [];

  const onSelectSave = (type: string, value: string, ownerId = null) => {
    let updatedValue = value;

    if (type === "componentId") {
      const found = allComponents.find((a: any) => a._id === value);
      updatedValue = found?.name;
    }

    if (item) {
      const itemObj = {
        id: item?._id,
        [type]: value,
      };
      if (ownerId) {
        itemObj["ownerId"] = ownerId;
      }

      updateEvidence(item?._id, {componentId: value}, auth0)
        .then(() => {
          showNotification({
            title: `${
              type === "componentId"
                ? "Product area"
                : type === "priority"
                  ? "Priority"
                  : "Domain"
            } updated`,
            color: "teal",
            message: `${
              type === "componentId"
                ? "Product area"
                : type === "priority"
                  ? "Priority"
                  : "Domain"
            } updated to "${updatedValue}" successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200,
          });
          loadEvidence();
        })
        .catch((e: any) => console.log(e));
    }
  };

  const saveProductArea = (productAreaName: string, componentId: string) => {
    setProductAreaName(productAreaName);
    setComponentId(componentId);
  };

  const copy = [...allComponents];
  const allComponentsIds = copy.map((c: any) => c._id);
  const filterComponentByDomain = (domainValue: string) => {
    const arr: any[] =
      components.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const filtered =
      allComponents.filter((s: any) => s.domain === domainValue) || [];
    const ids = filtered.map((a: any) => a._id);
    if (!ids.includes(componentId)) {
      setProductAreaName(null);
      setComponentId(null);
    }
  };

  const editOwner = (ownerObj: any, notification?: boolean) => {
    const itemObj = {
      id: item?._id,
      ownerId: ownerObj.id,
    };

    saveRequest(itemObj, auth0).then(() => {
      if (notification) {
        showNotification({
          title: "Owner updated",
          color: "teal",
          message: `Request owner updated to "${ownerObj?.name}" successfully`,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });
      }
      loadEvidence();
    });
  };

  const handleSelect = (item: any) => {
    setProductAreaName(item.name);
    saveProductArea(item.name, item._id);
    onSelectSave("componentId", item._id, item.ownerId);
  };

  useEffect(() => {
    setActiveTab("details");
    setOwnerName(item?.owner?.name);
    setOwner(item?.owner);

    if (!productAreaName || productAreaName === "Unassigned") {
      if (item?.origin === "salesforce" && idea?.componentId) {
        setProductAreaName(idea?.componentObj?.name);
        evidenceDomain && filterComponentByDomain(idea?.componentObj?.domain);
      }
    }
    if (evidenceDomain && allComponentsIds.includes(item?.componentObj?._id)) {
      filterComponentByDomain(item?.componentObj?.domain);
    }
    if (
      evidenceDomain &&
      domain &&
      !allComponentsIds.includes(item?.componentObj?._id)
    ) {
      filterComponentByDomain(domain);
    } else {
      setComponentId(item?.componentObj?._id);
      setProductAreaName(item?.componentObj?.name);
    }
  }, [item]);

  // Generate tabs and content based on ideaInfoTabsConfig
  const tabsConfig = ideaInfoTabsConfig || {};
  const tabKeys = Object.keys(tabsConfig);

  // Filter tabs based on displayIf condition
  const filteredTabs = tabKeys.filter((tabKey) => {
    const tab = tabsConfig[tabKey];
    return evaluateDisplayIf(item, tab.displayIf);
  });

  return (
    <Tabs value={activeTab} onTabChange={(value) => {
      if (isInAccordian) {
        setDetailsOpened(true);
        setTimeout(() => setActiveTab(value), 0); 
      } else {
        setActiveTab(value);
      }
    }}>
      {
        isInAccordian && 
        <Tabs.List>
          {/* Hardcoded Details Tab */}
          <Tabs.Tab value="details" sx={{padding: "16.6px"}}>
            <Text
              ta="left"
              fz={12}
              fw={600}
              color={activeTab === "details" ? "#212529" : "#5C5CEB"}
            >
              Details
            </Text>
          </Tabs.Tab>

          {/* Dynamically Generated Tabs */}
          {filteredTabs.map((tabKey) => {
            const tab = tabsConfig[tabKey];
            return (
              <Tabs.Tab key={tabKey} value={tabKey}>
                <Text
                  ta="left"
                  fz={12}
                  fw={600}
                  color={activeTab === tabKey ? "#212529" : "#5C5CEB"}
                >
                  {tab.label}
                </Text>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>
      }
      
      {
        !isInAccordian && 
        <>
          {/* Details Tab Panel */}
          <Tabs.Panel value="details" >
            <Box mt={12}>
              {/* Domain */}
              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Domain
                </Text>
                <Menu>
                  <Menu.Target>
                    <Tooltip hidden={item?.match?._id} label="Select domain">
                      <Flex align="center">
                        <Button variant="subtle" compact color="gray">
                          <Text fz={12} color="#000">{domain || "Unassigned"}</Text>
                        </Button>
                      </Flex>
                    </Tooltip>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {domainsNames.map((domainItem: string) => (
                      <Menu.Item
                        w="180px"
                        key={domainItem}
                        onClick={() => {
                          setDomain(domainItem);
                          const domainObject = domains.find((d: any) => {
                            return d.name == domainItem;
                          });
                          if (domainObject?.ownerId) {
                            setOwnerName(domainObject.ownerName);
                            setOwner(domainObject.ownerId);
                          }
                          filterComponentByDomain(domainItem);
                        }}
                      >
                        <Text fz={12}>{domainItem || "Unassigned"}</Text>
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
              </Flex>

              {/* Product Area */}
              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Product area
                </Text>
                <BagelAutocomplete
                  items={
                    evidenceDomain
                      ? components.filter((s: any) => s.domain === domain)
                      : components
                  }
                  selected={productAreaName}
                  onSelect={(item: any) => {
                    setProductAreaName(item?.name);
                    if (item.ownerId) {
                      setOwnerName(item?.ownerName);
                      setOwnerId(item.ownerId);
                    }
                    handleSelect(item);
                  }}
                  disabled={(!domain && evidenceDomain)}
                >
                  <Tooltip
                    maw={200}
                    multiline={true}
                    // hidden={item?.match?._id} 
                    label={
                      ideaPage &&
                      isMatch &&
                      productAreaName &&
                      productAreaName !== idea?.componentObj?.name
                        ? "This product area was set manually by a Bagel user. It does not match the Product Area of the Idea."
                        : !domain && evidenceDomain
                          ? "Select domain first"
                          : "Select product area"
                    }
                  >
                    <Menu.Target>
                      <Flex
                        h="32px"
                        fz={12}
                        sx={{
                          cursor: "pointer",
                          opacity: !domain && evidenceDomain ? 0.5 : 1,
                        }}
                        align="center"
                      >
                        <Button variant="subtle" compact color="gray">
                          <Text fz={12} color={
                            ideaPage &&
                            isMatch &&
                            productAreaName &&
                            productAreaName !== idea?.componentObj?.name
                              ? "red"
                              : "black"
                          }>{productAreaName || "Unassigned"}</Text>
                        </Button>
                      </Flex>
                    </Menu.Target>
                  </Tooltip>
                </BagelAutocomplete>
              </Flex>

              {/* Owner */}
              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Owner
                </Text>
                <BagelAutocomplete
                  items={owners.map((member: any) => ({
                    name: member.name,
                    id: member._id,
                    useId: true,
                  }))}
                  selected={ownerId}
                  onSelect={(ownerItem: any) => {
                    setOwnerName(ownerItem?.name);
                    setOwnerId(ownerItem?.id);
                    setOwner(ownerItem);
                    editOwner(ownerItem, true);
                  }}
                  // disabled={item?.match?._id}
                >

                  <Tooltip label="Select owner"  >
                    <Button variant="subtle" compact color="gray">
                      <Text fz={12} color="black">{capitalizeFirstLetter(ownerName) || "Unassigned"}</Text>
                    </Button>
                  </Tooltip>
                        
                </BagelAutocomplete>
              </Flex>
            </Box>
          </Tabs.Panel>

          {/* Dynamically Generated Tabs Panels */}
          {filteredTabs.map((tabKey) => {
            const tab = tabsConfig[tabKey];
            return (
              <Tabs.Panel key={tabKey} value={tabKey}>
                <ScrollArea h={176} mah={176}>
                  <Box mt={12}>
                    {tab.properties.map((property: any) => {
                      let value = getNestedFieldValue(item, property.fieldPath);

                      if (value === undefined || value === null || value === "") {
                        value = "N/A";
                      } else if (typeof value === "number") {
                        value = formatNumberValue(value);
                      } else if (typeof value === "boolean") {
                        // Added boolean check
                        value = value ? "Yes" : "No";
                      }

                      return (
                        <Flex key={property.fieldPath} h={32} align="center">
                          <Text fz={12} h={16} w={120}>
                            {property.label}
                          </Text>
                          <Text fz={12}>{value}</Text>
                        </Flex>
                      );
                    })}
                  </Box>
                </ScrollArea>
              </Tabs.Panel>
            );
          })}
        </>
      }
    </Tabs>
  );
};

export default CardInfo;
