export const applyFilter = (
  ideas: any[],
  filter: any,
  components: any[]
): any[] => {
  const filterValues = Array.isArray(filter.value)
    ? filter.value.map((v: string) => v.toLowerCase())
    : [filter.value.toLowerCase()];
  
  if (filterValues.includes("all") || filterValues.length === 0) {
    return ideas;
  }
  
  if (filter.key === "domain") {
    // Use reduce to accumulate filtered component IDs
    const filteredComponentIds = components.reduce((acc: any[], comp: any) => {
      if (typeof comp.domain === "string" && filterValues.includes(comp.domain.toLowerCase())) {
        acc.push(comp.id || comp._id); // Add the component ID to the accumulator
      }
      return acc;
    }, []);
  
    return ideas.filter((idea: any) =>
      filteredComponentIds.includes(idea.componentId)
    );
  } else {
    return ideas.filter((idea: any) => {
      const ideaValue = idea[filter.key];
      if (typeof ideaValue === "string") {
        return filterValues.includes(ideaValue.toLowerCase());
      }
      return filterValues.includes(ideaValue);
    });
  }
};