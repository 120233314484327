import axios from "axios";
import { getAuthHeaders } from "../Api";

export interface CreateDomain {
  name: string;
  value?: string;
  ownerName?: string;
  ownerId?: string;
}

export interface UpdateDomain {
  name: string;
  value?: string;
  ownerName?: string;
  ownerId?: string;
}

const ROUTE_PATH = "domains";

export const fetchDomains = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createDomain = (auth0: any, body: { updateData: CreateDomain }) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}`,
        body,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
  
export const editDomain = (
  auth0: any,
  body: { updateData: UpdateDomain; domainId: string }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}/${body.domainId}`,
        body.updateData,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteDomain = (auth0: any, domainId: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.delete(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}/${domainId}`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchDomainToggle = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/${ROUTE_PATH}/toggle`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
}