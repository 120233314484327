import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Modal,
  Stack,
  Text,
  Tooltip,
  Anchor,
} from "@mantine/core";
import { IconBell, IconCheck, IconMenu2, IconCircleCheck, IconX, IconChevronDown } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import posthog from "posthog-js";
import SyncContext from "../../../../../../context/SyncContext";
import IdeaContext from "../../../../IdeaContext";
import {
  labelMatch,
  assignRequestToNewIdea,
  ideaValidation,
} from "../../../../../../Api";
import { isBagelAdmin } from "../../../../../../utils";
import AssignmentVerificationModal from "./AssignmentVerificationModal";
import BagelUsersActions from "./BagelUsersActions";

import useEvidenceActions from "../../../../../../hooks/useEvidenceActions";
import { MatchReAssignment } from "../MatchReAssignment";
import AssignActionsMenu from "../AssignActionsMenu";
import CommentsMenu from "../../Comment/CommentsMenu";

const MatchActions = ({ 
  navToPerspective, 
  componentId, 
  chosenEvidence, 
  validation,
  setideaValidationData,
  setMaturity,
  handlebannerText,
  setLoading,
  setValidationCount,
  setIsValidationDone,
}: { 
  navToPerspective: any, 
  componentId: string,
  chosenEvidence: any, 
  validation?: boolean,
  setideaValidationData?: any,
  setMaturity?: any,
  handlebannerText?: any,
  setLoading?: (loading: boolean) => void,
  setValidationCount: any;
  setIsValidationDone: any;
 }) => {
  const auth0 = useAuth0();

  const {
    loadEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,
    matches,
    setMatches
    
  } = useContext(IdeaContext);

  console.log('componentId')

  console.log(componentId)

  const { validate, dismiss, isLoading } = useEvidenceActions({ evidenceId: chosenEvidence?._id });
  const { defaultPriority, defaultStatus,allComponents } = useContext(SyncContext);

  const isBagelUser = isBagelAdmin(auth0?.user);
  // const [loadingAssign, setLoadingAssign] = useState(false);
  // const [loadingDismissed, setLoadingDismissed] = useState(false);
  const [labelingDisabaled, setLabelingDisabaled] = useState(
    chosenEvidence?.manualLabel === "sameRequest"
  );
  const [opened, setOpened] = useState(false);
  const [verifyOpened, setVerifyOpened] = useState(false);
  const assignedRequest = (chosenEvidence?.requests && chosenEvidence?.requests?.length > 0)
    ? chosenEvidence?.requests[0]
    : null;
  const assignedIdea = (chosenEvidence?.ideas && chosenEvidence?.ideas?.length > 0)
    ? chosenEvidence?.ideas[0]
    : null;
  const [isBagelUsersActionsOpened, setIsBagelUsersActionsOpened] = useState(false);

  const createNew = () => {
    const newIdeaObj = {
      title: chosenEvidence?.title,
      description: chosenEvidence?.text,
      componentId: componentId,
      ownerId: null,
      rolloutDate: null,
      status: defaultStatus,
      priority: defaultPriority,
    };

    const _body = {
      idea: newIdeaObj,
      evidence: { ...chosenEvidence },
    };

    // Check if a product area has been selected
    if (!componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // Find the product area by componentId
    const foundComponent = allComponents.find(
      (c) => c._id === componentId
    );

    // If no matching component is found, show an error
    if (!foundComponent) {
      showNotification({
        title: "Invalid Product Area",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // If a valid product area is found, assign its ownerId to the idea and evidence objects
    _body.idea.ownerId = foundComponent.ownerId;
    _body.evidence.ownerId = foundComponent.ownerId;

    // Submit the new idea request
    assignRequestToNewIdea(_body, auth0)
      .then((response) => {
        const ideaId = response.createNewIdeaRes?.idea?._id;

        showNotification({
          title: "Assigned Successfully!",
          color: "teal",
          message: (
            <Box>
              <Text>New idea has been created.</Text>
              {ideaId && (
                <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
                </Anchor>
              )}
            </Box>
          ),
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });

        if (ideaId) {
          setTimeout(() => {
            window.open(`/idea/${ideaId}`);
          }, 1300);
        } else {
          console.log("Did not receive an idea ID for the new idea", response);
        }
      })
      .catch((error) => {
        console.error("Error creating idea:", error);
        showNotification({
          title: "Error Creating Idea",
          color: "red",
          message:
            "An error occurred while creating the idea. Please try again.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
      })
  };

  const onValidateMatch = (matchId: string, ideaId: string, evidence: any ) => {

    if (evidence?.ideaId) {

      

      setVerifyOpened(true);

    } else {
      // setLoadingAssign(true);
      if(validation){
        setLoading?.(true);
      }
      
      // validateMatch(auth0, matchId, ideaId, evidence, evidence?._id)
      const matchesCopy = [...matches]

      const filtered = matchesCopy.filter((match: any)=> match._id !== chosenEvidence?._id)

      setMatches(filtered)
      validate({ matchId, ideaId, sourceOfAssignment:"suggestion" })
        .then((res) => {
          console.log("validateMatch", { res });
          setChosenEvidence({});
          
          loadEvidence();

          if (res.evidence) {
            reCalculatPerspectiveForRequest(
              { ...res.evidence, state: "validated" },
              "update"
            );
          }
          setCardView(false);
          setAddEvidenceFormScreen(false);
          showNotification({
            title: "Evidence added successfully",
            color: "teal",
            message: ``,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200,
          });
          if (validation) {
            ideaValidation(auth0, idea._id)
              .then((res) => {
                setideaValidationData(res.data);
                setMaturity(res.data.maturityLevel);
                handlebannerText(res.data.maturityLevel);
                setValidationCount((prevCount:number) => {
                  const newCount = prevCount + 1;
                  if (newCount >= 5) {
                    setIsValidationDone(true); 
                  }
                  return newCount;
                });
                setLoading?.(false);
              })
              .catch((e) => console.log(e));
          }
        })
        .then(() => {
          if(!validation) {
            setChosenEvidence(null);
          }
          // setLoadingAssign(false);
        })
        .catch((e) => {
          showNotification({
            title: "Problem occured!",
            color: "red",
            message: "Problem occured while adding evidence...",
            icon: <IconX size="1.1rem" />,
            autoClose: 1200,
          });
          console.log(e);
        });
    }
  };

  const dismissMatch = (matchId: string) => {
    // setLoadingDismissed(true);
    if(validation){
      setLoading?.(true);
    }

    // dismissSuggested(auth0, matchId, ideaId || "", chosenEvidence?._id, true)
    dismiss({
      ideaId,
      matchId, 
      isGap: true
    })
      .then((res) => {
        setAddEvidenceFormScreen(false);
        setCardView(false);
        reCalculatPerspectiveForRequest({ _id: matchId }, "remove");
        showNotification({
          title: "This evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });

        console.log(res);
        loadEvidence();
        if (validation) {
          ideaValidation(auth0, idea._id)
            .then((res) => {
              setideaValidationData(res.data);
              setMaturity(res.data.maturityLevel);
              handlebannerText(res.data.maturityLevel);
              setValidationCount((prevCount: number) => {
                const newCount = prevCount + 1;
                if (newCount >= 5) {
                  setIsValidationDone(true); 
                }
                return newCount;
              });
              setLoading?.(false);
            })
            .catch((e) => console.log(e));
        }
      })
      .then(() => {
        if(!validation) {
          setChosenEvidence(null);
        }
        // setLoadingDismissed(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const labelMatchBagelUser = (sameRequest: boolean) => {
    setLabelingDisabaled(true);
    labelMatch(
      chosenEvidence?.match?._id,
      ideaId || "",
      chosenEvidence?._id,
      sameRequest,
      auth0
    )
      .then((res) => {
        console.log(res);
        showNotification({
          title: "This evidence has been added",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setLabelingDisabaled(chosenEvidence?.manualLabel === "sameRequest");
  }, [chosenEvidence]);

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      {opened && <Modal
        ml="30vw"
        opened={opened}
        onClose={() => setOpened(false)}
        yOffset={123}
        size="34vw"
        title={
          <Text fz={20} fw={600}>
            Assign to another idea
          </Text>
        }
      >
        <Box h={440}>
          <MatchReAssignment
            idea={idea}
            item={chosenEvidence}
            setOpened={setOpened}
            navToPerspective={navToPerspective}
            TextInputW="100%"
            BoxW="38vw"
          />
        </Box>
      </Modal>}
      {verifyOpened && <AssignmentVerificationModal
        verifyOpened={verifyOpened}
        setVerifyOpened={setVerifyOpened}
        navToPerspective={navToPerspective}
        reassignedRequest={assignedRequest}
        chosenEvidence={chosenEvidence}
        validation={validation}
        setideaValidationData={setideaValidationData}
        setMaturity={setMaturity}
        handlebannerText={handlebannerText}
        setLoading={setLoading}
        setValidationCount={setValidationCount}
        setIsValidationDone={setIsValidationDone}
      />}

      {!validation && <CommentsMenu/>}
      {isBagelUser && !validation && (
        <Stack align="center" justify='left' style={{ position: "relative", display: "inline-block" }} mr={2}>
          {/* Target Button */}
          <ActionIcon 
            variant="transparent" 
            onClick={()=> setIsBagelUsersActionsOpened(!isBagelUsersActionsOpened)} 
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconMenu2/>
          </ActionIcon>
  
          {/* Dropdown */}
          {isBagelUsersActionsOpened && (
            <Stack
              justify="center"
              align="center"
              style={{
                position: "absolute",
                bottom: "100%", // Position the dropdown above the button
                /* left: 0, */
                marginBottom: 8, // Spacing between target and dropdown
               
                left: "50%", // Start at the middle of the parent
                transform: "translateX(-50%)", // Adjust to center
                zIndex: 1000,
                background: "white",
                border: "1px solid #ddd",
                borderRadius: 4,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "8px 12px",
                /* minWidth: 200, */
              }}
              
            >
              <BagelUsersActions
                labelMatchBagelUser={labelMatchBagelUser}
                labelingDisabaled={labelingDisabaled}
              />
            </Stack>
          )}
        </Stack>
        
      )}
      <Flex align="center" justify="space-between" w={!validation ? undefined : "100%"}>
        {!isLoading.dismiss ? (
          <Tooltip
            label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
            multiline
            maw={200}
            offset={12}
          >
            <Flex
              align="center"
              w="107px"
              h="32px"
              mr="12px"
              sx={{ 
                justifyContent: "center",
                backgroundColor: "#5C5CEB1A",
                cursor: " pointer",
                borderRadius: "4px"
              }}
              onClick={() => {
                dismissMatch(chosenEvidence?.match?._id);
                posthog.capture(
                  "suggested_not_relevant_clicked",
                  chosenEvidence?.match
                );
              }}
            >
              <Box>
                <IconX size={16} color="#5C5CEB" style={{marginTop:"5px"}}/>
              </Box>

              <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1} fw={500}>
                Not relevant
              </Text>
            </Flex>
          </Tooltip>
        ) : (
          <Tooltip
            label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
            multiline
            maw={200}
            offset={12}
          >
            <Flex align="center" w="107px" mr="12px" sx={{ cursor: "default" }}>
              <Box>
                <IconX size={16} color="#D8D8DB" />
              </Box>
              <Text ml="4px" fw={500} color="#D8D8DB" fz={12} lineClamp={1}>
                Not relevant
              </Text>
            </Flex>
          </Tooltip>
        )}
        <Button.Group>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="140px"
              loading={isLoading.validate}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: "0px 5px",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5353D3", 0.05),
                  },
                },

                loader: {
                  position: "absolute",
                  color: theme.white,
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={() => {
                onValidateMatch(
                  chosenEvidence?.match?._id,
                  ideaId || "",
                  chosenEvidence
                );
                posthog.capture("suggested_assign_clicked", chosenEvidence?.match);
              }}
            >
              {!isLoading.validate && (
                <>
                  <IconCircleCheck size={16} fill="white" color="#5C5CEB"/>
                  <Text ml={5} fw={500}>Assign to this idea</Text>
                </>
              )}
            </Button>
          </Tooltip>
          {!validation && 
            <AssignActionsMenu
              actions={[
                {
                  label: "Assign to another idea",
                  onClick: () => setOpened(true),
                },
                {
                  label: "Assign to a new idea",
                  tooltip: "Assign to a new idea",
                  onClick: createNew,
                },
              ]}
            />
          }
        </Button.Group>
      </Flex>
    </Flex>
  );
};

export default MatchActions;
