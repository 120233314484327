import { Anchor, Box, Button, Flex, Modal, Text, Tooltip } from "@mantine/core";
import { IconCircleCheck, IconX, IconCheck} from "@tabler/icons";
import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import IdeaContext from "../../../IdeaContext";
import SyncContext from "../../../../../context/SyncContext";
import { showNotification } from "@mantine/notifications";
import { openModal } from "@mantine/modals";
import LogoIcon from "../../../../layouts/LogoIcon";
import { DeclineModal } from "./DeclineModal";
import CommentsMenu from "../Comment/CommentsMenu";
import AssignActionsMenu from "./AssignActionsMenu";
import { RequestAssignment } from "./RequestAssignment";
import { assignRequestToNewIdea } from "../../../../../Api";

const ValidatedRequestActions = ({ navToPerspective,chosenEvidence, validation }: { navToPerspective: any; chosenEvidence: any, validation?: boolean  }) => {
  const { loadEvidence, idea } = useContext(IdeaContext);
  const { defaultPriority, defaultStatus,allComponents } = useContext(SyncContext);
  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);

  const createNew = () => {
    const newIdeaObj = {
      title: chosenEvidence?.title,
      description: chosenEvidence?.text,
      componentId: chosenEvidence?.componentId,
      ownerId: null,
      rolloutDate: null,
      status: defaultStatus,
      priority: defaultPriority,
    };

    const _body = {
      idea: newIdeaObj,
      evidence: { ...chosenEvidence },
    };

    if (!chosenEvidence?.componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }
    if (chosenEvidence?.componentId) {
      const foundComponent = allComponents.find((c: any)=> c._id === chosenEvidence?.componentId)
      if(!foundComponent){
        showNotification({
          title: "Invalid Product Area",
          color: "red",
          message: "Please assign a producat area to this request before creating a new idea.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
        return;
      }
  
      _body.idea.ownerId = foundComponent.ownerId;
      _body.evidence.ownerId = foundComponent.ownerId;
  
      // Submit the new idea request
      assignRequestToNewIdea(_body, auth0)
        .then((response) => {
          const ideaId = response.createNewIdeaRes?.idea?._id;
          showNotification({
            title: "Assigned successfully!",
            color: "teal",
            message: <Box>
              <Text>New idea has been created</Text>
              <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
              </Anchor>
            </Box>,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          if (ideaId) {
            setTimeout(() => {
              window.open(`/idea/${ideaId}`);
            }, 1300);
          } else {
            console.log(`did not receive request id for new idea`, response);
          }
        })
        .catch(console.log)
    }
  };

  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModal
          request={chosenEvidence}
          idea={idea}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}

        />
      ),
    });
  };

  
  
  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      {opened && <Modal
        ml='30vw' 
        opened={opened}
        onClose={() => setOpened(false)}
        yOffset={123}
        size="34vw"
        title={
          <Text fz={20} fw={600}>
            Reassign to another idea
          </Text>
        }
      >
        <Box h={440}>
          <RequestAssignment
            idea={idea}
            item={chosenEvidence}
            setOpened={setOpened}
            navToPerspective={navToPerspective}
            TextInputW="100%"
            BoxW="38vw"
          />
        </Box>
      </Modal>}
      {!validation && <CommentsMenu />}
      <Flex align="center">
        <Button.Group>
          <Tooltip
            label="Re-assign to another Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="180px"
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: "0px 5px",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5353D3", 0.05),
                  },
                },

                loader: {
                  position: "absolute",
                  color: theme.white,
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={() => setOpened(true)}
            >
              <>
                <IconCircleCheck size={16} fill="white" color="#5C5CEB"/>
                <Text ml={5} fw={500}>Re-assign to another idea</Text>
              </>
            </Button>
          </Tooltip>
          <AssignActionsMenu
            actions={[
              {
                label: "Decline",
                onClick: () => openDeclineModal()
              },
              {
                label: "Re-assign to a new idea",
                tooltip: "Re-assign to a new idea",
                onClick: createNew,
              },
            ]}
          />
        </Button.Group>
      </Flex>
    </Flex>
  );
};

export default ValidatedRequestActions;