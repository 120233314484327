import { useEffect, useState, useCallback, useContext } from 'react';
import { fetchCounters } from '../../Api';
import { useAuth0 } from "@auth0/auth0-react";
import { SourceIcon } from '../../utils/SourceIcon';
import SyncContext from '../../context/SyncContext';
import { Tooltip } from "@mantine/core";
import MissingSources from './MissingSources';
import { OverflownText } from '../OverflownText';

interface BusinessName {
  name: string;
  count: number;
}

interface Source {
  _id: string;
  origin: string;
  business_names: BusinessName[];
  totalCount?: number;
}

const EvidenceSources = () => {
  const [sources, setSources] = useState<Source[]>([]);
  const [loading, setLoading] = useState(true);
  const auth0 = useAuth0();
  const { discoveryEvidencesSources: discoveryEvidencesSources } = useContext(SyncContext);

  const CARD_MAX_VISIBLE_BUSINESS_SOURCES = 3;
  const fetchSources = useCallback(async () => {
    try {
      const data = await fetchCounters(auth0);
      setSources(data.evidenceCounters || []);
    } catch (error) {
      console.error('Error fetching evidence sources:', error);
    } finally {
      setLoading(false);
    }
  }, [auth0]);

  useEffect(() => {
    fetchSources();
  }, []);

  if (loading) return <p>Loading...</p>;

  const smallSources: BusinessName[] = [];
  const filteredSources: Source[] = [];

  sources.forEach((source) => {
    const totalCount = source.business_names.reduce((sum, item) => sum + item.count, 0);
  
    if (!discoveryEvidencesSources.includes(source.origin)) {
      smallSources.push(...source.business_names);
    } else {
      filteredSources.push({ ...source, totalCount });
    }
  });
  
  filteredSources.sort((a, b) => (b.totalCount || 0) - (a.totalCount || 0));

  if (smallSources.length > 0) {
    filteredSources.push({
      _id: "other",
      origin: "other",
      business_names: smallSources,
      totalCount: smallSources.reduce((sum, item) => sum + item.count, 0),
    });
  }
  

  
  const totalEvidencesAllSources = filteredSources.reduce((sum, src) => sum + (src.totalCount || 0), 0);
  
  const missingSources = discoveryEvidencesSources.filter(
    (origin) => !sources.some((source) => source.origin === origin) && origin !== "Other"
  );
  
  
  return (
    <>
      <div style={styles.totalEvidencesMessage}>
        <span style={styles.totalCount}>{totalEvidencesAllSources.toLocaleString()}</span> Evidence (Feature Requests, Product Gaps, Pain points) found across all sources
      </div>

      <div style={styles.container}>
        {filteredSources.map((source) => {
          const hasData = source.business_names.length > 0 && source.totalCount !== 0;
          const cardClass = hasData ? 'card' : 'emptyCard';
          const cardStyle = hasData ? styles.card : styles.emptyCard;
          return (
            <div key={source._id} className={cardClass} style={cardStyle}>
              <Tooltip
                label={source.origin.charAt(0).toUpperCase() + source.origin.slice(1)}
                position="top"
                withArrow
              >
                <div style={styles.iconWrapper}>
                  <SourceIcon sourceName={source.origin} width={30} height={30} />
                </div>
              </Tooltip>

              {hasData && (
                <ul style={styles.list}>
                  {source.business_names.slice(0, CARD_MAX_VISIBLE_BUSINESS_SOURCES).map((business, index) => (
                    <li key={index} style={styles.listItem}>
                      <OverflownText
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        overTooltip={business.name.charAt(0).toUpperCase() + business.name.slice(1)}
                      >
                      
                        <span style={styles.label}>{business.name}</span>
                      </OverflownText>
                      <div style={styles.count}>{business.count}</div>
                    </li>
                  ))}
                  {source.business_names.length > CARD_MAX_VISIBLE_BUSINESS_SOURCES && (
                    <li style={styles.listItem}>
                      <span style={styles.label}>Additional</span>
                      <div style={styles.count}>
                        {source.business_names
                          .slice(CARD_MAX_VISIBLE_BUSINESS_SOURCES)
                          .reduce((sum, b) => sum + b.count, 0)}
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div>
          );
        })}
        {missingSources.length ? <MissingSources missingSources={missingSources} /> : null}
      </div>

    </>
  );
};

const styles = {
  totalEvidencesMessage: {
    fontSize: '17px',
    color: '#585C68',
    textAlign: 'center' as const,
    fontWeight: '400',
    marginTop: '20px',
  },
  totalCount:{
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'flex-start' as const,
    gap: '16px',
    padding: '24px',
    background: '#F6F7FB',
  },
  card: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const,
    width: '170px',
    minHeight: '330px',
    padding: '16px',
    background: '#5C5CEB1A',
    borderRadius: '8px',
    textAlign: 'left' as const,
    flexGrow: 1,
    maxWidth: 'min(170px, calc(25% - 22px))',
  },
  iconWrapper: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    borderRadius: '4px',
    minWidth: '40px',
    minHeight: '40px',
    overflow: 'hidden',
  },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  list: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const,
    fontSize: '14px',
    color: '#444',
    listStyle: 'none' as const,
    padding: 0,
  },
  listItem: {
    fontWeight: '500',
    marginBottom: '6px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const,
  },
  label: {
    background: '#5C5CEB1A',
    color: '#5C5CEB',
    padding: '4px 4px 2px 4px',
    borderRadius: '2px',
    fontSize: '10px',
    maxWidth: '150px',
    fontWeight: '600',
    textTransform: 'uppercase',
    display: 'block' as const,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  count: {
    fontSize: '12px',
    fontWeight: '600',
    marginTop: '2px',
    color: '#585C68',
    textAlign: 'left' as const,
  },
  emptyCard: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const,
    width: '160px',
    height: '134px',
    padding: '12px',
    border: '1px solid #5C5CEB66',
    borderRadius: '8px',
    color:'#9a9af1',
    textAlign: 'left' as const,
  },

};

export default EvidenceSources;
