import { Box, ScrollArea, Text } from "@mantine/core"
import { stripHtml } from "../../../../../../utils";

const SupportTicketTextArea = ({
  title,
  text,
  excerptsArr,
  setOpened,
}: {
    title: string;
    text: string;
    excerptsArr: any[];
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
  return (
    <div>{title && (
      <Text fz={14} fw={600} lineClamp={1} mb={14} lh={"18px"}>
        {title}
      </Text>
    )}
    <ScrollArea mt={15} >
        
      {text && (
        <Box
          py={8}
          px={12}
          bg="#F8F9FA"
          sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
        >
          <Text
            fz={14}
            lh={"18px"}
            color="#212529"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {stripHtml(text)}
          </Text>
        </Box>
      )}
      {excerptsArr && excerptsArr.length > 0 && (
        <Box
          py={8}
          px={12}
          bg="#F8F9FA"
          sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
        >
          <Text
            mb="5px"
            fw={600}
            style={{ color: "black", fontSize: "14px" }}
          >
            {excerptsArr.length === 1 ? "Excerpt:" : "Excerpts:"}
          </Text>
          <div>
            {excerptsArr.map((excerptItem: any, index: number) => (
              <Box key={index}>
                <Text
                  fz={14}
                  lh={"18px"}
                  color="#585C68"
                  sx={{
                    whiteSpace: "pre-line",
                    position: "relative",
                    "&::after": {
                      content: "'...'",
                      display: "inline",
                      paddingLeft: "4px",
                    },
                    "&::before": {
                      content: "'...'",
                      display: "inline",
                      paddingLeft: "4px",
                    },
                  }}
                >
                  {excerptItem?.excerpt || "N/A"}
                </Text>
              </Box>
            ))}
          </div>
        </Box>
      )}
    </ScrollArea>
    {
      <Box
        w='100%'
        py={8}
        px={12}
        bg="#F8F9FA"
        sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
      >
        <Text
          mt={10}
          fz={13}
          color="#5C5CEB"
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setOpened(true)}
        >
            View full ticket
        </Text>
      </Box>
    }</div>
  )
}

export default SupportTicketTextArea