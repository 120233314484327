import { Card, Group, Sx, Text } from "@mantine/core";
import { ReactElement } from "react";

interface InfoCardProps {
  label: string;
  text: string;
  icon?: ReactElement<any, any>;
  width?: string;
  cardProps?: any;
  isAllowedToEditDates?: boolean;
  sx?: Sx | (Sx | undefined)[] | undefined;
  isGenerated?: boolean;
}

export function InfoCard(props: InfoCardProps) {
  return (
    <Card
      sx={{
        ...props.sx,
        ...(props.isAllowedToEditDates ? { cursor: props.isGenerated ? 'default' : "pointer" } : {}),
      }}
      p="xs"
      radius="md"
      withBorder
      {...props.cardProps}
    >
      <Text fz="xs" c="dimmed" lineClamp={1} tt={"capitalize"}>
        {props.label}
      </Text>
      <Group spacing="xs" noWrap>
        {props.icon}
        <Text fz="md" fw={500} lineClamp={1} tt={"capitalize"}>
          {props.text}
        </Text>
      </Group>
    </Card>
  );
}
