import React from "react";
import { Box, Tooltip } from "@mantine/core";

interface SelectionIndicatorProps {
  id: string;
  position: "above" | "below" | "inside";
  selectedArea: { id: string; position: "above" | "below" | "inside" } | null;
  hoveredLine: { id: string; position: "above" | "below" | "inside" } | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
  isDisabled?: boolean;
}

const SelectionIndicator: React.FC<SelectionIndicatorProps> = ({
  id,
  position,
  selectedArea,
  hoveredLine,
  onMouseEnter,
  onMouseLeave,
  onClick,
  isDisabled = false
}) => {
  const isSelected = selectedArea?.id === id && selectedArea?.position === position;
  const isHovered = hoveredLine?.id === id && hoveredLine?.position === position;

  const getColor = () => {
    if (isSelected || isHovered) {
      return isDisabled ? "#D8D8DB" : "#5C5CEB"; 
    }
    return "transparent"; 
  };  

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        cursor: isDisabled ? "not-allowed" : "pointer",
        width: "200px",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={!isDisabled ? onClick : undefined}
      
    >
      <Box
        style={{
          width: "11px",
          height: "11px",
          borderRadius: "50%",
          backgroundColor: getColor(),
          marginRight: "-4px",
        }}
      />
      <Box
        style={{
          height: "2px",
          flexGrow: 1,
          borderRadius: "4px",
          backgroundColor: getColor(),
        }}
      />
      {isDisabled && isHovered && (
        <Tooltip
          label="This area cannot be converted to a sub-area as it has sub-areas itself."
          position="top"
          withArrow
          sx={{ wordWrap: "break-word", whiteSpace: "normal", maxWidth: 300 }}
        >
          <Box
            style={{
              backgroundColor: "#D8D8DB",
              width: "100%",
              height: "2px",
              borderRadius: "4px",
              display: "inline-block",
              position: "relative",
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default SelectionIndicator;