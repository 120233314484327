import { Box, Button, Loader, Paper, Table, Text, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { listFields } from "../../../Api";
import { fetchDomains } from "../../../api/domains";
import { useAuth0 } from "@auth0/auth0-react";
import RowItem from "./RowItem";
import AddAreaRow from "./AddAreaRow";
import ProductDomain from "./ProductDomain/ProductDomain";
import ReassignProductAreaModal from "./ReassignProductAreaModal";
import SyncContext from "../../../context/SyncContext";
import {listProductAreas} from "../../../api/product-areas";

interface ElementProps {
  accountId: string;
  childComponents: [];
  createdAt: string;
  name: string;
  sourceValues: [];
  description: string;
  _id: string;
}

interface DomainProps {
  accountId: string;
  name: string;
  value: string;
  _id: string;
}

function ProductArea() {
  const auth0 = useAuth0();
  const { allComponents, owners, setAllComponents  } = useContext(SyncContext)
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState<ElementProps[]>([]);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [addFormContent, setAddFormContent] = useState(null);
  const [removedRow, setRemovedRow] = useState(null);
  const [domains, setDomains] = useState<DomainProps[]>([]);
  const [currentId, setCurrentId] = useState<null | string>(null);
  const [openProductAreaModal, setOpenProductAreaModal] = useState(false);
  const [loadPage, setLoadPage] = useState(false);

  const getComponents = async () => {
    await listProductAreas(auth0).then((res) => {
      // console.log({res})
      setElements(res.productAreas);
      setLoading(false);
    });
  };

  const getDomains = async () => {
    await fetchDomains(auth0).then((res) => {
      setDomains(res.domains);
    });
  };

  const handleAddArea = () => {
    setAddIsOpen(true);
  };

  const handleRemoveValue = async (id: string) => {
    setCurrentId(id);
    setOpenProductAreaModal(true);
    // axios dealt with in the ReassignValueModal.tsx
  };

  const getFields = () => {
    listFields(auth0)
      .then((res) => {
        setAllComponents(res.allComponents);
      })
  }

  useEffect(() => {
    setLoading(true);
    getDomains();
  }, []);

  useEffect(() => {
    setLoading(true);
    getFields();
    getComponents();
  }, [addFormContent, removedRow, loadPage]);

  if (loading) {
    return <Loader variant="dots" mt={"lg"} ml={"lg"} />;
  }

  return (
    <Box bg={"#fff"} sx={{ height: "calc(100vh - 70.76px)" }} style={{ overflowY: "scroll" }}>
      <Title
        order={3}
        ml="sm"
        pt={"md"}
        pl={"sm"}
        pb={"xl"}
        mb={0}
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Text mb={"lg"}>Product Areas</Text>
      </Title>

      {/* check if elements.options or something like that  */}
      {currentId && (
        <ReassignProductAreaModal
          openProductAreaModal={openProductAreaModal}
          setOpenProductAreaModal={setOpenProductAreaModal}
          currentOption={
            currentId && allComponents.find((o: any) => o._id === currentId)
          }
          currentId={currentId}
          setRemovedRow={setRemovedRow}
          elements={elements}
          loadPage={loadPage}
          setLoadPage={setLoadPage}
        />
      )}
      <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
        <Paper mt={"lg"} withBorder>
          <Table>
            <thead>
              <tr style={{ backgroundColor: "#f3f3f3" }}>
                <th style={{ width: "50px", padding: "12px" }}></th>
                <th style={{ width: "50px", padding: "12px" }}></th>
                <th style={{ width: "150px", padding: "12px" }}>
                  Product area
                </th>
                <th style={{ width: "150px" }}>Description</th>
                <th style={{ width: "150px", textAlign: "center" }}>Owner</th>
                <th style={{ width: "150px", textAlign: "center" }}>Domain</th>
                <th
                  style={{
                    width: "60px",
                    padding: "12px",
                    textAlign: "center",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {domains &&
                elements?.map((element: any, index: number) => {
                  return (
                    <>
                      <RowItem
                        elements={elements}
                        element={element}
                        members={owners}
                        isChild={false}
                        isFirst={index === 0}
                        setRemovedRow={setRemovedRow}
                        index={index}
                        domains={domains}
                        handleRemoveValue={handleRemoveValue}
                        parent={elements[index-1]}
                        loadPage={loadPage}
                        setLoadPage={setLoadPage}
                        key={element._id}
                        setLoading={setLoading}
                      />
                      {element &&
                        element.childComponents?.length > 0 &&
                        element.childComponents.map(
                          (child: any, childIndex: number) => (
                            <RowItem
                              elements={elements}
                              element={child}
                              members={owners}
                              isChild={true}
                              setRemovedRow={setRemovedRow}
                              index={childIndex}
                              domains={domains}
                              handleRemoveValue={handleRemoveValue}
                              parent={element}
                              loadPage={loadPage}
                              setLoadPage={setLoadPage}
                              key={child._id}
                              setLoading={setLoading}
                            />
                          )
                        )}
                    </>
                  );
                })}
              {domains && addIsOpen && (
                <AddAreaRow
                  members={owners}
                  setAddIsOpen={setAddIsOpen}
                  setAddFormContent={setAddFormContent}
                  domains={domains}
                />
              )}
            </tbody>
          </Table>
          <Box
            px="xl"
            py="md"
            sx={{
              borderTop: "1px solid #dee2e6",
            }}
          >
            <Button
              bg={"#5C5CEA"}
              sx={{
                fontWeight: 700,
                ":hover": { backgroundColor: "#9f9af8" },
                "&[disabled]": {
                  pointerEvents: "all",
                  backgroundColor: "#fff",
                  cursor: "not-allowed",
                  ":hover": {
                    backgroundColor: "#fff",
                  },
                },
              }}
              color={"#fff"}
              onClick={handleAddArea}
              disabled={addIsOpen === true ? true : false}
            >
              + Add Area
            </Button>
          </Box>
        </Paper>
        <ProductDomain />
      </Box>
    </Box>
  );
}

export default ProductArea;
