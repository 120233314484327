import React from "react";
import { Box } from "@mantine/core";
import ProgressBarPart from "../../../../ideaNiv/right-bar/perspective/ProgressBarPart";

interface PerspectiveBarProps {
  assigned: number;
  suggested: number;
  topValue: number; // This represents the highest count among all rows (100%)
}

const PerspectiveBar = ({
  assigned,
  suggested,
  topValue,
}: PerspectiveBarProps) => {
  // Guard against division by zero.
  const denominator = topValue || 1;

  // Calculate percentages relative to the highest value.
  const assignedPct = (assigned / denominator) * 100;
  const suggestedPct = (suggested / denominator) * 100;
  // The remainder is computed as 100% minus the sum of the two percentages.
  const restPct = 100 - assignedPct - suggestedPct;

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        height: "10px",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <ProgressBarPart widthPercentage={assignedPct} color="#5C5CEB" />
      <ProgressBarPart widthPercentage={suggestedPct} color="#5C5CEB" />
      <ProgressBarPart widthPercentage={restPct} color="rgba(92, 92, 235, 0.05)" />
    </Box>
  );
};

export default PerspectiveBar;