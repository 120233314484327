export const getWeekFromDate = (date: Date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const dayOfWeek = startOfYear.getDay(); // 0 = Sunday, 6 = Saturday
  const oneDay = 1000 * 60 * 60 * 24;

  const firstMondayOffset = (dayOfWeek === 0 ? 1 : 8 - dayOfWeek) * oneDay;
  const firstMonday = new Date(startOfYear.getTime() + firstMondayOffset);

  const diff = date.getTime() - firstMonday.getTime();
  const weekNumber = Math.floor(diff / (7 * oneDay));

  const weekStartDate = new Date(
    firstMonday.getTime() + weekNumber * 7 * oneDay
  );
  const formattedDate = `${String(weekStartDate.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(weekStartDate.getDate()).padStart(
    2,
    "0"
  )}-${weekStartDate.getFullYear()}`;

  return formattedDate;
};

export const getQuarterFromDate = (date: Date) => {
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const year = localDate.getFullYear();
  const month = localDate.getMonth();
  const quarter = Math.floor(month / 3) + 1;
  return `Q${quarter} ${year}`;
};

export const isIdExist = (idString: string, statusId: string) => {
  const ids = idString.split("-").map(Number);
  const idInt: any = Number(statusId);
  return ids.includes(idInt);
};

export const getClosestDataPoint = (target: string, availableLabels: string[]) => {
  if (!target || availableLabels.length === 0) return target;

  const isQuarterFormat = availableLabels.some(
    (label) => /^Q\d \d{4}$/.test(label) || label === "Older"
  );

  if (isQuarterFormat) {
    return availableLabels.find((label) => label === target) || target;
  }

  const targetDate = new Date(target);
  const parsedLabels = availableLabels
    .map((label) => ({
      label,
      date: new Date(label),
    }))
    .filter((item) => !isNaN(item.date.getTime()));

  if (parsedLabels.length === 0) return target;

  parsedLabels.sort((a, b) => a.date.getTime() - b.date.getTime());

  let closest = parsedLabels[0].label;
  let minDiff = Math.abs(parsedLabels[0].date.getTime() - targetDate.getTime());

  for (let i = 1; i < parsedLabels.length; i++) {
    const diff = Math.abs(
      parsedLabels[i].date.getTime() - targetDate.getTime()
    );
    if (diff < minDiff) {
      minDiff = diff;
      closest = parsedLabels[i].label;
    }
  }

  return closest;
};
