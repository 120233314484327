import {
  Grid,
  Avatar,
  Group,
  ActionIcon,
  Text,
  Menu,
  Box,
} from "@mantine/core";
import {
  IconDots,
  IconSend,
  IconTrash,
  IconPencil,
} from "@tabler/icons";
import moment from "moment";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { searchUsers, searchMembers } from "../../Api";
import { getBagelId } from "../../utils";
import { SourceIcon } from "../../utils/SourceIcon";
import { getInitials } from "../../utils/getInitials";
import MentionTextarea, { MentionSuggestion } from "../MentionTextarea";
import { useMentions } from "../MentionTextarea/MentionContext";

const Comment = ({
  comment,
  index,
  editComment,
  deleteComment,
  loading,
}: {
  comment: any;
  index: number;
  editComment: (comment: any, text: string) => void;
  deleteComment: (commentId: string) => void;
  loading: any;
}) => {
  const auth0 = useAuth0();
  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();

  const [value, setValue] = useState(comment.text);
  const [edit, setEdit] = useState(false);
  const { user } = useAuth0();
  const bagelId = getBagelId(user);
  const canEdit = bagelId === comment.userId;
  const editEnable = () => {
    setEdit(!edit);
  };

  const searchUser = ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    setSuggestionsLoading(true);

    return new Promise<MentionSuggestion[]>((resolve, reject) => {
 
      Promise.all([searchUsers(query, auth0), searchMembers(query, auth0)])
        .then(([users, members]) => {
 
          const userSuggestions: MentionSuggestion[] =
            users?.map((user: any) => ({
              id: user._id,
              email: user.email,
              name: user.name,
              picture: user.picture,
            })) ?? [];

          const memberSuggestions: MentionSuggestion[] =
            members
              ?.filter((member: any) => member.name)
              .map((member: any) => ({
                id: member._id,
                email: member.email,
                name: member.name,
                picture: member.picture,
              })) ?? [];

          const combinedSuggestions = [
            ...userSuggestions,
            ...memberSuggestions,
          ];
          const emailToUserMap = new Map<string, MentionSuggestion>();

          combinedSuggestions.forEach((suggestion) => {
            if (
              !emailToUserMap.has(suggestion.email) ||
              memberSuggestions.find(
                (member) => member.email === suggestion.email
              )
            ) {
              emailToUserMap.set(suggestion.email, suggestion);
            }
          });

          const uniqueSuggestions = Array.from(emailToUserMap.values());
      
          setSuggestions(uniqueSuggestions);
          resolve(uniqueSuggestions);
        })
        .catch((error) => {
          console.error("Error searching users and members:", error);
          reject(error);
        })
        .finally(() => setSuggestionsLoading(false));
    });
  };

  return (
    <Grid mb={0} p={0} m={0} key={index} mr={2}>
      <Grid.Col span="content">
        <Avatar size="sm" color={"orange"} radius="xl">
          {getInitials(comment.userName)}
        </Avatar>
      </Grid.Col>
      <Grid.Col pt={0} span="auto">
        <Group position="apart">
          <Text size="xs" mt={4}>
            {comment.userName}
          </Text>
          <Group position="right" spacing={8}>
            {canEdit && (
              <Menu>
                <Menu.Target>
                  <Box h={16}>
                    <IconDots size={16} />
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconPencil size={14} />}
                    onClick={editEnable}
                  >
                    Edit comment
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    icon={<IconTrash size={14} />}
                    onClick={() => deleteComment(comment._id)}
                  >
                    Delete comment
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
            {comment.createdAt && (
              <Text size="xs">{moment(comment.createdAt).fromNow()}</Text>
            )}
          </Group>
        </Group>
        {/* @ts-ignore */}
        <MentionTextarea<MentionSuggestion>
          placeholder="Your comment"
          getMentions={searchUser}
          commentValue={value}
          setCommentValue={setValue}
          editable={canEdit && edit}
          debounceWait={1000}
          sx={{
            ...(!(canEdit && edit)
              ? {
                background: "#5C5CEA33",
                borderRadius: 8,
                borderWidth: 0,
              }
              : { borderRadius: 8, border: "1px solid #D8D8DB" }),
            fontSize: 12,
          }}
          rightSection={
            <ActionIcon
              onClick={() => {
                setEdit(!edit);
                editComment(comment, value);
              }}
              size="md"
              mr={4}
              variant="filled"
              color="blue"
              radius="md"
              loading={loading.comment}
              disabled={value == ""}
            >
              <IconSend size={20} />
            </ActionIcon>
          }
        />

        {comment.origin && (
          <Group mt={3} position="right">
            <SourceIcon sourceName={comment.origin} width={15} height={15} />
          </Group>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default Comment;
