import { useAuth0 } from "@auth0/auth0-react";
import {
  Text,
  TextInput,
  Box,
  createStyles,
  Button,
  Anchor,
  Stack,
  Flex,
  Card,
  Loader,
  Checkbox,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSearch, IconCheck, IconX, IconPlus } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { openConfirmModal } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { assignRequestToNewIdea, deleteIdea, getIdeaMatchesForEvidence, getSuggestionsForIdea, searchIdeas, } from "../../../../../Api";
import { getBagelId, useThrottle } from "../../../../../utils";
import { SearchResultIdeaItem } from "../SearchResultIdeaItem";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";


export function MatchReAssignment({
  item,
  idea,
  navToPerspective,
  setOpened,
  TextInputW,
  TextInputPH,
  BoxW,
  
}: {
  item: any;
  idea: any;
  navToPerspective: any;
  setOpened: any;
  TextInputW?: string;
  TextInputPH?: string;
  BoxW?: string;
  
}) {
  const { defaultPriority, defaultStatus, allComponents } =
    useContext(SyncContext);

  const { chosenEvidence, ideaId: oldIdeaId } =
    useContext(IdeaContext);


  const { validate } = useEvidenceActions({evidenceId: chosenEvidence._id})

  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [ideaMatches, setIdeaMatches] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [chosenIdea, setChosenIdea] = useState<any>();
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showMandatoryPAMSG, setShowMandatoryPAMSG] = useState(false);
  const [onlyMyIdeas, setOnlyMyIdeas] = useState(false);

  const userId = getBagelId(auth0.user);
  const cxItem = item?.cxItem


  const assignedRequest = (item?.requests && item?.requests?.length > 0 )
    ? item?.requests[0]
    : null;
  /* const assignedIdea = item?.ideas
    ? item?.ideas[0]
    : null; */
  
  const openDeleteRequestPopup = () =>
    openConfirmModal({
      title: "Delete idea",
      centered: true,
      children: (
        <Text size="sm">
          This idea has no evidence. do you want to delete it?
        </Text>
      ),
      labels: { confirm: "Delete idea", cancel: "No, keep it for now" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteIdea(item?._id, auth0)
          .then((response) => {
            navigate("/requests");
          })
          .catch((e: any) => console.log(e));
      },
    });

  const handleAssign = (ideaItemId: string) => {
    
    setLoading(true);

    
    const SALESFORCE = "salesforce";
    if (cxItem?.itemType === "account") {
      if (!cxItem.origin) {
        //if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = item.chatCompany.sfId;
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          item?.company?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true;
            }
          });
        }
        if (hasSalesforce) {
          cxItem.origin = SALESFORCE;
        } else {
          cxItem.origin =
                cxItem?.chatCompany.sources?.length > 0
                  ? item?.company?.sources[0]?.origin
                  : undefined;
        }
      }
    }
      
   
    validate({
      ideaId: ideaItemId,
      oldIdeaId,
      matchId: chosenEvidence?.match?._id,
      sourceOfAssignment:"suggestion" 
    })
      .then((res) => {
        setOpened(false)
        console.log("addRequestsToIdea", {requests: res});
      })
      .catch((e) => console.log(e))
      .finally(() => {
        window.open(`/idea/${chosenIdea?._id || ''}`);
        navToPerspective();
        /* setActiveSearch(false); */
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        setLoading(false);
      }); 
  };

  const chooseIdea = (ideaObj: any) => {
    setChosenIdea(ideaObj);
  };

  const doSearch = async () => {
    searchIdeas({ query, onlyMyIdeas: onlyMyIdeas ? userId : undefined}, auth0)
      .then((results: any[]) => {
        if(results.length > 0){
          
          setSearchResults(results);
          setShowMandatoryPAMSG(false)
          setShowSearchResults(true)
        }
        else{
          setSearchResults([])
        }
      })
      .catch((e) => console.log(e));
  };

  const throttledSearch = useThrottle(() => doSearch(), 1000);

  const getSuggestions = () => {
    const title = item?.textItem?.title || item?.title || "";
    const text = item?.textItem?.text || item?.description || "";
    getSuggestionsForIdea(title, text, auth0)
      .then((matches: any) => {
        const filtered =
          matches?.ideaMatches?.filter((m: any) => m.ideaId !== idea?._id) ||
          [];
        setIdeaMatches(filtered);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingMatches(false));
  };

  const getIdeasMatches = () => {
    setLoadingMatches(true);
    getIdeaMatchesForEvidence(item?._id, auth0)
      .then((res: any) => {
        const filtered =
        res?.filter((m: any) => m.ideaId !== idea?._id) || [];

        if (filtered.length === 0) {
          setLoadingMatches(false);
        } else {
          setIdeaMatches(filtered);
          setLoadingMatches(false);
        }
      })
      .catch((e) => console.log(e));
  };


  const title = item?.title || item?.textItem?.title || "";
  const description = item?.text || item?.textItem?.text || '';

  const newIdeaObj = {
    title: title,
    description: description,
    componentId: item?.componentId || item?.mlComponentId, 
    ownerId: null,
    rolloutDate: null,
    status: defaultStatus,
    priority: defaultPriority,
  };

  const request ={...item}

  const createNew = () => {
    if (!item?.componentId) {
      setShowMandatoryPAMSG(true);
      return
    }

    if (item?.componentId) {

      const foundComponent = allComponents.find((c: any)=> c._id === item?.componentId)

      if(!foundComponent){
        setShowMandatoryPAMSG(true);
        return
      }

      if(foundComponent){
        newIdeaObj.ownerId = foundComponent.ownerId
        request.ownerId = foundComponent.ownerId
      }

      /* setLoading((prev) => ({
        ...prev,
        new: true
      })); */
    
      assignRequestToNewIdea(
        { idea: newIdeaObj, request },
        auth0
      )
        .then((response) => {
          const ideaId = response.createNewIdeaRes?.idea?._id;
          showNotification({
            title: "Assigned successfully!",
            color: "teal",
            message: <Box>
              <Text>New idea has been created</Text>
              <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
              </Anchor>
            </Box>,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          if (ideaId) {
            setTimeout(() => {
              window.open(`/idea/${ideaId}`);
            }, 1300);
          } else {
            console.log(`did not receive request id for new idea`, response);
          }
        })
        .catch(console.log)
        
    }
  };


  useEffect(() => {
    query !== "" ? throttledSearch() : setShowSearchResults(false);

    
  }, [query,onlyMyIdeas]);

  useEffect(() => {
    
    getIdeasMatches();

  }, []);

  return (
    <Stack justify="space-between" h={400} mah={400} pt={5}>
      <Box>
        <div
          onFocusCapture={() => setOpen(true)}
          onBlurCapture={() => setOpen(false)}
        >
          <TextInput
            w={TextInputW || "28vw"}
            placeholder={
              TextInputPH ? TextInputPH : "Search and assign idea..."
            }
            icon={<IconSearch size={14} />}
            radius="md"
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
          <Checkbox
            sx={{ margin: "5px 0"}}
            label="Only search Ideas I own"
            color="indigo"
            checked={onlyMyIdeas}
            onChange={(event) => setOnlyMyIdeas(event.currentTarget.checked)}
            mb="xs"
          />
        </div>
        <Box>{ 
          showSearchResults ?
            <Box>

            
              {searchResults.length > 0 && (
                <Box
                  sx={{
                    height: 304,
                    overflowY: 'auto', // Enables vertical scrolling
                    overflowX: 'hidden', // Hides horizontal scrollbar
                    border: '1px solid #D8D8DB',
                    borderRadius: 6,
                    backgroundColor: '#F8F9FA',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap', // Ensures no wrapping of content
                  }}
                >
                  {searchResults.length > 0 && (
                    <Flex justify='space-between' pl="sm"  w='97%'>
                      <Box
                        
                        py="md"
                        sx={{
                          borderTop: "1px solid #e5e7eb",
                        }}
                      >
                        <Text size="sm">{searchResults.length} results</Text>
                      </Box>
                      <Flex 
                        px="sm"
                        align='center' 
                        ml='md'
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={()=>{
                          setQuery('')
                          setOnlyMyIdeas(false)
                          setShowSearchResults(false)
                        } }
                      >
                        <Box mr='3px' mt='2px'>
                          <IconX size={12} color='#5C5CEB'/>
                        </Box>
          
                        <Text fz="12px" color='#5C5CEB'> Clear</Text>
                      </Flex> 
                    </Flex>
                    
                  )}{" "}
                  <Box w={500} h={350} px="xs"  style={{'overflowY': 'auto','scrollbarWidth': 'none'}}>
                    {searchResults.length > 0 &&
              searchResults.map((idea: any) => {
                return (
                  <SearchResultIdeaItem
                    key={idea._id}
                    reAssign
                    loading={false}
                    ideaItem={idea}
                    chooseIdea={chooseIdea}
                    chosenIdea={chosenIdea}
                  />
                );
              })}
                  </Box>
                  <>
                    {" "}
            
            
                  </>
                </Box>
              )}
            </Box>
            : showMandatoryPAMSG ? 
              <Box bg='rgba(92, 92, 235, 0.1)' py='lg' px ={50}  mx ={50} mt={60} sx={{borderRadius: 8}}>
Please assign a producat area to this request before creating a new idea
              </Box>
              :
              <Box>
                <Text size="xs" mb="xs" px={16} fw={500}my='sm'>
              Suggested by Bagel
                </Text>
                <Box px={16} sx={{
                  height: 264,
                  overflowY: 'auto', // Enables vertical scrolling
                  overflowX: 'auto',
                  scrollbarWidth: 'none', // Hides horizontal scrollbar
                  border: '1px solid #D8D8DB',
                  borderRadius: 6,
                  backgroundColor: '#F8F9FA',
                  boxSizing: 'border-box',
                  whiteSpace: 'nowrap', // Ensures no wrapping of content
                }}>
                  {ideaMatches.length > 0 ? (
                    ideaMatches
                      .slice(0, 2)
                      ?.map((ideaMatch) => (
                        <SearchResultIdeaItem
                          key={ideaMatch?.idea?._id}
                          reAssign
                          loading={false}
                          ideaItem={ideaMatch?.idea}
                          chooseIdea={chooseIdea}
                          chosenIdea={chosenIdea}
                        />
                      ))
                  ) : loadingMatches ? (
                    <Box mb={28}>
                      <Loader />
                    </Box>
                  ) : (
                    <Card
                      withBorder
                      radius="md"
                      sx={{ borderColor: "#EAEAFE" }}
                      mb="sm"
                    >
                      <Text>No suggestions found</Text>
                    </Card>
                  )}
                </Box>
                
              </Box>
       
        
        }
        
        </Box>
      </Box>
      

      <Flex justify="space-between" mr="md">
        {/* <Flex 
         
          align='center' 
          
          sx={{
            cursor: 'pointer'
          }}
          onClick={createNew}
          ml={6}
        >
          <Box mr='3px' mt='2px'>
            <IconPlus size={12} color='#5C5CEB'/>
          </Box>
          
          <Text fz="12px" color='#5C5CEB'> Assign to a new idea</Text>
        </Flex>  */}
        <Button
          loading={loading}
          fz="12px"
          color="indigo"
          w="75px"
          mr={4}
          disabled={!chosenIdea}
          styles={(theme) => ({
            root: {
              height: "32px",
              backgroundColor: "#5C5CEB",
              border: 0,
              fontWeight: 400,
              padding: 0,
              "&:hover": {
                backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
              },
            },

            leftIcon: {
              marginRight: 15,
            },
          })}
          onClick={() => {
            handleAssign(chosenIdea?._id);
          }}
        >
          <IconPlus size={14} />
          <Text>assign</Text>
        </Button>
      </Flex>
    </Stack>
  );
}