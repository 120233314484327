import { Box, Text, Tooltip } from "@mantine/core"
import { OverflownText } from "../../../../../OverflownText"



const Texts = ( 
  { title, 
    description } 
    : 
    { title: string,
         description: string
        } ) => {
  return (
    <Tooltip
      maw={500}
      multiline label={
        <Box>
          <Text>
            {title}
          </Text>
          <Text mt='xs'>
            {description}
          </Text>
        </Box>
      }>
      <Box maw={"calc(100vw - 927px)"} w={700}>
        <OverflownText  lineClamp={1} size="sm" weight={600} color="#212529">
          <Text 
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}>
            {title}
          </Text> 
          
        </OverflownText>
      </Box>
      
    </Tooltip>
  )
}

export default Texts