import {
  Menu,
  Button,
  Box,
  Text,
  TextInput,
  UnstyledButton,
  Group,
  ActionIcon,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  IconBookmark,
  IconEdit,
  IconSearch,
  IconStar,
  IconTrash,
  IconX,
  IconChevronDown,
  IconCheck
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ViewsItem({
  view,
  index,
  selectedView,
  setSelectedView,
  upsertViews,
}: {
  view: any;
  index: number;
  selectedView: any;
  setSelectedView: (view: any) => void;
  upsertViews: (body: any, func: () => void) => void;
}) {
  const { hovered, ref } = useHover();
  const [name, setName] = useState(view.name);
  const [itemState, setItemState] = useState("view");

  return (
    <Box
      px="sm"
      p={6}
      mx={5}
      mb={5}
      mt={index === 0 ? 5 : 0}
      key={view._id}
      ref={ref}
      sx={{
        borderRadius: 8,
        background: view._id === selectedView._id ? "#5C5CEA1A" : "none",
        ":hover": {
          background: "#5C5CEA1A",
        },
      }}
    >
      {itemState == "view" ? (
        <Group position="apart" noWrap>
          <UnstyledButton
            onClick={() => setSelectedView(view)}
            sx={{ width: "-webkit-fill-available" }}
          >
            <Text py={2} size="sm">{view.name}</Text>
          </UnstyledButton>
          <IconStar
            size={14}
            style={{
              display: view.stared && !hovered ? "block" : "none",
              fill: "#5B60E3",
              color: "#5B60E3",
            }}
          />
          <Group hidden={!hovered || view?.isGlobal} spacing={0} noWrap>
            {/* <ActionIcon
              p={0}
              onClick={() => {
                upsertViews({ _id: view._id, stared: !view.stared }, () =>
                  setItemState("view")
                );
              }}
            >
              <IconStar
                style={{
                  fill: view.stared ? "#5B60E3" : "inherrit",
                  color: view.stared ? "#5B60E3" : "inherrit",
                }}
                size={14}
              />
            </ActionIcon> */}
            <ActionIcon size="sm" onClick={() => setItemState("edit")} p={0}>
              <IconEdit size={14} />
            </ActionIcon>
            <ActionIcon size="sm" onClick={() => setItemState("delete")} p={0}>
              <IconTrash size={14} />
            </ActionIcon>
          </Group>
        </Group>
      ) : itemState == "edit" && !view?.isGlobal ? (
        <Group spacing={5} noWrap>
          <TextInput
            variant="unstyled"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            placeholder="View name"
            size="xs"
            sx={{
              input: {
                fontSize: "inherit !important",
                lineHeight: "1.55  !important",
                padding: 0,
              },
            }}
          />
          <ActionIcon sx={{ height: 28 }} onClick={() => setItemState("view")}>
            <IconX size={14} />
          </ActionIcon>
          <Button
            compact
            sx={{
              backgroundColor: "#5B60E3",
              ":hover": {
                backgroundColor: "#474bb0",
              },
              height: 28,
            }}
            onClick={() => {
              upsertViews({ _id: view._id, name }, () => {
                setItemState("view");
                showNotification({
                  title: "Saved successfully!",
                  color:"teal",
                  message: "view name saved successfully",
                  icon:<IconCheck size="1.1rem" />,
                  autoClose: 1200
                })
              });
            }}
          >
            Save
          </Button>
        </Group>
      ) : itemState == "delete" && !view?.isGlobal ? (
        <Group spacing={8} noWrap>
          <Button
            compact
            h={28}
            sx={{
              color: "#5B60E3",
              borderColor: "#5B60E3",
              ":hover": {
                background: "#5C5CEA1A",
              },
            }}
            variant="outline"
            onClick={() => setItemState("view")}
          >
            Back
          </Button>
          <Button
            fullWidth
            compact
            color="red"
            variant="outline"
            h={28}
            onClick={() => {
              upsertViews({ _id: view._id, deleted: true }, () =>{
                setItemState("view")
                showNotification({
                  title: "deleted successfully!",
                  color:"teal",
                  message: "view deleted successfully",
                  icon:<IconTrash size="1.1rem" />,
                  autoClose: 1200
                })
              });
            }}
          >
            Confirm delete
          </Button>
        </Group>
      ) : (
        <></>
      )}
    </Box>
  );
}

export function ViewsMenu({
  savedViews,
  selectedView,
  setSelectedView,
  upsertViews,
}: {
  savedViews: any[];
  selectedView: any;
  setSelectedView: (view: any) => void;
  upsertViews: (body: any, func: () => void) => void;
}) {
  const [value, setValue] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _selectedView = queryParams.get("selectedView");

  const activeViewHere = savedViews.find(sv => sv?.name?.toLowerCase() == _selectedView?.toLowerCase())
  return (
    <Group position="center">
      <Menu shadow="md" width={230} position="bottom" withArrow>
        <Menu.Target>
          <UnstyledButton
            sx={{
              padding: '6px 10px',
              color: activeViewHere ? "#5B60E3" : "#1618324D",
              borderColor: "#E5E7EB",
              ":hover": {
                backgroundColor: "rgba(248, 249, 250, 1)",
                color:activeViewHere ? "#5B60E3" : "black"
              },
            }}
          >
            <Group noWrap>
              <Text size="xs">{activeViewHere ? selectedView.name  : savedViews.length +' more...'}</Text>
              <IconChevronDown size={14} />
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown sx={{ padding: 0 }}>
          <TextInput
            mt={5}
            mx={5}
            radius="md"
            placeholder="Search"
            value={value}
            onChange={(event: any) => setValue(event.currentTarget.value)}
            icon={<IconSearch size={14.5} />}
            sx={{
              input: {
                backgroundColor: "#F9FAFB",
                ":focus": {
                  borderColor: "#b1b1b1;",
                },
              },
            }}
          />
          <Box sx={{ maxHeight: 230, overflowY: "scroll" }}>
            {(value && value !== ""
              ? savedViews.filter((view) =>
                view.name.toLowerCase().includes(value.toLowerCase())
              )
              : savedViews
            ).length > 0 ? (
                (value && value !== ""
                  ? savedViews.filter((view) =>
                    view.name.toLowerCase().includes(value.toLowerCase())
                  )
                  : savedViews
                ).map((view, index) => (
                  <ViewsItem
                    key={index}
                    view={view}
                    index={index}
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    upsertViews={upsertViews}
                  />
                ))
              ) : (
                <Text p="sm" align="center" key={"not-found"}>
                Saved views not found.
                </Text>
              )}
          </Box>
        </Menu.Dropdown>
      </Menu>
      {/* {(selectedView?._id && !selectedView.isDefault ) && <Button variant="default" color="gray" 
        sx={{
          fontSize: 12,
          fontWeight:400,
          backgroundColor: "#5C5CEA33",
          borderColor: "#5C5CEA33",
          ":hover": {
            backgroundColor: "#5B60E360",
          },
        }} 
        onClick={() => upsertViews({ _id: selectedView._id, isDefault: true }, () => setSelectedView((prev:any)=> ({...prev, isDefault: true})))}
        compact>
        Set as default
      </Button>} */}
    </Group>
  );
}
