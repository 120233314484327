import { Box, Button, Flex, Group, Tabs, Text, TextInput } from '@mantine/core';
import { IconCircleCheck, IconPlus, IconSearch } from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import InitialHeaderRow from './InitialHeaderRow';
import SearchRow from './SearchRow';
import Sort from './sort/Sort';
import FilterBar from './Filters/FilterBar';
import FiltersContext from '../../ideas/filters/context/FiltersContext';
import posthog from 'posthog-js';
import IdeaContext from '../IdeaContext';
import { addOptionsToFilters } from '../../../context/helpers';
import SyncContext from '../../../context/SyncContext';
import { ConvertIcon, ProgressIcon20, ProgressIcon40, ProgressIcon60, ProgressIcon80, VerifiedIcon } from '../../../icons/x-symbol-svgrepo-com';
import ValidateModal from './ValidateModal'
import { ideaValidation, saveIdea } from '../../../Api';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import GeneratedBar from './GeneratedBar';
interface EvidencesHeaderProps {
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  searchEvidence: (query: string) => void;
  activeSearch: boolean;
  searchedEvidence: any[]; // Specify the type of elements in searchedEvidence if possible
  showMatchesResults: boolean;
  setShowMatchesResults: React.Dispatch<React.SetStateAction<boolean>>;
  showRequestsResults: boolean;
  setShowRequestsResults: React.Dispatch<React.SetStateAction<boolean>>;
  showQueryResults: boolean;
  setShowQueryResults: React.Dispatch<React.SetStateAction<boolean>>;
  textItemsResultsLength: number;
  query: string;
  sortDescending: boolean;
  reviewMode: any;
  navToPerspective: any;
  setActiveSearch: any;
  allRequests: any;
  displayedMatches:any;
  setIsValidationDone: (loading: boolean) => void;
  isValidationDone: boolean;
}


const EvidencesHeader: React.FC<EvidencesHeaderProps> = ({
  addEvidenceFormScreen,
  setAddEvidenceFormScreen,
  setCardView,
  searchEvidence,
  activeSearch, 
  searchedEvidence,
  showMatchesResults,
  setShowMatchesResults,
  showRequestsResults,
  setShowRequestsResults,
  showQueryResults,
  setShowQueryResults,
  textItemsResultsLength,
  query,
  sortDescending,
  reviewMode,
  navToPerspective,
  setActiveSearch,
  allRequests,
  displayedMatches,
  setIsValidationDone,
  isValidationDone
}) => {

  const navigate = useNavigate();
  const [validateModalOpened, setValidateModalOpened] = useState(false);
  const [ideaValidationData, setideaValidationData] = useState<any>(null);
  const [validationEvidence, setValidationEvidence] = useState(null);
  const [title, setTitle] = useState<string | boolean>("Improve matches accuracy by providing quick feedback");
  const [text, setText] = useState<string | boolean>("Tag a few evidence to fine tune the AI model");
  const [maturity, setMaturity] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [validationCount, setValidationCount] = useState<number>(0);

  const {
    idea,quarters, weeks, isGenerated, fetchData
  } = useContext(IdeaContext);

  useEffect(() => {
    if (ideaValidationData?.evidences?.length && allRequests?.length) {
      const evidenceId = ideaValidationData.evidences[0]?.evidenceId;
      const matchedRequest = allRequests.find((req: any) => req._id === evidenceId);
      if(!matchedRequest){
        console.error("IdeaValidation - Evidence returned from api not found in list of suggested evidences:", "AccountId- " + idea.accountId + " EvidenceId- "+evidenceId + " IdeaId- "+ idea._id);
        setValidationEvidence(displayedMatches[0])
      } else {
        setValidationEvidence(matchedRequest || null);
      }
    } else {
      setValidationEvidence(null);
    }
  }, [ideaValidationData, allRequests]);

  const auth0 = useAuth0();
  const {
    ideasFiltersConfig,
    setIdeasFiltersConfig,
    components,
    owners,
    members,
    tagsOptions,
    enableIdeaValidation
  } = useContext(SyncContext);

  const {
    activeFilters,
    setActiveFilters,
  } = useContext(FiltersContext);

  useEffect(() => {
    if (quarters.length > 0 || weeks.length > 0) {
      const updatedFilters = addOptionsToFilters(ideasFiltersConfig, { 
        quarters: [...quarters.map(
          (q: string) => (q.toLocaleLowerCase())
        ), 'older'], 
        weeks: weeks.map(
          (w: string) => (w.toLocaleLowerCase())
        ),
        components,
        owners,
        members,
        tagsOptions
      });
      setIdeasFiltersConfig?.(updatedFilters);
    }
  }, [quarters, weeks, isGenerated]);

  useEffect(() => {
    setMaturity(idea?.maturity?.maturityLevel)
    handlebannerText(idea?.maturity?.maturityLevel)
    if(idea?.validationObject?.verifyState == 'completed' || maturity == 5){
      setIsValidationDone(true)
    } else if(idea?.validationObject?.verifyState == 'continue') {
      setIsValidationDone(false)
      setValidationCount(idea?.validationObject.validationCount)
    }
  }, [idea, isGenerated]);
  let ProgressIcon = ProgressIcon20
  const handlebannerText = (maturity : number | undefined) => {
    switch(maturity){
    case(5): {
      setTitle(false)
      setText(false)
      break;
    }
    case(4): {
      setTitle("Improve matches accuracy by providing quick feedback")
      setText("Tag a few evidence to fine tune the AI model")
      ProgressIcon = ProgressIcon80
      break;
    }
    case(3): {
      setTitle("Improve matches accuracy by providing quick feedback")
      setText("Tag a few evidence to fine tune the AI model")
      ProgressIcon = ProgressIcon60
      break;
    }
    case(2): {
      setTitle("Improve matches accuracy by providing quick feedback")
      setText("Tag a few evidence to fine tune the AI model")
      ProgressIcon = ProgressIcon40
      break;
    }
    default: {
      setTitle("Improve matches accuracy by providing quick feedback")
      setText("Tag a few evidence to fine tune the AI model")
      ProgressIcon = ProgressIcon20
      break;
    }  
    }
  }

  const handleValidate = () => {
    setLoading(true);
    ideaValidation(auth0, idea._id)
      .then((res) => {
        setideaValidationData(res.data);
        setMaturity(res.data.maturityLevel)
        handlebannerText(res.data.maturityLevel)
        setLoading(false);
      })
      .catch((e) => console.log(e))
    setValidateModalOpened(true)
  }

  return (
    <Box mt={24}  /* sx={{ borderBottom: "1px solid #D8D8DB" }} */>
      {
        validateModalOpened && (
          <ValidateModal
            ideaId={idea._id}
            maturity={maturity}
            validateModalOpened={validateModalOpened}
            setValidateModalOpened={setValidateModalOpened}
            reviewMode={reviewMode}
            navToPerspective={navToPerspective}
            setActiveSearch={setActiveSearch}
            validationEvidence={validationEvidence}
            loading={loading}
            setLoading={setLoading}
            setideaValidationData={setideaValidationData}
            setMaturity={setMaturity}
            handlebannerText={handlebannerText}
            setValidationCount={setValidationCount}
            validationCount={validationCount}
            setIsValidationDone={setIsValidationDone}
            isValidationDone={isValidationDone}
          />
        )
      }
      {
        title && text && !isValidationDone && enableIdeaValidation && !isGenerated &&  (
          <Group mb={20} position='apart' p="5px" mx="20px" style={{borderRadius: 4, backgroundColor: "#FF8700"}}>
            <Group spacing={8}>
              {validationCount === 0
                ? <VerifiedIcon/>
                : <ProgressIcon /> }
              <Box fz={"12px"} ml={"5px"}>
                <Text fw={700}>
                  {title}
                </Text>
                <Text>
                  {text}
                </Text>
              </Box>
            </Group>
            <Button 
              size="xs" 
              fz={"12px"}
              fw={500}
              style={{ color: "#ffffff", backgroundColor: "#000000", height: "32px" }}
              onClick={() => handleValidate()}
            >
              {validationCount === 0
                ? "Start validating" 
                : "Continue"}
            </Button>
          </Group>
        )
      }
      {isGenerated && <GeneratedBar/>}
      {/* Actions */}

      <Group mb={12} position='apart' px="20px">
        <Group spacing={8}>
          <TextInput
            styles={{
              wrapper:{
                border: "1px solid #D8D8DB",
                borderRadius: "7px",
                backgroundColor: "#fff"
              },
              input:{
                height:32
              }
            }}
            mr={8}
            variant="unstyled"
            placeholder="Search evidence..."
            icon={<IconSearch size={14} />}
            value={query}
            onChange={(event) => {
              searchEvidence(event.currentTarget.value);
              posthog.capture('search_evidence_in_idea', {
                ideaId: idea._id,
                query: event.currentTarget.value
              })
            }}
          />
          <FilterBar 
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </Group>
        <Flex>
          {/* Sort */}
          <Sort />
          {!isGenerated && <Flex
            w={70}
            align="center"
            px="2px"
            sx={{
              borderRadius: "6px",
              border: "1px solid #5C5CEB",
              cursor: "pointer",
            }}
            bg="#5C5CEB"
            h={32}
            ml="xs"
            onClick={() => {
              setCardView(false);
              setAddEvidenceFormScreen(true);
              posthog.capture('add_evidence_clicked', {
                ideaId: idea._id,
                type: 'manually'
              })
            }}
          >
            <Text ml={4} mt={4} p={0} color="white">
              <IconPlus height={16} />
            </Text>
            <Text color="white" fz="14px" fw={500}>
              Add
            </Text>
          </Flex>}
        </Flex>
      </Group>

      {/* Header and filter by suggested */}
      <>
        {!activeSearch ? (
          <Flex>
            <InitialHeaderRow/>
            {isValidationDone && enableIdeaValidation &&
              <Button 
                size="xs" 
                fz={"12px"}
                fw={500}
                style={{ color: "#000000", backgroundColor: "#ffffff", height: "32px", cursor:"default" }}
                p={"5px"}
                mr={"10px"}
              >
                <IconCircleCheck fill="#0BAC4D" color="#ffffff" size={18}/>
                <Text ml={"5px"} fz={"12px"} fw={500}>Validated</Text>
              </Button>
            }
          </Flex>
        ) : (
          
          <SearchRow
            searchEvidence={searchEvidence}
            searchedEvidence={searchedEvidence}
            showMatchesResults={showMatchesResults}
            setShowMatchesResults={setShowMatchesResults}
            showRequestsResults={showRequestsResults}
            setShowRequestsResults={setShowRequestsResults}
            showQueryResults={showQueryResults}
            setShowQueryResults={setShowQueryResults}
            resultsLength={textItemsResultsLength}
          />
  
        )}
      </>
    </Box>
  );
};

export default EvidencesHeader