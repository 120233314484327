import {
  Box,
  Flex,
  Text,
  useMantineTheme,
  ScrollArea,
  Divider
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../context/SyncContext";
import {deleteProductArea} from "../../../api/product-areas";
import CustomModal from "./CustomModal";

interface ProductAreaModalProps {
  openProductAreaModal: any;
  setOpenProductAreaModal: any;
  currentOption: any;
  currentId: string;
  // formValues: any;
  setRemovedRow: any;
  elements: any;
  loadPage: boolean;
  setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
  
}

interface FilteredProductAreas {
  name: string;
  value: string;
  ownerName: string;
  _id: string;
}

function ReassignProductAreaModal({
  openProductAreaModal,
  setOpenProductAreaModal,
  currentOption,
  currentId,
  // formValues,
  setRemovedRow,
  elements,
  loadPage,
  setLoadPage, 
}: ProductAreaModalProps) {
  const auth0 = useAuth0();
  const theme = useMantineTheme();
  const { allComponents } = useContext(SyncContext)
  const [formLoader, setFormLoader] = useState(false);
  
  const [newId, setNewId] = useState("");
  const [filteredProductAreas, setFilteredProductAreas] = useState<
    FilteredProductAreas[]
  >([]);
  const [productArea, setProductArea] = useState<any>()

  const cancelAndClose = () => {
    setOpenProductAreaModal(false);
    setNewId("");
  };

  const removeProductArea = (productAreaToDelete: string, productAreaForReassignment: string)=>{
    productArea && deleteProductArea(productAreaToDelete, productAreaForReassignment, auth0).then((res)=>{
      console.log(res)
      setOpenProductAreaModal(false)
      setLoadPage(!loadPage)
      setProductArea(null)
    }).catch((e) => console.log(e))
  }
  useEffect(() => {
    if (Array.isArray(allComponents) && currentOption?._id) {
      const remainingAreas = allComponents.filter(
        (item: any) => item._id !== currentOption._id
      );
      const parentMap: Record<string, any[]> = {};
      remainingAreas.forEach((item) => {
        const parentId = item.parentId ?? "root";

        if (!parentMap[parentId]) {
          parentMap[parentId] = [];
        }
        parentMap[parentId].push(item);
      });
      const sortedAreas: FilteredProductAreas[] = [];
      const addAreasRecursively = (parentId: string) => {
        if (parentMap[parentId]) {
          parentMap[parentId].forEach((area) => {
            sortedAreas.push(area);
            addAreasRecursively(area._id); 
          });
        }
      };
      addAreasRecursively("root"); 
      setFilteredProductAreas(sortedAreas);
    } else {
      setFilteredProductAreas([]);
    }
  }, [allComponents, currentOption]);
  return (
    <CustomModal
      opened={openProductAreaModal}
      onClose={() => setOpenProductAreaModal(false)}
      title={ currentOption?.name && (
        <Box>
          <Text fw={700}>
            To remove Product Area{" "}
            <Text fs="italic" span>
              {currentOption.name}
            </Text>
            , you need to reassign the ideas which are linked to it.{" "}
            <Text color="red">This is irreversible.</Text>
          </Text>
        </Box>
      )}
      reAssign={true}
      primaryAction={{
        label: "Reassign",
        onClick: () => removeProductArea(currentOption._id, productArea._id),
        disabled: !productArea?._id,
        isLoading: formLoader
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: () => {
          cancelAndClose();
          setProductArea(null);
        }
      }}
    >
      {currentOption?.name && (
        <Box>
          <Box mx="-md" mb="8px">
            <ScrollArea style={{ height: "61vh", background: "#F8F9FA" }}>
              <Divider mb="-8px" size={2} color="#D8D8DB" />
              {filteredProductAreas &&
                filteredProductAreas.length > 0 &&
                filteredProductAreas.map((item: any, index: any) => (
                  <Box key={index}>
                    {!item.parentId && <Divider my="5px" color="#D8D8DB" />}
                    <Flex 
                      px={item.parentId ? undefined : "md"} 
                      pl={item.parentId ?  "2rem" : undefined}
                      h={"35px"}
                      sx={{
                        backgroundColor:item._id == productArea?._id ? "#cfd2d6" : "none",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#cfd2d6",
                        },
                      }}
                      onClick={() => {
                        setProductArea(item); 
                        // setCloseOnBlur(true); 
                      }} 
                    >
                      <Text fw={item.parentId ? 400 : 600} size={14} style={{ lineHeight: 1, marginTop: '12px' }}>
                        {item.name}
                      </Text>
                    </Flex>
                  </Box>
                ))}
            </ScrollArea>
          </Box>
        </Box>
      )}
    </CustomModal>
  );
}

export default ReassignProductAreaModal;
