import { useContext, useEffect, useState } from "react";
import DiscoveryContext from "./DiscoveryContext";
import { Box } from "@mantine/core";
import EvidenceCardNiv from "../ideaNiv/body/EvidenceCardNiv";

const EvidenceList = () => {
  
  const {
    displayedMatches,
    displayedRequestsMatches,
    chosenEvidence,
    chooseMatch,
    activeSearch,
    searchedEvidence,
  } = useContext(DiscoveryContext);

  const [matches, setMatches] = useState<any[]>([]);

  useEffect(() => {

    if (activeSearch) {
      setMatches(searchedEvidence);
    } 
    else if (!activeSearch) {
      const concated = [...displayedMatches, ...displayedRequestsMatches].sort(
        (a, b) => b.rank - a.rank
      );
      setMatches(concated);
    }
  }, [
    displayedMatches,
    displayedRequestsMatches,
    activeSearch,
    searchedEvidence,
  ]);

  return (
    <Box
      pt={20}
      pb={14}
      pl={36}
      pr={86}
      w="98%"
    >
      {matches?.length > 0 &&
        matches?.map((evidence: any, index: number) => (
          <EvidenceCardNiv
            key={evidence._id}
            item={evidence}
            index={index}
            fromSearch={false}
            chosenEvidence={chosenEvidence}
            chooseMatch={chooseMatch}
            ideaPage={false}
          />
        ))}
    </Box>
  );
};

export default EvidenceList;
