import { Box, Stack, Text } from "@mantine/core";
import Panel from "./Panel/Panel";
import { DARK_GREY_TEXT } from "../../../../constants/colors";

const Header = () => {
  return (
    <Stack 
      h={134} 
      p={16}
      sx={{ borderBottom: "1px solid #D8D8DB" }} 
      justify="space-between"
    >
      <Box>
        <Text fz={22} fw={600}>
          Outlier Trends
        </Text>
        <Text fz={16} color={DARK_GREY_TEXT} >
        Ideas with Significant Recent Changes in Customer Demand
        </Text>
      </Box>
      <Panel />
    </Stack>
  );
};

export default Header;
