import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { Stack, Tooltip, Text, Flex, Box } from "@mantine/core";
import { TextCell } from "../components/TableCells/TextCell";
import { OwnerCell } from "../components/TableCells/OwnerCell";
import { PriorityCell } from "../components/TableCells/PriorityCell";
import { TemplateCell } from "../components/TableCells/TemplateCell";
import { CommitmentsCell } from "../components/TableCells/CommitmentsCell";
import { DynamicCell } from "../components/TableCells/DynamicCell";
import { updateAccessorByState } from "../../../utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import SyncContext from "../../../context/SyncContext";
import IdeasContext from "../IdeasContext";
import { useNavigate } from "react-router-dom";
import StarCell from "../components/TableCells/StarCell";
import NewRequestsCell from "../components/TableCells/NewRequestsCell";
import { TagsCell } from "../components/TableCells/TagsCell";
import { IconArrowDown } from "@tabler/icons";
import { FlippedArrow } from "./FlippedArrow";
import { useHover } from "@mantine/hooks";
import { DeliveryStatusCell } from "../components/TableCells/DeliveryStatusCell";
import { BarCell } from "../components/TableCells/bar/BarCell";
import { calcTopValues, parseFiltersFromQuery } from "../ideasHelpers";

export default function IdeasTable({
  ideaColumns,
  ideas,
  isAllowedToEditDates,
  handlePageChange,
  totalRecords,
  recordsPerPage,
  page,
  sortStatus,
  setSortStatus,
  loading,
  PAGE_SIZE,
}: {
  ideaColumns: any[];
  ideas: any[];
  isAllowedToEditDates: boolean;
  handlePageChange: (newPage: string) => void;
  totalRecords: number | undefined;
  recordsPerPage: number;
  page: number;
  sortStatus: DataTableSortStatus;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
  loading: boolean;
  PAGE_SIZE: number;
}) {
  const {
    requestPriorities,
    ideaPriorities,
    displayState,
    ideasTableDisplay,
    customEvidenceObj,
    generatedEnabled
  } = useContext(SyncContext);

  const { sortState, isTagsHovered, selectedView, tableColoumns } = useContext(IdeasContext);

  const [columns, setColumns] = useState<any[]>([]);

  const { ref } = useHover();

  const [dynamicSxObj, setDynamicSxObj] = useState<any>();

  const navigate = useNavigate();
  const cellComponents: { [key: string]: React.FC<any> } = {
    TextCell,
    OwnerCell,
    PriorityCell,
    TemplateCell,
    CommitmentsCell,
    StarCell,
    DynamicCell,
    NewRequestsCell,
    TagsCell,
    DeliveryStatusCell,
    BarCell,
    // Add other cell components as needed
  };

  const dealBreakerObj = requestPriorities.find(
    (r: any) => r.key === "Deal Breaker"
  ) || {
    key: "Deal Breaker",
    label: "Deal Breaker",
  };

  const handleOnRowClick = (ideaId: string) => {
    // Get the current query parameters.
    const currentParams = new URLSearchParams(location.search);
    // Create a new URLSearchParams instance to hold only evidence filters.
    const evidenceFilters = new URLSearchParams();
  
    // Iterate over all current query parameters.
    currentParams.forEach((value, key) => {
      if (key.startsWith("filter.evidence")) {
        evidenceFilters.set(key, value);
      }
    });
  
    // Build the new URL, adding the evidence filters if any exist.
    const queryString = evidenceFilters.toString();
    const url = `/idea/${ideaId}${queryString ? `?${queryString}` : ""}`;
  
    // Save current filters in sessionStorage.
    sessionStorage.setItem("lastFilters", `?${currentParams.toString()}`);
  
    // Navigate to the new URL.
    navigate(url);
  };

  const onSortStatusChanged = (sortStatus: DataTableSortStatus) => {
    setSortStatus((prevStatus) => {
      if (prevStatus?.columnAccessor === sortStatus.columnAccessor) {
        return sortStatus;
      } else {
        return { columnAccessor: sortStatus.columnAccessor, direction: "asc" };
      }
    });
  };

  const draggIt = (draggedTitle: string, droppedTitle: string) => {
    setColumns((prevColumns) => {
      const draggedIndex = prevColumns.findIndex(
        (col) => col.title === draggedTitle
      );
      const droppedIndex = prevColumns.findIndex(
        (col) => col.title === droppedTitle
      );

      if (
        draggedIndex === -1 ||
        droppedIndex === -1 ||
        draggedIndex === droppedIndex
      ) {
        // No valid reordering required
        return prevColumns;
      }

      // Extract the dragged column
      const draggedColumn = prevColumns[draggedIndex];

      // Create a new array excluding the dragged column
      const filteredColumns = prevColumns.filter(
        (_, index) => index !== draggedIndex
      );

      // Insert the dragged column at the dropped position
      const splicedCols = [
        ...filteredColumns.slice(0, droppedIndex),
        draggedColumn,
        ...filteredColumns.slice(droppedIndex),
      ].map((col, index) => ({
        ...col,
        index, // Reassign the correct index
      }));

      return splicedCols;
    });
  };

  const handleOnDrag = (e: React.DragEvent, draggedColTitle: string) => {
    e.dataTransfer.setData("title", draggedColTitle);
  };

  const handleOnDrop = (e: React.DragEvent, droppedColTitle: string) => {
    const draggedCol = e.dataTransfer.getData("title") as string;

    draggIt(draggedCol, droppedColTitle);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    setColumns(tableColoumns);
  }, [tableColoumns]);

  const cols = useMemo(
    () => calcTopValues(ideas, columns),
    [tableColoumns, sortState, ideas, sortStatus, columns]
  );

  useEffect(() => {
    const newSxObj: any = {};

    columns.forEach((colItem: any, index: number) => {
      const colBackground = colItem.customBackground;
      if (colBackground) {
        newSxObj[`tbody td:nth-of-type(${index + 1})`] = {
          backgroundColor: colBackground,
          "&:hover": {
            backgroundColor: "#EFEFFD !important",
          },
        };
      }
    });

    setDynamicSxObj(newSxObj);
  }, [columns, tableColoumns]);

  return (
    <Stack>
      <DataTable
        pinLastColumn
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          thead: {
            position: "sticky",
            top: 0,
            zIndex: 4,
            backgroundColor: "#F3F4F6",
          },
          "thead tr:first-of-type": {
            borderColor: "#dee2e6",
            fontSize: 12,
          },
          "thead th": {
            height: "48px",
            /* padding: "6px 10px !important", */
            fontSize: 12,
            textAlign: "left",
            verticalAlign: "middle",
            backgroundColor: "#EFEFFD",
            "&:hover": {
              backgroundColor: "#EFEFFD !important",
            },
          },
          "tbody td": {
            height: "48px",
            fontSize: 12,
          },
          "thead th:first-of-type": {
            position: "sticky",
            left: 0,
            zIndex: 5,
            backgroundColor: "#EFEFFD",
            borderRight: "1px solid #dee2e6",
          },
          "thead th:last-of-type": {
            position: "sticky",
            right: 0,
            zIndex: 5,
            backgroundColor: "#EFEFFD",
            borderLeft: "1px solid #dee2e6 !important",
          },
          "tbody td:first-of-type": {
            position: "sticky",
            left: 0,
            zIndex: 3,
            backgroundColor: "white",
            borderRight: "1px solid #dee2e6",
            "&:hover": {
              backgroundColor: "#EFEFFD !important",
            },
          },

          "tbody tr[data-state='all-generated'] td:first-of-type": {
            backgroundColor: "#FFFCE6",
          },
          "tbody td:last-of-type": {
            backgroundColor: "white",
            borderLeft: "1px solid #dee2e6 !important",
            /* "&:hover": {
              backgroundColor: "#dbe8ff !important", 
            }, */
          },
          "tbody tr": {
            "&:hover": {
              backgroundColor: !isTagsHovered
                ? "#EFEFFD !important"
                : "white !important", // Row hover background color
              "& td:first-of-type": {
                backgroundColor: !isTagsHovered
                  ? "#EFEFFD !important"
                  : "white !important", // First column hover color
              },
              "& td:last-of-type": {
                backgroundColor: !isTagsHovered
                  ? "#EFEFFD !important"
                  : "white !important", // Last column hover color
              },
              "& td": {
                backgroundColor: "inherit !important", // Ensure all cells follow the row hover style
              },
            },
          },
          ...dynamicSxObj,
        }}
        sortStatus={sortStatus}
        onSortStatusChange={onSortStatusChanged}
        withBorder
        idAccessor={"_id"}
        storeColumnsKey={"Ideas-table"}
        records={ideas}
        sortIcons={{
          sorted: <FlippedArrow />,
          unsorted: (
            <IconArrowDown
              size={14}
              strokeWidth={2.6}
              style={{
                color: "rgba(92, 92, 235, 0.4)",
                transform: "scale(0.95, 1)",
              }}
            />
          ),
        }}
        columns={[
          ...cols
            .filter((col: any) => col.enabled && !col.hidden)
            .sort((a: any, b: any) => a.index - b.index)
            .map((col: any) => {
              const CellComponent = cellComponents[col.renderComponent]
                ? cellComponents[col.renderComponent]
                : cellComponents["TextCell"];

              return {
                ...col,
                draggble: false,
                accessor:
                  col.renderComponent == "DynamicCell" ||
                  col.renderComponent == "BarCell"
                    ? updateAccessorByState(col.accessor, sortState)
                    : col.accessor,
                title: (
                  <div
                    style={{ cursor: "grab" }}
                    className={col.title}
                    draggable
                    onDragStart={(e) => handleOnDrag(e, col?.title)}
                    onDrop={(e) => handleOnDrop(e, col?.title)}
                    onDragOver={handleDragOver}
                  >
                    <Flex>
                      <Tooltip label={col?.tooltip} disabled={!col?.tooltip}>
                        {<Text>{col?.title}</Text>}
                      </Tooltip>
                    </Flex>
                  </div>
                ),
                width: col.width,
                sortable: col?.sortable,
                openIdeaOnCLick: col?.openIdeaOnCLick,

                resizable: false,
                render: (data: any) => (
                  <Box ref={ref}>
                    <CellComponent
                      data={data}
                      col={col}
                      renderParams={col.renderParams || {}}
                      ideaPriorities={ideaPriorities}
                      dealBreakerObj={dealBreakerObj}
                      customEvidenceObj={customEvidenceObj}
                      isAllowedToEditDates={isAllowedToEditDates}
                      ideasTableDisplay={ideasTableDisplay}
                      displayState={displayState}
                      accessor={col.accessor}
                    />
                  </Box>
                ),
              };
            }),
        ]}
        rowStyle={(record: any) => {
          if (
            record.state === "generated" &&
            selectedView?.name?.toLowerCase() === "all" &&
            generatedEnabled
          ) {
            return { backgroundColor: "#FFFCE6" };
          }
          return undefined;
        }}
        customRowAttributes={({ state }) => ({
          "data-state": `${selectedView?.name?.toLowerCase()}-${state}` ,
        })}
        height={`calc(100vh - 240px)`}
        totalRecords={totalRecords}
        recordsPerPage={recordsPerPage}
        fetching={loading}
        page={page}
        onPageChange={(p) => handlePageChange(p.toString())}
        onCellClick={({ record: idea, column }: any) => {
          if (
            column?.openIdeaOnCLick === undefined ||
            column?.openIdeaOnCLick
          ) {
            if (column?.renderComponent === "StarCell") {
              return;
            } else {
              handleOnRowClick(idea._id);
            }
          }
        }}
      />
    </Stack>
  );
}
