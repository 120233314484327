import {
  Box,
  Flex,
  ScrollArea,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import IdeaContext from "../../IdeaContext";
import { useAuth0 } from "@auth0/auth0-react";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import React from "react";
import { formatNumberValue } from "../../Funcs";
import {getNestedFieldValue} from "../../helpers/calculateImpactResults";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";
import { SparklesIcon } from "../../../../icons/x-symbol-svgrepo-com";
  
// Function to evaluate displayIf condition
export const evaluateDisplayIf = (data: any, displayIf: any): boolean => {
  if (!displayIf) return true;
  
  const value = getNestedFieldValue(data, displayIf.fieldPath);
  switch (displayIf.operator) {
  case 'exists':
    return value !== null && value !== undefined && value !== '' && value !== 'N/A';
  case 'equals':
    return value === displayIf.value;
  case 'notEquals':
    return value !== displayIf.value;
  default:
    return true;
  }
};
  
const CardInfoTextItem = ({ item, ideaPage, isInAccordian, activeTab, setActiveTab, setDetailsOpened }: { item: any, ideaPage: boolean, isInAccordian: boolean, activeTab: any, setActiveTab: any, setDetailsOpened: (isOpen: boolean) => void }) => {

  const {
    evidenceDomain,
    ideaInfoTabsConfig,
    components
  } = useContext(SyncContext);
  
  const ContextToUse: React.Context<any> = 
    ideaPage ? IdeaContext : DiscoveryContext;
  
  const { idea } = useContext(ContextToUse);

  // Generate tabs and content based on ideaInfoTabsConfig
  const tabsConfig = ideaInfoTabsConfig || {};
  const tabKeys = Object.keys(tabsConfig);
  
  // Filter tabs based on displayIf condition
  const filteredTabs = tabKeys.filter((tabKey) => {
    const tab = tabsConfig[tabKey];
    return evaluateDisplayIf(item, tab.displayIf);
  });
  
  const ideaComponentId = idea?.componentId || null
  const isMlsuggestion: boolean = item?.ml?.mlComponentId? true : false
  const ideaComponentExists: boolean = idea?.componentObj ? true : false
  /* const mlComponent = components?.flatMap((c:any) => 
    c._id === item?.ml?.mlComponentId
      ? [c] 
      : c.childComponents?.filter((child:any) => child._id === item?.ml?.mlComponentId) || []
  )?.[0] || null; */


  const mlComponent = item?.mlComponent

  const isSame = ideaComponentId === item?.ml?.mlComponentId
  const isChild = item?.mlComponent?.parentId === ideaComponentId
  const isParent = idea?.componentObj?.parentId ===item?.ml?.mlComponentId
  const warnUser = !isSame && !isChild && !isParent && ideaComponentExists
  useEffect(()=>{
    setActiveTab("details");
  },[setActiveTab])


  return (
    <Tabs value={activeTab} onTabChange={(value) => {
      if (isInAccordian) {
        setDetailsOpened(true);
        setTimeout(() => setActiveTab(value), 0); 
      } else {
        setActiveTab(value);
      }
    }}>
      {
        isInAccordian && 
        <Tabs.List>
          {/* Hardcoded Details Tab */}
          <Tabs.Tab value="details" sx={{padding: "16.6px"}}>
            <Text
              ta="left"
              fz={12}
              fw={600}
              color={activeTab === "details" ? "#212529" : "#5C5CEB"}
            >
              Details
            </Text>
          </Tabs.Tab>
    
          {/* Dynamically Generated Tabs */}
          {filteredTabs.map((tabKey) => {
            const tab = tabsConfig[tabKey];
            return (
              <Tabs.Tab key={tabKey} value={tabKey}>
                <Text
                  ta="left"
                  fz={12}
                  fw={600}
                  color={activeTab === tabKey ? "#212529" : "#5C5CEB"}
                >
                  {tab.label}
                </Text>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>
      }

      {
        !isInAccordian && (
          <>
            {/* Details Tab Panel */}
            <Tabs.Panel value="details">
              <Box mt={12}>
                {/* Domain */}
                {evidenceDomain && isMlsuggestion &&
                <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                        Domain
                  </Text>
                  <Tooltip maw={280} multiline label={warnUser ? 'Suggested by AI, but it does not match the product area of the idea' : 'Suggested by AI'}>
                    <Flex h={32} align="center">
                      <Text fz={12} color={warnUser ? '#DB2525' : isMlsuggestion ? "#5C5CEB" : 'black'}>
                        {capitalizeFirstLetter(mlComponent?.domain) || "Unassigned"}
                      </Text>
                      {isMlsuggestion &&<Box ml={4.2} mb={-3}>
                        <SparklesIcon  color={warnUser ? '#DB2525' : '#5B60E3'} />
                      </Box> } 
                    </Flex>
                  </Tooltip>
                </Flex>}
        
                {/* Product Area */}
                {isMlsuggestion && <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                        Product area
                  </Text>
                  <Tooltip maw={280} multiline label={warnUser ? 'Suggested by AI, but it does not match the product area of the idea' : 'Suggested by AI'}>
                    <Flex align="center" h={32}>
                      <Text fz={12} color={warnUser ? '#DB2525' : isMlsuggestion ? "#5C5CEB" : 'black'}>
                        {capitalizeFirstLetter(mlComponent?.name) || "Unassigned"}
                      </Text>
                      {isMlsuggestion &&<Box ml={4.2} mb={-3}>
                        <SparklesIcon  color={warnUser ? '#DB2525' : '#5B60E3'} />
                      </Box>}
                    </Flex>
                    

                  </Tooltip>
                  
                </Flex>}

                {/* */}
        
                {/* Owner */} 
                {/* <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                        Owner
                  </Text>
                  <Text fz={12}>
                    {item?.owner?.name || "Unassigned"}
                  </Text>
                </Flex> */}
              </Box>
            </Tabs.Panel>
        
            {/* Dynamically Generated Tabs Panels */}
            {filteredTabs.map((tabKey) => {
              const tab = tabsConfig[tabKey];
              return (
                <Tabs.Panel key={tabKey} value={tabKey}>
                  <ScrollArea h={176} mah={176}>
                    <Box mt={12}>
                      {tab.properties.map((property: any) => {
                        let value = getNestedFieldValue(item, property.fieldPath);
                          
                        if (value === undefined || value === null || value === '') {
                          value = "N/A";
                        } else if (typeof value === 'number') {
                          value = formatNumberValue(value);
                        } else if (typeof value === 'boolean') { // Added boolean check
                          value = value ? "Yes" : "No";
                        }
        
                        return (
                          <Flex key={property.fieldPath} h={32} align="center">
                            <Text fz={12} h={16} w={120}>
                              {property.label}
                            </Text>
                            <Text fz={12}>{value}</Text>
                          </Flex>
                        );
                      })}
                    </Box>
                  </ScrollArea>
                </Tabs.Panel>
              );
            })}
          </>
        )}
    </Tabs>
  );
};
  
export default CardInfoTextItem;