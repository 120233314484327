import { useAuth0 } from "@auth0/auth0-react";
import {
  markItemNotificationsRead,
  searchMembers,
  searchUsers,
} from "../../../../Api";
import MentionTextarea, { MentionSuggestion } from "../../../MentionTextarea";
import { useMentions } from "../../../MentionTextarea/MentionContext";
import { useContext, useEffect, useRef, useState } from "react";
import IdeaContext from "../../IdeaContext";
import {
  Box,
  Button,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import { IconSend, IconX } from "@tabler/icons";
import { CommentsNiv } from "./CommentsNiv";
import { useClickOutside } from "@mantine/hooks";
import useComments from "../../../../hooks/useComments";

const CommentsSection = ({
  item,
  setOpened,
}: {
  item: any;
  setOpened: any;
}) => {
  const { idea, chosenEvidence, getUnreadNotificationsCountByRequest } =
    useContext(IdeaContext);
  const { addComment, loading, fetchComments, ...rest } = useComments({
    evidenceId: chosenEvidence._id,
  });

  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();
  const [commentValue, setCommentValue] = useState("");

  const auth0 = useAuth0();
  const viewport = useRef<HTMLDivElement>(null);
  const scrollToCommentsBottom = () =>
    viewport?.current?.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });

  const closePanel = () => {
    setOpened(false);
  };

  const searchUser = ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    /* setSuggestionsLoading(true); */

    return new Promise<MentionSuggestion[]>((resolve, reject) => {
      console.log({ query });

      Promise.all([searchUsers(query, auth0), searchMembers(query, auth0)])
        .then(([users, members]) => {
          
          const userSuggestions: MentionSuggestion[] =
            users?.map((user: any) => ({
              id: user._id,
              email: user.email,
              name: user.name,
              picture: user.picture,
            })) ?? [];

          const memberSuggestions: MentionSuggestion[] =
            members
              ?.filter((member: any) => member.name)
              .map((member: any) => ({
                id: member._id,
                email: member.email,
                name: member.name,
                picture: member.picture,
              })) ?? [];

          const combinedSuggestions = [
            ...userSuggestions,
            ...memberSuggestions,
          ];
          const emailToUserMap = new Map<string, MentionSuggestion>();

          combinedSuggestions.forEach((suggestion) => {
            if (
              !emailToUserMap.has(suggestion.email) ||
              memberSuggestions.find(
                (member) => member.email === suggestion.email
              )
            ) {
              emailToUserMap.set(suggestion.email, suggestion);
            }
          });

          const uniqueSuggestions = Array.from(emailToUserMap.values());
        
          setSuggestions(uniqueSuggestions);
          resolve(uniqueSuggestions);
        })
        .catch((error) => {
          console.error("Error searching users and members:", error);
          reject(error);
        })
        .finally(() => setSuggestionsLoading(false));
    });
  };

  const markItemNotificationsReadFunc = async () => {
    await markItemNotificationsRead(item._id, auth0).then((res) => {
      console.log({ res });
    });
  };
  useEffect(() => {
    if (getUnreadNotificationsCountByRequest(item?._id) > 0)
      markItemNotificationsReadFunc();
    /* scrollToBottom(); */
  }, [chosenEvidence]);

  // Refs for preventing closing the window
  const editorBoxRef = useRef<HTMLDivElement>(null);
  const suggestionBoxRef = useRef<HTMLDivElement>(null);
  const closeOnClickOutside = useClickOutside(() => {
    if (
      (editorBoxRef.current &&
        !editorBoxRef.current.contains(document.activeElement)) ||
      (suggestionBoxRef.current &&
        !suggestionBoxRef.current.contains(document.activeElement))
    ) {
      closePanel();
    }
  });

  useEffect(() => {
    if (getUnreadNotificationsCountByRequest(item?._id) > 0)
      markItemNotificationsReadFunc();
  }, [chosenEvidence]);

  useEffect(() => {
    fetchComments();
  }, []);

  console.log({commentValue})
  return (
    <Box ref={closeOnClickOutside} h="calc(100vh - 138px)" w="496px">
      {/* Comments header */}
      <Group
        py={14}
        px={35}
        position="apart"
        sx={{ borderBottom: "1px solid #D8D8DB" }}
        bg="#FDFEFF"
      >
        <Text color="#212529" fz={20} fw={600}>
          Comments
        </Text>
        <Group
          align="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setOpened(false)}
        >
          <IconX size={16} color="#5C5CEB" />
          <Text ml="3px" color="#5C5CEB" fz={12}>
            Close
          </Text>
        </Group>
      </Group>

      {/* Comments Body */}
      <ScrollArea
        viewportRef={viewport}
        style={{ width: "100%", height: "calc(100vh - 291px)" }}
      >
        <CommentsNiv
          addComment={addComment}
          loading={loading}
          fetchComments={fetchComments}
          {...rest}
        />
      </ScrollArea>

      {/* Add a comment */}
      <Box
        sx={{
          background: "#F5F6FE",
          borderTop: "1px solid #E5E7EB",
          padding: 10,
        }}
      >
        <MentionTextarea<MentionSuggestion>
          placeholder="Write your message"
          getMentions={searchUser}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          editable
          clearOnRightSectionClick
          debounceWait={1000}
          sx={{
            background: "#fff",
            border: "1px solid #D8D8DB",
            borderRadius: 8,
            fontSize: 12,
            maxHeight: "60px",
            overflow: "auto",
          }}
          editorBoxRef={editorBoxRef}
          suggestionBoxRef={suggestionBoxRef}
        />
        <Group position="right" mt={8}>
          <Button
            compact
            onClick={async () => {
              await addComment(commentValue)
                .then(() => setCommentValue(""))
              
              scrollToCommentsBottom();
            }}
            variant="filled"
            color="blue"
            radius="md"
            loading={loading.add}
            disabled={commentValue.trim() === '<p></p>' || commentValue.trim() === ''}
            leftIcon={<IconSend size={16} />}
          >
            Send
          </Button>
        </Group>
      </Box>
    </Box>
  );
};
export default CommentsSection;
