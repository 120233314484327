import { AppShell } from "@mantine/core";
import { MainNav } from "./MainNav";
import { MainHeader } from "./Header/MainHeader";
import { ModalsProvider } from "@mantine/modals";
import './MainLayout.css';  // Import the external CSS file
import { useState } from "react";
import { BgColorRouteContext } from "../../context/BgColorContext";

export const MainLayout = (props: any) => {
  const [bgColor, setBgColor] = useState("#F8F8FB");
  const [pageName, setPageName] = useState("idea");

  // Determine the classes to apply based on bgColor
  const containerClass = pageName === "idea" ? "" : "responsive-container";
  const contentWrapperClass = pageName === "idea" ? "" :  "responsive-content-wrapper";

  return(
    <AppShell
      padding={0}
      navbar={<MainNav />}
      header={<MainHeader />}
      styles={{
        main: {
          backgroundColor: bgColor,
          overflow: "hidden"
        },
      }}
    >
      <BgColorRouteContext.Provider value={{ setBgColor, setPageName }}>
        <div className={containerClass}>
          <div className={contentWrapperClass}>
            <ModalsProvider labels={{ confirm: "Submit", cancel: "Cancel" }}>
              {props.children}
            </ModalsProvider>
          </div>
        </div>
      </BgColorRouteContext.Provider>
    </AppShell>
  )
};
