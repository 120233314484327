import { useContext } from "react";
import { Box } from "@mantine/core";
import DashboardContext from "../../../DashboardContext";
import { IdeaCard } from "./idea-card/IdeaCard";

const PlungingIdeasList = () => {
  // Adjust component

  const { filteredPluningIdeas } = useContext(DashboardContext);

  return (
    <Box pt={4} pr={4}>
      {filteredPluningIdeas &&
        filteredPluningIdeas.length > 0 &&
        filteredPluningIdeas.map((idea: any) => {
          return (
            <Box key={idea._id} sx={{ cursor: "pointer" }} >
              <IdeaCard
                ideaItem={idea}
                title={idea?.title || "N/A"}
                surging={false}
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default PlungingIdeasList;
