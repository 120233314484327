// src/hooks/useSuggestionMatches.ts

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getMatches, GetMatchesParams } from "../api/matches";
import { getSuggestionsForIdea } from "../Api";
import { showNotification } from "@mantine/notifications";

interface UseSuggestionMatchesParams {
  evidence?: any;
  evidenceId?: string;
  ideaId?: string;
}

export const useSuggestionMatches = ({
  evidence,
  evidenceId,
  ideaId,
}: UseSuggestionMatchesParams) => {
  const auth0 = useAuth0();
  const [itemMatches, setItemMatches] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      showNotification({
        title: "Error",
        message: error,
        color: "red",
        autoClose: 3000,
      });
    }
  }, [error]);

  useEffect(() => {
    // Ensure that at least one of evidenceId or ideaId is provided
    if (!evidenceId && !ideaId) {
      setError("Either evidenceId or ideaId must be provided.");
      return;
    }

    const fetchSuggestions = async () => {
      if (!evidence) return;
      const title = evidence.title || "";
      const text = evidence.description || "";
      // Fetch suggestions based on the evidence's title and description
      const suggestions = await getSuggestionsForIdea(title, text, auth0);
      if (suggestions?.ideaMatches?.length > 0) {
        console.log({ mlIdeas: suggestions.ideaMatches });
        setItemMatches(suggestions.ideaMatches);
      }
    };

    const fetchMatches = async () => {
      setLoading(true);
      setError(null);
      try {
        let params: GetMatchesParams;
        if (ideaId) {
          params = { ideaId };
        } else if (evidenceId) {
          params = { evidenceId };
        } else {
          throw new Error("Either ideaId or evidenceId must be provided.");
        }
    
        // Fetch matches using the API
        const matchesResponse = await getMatches(params, auth0);
        const matches = matchesResponse.matches || [];
        console.log({ matchesResponse });
    
        if (matches.length > 0) {
          if (evidenceId && evidence) {
            // Filter out matches where match.ideaId doesn't equal evidence.gtmIdeaId
            const filteredMatches = matches.filter(
              (match: any) => match.ideaId !== evidence?.gtmIdeaId
            );
            if (filteredMatches.length > 0) {
              setItemMatches(
                filteredMatches.map((match: any) => ({
                  ...match,
                  matchId: match._id,
                }))
              );
            } else {
              // If filtering left no matches, try fetching suggestions
              await fetchSuggestions();
            }
          } else if (ideaId) {
            setItemMatches(matchesResponse);
          }
        } else if (evidence) {
          // No matches found: fetch suggestions based on evidence
          await fetchSuggestions();
        }
      } catch (error: any) {
        console.error("Error fetching suggestion matches:", error);
        setError(error.message || "An error occurred while fetching matches.");
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [evidenceId, ideaId, auth0]);

  return { itemMatches, loading, error };
};
