import { Flex, Text, Tooltip } from "@mantine/core";
import { LIGHT_BLUE_BACKGROUND } from "../../../../../constants/colors";
import { TrendsCalendarIcon } from "../../../../../icons/x-symbol-svgrepo-com";

export const TimeFrame = () => {
  const currentDate = new Date();
  const threeMonthsAgoDate = new Date();
  threeMonthsAgoDate.setMonth(currentDate.getMonth() - 1);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  const getLastMonday = (date: Date) => {
    const lastMonday = new Date(date);
    const day = lastMonday.getDay();
    const diff = (day === 0 ? 6 : day - 1); // Adjust if today is Sunday
    lastMonday.setDate(lastMonday.getDate() - diff);
    return lastMonday;
  };

  const getLastSunday = (date: Date) => {
    const lastSunday = new Date(date);
    if (lastSunday.getDay() === 0) {
      lastSunday.setDate(lastSunday.getDate() - 7); // If today is Sunday, take the last Sunday
    } else {
      lastSunday.setDate(date.getDate() - date.getDay());
    }
    return lastSunday;
  };

  // Function to get the next Monday
  const getNextMonday = (date: Date) => {
    const nextMonday = new Date(date);
    const day = nextMonday.getDay();
    const diff = (day === 0 ? 1 : 8 - day); // Adjust if today is Sunday
    nextMonday.setDate(nextMonday.getDate() + diff - 7); // Subtract 7 days to get the Monday one week before
    return nextMonday;
  };

  const lastSunday = getLastSunday(currentDate);
  const threeMonthsAgoMonday = getLastMonday(threeMonthsAgoDate);
  const nextMondayMonthAgo = getNextMonday(threeMonthsAgoMonday);

  return (
    <Tooltip label={'Time Frame'}>
      <Flex
        bg={LIGHT_BLUE_BACKGROUND}
        sx={{ borderRadius: 4 }}
        h={32}
        align='center'
        px={5}
        justify='space-between'
      >
        <TrendsCalendarIcon />
        <Text fz={12} ml={5}>{formatDate(nextMondayMonthAgo)}</Text>
        <Text ml={3} mr={4}>-</Text>
        <Text fz={12}>{formatDate(lastSunday)}</Text>
      </Flex>
    </Tooltip>
  );
};

export default TimeFrame;