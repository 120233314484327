import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  LineChart,
  Line
} from "recharts";

const CardGraph = ({
  dataArr,
}: {
  dataArr: any[];
}) => {
  
  const noOlderArr = dataArr.filter((week) => week.monday !== 'Older')

  return (
    <div style={{ width: '100px' }}>
      <ResponsiveContainer width="100%" height={45}>
        <LineChart
          width={100}
          height={45}
          data={noOlderArr}
        >
          <XAxis dataKey="name" hide />
          <YAxis hide />
          {/* <Tooltip content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { name, AssignedValue, SuggestedValue } = payload[0].payload; 
              const currentDate = new Date();
              const oneMonthAgoDate = new Date();
              oneMonthAgoDate.setMonth(currentDate.getMonth() - 3);

              return (
                <Card bg='white' w={200} p="sm" withBorder shadow="xs">
                  <Flex
                    bg='white'
                    sx={{ borderRadius: 4 }}
                    h={32}
                    align='center'
                    px={5}
                    justify='space-between'
                  >
                    <Text fz={12} ml={5}>{formatDate(oneMonthAgoDate.toISOString())}</Text>
                    <Text ml={3} mr={4}>-</Text>
                    <Text fz={12}>{formatDate(currentDate.toISOString())}</Text>
                  </Flex>
                </Card>
              );
            }
            return null;
          }} /> */}
          <Line connectNulls type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" dot={{ r: 1 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CardGraph;