import {createContext, useCallback, useContext, useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChildProps } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import {
  getIdea,
  getIdeaMatches,
  getIdeaRequests,
  getRequestsMatchesForAnIdea,
  getUnreadNotificationsCount,
} from "../../Api";
import {
  getCalculatedPerspectiveForRequestNew,
} from "./helpers/aggregateRequestCompanyData";
import { useLocalStorage } from "@mantine/hooks";
import SyncContext from "../../context/SyncContext";
import { showNotification } from "@mantine/notifications";

export interface SortState {
  column: string;
  direction: number;  // 1 for ascending, -1 for descending
}

interface IdeaContextType {
  idea: any;
  allRequests: any[];
  ideaId: string | undefined;
  matches: any[];
  computed: string;
  matchesLoading: boolean;
  matchesCount: number;
  requestsCount: number;
  loadIdea: () => void;
  // pendingRequests: any[];
  // validatedRequests: any[];
  loadEvidence: () => void;
  displayedRequests: any[];
  setDisplayedRequests: React.Dispatch<React.SetStateAction<any[]>>;
  // tierData: any;
  // accountTypesData: any;
  // plansData: any;
  perspectiveData: { [key: string]: any };
  setPerspectiveData: React.Dispatch<
  React.SetStateAction<{ [key: string]: any }>>;
  loadingEvidence: boolean;
  roundChartsData: any;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setshowmatches: (val: boolean | ((prevState: boolean) => boolean)) => void;
  showmatches: boolean;
  setShowValidated: (val: boolean | ((prevState: boolean) => boolean)) => void;
  showValidated: boolean;
  unreadNotificationsLoading: boolean;
  unreadNotificationsMap: any[];
  getUnreadNotificationsCountByRequest: (requestId: string) => any;
  reCalculatPerspectiveForRequest: (request: any, action: "init" | "add" | "update" | "remove") => void;
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  cardView: boolean;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  chosenEvidence: any | null;
  setChosenEvidence: React.Dispatch<any | null>;
  displayedMatches: any[];
  setDisplayedMatches: React.Dispatch<React.SetStateAction<any[]>>;
  activeSort: SortState | null;
  setActiveSort: React.Dispatch<React.SetStateAction<SortState | null>>;
  matchesTimer: () => void;
  matchesTrigger: number;
  getMatchesUpdate: () => void;
  loadingIdea: boolean;
  setMatchesTrigger: React.Dispatch<React.SetStateAction<number>>;
  triggerImpact: boolean
  setTriggerImpact: React.Dispatch<React.SetStateAction<boolean>>;
  requestsCompanies: any[];
  matchesCompanies: any[];
  requestsMatches: any[];
  displayedRequestsMatches: any[];
  setDisplayedRequestsMatches: React.Dispatch<React.SetStateAction<any[]>>;
  requestsMatchesCompanies: any[];
  showPending: boolean;
  setShowPending: React.Dispatch<React.SetStateAction<boolean>>;
  activeInboxTab: boolean;
  setActiveInboxTab: React.Dispatch<React.SetStateAction<boolean>>;
  quarters: string[];
  requestsTrend: any[];
  setRequestsTrend: React.Dispatch<React.SetStateAction<any[]>>;
  matchesTrend: any[];
  setMatchesTrend: React.Dispatch<React.SetStateAction<any[]>>;
  requestsMatchesTrend: any[];
  setRequestsMatchesTrend: React.Dispatch<React.SetStateAction<any[]>>;
  weeks: string[];
  perspectiveCalculated: boolean;
  isCommentPresent: any;
  setIsCommentPresent: React.Dispatch<React.SetStateAction<any>>;
  comments: any;
  setComments: any;
  setMatches: React.Dispatch<React.SetStateAction<any[]>>;
  setRequestsMatches: React.Dispatch<React.SetStateAction<any[]>>;
  isGenerated: boolean;
  fetchData: any
}

const IdeaContext = createContext<IdeaContextType>({} as IdeaContextType);

const IdeaContextProvider = ({ children }: ChildProps) => {
  const auth0 = useAuth0();
  const { perspectiveConfigurations } = useContext(SyncContext);

  const { id: ideaId } = useParams();
  const [idea, setIdea] = useState<any | null>(null);
  const [title, setTitle] = useState("");
  const [matches, setMatches] = useState<any[]>([]);
  const [matchesTrend, setMatchesTrend] = useState<any[]>([]);
  const [matchesLoading, setMatchesLoading] = useState<boolean>(true);
  const [matchesCount, setMatchesCount] = useState(0);
  const [displayedMatches, setDisplayedMatches] = useState<any[]>([]);
  const [showmatches, setshowmatches] = useLocalStorage({ key: 'showmatches', defaultValue: true });
  const [showValidated, setShowValidated] = useState<boolean>(true);
  const [showPending, setShowPending] = useState<boolean>(false);
  const [requestsLoading, setRequestsLoading] = useState<boolean>(false);
  const [requestsCount, setRequestsCount] = useState(0);
  const [displayedRequests, setDisplayedRequests] = useState<any[]>([]);
  const [requestsTrend, setRequestsTrend] = useState<any[]>([]);
  const [quarters, setQuarters] = useState<string[]>([]);
  const [weeks, setWeeks] = useState<string[]>([]);
  const [allRequests, setAllRequests] = useState<any[]>([]);
  const [computed, setComputed] = useState("");
  const [perspectiveData, setPerspectiveData] = useState<{
    [key: string]: any;
  }>({
    // "Customer Tier": {},
    // "Account Type": {},
    // "Customer Plan": {},
    // "Business Sources": {},
  });
  const [loadingEvidence, setLoadingEvidence] = useState(true);
  const [roundChartsData, setRoundChartsData] = useState<any>({});
  const [type, setType] = useState("businessName");
  const [unreadNotificationsLoading, setUnreadNotificationsLoading] =
    useState<boolean>(false);
  const [unreadNotificationsMap, setUnreadNotificationsMap] = useState<any[]>(
    []
  );
  const [addEvidenceFormScreen, setAddEvidenceFormScreen] = useState(false);
  const [cardView, setCardView] = useState(false);
  const [chosenEvidence, setChosenEvidence] = useState<any>(null);
  const [activeSort, setActiveSort] = useState<SortState | null>(null);
  const [matchesTrigger, setMatchesTrigger] = useState(0);
  const [loadingIdea, setLoadingIdea] = useState(true);
  const [triggerImpact, setTriggerImpact]= useState(false);
  const [requestsCompanies, setRequestsCompanies] = useState<any[]>([])
  const [matchesCompanies, setMatchesCompanies] = useState<any[]>([])
  const [requestsMatches, setRequestsMatches] = useState<any[]>([])
  const [requestsMatchesTrend, setRequestsMatchesTrend] = useState<any[]>([])
  const [displayedRequestsMatches, setDisplayedRequestsMatches] = useState<any[]>([])
  const [requestsMatchesCompanies, setRequestsMatchesCompanies] = useState<any[]>([])
  const [activeInboxTab, setActiveInboxTab] = useState(false)
  const [perspectiveCalculated, setPerspectiveCalculated] = useState(false)
  const [isCommentPresent, setIsCommentPresent] = useState(false);
  const [comments, setComments] = useState<any>({});
  const [isGenerated, setIsGenerated] = useState(false);
  /* console.log('isGenerated')
  console.log(isGenerated) */

  const loadIdea = async () => {
    if (!ideaId) {
      return false;
    }

    const res = await getIdea(ideaId, auth0);
    setLoadingIdea(false)
    setIdea(res);
    setTitle(res.title);
    setIsGenerated(res.state === 'generated')

    if (res.matchesComputeStatus === "done") {
      setComputed("done");
    }
  };

  const getAllRequests = async () => {
    /* console.log(" filters- getAllRequests called"); */
    if (!ideaId) {
      return false;
    }
    setMatchesLoading(true);
    const res = await getIdeaRequests(ideaId, auth0);
    setQuarters(res?.quarters)
    setWeeks(res?.weeks)
    const allRequestsRes = res.evidences || [];
 
    // console.log(allRequestsRes)

    const requestsOrgs = allRequestsRes.map((a: any)=> a = a?.company).filter((company: any)=> company?._id !== 'N/A')

    setRequestsCompanies(requestsOrgs)
    setAllRequests(allRequestsRes);
     

   
    if (allRequestsRes?.length > 0) {
      setDisplayedRequests(allRequestsRes)
      setRequestsTrend(allRequestsRes)
    }
    setLoadingEvidence(false);
    // setRoundChartsData(res?.roundChartsData)
    setMatchesLoading(false);
    /* console.log(res?.businessCategories); */
  };

  const getUnreadNotifications = async () => {
    if (!ideaId) {
      return false;
    }
    setUnreadNotificationsLoading(true);
    await getUnreadNotificationsCount(ideaId, auth0)
      .then(({ requests }) => {
        setUnreadNotificationsMap(requests);
      })
      .catch((e) => console.log(e))
      .finally(() => setUnreadNotificationsLoading(false));
  };

  const getUnreadNotificationsCountByRequest = (requestId: string) => {
    return (
      unreadNotificationsMap.find((request) => request._id == requestId)
        ?.unreadNotificationsCount || 0
    );
  };

  const getMatches = async () => {
    if (ideaId) {
      setMatchesLoading(true);
      const res = await getIdeaMatches(ideaId, auth0);
      const matches = res?.evidences || [];
      const matchesOrgs = res?.evidences?.map((a: any)=> a = a.company).filter((company: any)=> company?._id !== 'N/A')

      setMatchesCompanies(matchesOrgs)
      setMatches(matches);
      setMatchesTrend(matches)
      setDisplayedMatches(matches);
      setMatchesCount(matches?.length);
      setMatchesLoading(false); 
      

    }
  };

  const loadEvidence = async () => {
  
    return await getAllRequests()
      .then(async () => {
        await getMatches().catch((e) => console.log(e))
        await getRequestsMatches().catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const reCalculatPerspectiveForRequest = useCallback(
    (request: any, action: "init" | "add" | 'update' | "remove") => {

      /* console.log('calculating') */
      const matchesMapped = matches.map(
        (m: any) => (m = { ...m, status: "match", source: "suggestion", isGapMatch : true })
      );
      const requestsMatchesMapped = requestsMatches.map(
        (m: any) => (m = { ...m, status: "match", source: "suggestion", isRequestMatch : true })
      ); 
      
      const allRequestsMapped = allRequests.map(
        (a: any, index: number) =>
          (a = {
            ...a,
            
            sortInit: index,
          })
      );

      let requestsToCalculate: any[] = matchesMapped.concat(requestsMatchesMapped).concat(allRequestsMapped);

      if (action === "remove") {
        requestsToCalculate = requestsToCalculate.filter(
          (r) => r._id !== request._id
        );
      } else if (action === "add") {
        requestsToCalculate = requestsToCalculate.concat(request);
      } else if (action === "update") {
        requestsToCalculate = requestsToCalculate.map(r =>
          r._id === request._id ? { ...r, ...request } : r
        );
      }

      getCalculatedPerspectiveForRequestNew(requestsToCalculate, perspectiveConfigurations)
        .then((data) => {
          /* console.log("getCalculatedPerspectiveForRequest", { data, perspectiveConfigurations }); */
           
          setPerspectiveData((prev) => ({
            ...prev,
            ...data
          }));
          setPerspectiveCalculated(!perspectiveCalculated)
        })
        .catch((error) => {
          console.error("Error recalculating company data:", error);
        });
    },
    [allRequests, matches, requestsMatches, activeInboxTab, showmatches, showValidated]
  );

  const getMatchesUpdate = () => {
    if (ideaId && matchesTrigger > 0) {
      getIdeaMatches(ideaId, auth0)
        .then((res: any) => {
          setMatches(res?.evidences || []);
          
          const matchesOrgs = res?.evidences?.map((a: any)=> a = a.company).filter((company: any)=> company?._id !== 'N/A')

          setMatchesCompanies(matchesOrgs)

          setDisplayedMatches(res?.evidences || []);
          setMatchesTrend(res?.evidences || []);
          setMatchesCount(res?.evidences?.length || 0);
          setComputed(res?.computed || "");
          
        })
        .catch((e: any) => console.log(e))
        .finally(() => {
          setMatchesLoading(false);
        });
    } 
  };

  const getRequestsMatches = async ()=>{
    ideaId && getRequestsMatchesForAnIdea(ideaId, auth0).then((res)=>{

      setRequestsMatches(res?.evidences)
      setRequestsMatchesTrend(res?.evidences)
      setDisplayedRequestsMatches(res?.evidences)
      const mapped = res?.evidences?.map((a: any)=> a = a.company).filter((company: any)=> company?._id !== 'N/A')
      setRequestsMatchesCompanies(mapped)
    })
  }

  const matchesTimer = () => {
    setTimeout(
      () => {
        setMatchesTrigger(matchesTrigger + 1);
      },
      matchesTrigger < 2 ? 10000 : 60000
    );
  };

  
  const navigate = useNavigate();

  async function fetchData() {
    try {
      await loadIdea();
      await loadEvidence();
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.error?.message === 'Invalid id format') {
        showNotification({
          title: e.response.data.error.message,
          message: "Redirecting you back to home page",
          color: "red",
          autoClose: 3000,
        });
        
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    }
    getUnreadNotifications();
  }

  useEffect(() => {

    
    fetchData();
  }, [ideaId, triggerImpact]);
  
  //TODO remove on aggregations fix
  useEffect(() => {
    reCalculatPerspectiveForRequest({}, "init")
  }, [allRequests, matches, requestsMatches]);



  return (
    <>
      <IdeaContext.Provider
        value={{
          idea,
          allRequests,
          ideaId,
          matches,
          computed,
          matchesLoading,
          matchesCount,
          requestsCount,
          loadIdea,
          loadEvidence,
          displayedRequests,
          setDisplayedRequests,
          // tierData,
          // accountTypesData,
          // plansData,
          perspectiveData,
          setPerspectiveData,
          loadingEvidence,
          roundChartsData,
          type,
          setType,
          showmatches,
          setshowmatches,
          showValidated, 
          setShowValidated,
          unreadNotificationsLoading,
          unreadNotificationsMap,
          getUnreadNotificationsCountByRequest,
          reCalculatPerspectiveForRequest,
          addEvidenceFormScreen,
          setAddEvidenceFormScreen,
          cardView,
          setCardView,
          chosenEvidence,
          setChosenEvidence,
          displayedMatches,
          setDisplayedMatches,
          activeSort,
          setActiveSort,
          matchesTimer,
          matchesTrigger,
          getMatchesUpdate,
          loadingIdea,
          setMatchesTrigger,
          triggerImpact, 
          setTriggerImpact,
          requestsCompanies,
          matchesCompanies, 
          requestsMatches,
          displayedRequestsMatches, 
          setDisplayedRequestsMatches,
          requestsMatchesCompanies,
          showPending,
          setShowPending,
          activeInboxTab,
          setActiveInboxTab,
          quarters,
          requestsTrend,
          setRequestsTrend,
          matchesTrend,
          setMatchesTrend,
          requestsMatchesTrend,
          setRequestsMatchesTrend,
          weeks,
          perspectiveCalculated,
          isCommentPresent,
          setIsCommentPresent,
          comments,
          setComments,
          setMatches, 
          setRequestsMatches,
          isGenerated,
          fetchData
        }}
      >
        {children}
      </IdeaContext.Provider>
    </>
  );
};

export { IdeaContext, IdeaContextProvider };
export default IdeaContext;
