import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Checkbox,
  ScrollArea,
  TextInput,
  Text,
  Highlight,
  Stack,
} from "@mantine/core";
import { IconListSearch } from "@tabler/icons";

import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import FiltersContext from "../context/FiltersContext";
import OperationSelect from "../OperationSelect";
import AddFilterBtn from "../AddFilterBtn";
import SyncContext from "../../../../context/SyncContext";
import { Filter } from "../FiltersMenu";
import FilterFooter from "../FilterFooter";
import { findFiltersByPath } from "../../../../context/helpers";

interface CheckboxFilterProps {
  initialfilters: Filter;
  MenuType: "idea" | "evidence";
  resetFilters: (
      MenuType: "idea" | "evidence",
      selectedParentFilter?: string | null
  ) => void;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  MenuType,
  resetFilters,
}) => {
  const { ideasFiltersConfig } = useContext(SyncContext);
  const {
    activeFilters,
    setActiveFilters,
    selectedParentFilter,
    checkedComponents,
    setCheckedComponents,
    childFiltersValue,
    childQuery,
    setChildQuery,
    query,
    setQuery,
    operator,
    setOperator,
    setOpenedMenuType,
    setSelectedParentFilter,
  } = useContext(FiltersContext);

  console.log(selectedParentFilter);

  const _selectedParentFilter: string[] =
      selectedParentFilter?.split(".") || [""];

  // Function to recursively get the selected filter based on the path
  const getSelectedFilter = (config: any, path: string[]): any => {
    let current = config[MenuType];

    for (const key of path) {
      if (current && current[key]) {
        current = current[key];
      } else if (current && current.childFilters) {
        // Try to find the filter in childFilters by key or by name (case-insensitive)
        const keys = Object.keys(current.childFilters);
        const matchingKey = keys.find(
          (k) => k.toLowerCase().replace(/\s+/g, "-") === key.toLowerCase()
        );

        if (matchingKey) {
          current = current.childFilters[matchingKey];
        } else {
          // Try to find by 'name' property
          const matchingFilter = Object.values(current.childFilters).find(
            (filter: any) => filter.name.toLowerCase() === key.toLowerCase()
          );
          if (matchingFilter) {
            current = matchingFilter;
          } else {
            return undefined;
          }
        }
      } else {
        return undefined;
      }
    }
    return current;
  };

  const selectedFilter = getSelectedFilter(
    ideasFiltersConfig,
    _selectedParentFilter
  );

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
  const selectedOperators = selectedFilter?.operations;
  const selectedOptions = selectedFilter?.options;

  useEffect(() => {
    const currentFilters: any = activeFilters.find(
      (aF) => aF.parent === selectedParentFilter
    );

    if (currentFilters?.selected && selectedParentFilter && currentFilters) {
      setCheckedComponents((prev) => ({
        ...prev,
        [selectedParentFilter]: currentFilters.selected.map((o: string) =>
          o.toLowerCase()
        ),
      }));
    }
  }, []);

  // Recursive function to render options, including childComponents
  const renderOptions = (options: any, parentPath: string[] = []) => {
    if (!options) return null;

    return options.map((option: any, index: number) => {
      const currentPath = [
        ...parentPath,
        option.value?.toLowerCase() || option.label?.toLowerCase(),
      ];
      const hasChildren =
          option.childComponents && option.childComponents.length > 0;

      return (
        <div
          key={option.value || option.label || index}
          style={{ paddingLeft: parentPath.length * 20 }}
        >
          <Checkbox
            my={5}
            sx={{
              cursor: "pointer",
              input: {
                ":checked": {
                  backgroundColor: "#5B60E3",
                  borderColor: "#5B60E3",
                },
              },
            }}
            pr="sm"
            pl={"sm"}
            py={3}
            value={option.value?.toLowerCase() || option.label?.toLowerCase()}
            label={
              <Text>
                <Highlight highlight={capitalizeFirstLetter(query)}>
                  {capitalizeFirstLetter(option.label)}
                </Highlight>
              </Text>
            }
          />
          {hasChildren && renderOptions(option.childComponents, currentPath)}
        </div>
      );
    });
  };

  // Function to find an option (and its children) by value
  const findOptionByValue = (optionsList: any[], value: string): any => {
    for (const option of optionsList) {
      if (
        (option.value?.toLowerCase() || option.label?.toLowerCase()) === value
      ) {
        return option;
      }
      if (option.childComponents) {
        const found = findOptionByValue(option.childComponents, value);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to get all child values (recursive)
  const getAllChildValues = (option: any): string[] => {
    let values = [];
    if (option.value || option.label) {
      values.push(option.value?.toLowerCase() || option.label?.toLowerCase());
    }
    if (option.childComponents) {
      for (const child of option.childComponents) {
        values = values.concat(getAllChildValues(child));
      }
    }
    return values;
  };

  const handleCheckedComponentsChange = (selectedArray: string[]) => {
    if (selectedParentFilter) {
      const prevSelectedArray = checkedComponents[selectedParentFilter] || [];

      // Find out which checkbox was toggled
      const addedValues = selectedArray.filter(
        (value) => !prevSelectedArray.includes(value)
      );
      const removedValues = prevSelectedArray.filter(
        (value) => !selectedArray.includes(value)
      );

      const toggledValue =
          addedValues.length > 0 ? addedValues[0] : removedValues[0];
      const isAdding = addedValues.length > 0;

      // Now we need to find if the toggledValue has childComponents
      const toggledOption = findOptionByValue(optionsToRender, toggledValue);

      if (toggledOption) {
        const allValues = getAllChildValues(toggledOption);

        if (isAdding) {
          // Add all child values to selectedArray
          allValues.forEach((val) => {
            if (!selectedArray.includes(val)) {
              selectedArray.push(val);
            }
          });
        } else {
          // Remove all child values from selectedArray
          selectedArray = selectedArray.filter(
            (value) => !allValues.includes(value)
          );
        }
      }

      // Update the state
      setCheckedComponents((prev) => ({
        ...prev,
        [selectedParentFilter]: selectedArray,
      }));
    }
  };

  const optionsToRender = useMemo(() => {
    if (!selectedOptions) return [];

    let optionsList: any[] = [];

    if (Array.isArray(selectedOptions)) {
      optionsList = selectedOptions.map((option: any) => ({
        label: option?.label?.label || option.label,
        value: option?.value?.label || option.value,
        childComponents: option.childComponents || [],
      }));
    } else if (typeof selectedOptions === "object") {
      optionsList = Object.entries(selectedOptions).map(
        ([key, value]: [string, any]) => ({
          label: value.name || value.label || key,
          value: value.value || key,
          childComponents: value.childComponents || [],
        })
      );
    }

    if (query) {
      const lowerQuery = query.toLowerCase();
      const filterOptions = (opts: any[]): any[] => {
        return opts
          .map((option) => {
            // Recursively filter the child components
            const filteredChildren = filterOptions(option.childComponents || []);
            // Include the option if its own label matches or if any child matches
            if (
              option.label.toLowerCase().includes(lowerQuery) ||
                  filteredChildren.length > 0
            ) {
              return {
                ...option,
                childComponents: filteredChildren,
              };
            }
            return null;
          })
          .filter((option) => option !== null);
      };
      optionsList = filterOptions(optionsList);
    }

    return optionsList;
  }, [selectedOptions, query]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      selectedParentFilter &&
        setChildQuery((prev) => ({
          ...prev,
          [selectedParentFilter]: newValue,
        }));
      setQuery(newValue);
    },
    [selectedParentFilter]
  );

  return (
    selectedParentFilter && (
      <>
        {selectedHasOperations && (
          <OperationSelect
            operations={selectedOperators}
            onChange={(value) => {
              setOperator((prev: any) => ({
                ...prev,
                [selectedParentFilter]: value,
              }));
            }}
          />
        )}
        <TextInput
          mx={5}
          radius="md"
          value={childQuery && childQuery[selectedParentFilter]}
          placeholder={`Search ${_selectedParentFilter[
            _selectedParentFilter.length - 1
          ].toLocaleLowerCase()}`}
          onChange={handleInputChange}
          icon={<IconListSearch size={14.5} />}
          sx={{
            input: {
              backgroundColor: "#F9FAFB",
              ":focus": {
                borderColor: "#b1b1b1;",
              },
            },
          }}
        />
        <ScrollArea
          style={{
            height:
                      selectedParentFilter === "evidence_priority" ? 150 : 280,
          }}
        >
          <Checkbox.Group
            mt={8}
            value={checkedComponents[selectedParentFilter]}
            onChange={handleCheckedComponentsChange}
          >
            {renderOptions(optionsToRender)}
          </Checkbox.Group>
        </ScrollArea>
        <FilterFooter
          onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
          ApplyButton={
            <AddFilterBtn
              BtnText="Apply"
              selectedParentFilter={selectedParentFilter}
              childFiltersValue={childFiltersValue}
              disabled={
                (!checkedComponents[selectedParentFilter] ||
                              checkedComponents[selectedParentFilter]?.length === 0) ||
                          ((selectedParentFilter !== null &&
                                  childFiltersValue[selectedParentFilter]?.length === 0) ||
                              (selectedHasOperations && !operator))
              }
              onClick={() => {
                const parentFilterObj = findFiltersByPath(
                  ideasFiltersConfig,
                  `${MenuType}.${selectedParentFilter}`
                );

                setActiveFilters((prevFilters: any) => [
                  {
                    ...parentFilterObj,
                    type: parentFilterObj?.type,
                    parent: selectedParentFilter,
                    operator:
                                operator && operator[selectedParentFilter],
                    selected:
                                checkedComponents[selectedParentFilter],
                    query: null,
                  },
                  ...prevFilters.filter(
                    (prevF: any) =>
                      prevF.parent !== selectedParentFilter
                  ),
                ]);
                setOpenedMenuType(null);
                setSelectedParentFilter(null);
              }}
            />
          }
        />
      </>
    )
  );
};

export default CheckboxFilter;