import { Button, Menu, Text, Tooltip } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";

const AssignActionsMenu = ({
  actions,
}: {
  actions: { label: string; tooltip?: string; onClick: () => void }[];
}) => {
  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          color="indigo" 
          styles={{ 
            root: { 
              height: "32px",
              width: "32px",
              margin: "0px", 
              padding: "0px",
              backgroundColor:"#5C5CEB", 
              '&:hover': {
                backgroundColor: "#5353D3 !important",
              },
              '&:active': {
                backgroundColor: "#5353D3 !important",
              }
            } }}>
          <IconChevronDown size={16} color="white" />
        </Button>
      </Menu.Target>
      <Menu.Dropdown ml= "-35px" sx={{ borderRadius: "8px", width: "250px" }}>
        {actions.map((action, index) => (
          <Menu.Item key={index} w={"240px"} pl={"15px"} onClick={action.onClick}>
            {action.tooltip ? (
              <Tooltip label={action.tooltip} position="top" withArrow>
                <Text fz={14}>{action.label}</Text>
              </Tooltip>
            ) : (
              <Text fz={14}>{action.label}</Text>
            )}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default AssignActionsMenu;
