// src/hooks/useEvidenceActions.ts

import { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  validateEvidence,
  dismissEvidence,
  assignEvidencesToAnotherIdea,
  ValidateEvidencePayload,
  DismissEvidenceParams,
  AssignEvidencesPayload,
} from "../api/evidences";
import { showNotification } from "@mantine/notifications";

/**
 * Interface for the hook's return value
 */
interface UseEvidenceActionsReturn {
  validate: (
    payload: ValidateEvidencePayload
  ) => Promise<{ success: boolean; evidence: any }>;
  validateMultiple: (
    payload: AssignEvidencesPayload
  ) => Promise<{ success: boolean; evidences: any[] }>;
  dismiss: (params: DismissEvidenceParams) => Promise<boolean>;
  isLoading: {
    validate: boolean;
    validateMultiple: boolean;
    dismiss: boolean;
  };
  error: {
    validate: string | null;
    validateMultiple: string | null;
    dismiss: string | null;
  };
}

/**
 * Custom hook to handle evidence validation and dismissal with separate states
 */
const useEvidenceActions = ({
  evidenceId,
}: {
  evidenceId?: any;
}): UseEvidenceActionsReturn => {
  const auth0 = useAuth0();

  const [isLoading, setIsLoading] = useState<{
    validate: boolean;
    validateMultiple: boolean;
    dismiss: boolean;
  }>({
    validate: false,
    validateMultiple: false,
    dismiss: false,
  });

  const [error, setError] = useState<{
    validate: string | null;
    validateMultiple: string | null;
    dismiss: string | null;
  }>({
    validate: null,
    validateMultiple: null,
    dismiss: null,
  });

  useEffect(() => {
    const errorMessage = error.validate || error.validateMultiple || error.dismiss;
    if (errorMessage) {
      showNotification({
        title: "Error",
        message: errorMessage,
        color: "red",
        autoClose: 3000,
      });
    }
  }, [error]);

  /**
   * Validate an evidence
   * @param payload - The payload containing ideaId and optional matchId
   * @returns A promise resolving to a boolean indicating success
   */
  const validate = useCallback(
    async (
      payload: ValidateEvidencePayload
    ): Promise<{ success: boolean; evidence: any }> => {
      setIsLoading((prev) => ({ ...prev, validate: true }));
      setError((prev) => ({ ...prev, validate: null }));
      try {
        const response = await validateEvidence(evidenceId, payload, auth0);
        if (response.success) {
          return response as { success: boolean; evidence: any };
        } else {
          setError((prev) => ({
            ...prev,
            validate: "Failed to validate evidence.",
          }));
          return { success: false, evidence: null };
        }
      } catch (e: any) {
        setError((prev) => ({
          ...prev,
          validate: e.message || "An error occurred during validation.",
        }));
        return { success: false, evidence: null };
      } finally {
        setIsLoading((prev) => ({ ...prev, validate: false }));
      }
    },
    [auth0]
  );

  /**
   * Validate multiple evidences
   */
  const validateMultiple = useCallback(
    async (
      payload: AssignEvidencesPayload
    ): Promise<{ success: boolean; evidences: any[] }> => {
      setIsLoading((prev) => ({ ...prev, validateMultiple: true }));
      setError((prev) => ({ ...prev, validateMultiple: null }));
      try {
        const response = await assignEvidencesToAnotherIdea(payload, auth0);
        if (response) {
          return { success: true, evidences: response };
        } else {
          setError((prev) => ({
            ...prev,
            validateMultiple: "Failed to validate multiple evidences.",
          }));
          return { success: false, evidences: [] };
        }
      } catch (e: any) {
        setError((prev) => ({
          ...prev,
          validateMultiple: e.message || "An error occurred during validation.",
        }));
        return { success: false, evidences: [] };
      } finally {
        setIsLoading((prev) => ({ ...prev, validateMultiple: false }));
      }
    },
    [auth0]
  );

  /**
 * Dismiss an evidence.
 * @param params - Object containing:
 *   - ideaId: string - The ID of the idea associated with the evidence.
 *   - matchId?: string - (Optional) The ID of the match to dismiss.
 *   - rejectInfo?: RejectInfo - (Optional) Additional information about the rejection.
 * @returns A promise resolving to a boolean indicating whether the dismissal was successful.
 */
  const dismiss = useCallback(
    async (params: DismissEvidenceParams): Promise<boolean> => {
      setIsLoading((prev) => ({ ...prev, dismiss: true }));
      setError((prev) => ({ ...prev, dismiss: null }));
      try {
        // Pass evidenceId, ideaId, matchId (if available), rejectInfo, and auth0 to the API function.
        const response = await dismissEvidence(evidenceId, params, auth0);
        if (response.success) {
          return true;
        } else {
          setError((prev) => ({
            ...prev,
            dismiss: "Failed to dismiss evidence.",
          }));
          return false;
        }
      } catch (e: any) {
        setError((prev) => ({
          ...prev,
          dismiss: e.message || "An error occurred during dismissal.",
        }));
        return false;
      } finally {
        setIsLoading((prev) => ({ ...prev, dismiss: false }));
      }
    },
    [auth0, evidenceId]
  );

  return { validate, validateMultiple, dismiss, isLoading, error };
};

export default useEvidenceActions;
