import { useContext } from "react";
import { Box, Flex, ScrollArea, Text, Tooltip } from "@mantine/core";
import DashboardContext from "../../DashboardContext";
import { LIGHT_BLUE_BACKGROUND } from "../../../../constants/colors";
import { IconArrowRight } from "@tabler/icons";
import ImpactListDasboard from "./impact/ImpactListDasboard";
import SyncContext from "../../../../context/SyncContext";
import DashboardTrend from "./DashboardTrend";
import PerspectivesMenu from "./Perspectives/PerspectivesMenu";
import { OverflownText } from "../../../OverflownText";

const IdeaDataCard = () => {
  const { chosenIdea } = useContext(DashboardContext);

  const { impactAggregationProperties } = useContext(SyncContext);

  const trendsData = (chosenIdea?.trends?.weeks || []).slice(0, -1);
  trendsData.map((data: any) => {
    if (data.total > 120) {
      data.total = 120; // Cap total at 120
    }
  });
  return (
    <Box
      pl={45}
      pr={40}
      pt={24}
      sx={{
        height: 'calc(100vh - 197px)',
        maxHeight: 'calc(100vh - 197px)',
        overflowX: 'hidden',
        width: '100%',
        borderLeft: "1px solid #d8d8d8",
      }}
    >
      {/* <ScrollArea
        h={"calc(100vh - 197px)"}
        mah={"calc(100vh - 197px)"} 
        px={40}
        pt={24}
        sx={{
          borderLeft: "1px solid #d8d8d8",
        }}
      > */}
      <>
        {chosenIdea && (
          <>
            <Flex justify="space-between" mb={4}>
              <Tooltip label={chosenIdea?.title} w={300} multiline>
                <Flex>
                  <OverflownText 
                    maw={300} 
                    lineClamp={1}
                    size="sm" 
                    weight={600} 
                    color="#212529" >
                    <Text sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}>
                      {chosenIdea?.title}
                    </Text>
                
                  </OverflownText>
                </Flex>
                
              </Tooltip>
              
              <Flex
                w={95}
                h={24}
                bg={LIGHT_BLUE_BACKGROUND}
                sx={{ borderRadius: 4, cursor: "pointer" }}
                onClick={() =>
                  window.open(`/idea/${chosenIdea?._id}`, "_blank")
                }
                px={8}
                py={4}
                align="center"
                justify="space-between"
                mb={12}
              >
                <IconArrowRight size={14} />
                <Text fz={12} ta="center">
                  Go to idea
                </Text>
              </Flex>
            </Flex>
            <ImpactListDasboard
              impactAggregationProperties={impactAggregationProperties}
              idea={chosenIdea}
            />
            <Box h={143} mah={143} mt={50} mb="md">
              <DashboardTrend
                dataArr={trendsData}
                quarter={false}
                selectedValues={[]}
                setSelectedValues={() => null}
                moreThanThirty={false}
              />
            </Box>
            <PerspectivesMenu />
          </>
        )}
      </>
      {/* </ScrollArea> */}
    </Box>
  );
};

export default IdeaDataCard;
