import { useContext } from "react";
import { Text, Flex, Box, Tooltip } from "@mantine/core";
import DashboardContext from "../../../DashboardContext";
import PerspectiveBar from "./PerspectiveBar";

const PerspectiveData = () => {
  const { perspectiveIdeaData } = useContext(DashboardContext);

  if (!perspectiveIdeaData) return null;

  // Calculate the highest total count across all plans.
  const highestTotal = Math.max(
    ...Object.values(perspectiveIdeaData).map(
      (planData: any) => planData.Total?.Count || 0
    )
  );

  return (
    <Box w="100%" pr={30} mb="md">
      {Object.entries(perspectiveIdeaData).map(([planKey, planData]: any) => {
        // Each planData is an object with keys: Assigned, Suggested, Total.
        const assignedCount = planData.Assigned?.Count || 0;
        const suggestedCount = planData.Suggested?.Count || 0;
        const totalCount = planData.Total?.Count || 0;

        return (
          <Flex align="center" justify="space-between" my="md" py={4}>
            <Tooltip label={planKey}>
              <Text lineClamp={1} fz="12px" fw={500} color="#0A122E" w="40%">
                {planKey}
              </Text>
            </Tooltip>
            <PerspectiveBar
              assigned={assignedCount}
              suggested={suggestedCount}
              topValue={highestTotal}
            />
            <Flex pl={18} h={16} w={62} align="center" justify="flex-end">
              
              
             
              <Text color="#5C5CEB" fz="13px" mr="4px" w={15} align="center">
                {assignedCount + suggestedCount || 0}
              </Text>
           
  
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default PerspectiveData;
