

export const calcTrend = (assigned: any[], suggested: any[], quarters: string[])=>{

  const graphDataArr: any[] = [

    {name: 'Older', Assigned: 0, Suggested: 0},
    {name: quarters[0], Assigned: 0, Suggested: 0},
    {name: quarters[1], Assigned: 0, Suggested: 0},
    {name: quarters[2], Assigned: 0, Suggested: 0},
    {name: quarters[3], Assigned: 0, Suggested: 0},
    {name: quarters[4], Assigned: 0, Suggested: 0},
    {name: quarters[5], Assigned: 0, Suggested: 0},

  ]
     
  assigned.forEach((evidence: any)=>{

    switch (evidence?.quarter) {
    case 'Older':
      graphDataArr[0].Assigned ++
      break;
    case quarters[0]:
      graphDataArr[1].Assigned ++
      break;
    case quarters[1]:
      graphDataArr[2].Assigned ++
      break;
    case quarters[2]:
      graphDataArr[3].Assigned ++
      break;
    case quarters[3]:
      graphDataArr[4].Assigned ++
      break;
    case quarters[4]:
      graphDataArr[5].Assigned ++
      break;
    case quarters[5]:
      graphDataArr[6].Assigned ++
      break;
    default:
      console.log("Number is out of range");
    }

  })

  suggested.forEach((evidence: any)=>{

    switch (evidence?.quarter) {
    case 'Older':
      graphDataArr[0].Suggested ++
      break;
    case quarters[0]:
      graphDataArr[1].Suggested ++
      break;
    case quarters[1]:
      graphDataArr[2].Suggested ++
      break;
    case quarters[2]:
      graphDataArr[3].Suggested ++
      break;
    case quarters[3]:
      graphDataArr[4].Suggested ++
      break;
    case quarters[4]:
      graphDataArr[5].Suggested ++
      break;
    case quarters[5]:
      graphDataArr[6].Suggested ++
      break;
    default:
      console.log("Number is out of range");
    }

  })

  graphDataArr.forEach((data) => {
    data.AssignedValue = data.Assigned
    data.SuggestedValue = data.Suggested
  });

  let moreThanThirty = false

  graphDataArr.forEach((data) => {
    if (data.Assigned > 120) {
      moreThanThirty = true
      data.Assigned = 120; // Cap Assigned at 120
      data.Suggested = 0; // Set Suggested to 0
    } else if ((data.Assigned + data.Suggested) > 120) {
      data.Suggested = (120 - data.Assigned)
      moreThanThirty = true
    }
  });

  return {graphDataArr: graphDataArr, moreThanThirty: moreThanThirty}

}


export const calcTrendWeeks = (assigned: any[], suggested: any[], weeks: string[])=>{

  const graphDataArr: any[] = [

    {name: weeks[0], Assigned: 0, Suggested: 0},
    {name: weeks[1], Assigned: 0, Suggested: 0},
    {name: weeks[2], Assigned: 0, Suggested: 0},
    {name: weeks[3], Assigned: 0, Suggested: 0},
    {name: weeks[4], Assigned: 0, Suggested: 0},
    {name: weeks[5], Assigned: 0, Suggested: 0},
    {name: weeks[6], Assigned: 0, Suggested: 0},
    {name: weeks[7], Assigned: 0, Suggested: 0},
    {name: weeks[8], Assigned: 0, Suggested: 0},
    {name: weeks[9], Assigned: 0, Suggested: 0},
    {name: weeks[10], Assigned: 0, Suggested: 0},
    {name: weeks[11], Assigned: 0, Suggested: 0},
    {name: weeks[12], Assigned: 0, Suggested: 0},
  ]
     
  assigned.forEach((evidence: any)=>{

    switch (evidence?.week) {
    case weeks[0]:
      graphDataArr[0].Assigned ++
      break;
    case weeks[1]:
      graphDataArr[1].Assigned ++
      break;
    case weeks[2]:
      graphDataArr[2].Assigned ++
      break;
    case weeks[3]:
      graphDataArr[3].Assigned ++
      break;
    case weeks[4]:
      graphDataArr[4].Assigned ++
      break;
    case weeks[5]:
      graphDataArr[5].Assigned ++
      break;
    case weeks[6]:
      graphDataArr[6].Assigned ++
      break;
    case weeks[7]:
      graphDataArr[7].Assigned ++
      break;
    case weeks[8]:
      graphDataArr[8].Assigned ++
      break;
    case weeks[9]:
      graphDataArr[9].Assigned ++
      break;
    case weeks[10]:
      graphDataArr[10].Assigned ++
      break;
    case weeks[11]:
      graphDataArr[11].Assigned ++
      break;
    case weeks[12]:
      graphDataArr[12].Assigned ++
      break; 
      
    default:
      console.log("Number is out of range");
    }

  })

  suggested.forEach((evidence: any)=>{

    switch (evidence?.week) {
    case weeks[0]:
      graphDataArr[0].Suggested ++
      break;
    case weeks[1]:
      graphDataArr[1].Suggested++
      break;
    case weeks[2]:
      graphDataArr[2].Suggested ++
      break;
    case weeks[3]:
      graphDataArr[3].Suggested ++
      break;
    case weeks[4]:
      graphDataArr[4].Suggested ++
      break;
    case weeks[5]:
      graphDataArr[5].Suggested ++
      break;
    case weeks[6]:
      graphDataArr[6].Suggested ++
      break;
    case weeks[7]:
      graphDataArr[7].Suggested ++
      break;
    case weeks[8]:
      graphDataArr[8].Suggested ++
      break;
    case weeks[9]:
      graphDataArr[9].Suggested ++
      break;
    case weeks[10]:
      graphDataArr[10].Suggested ++
      break;
    case weeks[11]:
      graphDataArr[11].Suggested ++
      break;
    case weeks[12]:
      graphDataArr[12].Suggested ++
      break;

    default:
      console.log("Number is out of range");
    }
  })

  graphDataArr.forEach((data) => {
    data.AssignedValue = data.Assigned
    data.SuggestedValue = data.Suggested
  });

  let moreThanThirty = false

  graphDataArr.forEach((data) => {
    if (data.Assigned > 120) {
      moreThanThirty = true
      data.Assigned = 120; // Cap Assigned at 120
      data.Suggested = 0; // Set Suggested to 0
    } else if ((data.Assigned + data.Suggested) > 120) {
      data.Suggested = (120 - data.Assigned)
      moreThanThirty = true
    }
  });

  return {graphDataArr: graphDataArr, moreThanThirty: moreThanThirty}
}

