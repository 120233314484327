import { Box, Flex, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEvidenceURL } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";

const EvidenceRouting: React.FC = () => {
  const { id: evidenceId } = useParams();
  const auth0 = useAuth0();
  const navigate = useNavigate(); 


  const [requestObj, setRequestObj] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
 

  const loadEvidenceURL = async (evidenceId: string, auth0: any) => {
    if (!evidenceId) {
      setLoading(false)
      return false;
    }

    try {
      const evidence = await getEvidenceURL(evidenceId, auth0);
      console.log("loadEvidenceURL", evidence);

      if(evidence?.state === 'declined'){
        setRequestObj(evidence)
        
      }

      else if (evidence?.ideaId && (evidence?.state === 'inReview' || evidence?.ideaId)) {
        console.log("redirecting");
        navigate(`/idea/${evidence?.ideaId}?requestId=${evidence?._id}`); // Use navigate for redirection
      }

      else if(!evidence?.ideaId && evidence?.state === 'inReview'){
        navigate(`/inbox/?requestId=${evidence?._id}`);
      }
      else{
        setRequestObj(null)
      }

      setLoading(false)

      return false;
    } catch (e: any) {
      console.error(e);
      return false; 
    }
  };

  useEffect(() => {

    /* console.log(evidenceId) */

    const loadURL = async () => {
      if ( !evidenceId || evidenceId?.length !== 24) {
        setLoading(false)
        return;
      }
     
      loadEvidenceURL(evidenceId, auth0); 
    };

    loadURL(); 
  }, [evidenceId]); 

  return loading? <></> : (!evidenceId || evidenceId?.length !== 24 || !requestObj) ? (
    <Flex justify="center" p={30} w="100%" mt='sm'>
      <Stack
        px={50}
        py={25}
        mt={36}
        w="460px"
        h="85px"
        bg="rgba(92, 92, 235, 0.1)"
        sx={{ borderRadius: "8px" }}
      >
   
         
        <Text fz={15} fw={600}>
          No request found
        </Text>
          
       
          

   
    
   
      </Stack>
    </Flex>
  ) : (
    <Flex justify="center" p={30} w="100%" mt='sm'>
      <Stack
        px={50}
        py={25}
        mt={36}
        w="460px"
        h="205px"
        bg="rgba(92, 92, 235, 0.1)"
        sx={{ borderRadius: "8px" }}
      >
        <Box>
          <Flex>
            <Text fz={15} fw={600}>
          This request was declined by
            </Text>
            <Text fz={15} fw={600} ml={4}>
              {requestObj?.rejectingUser?.name || 'N/A'}
            </Text>
          </Flex>
          <Box mt='md'>
            <Text fz={15}>
                    Reason:
            </Text>
            <Text fz={15} ml={4} mt={4}>
              {requestObj?.rejectInfo?.reason || 'N/A'}
            </Text>
          </Box>
          
        </Box>

   
    
   
      </Stack>
    </Flex>
  );
};

export default EvidenceRouting;
