import { Box, Grid } from "@mantine/core";
import Header from "./components/Header/Header";
import IdeaDataCard from "./components/Idea-data-card/IdeaDataCard";
import DashboardTabs from "./components/tabs/DashboardTabs";
import { BgColorRouteContext } from "../../context/BgColorContext";
import { useEffect, useContext } from "react";

const Dashboard = () => {
  const { setBgColor, setPageName } = useContext(BgColorRouteContext);

  useEffect(() => {
    setBgColor("#ffffff");
    setPageName("dashboard");
  }, [setBgColor, setPageName]);

  return (
    <Box m={0} 
      id="meiryoyo" 
      h={`calc(100vh - 65px)`} 
      //w={"calc(100vw - 63px)"} 
      sx={{ overflowX: "hidden", overflowY: 'hidden' }}  
      /* mr={8} */
    >
      <Header />
      <Grid columns={120} p={0} pt={7}>
        <Grid.Col span={75} p={0}>
          <DashboardTabs />
        </Grid.Col>
        <Grid.Col span={45} p={0}>
          <IdeaDataCard />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default Dashboard;