import { Modal, Button, Text, useMantineTheme, Flex, Tooltip, Loader } from '@mantine/core';
import { IconArrowBackUp, IconCheck } from '@tabler/icons';
import { ReactNode } from "react";

interface CustomModalProps {
  opened: boolean;
  onClose: () => void;
  title: any;
  reAssign?: boolean;
  children?: ReactNode; 
  primaryAction: ActionProps;
  secondaryAction: ActionProps;
}

interface ActionProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  isLoading?: boolean;
}

const CustomModal: React.FC<CustomModalProps> =  ({ reAssign, opened, onClose, title, children, primaryAction, secondaryAction  }) => {
  const theme = useMantineTheme();
  return (
    <Modal 
      opened={opened} 
      onClose={onClose} 
      title={title} 
      centered
      overlayProps={{opacity: 0.25, blur: 1, color: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}}
      sx = {{
        ".mantine-Paper-root":{
          minWidth: reAssign ? "600px" : "496px",
          minHeight: "596px",
          position: "relative"
        },
        ".mantine-Modal-inner":{
          minHeight: "100%",
          maxHeight: "none",
          overflowY: "scroll",
        },
        ".mantine-Modal-body":{
          backgroundColor: "#F8F9FA",
        },
        ".mantine-Modal-header":{
          padding: reAssign ? "2rem 1rem 1rem 2rem" : "1rem",
        },
        ".mantine-Modal-close":{
          top: reAssign ?  "-2rem" : "auto",
        },
      }}    
    >
      {children} 
      <Flex 
        justify="space-between" pt={8} pb={8} px={"16px"} bg="#F8F9FA"
        style={{ 
          borderTop: "1px solid #D8D8DB", 
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0, 
        }}
        align="center"
        direction="row"
        h={"48px"}
      >
      
        {secondaryAction && (
          <Button variant="subtle" color="#5C5CEB" style={{ paddingLeft: 5 }} onClick={secondaryAction.onClick}>
            <IconArrowBackUp color="#5C5CEB" size={16} />
            <Text color="#5C5CEB" size="12px" mx={4}>{secondaryAction.label}</Text>
          </Button>
        )}

        {primaryAction && primaryAction.tooltip ? (
          <Tooltip label={primaryAction.tooltip} position="top" withArrow>
            <div>
              <Button onClick={primaryAction.onClick} disabled={primaryAction.disabled}
                style={{ background: "#5C5CEB", borderRadius: 4, width: 69, height: 32, padding: 8 }}>
                <IconCheck size={16} />
                <Text size={12}>{primaryAction.isLoading ? <Loader color="violet" variant="dots" /> : primaryAction.label}</Text>
              </Button>
            </div>
          </Tooltip>
        ) : (
          primaryAction && (
            <Button onClick={primaryAction.onClick} disabled={primaryAction.disabled}
              style={{ background: "#5C5CEB", borderRadius: 4, border: "1px", width: 69, height: 32, padding: 8 }}>
              <IconCheck size={16} />
              <Text size={12}>{primaryAction.isLoading ? <Loader color="violet" variant="dots" /> : primaryAction.label}</Text>
            </Button>
          )
        )}
      </Flex>
    </Modal>
  );
};

export default CustomModal;