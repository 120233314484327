import { Accordion, Box,  Flex,  ScrollArea, Stack, Text } from '@mantine/core'
import  {  useEffect, useState } from 'react'
import CardInfo from './CardInfo'
import UpperScroller from './UpperScroller'
import { useElementSize } from '@mantine/hooks'
import CardInfoTextItem from './CardInfoTextItem'

const CardScroller = ({
  chosenEvidence, 
  reviewMode, 
  ideaPage,
  componentId,
  setComponentId,
  validation
}: 
  {
    
    reviewMode: boolean,
    chosenEvidence: any,
   ideaPage: boolean,
   componentId: string,
       setComponentId: React.Dispatch<React.SetStateAction<any>>,
    validation?: boolean;
  
  }
) => {

  const { ref , height } = useElementSize();
  const [value, setValue] = useState<string | null>(null)
  const [detailsOpened, setDetailsOpened] = useState(false)
  const [activeTab, setActiveTab] = useState<string | null>("details");

  const isGapMatch: boolean = chosenEvidence?.type === 'gap' && chosenEvidence?.mlComponent

  useEffect(() => {
    setDetailsOpened(false); 
  }, [chosenEvidence]);

  return (
    <Stack justify='space-between'   h={validation ? "445px" : `calc(100vh - ${reviewMode ? 250: 310}px)`}  /* h='100%' */ >
      <ScrollArea h={validation ? "450px" : "calc(100vh - 330px)"} mah={validation ? "450px" : "calc(100vh - 330px)"}> 
        <Box ref={ref}>
          <UpperScroller chosenEvidence={chosenEvidence}/>
        </Box>
      </ScrollArea>
      {!validation && 
      (
        <Box sx={{ borderTop: "1px solid #D8D8DB" }}>
          <Flex sx={{ borderBottom: "1px solid #D8D8DB" }}>
            <Accordion value={value} onChange={setValue} chevronPosition='left' p={0} m={0}>
              <Accordion.Item value="1"  onClick={()=> setDetailsOpened(!detailsOpened)}>
                <Accordion.Control pl='32px' py="10px"> 
                </Accordion.Control>
              </Accordion.Item>
            </Accordion> 
            {
              isGapMatch ? <CardInfoTextItem item={chosenEvidence} ideaPage={ideaPage} isInAccordian={true} activeTab={activeTab} setActiveTab={setActiveTab} setDetailsOpened={setDetailsOpened}/> :
                
                <CardInfo 
                  item={chosenEvidence} 
                  ideaPage={ideaPage} 
                  isInAccordian={true} 
                  activeTab={activeTab} 
                  setActiveTab={setActiveTab} 
                  setDetailsOpened={setDetailsOpened}
                  componentId={componentId}
                  setComponentId={setComponentId}
                />}
          </Flex>
          
          {detailsOpened && <Box px="35px" sx={{ overflowY: "auto", maxHeight: "180px", borderBottom: "1px solid #D8D8DB", minHeight: 350 }} >
            {
              isGapMatch ? <CardInfoTextItem item={chosenEvidence} ideaPage={ideaPage} isInAccordian={false} activeTab={activeTab} setActiveTab={setActiveTab} setDetailsOpened={setDetailsOpened}/> :
            
                <CardInfo 
                  item={chosenEvidence} 
                  ideaPage={ideaPage} 
                  isInAccordian={false} 
                  activeTab={activeTab} 
                  setActiveTab={setActiveTab} 
                  setDetailsOpened={setDetailsOpened}
                  componentId={componentId}
                  setComponentId={setComponentId}
                />}
          </Box>}
        </Box>
      )
      }
      
    </Stack> 
  )
}
export default CardScroller