import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DashboardContextType, dashboardInitialState } from "../../types/types";
import { getTopIdeasReport } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { applyFilter } from "./helpers/DashboardFiltersHelpers";

const DashboardContext = createContext<DashboardContextType>(dashboardInitialState);

const DashboardContextProvider = ({ children }: { children: React.ReactNode }) => {

  const auth0 = useAuth0();

  const {
    components,
    domains,
    allComponents,
    owners,
    displayState,
    perspectiveConfigurations,
    ideaStatuses
  } = useContext(SyncContext);

  const [surgingIdeas, setSurgingIIdeas] = useState<any[]>([]);
  const [pluningIdeas, setPluningIIdeas] = useState<any[]>([]);
  const [loadingIdeas, setLoadingIdeas] = useState(false);
  const [chosenIdea, setChosenIdea] = useState<any>(surgingIdeas[0])
  const [domain, setDomain] = useState('All');
  const [ownerName, setOwnerName] = useState('All');
  const [ownerId, setOwnerId] = useState<string | null>('');
  const [componentName, setComponentName] = useState<string | undefined>('All');
  const [chosenStatus, setChosenStatus] = useState<string>('All');
  const [componentId, setComponentId] = useState<string | null>('All');
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(components);
  const [perspective, setPerspective] = useState(perspectiveConfigurations.find((perspecite: any)=> perspecite.key === 'businessName'))
  const [activeTab, setActiveTab] = useState<string | null>('surging');
  const [trendsActiveFilters, setTrendsActiveFilters] = useState<any[]>([]);

  const filterComponentByDomain = (domainValue: string | string[]) => {
    
    const domainsArr = Array.isArray(domainValue)
      ? domainValue.map(val => val.toLowerCase())
      : [domainValue.toLowerCase()];
  
    if (domainsArr.includes("all") || domainsArr.length === 0) {
      setComponentsByDomain(components);
      return;
    }
    
    const filtered = components.filter((s: any) =>
      typeof s.domain === 'string' &&
      domainsArr.includes(s.domain.toLowerCase())
    );
    setComponentsByDomain(filtered);
  };

  const loadIdeas = async () => {
    
    const res = await getTopIdeasReport(auth0);
    setSurgingIIdeas(res.surging)
    setChosenIdea(res.surging[0])
    setPluningIIdeas(res.plunging)
    setLoadingIdeas(false)
  };

  async function fetchDashboardData() {
    setLoadingIdeas(true)
    try {
      await loadIdeas();
    } catch (e) {
      console.log(e);
    }
  }

  const chooseIdea = (id: string) => {
    let found = null
    const allIdeas = [...surgingIdeas, ...pluningIdeas]
    found = allIdeas?.find((ideaItem: any) => ideaItem?._id === id) || null;
    if (!found || found?._id === chosenIdea?._id) {
      return;
    }
    setChosenIdea(found);
  };

  const selectPerspectiveData = (ideaItem: any, perspectiveItem: any) => {
    const ideaAggregations = ideaItem?.aggregations;
    const filteredAggregations = (perspectiveConfigurations || []).reduce((acc, config) => {
      if (ideaAggregations && ideaAggregations.hasOwnProperty(config.key)) {
        acc[config.key] = ideaAggregations[config.key];
      }
      return acc;
    }, {});
  
    const relevantKey = perspectiveItem?.key;
    const relevantData = filteredAggregations[relevantKey];
  
    if (!relevantData) return {};
  
    // Sort the 'total' field if it exists.
    if (relevantData.total) {
      const entries = Object.entries(relevantData.total);
      entries.sort(([, a], [, b]) => (b as any).count - (a as any).count);
      relevantData.total = Object.fromEntries(entries);
    }
  
    const assignedObj = relevantData.assigned || {};
    const suggestedObj = relevantData.suggested || {};
    const totalObj = relevantData.total || {};
  
    // Create a union of plan keys from assigned, suggested, and total.
    const planKeys = new Set([
      ...Object.keys(assignedObj),
      ...Object.keys(suggestedObj),
      ...Object.keys(totalObj),
    ]);
  
    // Build the new object structure.
    const result: { [plan: string]: any } = {};
    planKeys.forEach((planKey) => {
      result[planKey] = {
        Assigned: assignedObj[planKey] || { count: 0, accounts: 0 },
        Suggested: suggestedObj[planKey] || { count: 0, accounts: 0 },
        Total: totalObj[planKey] || { count: 0, accounts: 0 },
      };
    });
  
    // --- Cleaning functions ---
    // Capitalize first letter of a string.
    const capitalizeFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);
  
    // Clean key: replace dashes with spaces, change "undefined" to "N/A", then capitalize.
    const cleanKey = (key: string) => {
      let newKey = key.replace(/-/g, " ");
      newKey = newKey === "undefined" ? "N/A" : capitalizeFirstLetter(newKey);
      return newKey;
    };
  
    const capitalizeInnerKeys = (obj: any): any => {
      const newObj: { [k: string]: any } = {};
      Object.entries(obj).forEach(([k, v]) => {
        newObj[capitalizeFirstLetter(k)] = v;
      });
      return newObj;
    };
  
    const finalResult: { [plan: string]: any } = {};
    Object.entries(result).forEach(([planKey, values]) => {
      const cleanedPlanKey = cleanKey(planKey);
      finalResult[cleanedPlanKey] = {
        Assigned: capitalizeInnerKeys(values.Assigned),
        Suggested: capitalizeInnerKeys(values.Suggested),
        Total: capitalizeInnerKeys(values.Total),
      };
    });
  
    return finalResult;
  };

  const filteredSurgingIdeas = useMemo(() => {
    if (!trendsActiveFilters.length) {
      return surgingIdeas; // Return all surging ideas if no filters are active
    }
  
    return trendsActiveFilters.reduce((acc, filter) => {
      return applyFilter(acc, filter, components);
    }, surgingIdeas); // Start with all surging ideas
  }, [trendsActiveFilters, surgingIdeas, components]);
  
  const filteredPluningIdeas = useMemo(() => {
    if (!trendsActiveFilters.length) {
      return pluningIdeas; // Return all plunging ideas if no filters are active
    }
  
    return trendsActiveFilters.reduce((acc, filter) => {
      return applyFilter(acc, filter, components);
    }, pluningIdeas); // Start with all plunging ideas
  }, [trendsActiveFilters, pluningIdeas, components]);


  const perspectiveIdeaData = useMemo(() => {
    const data = selectPerspectiveData(chosenIdea, perspective);
    if (!data) return null;
    return data;
  }, [chosenIdea, perspective]);

  useEffect(() => {
    setPerspective(perspectiveConfigurations.find((perspecite: any)=> perspecite.key === 'businessName'))
    fetchDashboardData(); 
  }, [perspectiveConfigurations]);

  return (
    <DashboardContext.Provider
      value={{
        surgingIdeas,
        filteredSurgingIdeas,
        chosenIdea,
        chooseIdea,
        components,
        allComponents,
        owners,
        displayState,
        domains,
        ownerId,
        setOwnerId,
        ownerName,
        setOwnerName,
        componentName,
        setComponentName,
        domain,
        setDomain,
        filterComponentByDomain,
        componentsByDomain,
        perspectiveConfigurations,
        perspective,
        setPerspective,
        perspectiveIdeaData,
        componentId,
        setComponentId,
        activeTab,
        setActiveTab,
        pluningIdeas,
        filteredPluningIdeas,
        chosenStatus,
        setChosenStatus,
        trendsActiveFilters,
        setTrendsActiveFilters,
        ideaStatuses 
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardContextProvider };
export default DashboardContext;