import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Divider,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import CustomModal from "./CustomModal";
import SelectionIndicator from "./SelectionIndicator";

function AreaReorderModal({
  openAreaReorderModal,
  setOpenAreaReorderModal,
  handleToggleIndentation,
  setChildItem,
  childItem,
  elements,
  element,
}: any) {
  const [selectedArea, setSelectedArea] = useState<{
    id: string;
    position: "above" | "below" | "inside";
    name: string;
    parent: boolean;
  } | null>(null);

  const [hoveredLine, setHoveredLine] = useState<{
    id: string;
    position: "above" | "below" | "inside";
  } | null>(null);

  const filterCurrentArea = (productAreasList: any[]) => {
    return productAreasList.filter((a: any) => {
      return (
        a._id !== element._id
      );
    })

  }

  const [filteredAreas, setFilteredAreas] = useState(
    filterCurrentArea(elements)
  );


  const handlePositionSelect = useCallback(
    (id: string, position: "above" | "below" | "inside", name: string, parent: boolean) => {
      setSelectedArea({ id, position, name, parent });
    },
    []
  );

  const handleClose = () => {
    setSelectedArea(null);
    setHoveredLine(null);
    setOpenAreaReorderModal(false);
  };

  useEffect(() => {
    if (openAreaReorderModal) {
      setSelectedArea(null);
      setHoveredLine(null);
    }
  }, [openAreaReorderModal]);
  

  const handleApply = () => {
    if (selectedArea) {
      let moveAfter = "";
      let parentId;
      if (selectedArea.parent) { 
        moveAfter = selectedArea.id
        parentId = ""; 
      } else {
        for (let i = 0; i < filteredAreas.length; i++) {
          const area = filteredAreas[i];
          if(selectedArea.id == area._id){
            parentId = area._id;
            setChildItem(false)
            childItem = false 
          } else {
            for (let j = 0; j < area.childComponents.length; j++) {
              const subArea = area.childComponents[j];
              if (subArea._id === selectedArea.id) {
                setChildItem(false)
                childItem = false
                parentId = area._id;
                moveAfter = selectedArea.id
                break;
              }
            }
          }
          if (parentId) break; 
        }
      }
      handleToggleIndentation(childItem, element._id, parentId, moveAfter);
    }
    handleClose();
  };

  const sortedSelectableAreas = React.useMemo(() => {
    return filteredAreas.flatMap((area: any) => [
      { id: area._id, position: "inside", name: area.name, parent: false },  
      ...area.childComponents.map((subArea: any) => ({
        id: subArea._id,
        position: "below",
        name: subArea.name,
        parent: false,
      })),  
      { id: area._id, position: "below", name: area.name, parent: true },  
    ]);
  }, [filteredAreas]);
  
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const currentIndex = sortedSelectableAreas.findIndex(
        (a: any) => a.id === selectedArea?.id && a.position === selectedArea?.position
      );
  
      if (e.key === "ArrowDown") {
        let nextIndex = currentIndex + 1;
        if (nextIndex >= sortedSelectableAreas.length) nextIndex = 0; 
        const nextArea = sortedSelectableAreas[nextIndex];
        handlePositionSelect(nextArea.id, nextArea.position, nextArea.name, nextArea.parent);
      }
  
      if (e.key === "ArrowUp") {
        let prevIndex = currentIndex - 1;
        if (prevIndex < 0) prevIndex = sortedSelectableAreas.length - 1; 
        const prevArea = sortedSelectableAreas[prevIndex];
        handlePositionSelect(prevArea.id, prevArea.position, prevArea.name, prevArea.parent);
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedArea, sortedSelectableAreas, handlePositionSelect]);
  

  const isApplyDisabled = (element.childComponents?.length > 0  && selectedArea?.parent === false)

  return (
    <CustomModal
      opened={openAreaReorderModal}
      onClose={handleClose}
      title={
        <Group position="apart" py={4}>
          <Text fw={600} size={20} color="#212529">
            Move <Text span>{element.name}</Text>
          </Text>
        </Group>
      }
      primaryAction={{
        label: "Apply",
        onClick: handleApply,
        disabled: selectedArea === null || isApplyDisabled,
        tooltip: isApplyDisabled
          ? "This area cannot be converted to a sub-area as it has sub-areas itself."
          : undefined
      }}
      secondaryAction={{
        label: "Cancel changes",
        onClick: () => setOpenAreaReorderModal(false)
      }}
    >
      <Box  mx="-md" mt="4px" mb="8px" >
        <ScrollArea style={{ height: "70vh", background: "#F8F9FA" }}>
          <Divider mb="8px" size={2} color="#D8D8DB" />
          {filteredAreas.map((area: any, index: any) => (
            <Box key={index}>
              <Box px="md">
                <Text fw={600} size={14} style={{ lineHeight: 1, marginTop: '12px' }}>
                  {area.name}
                </Text>
                <Box pl="2rem">
                  <SelectionIndicator
                    id={area._id}
                    position="inside"
                    selectedArea={selectedArea}
                    hoveredLine={hoveredLine}
                    onMouseEnter={() => setHoveredLine({ id: area._id, position: "inside" })}
                    onMouseLeave={() => setHoveredLine(null)}
                    onClick={() => handlePositionSelect(area._id, "inside", area.name, false)}
                    isDisabled={element.childComponents?.length > 0}
                  />
                </Box>

                {filterCurrentArea(area.childComponents).map((subArea: any, subIndex: any) => (
                  <Box key={subIndex} pl="2rem">
                    <Text size={14} style={{ lineHeight: 1 }}>{subArea.name}</Text>
                    <SelectionIndicator
                      id={subArea._id}
                      position="below"
                      selectedArea={selectedArea}
                      hoveredLine={hoveredLine}
                      onMouseEnter={() => setHoveredLine({ id: subArea._id, position: "below" })}
                      onMouseLeave={() => setHoveredLine(null)}
                      onClick={() => handlePositionSelect(subArea._id, "below", subArea.name, false)}
                      isDisabled={element.childComponents?.length > 0}
                    />
                  </Box>
                ))}
                <SelectionIndicator
                  id={area._id}
                  position="below"
                  selectedArea={selectedArea}
                  hoveredLine={hoveredLine}
                  onMouseEnter={() => setHoveredLine({ id: area._id, position: "below" })}
                  onMouseLeave={() => setHoveredLine(null)}
                  onClick={() => handlePositionSelect(area._id, "below", area.name, true)}
                />
              </Box>
              <Divider mt="5px" mb="5px" color="#D8D8DB" />
            </Box>
          ))}
        </ScrollArea>
      </Box>
    </CustomModal>
  );
}

export default AreaReorderModal;