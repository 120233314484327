import { Anchor, Box, Button, Flex, Menu, Modal, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconChevronDown, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { dismissSuggested, validateMatch, validateRequest, assignRequestToNewIdea } from "../../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import IdeaContext from "../../../IdeaContext";
import { openModal } from "@mantine/modals";
import LogoIcon from "../../../../layouts/LogoIcon";
import CommentsMenu from "../Comment/CommentsMenu";
import { showNotification } from "@mantine/notifications";
import { DeclineRequestModal } from "./DeclineRequestModal";
import SyncContext from "../../../../../context/SyncContext";
import ValidateRequestMatchPopUp from "./ValidateRequestMatchPopUp";
import posthog from "posthog-js";
import AssignActionsMenu from "./AssignActionsMenu";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";
import { RequestAssignment } from "./RequestAssignment";

const RequestMatchesActions = ({
  navToPerspective,
  componentId,
  chosenEvidence,
  validation,
}: {
  navToPerspective: any;
  componentId: string;
  chosenEvidence: any;
  validation?: boolean;
}) => {
  const {
    loadEvidence,
    setChosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setCardView,
    setAddEvidenceFormScreen,
    requestsMatches,
    setRequestsMatches
  } = useContext(IdeaContext);
  const { defaultPriority, defaultStatus,allComponents } = useContext(SyncContext);
  const { validate, dismiss, isLoading } = useEvidenceActions({ evidenceId: chosenEvidence._id });

  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);
  const [validateOpened, setValidateOpened] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  // const [loadingDismissed, setLoadingDismissed] = useState(false);
  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineRequestModal
          request={chosenEvidence?.request}
          idea={idea}
          matchId={chosenEvidence?._id}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}
        />
      ),
    });
  }; 

  const dismissMatch = (matchId: string) => {
    // setLoadingDismissed(true)
    dismiss({matchId, ideaId, isGap: false})
      .then((res) => {
        /* navToPerspective() */
        setAddEvidenceFormScreen(false);
        setCardView(false);
        reCalculatPerspectiveForRequest({_id: matchId}, 'remove');
        showNotification({
          title: "This evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });

        console.log(res);
        loadEvidence();
      })
      .then(()=> {
        setChosenEvidence(null)
        navToPerspective()
      })
      .catch((e) => {
        console.log(e);
      })
  };

  const onValidate = () => {
    setLoadingAssign(true)

    const requestsMatchesCopy = [...requestsMatches]

    const filtered = requestsMatchesCopy.filter((match: any)=> match._id !== chosenEvidence?._id)

    setRequestsMatches(filtered)

    // validateRequest(
    //   auth0,
    //   chosenEvidence?.request?._id,
    //   ideaId,
    //   chosenEvidence?._id
    // )
    ideaId && validate({
      ideaId,
      matchId: chosenEvidence?.match?._id,
      sourceOfAssignment: 'suggestion'
    })
      .then((res) => {
        console.log({ res });
        if (res.evidence) {
          reCalculatPerspectiveForRequest(
            { ...res.evidence },
            "add"
          );
        }
        loadEvidence();
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
      })
      .then((res) => console.log(res)
      )
      .catch((e) => console.log(e)).finally(()=> 
      {navToPerspective()
        setChosenEvidence(null)})
      .finally(()=>setLoadingAssign(false))
    ;
  };
  const createNew = () => {
    const newIdeaObj = {
      title: chosenEvidence?.title,
      description: chosenEvidence?.text,
      componentId: componentId,
      ownerId: null,
      rolloutDate: null,
      status: defaultStatus,
      priority: defaultPriority,
    };

    const _body = {
      idea: newIdeaObj,
      evidence: { ...chosenEvidence },
    };

    // Check if a product area has been selected
    if (!componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // Find the product area by componentId
    const foundComponent = allComponents.find(
      (c) => c._id === chosenEvidence.componentId
    );

    // If no matching component is found, show an error
    if (!foundComponent) {
      showNotification({
        title: "Invalid Product Area",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }

    // If a valid product area is found, assign its ownerId to the idea and evidence objects
    _body.idea.ownerId = foundComponent.ownerId;
    _body.evidence.ownerId = foundComponent.ownerId;

    // Submit the new idea request
    assignRequestToNewIdea(_body, auth0)
      .then((response) => {
        const ideaId = response.createNewIdeaRes?.idea?._id;

        showNotification({
          title: "Assigned Successfully!",
          color: "teal",
          message: (
            <Box>
              <Text>New idea has been created.</Text>
              {ideaId && (
                <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
                </Anchor>
              )}
            </Box>
          ),
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });

        if (ideaId) {
          setTimeout(() => {
            window.open(`/idea/${ideaId}`);
          }, 1300);
        } else {
          console.log("Did not receive an idea ID for the new idea", response);
        }
      })
      .catch((error) => {
        console.error("Error creating idea:", error);
        showNotification({
          title: "Error Creating Idea",
          color: "red",
          message:
            "An error occurred while creating the idea. Please try again.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
      })
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      // h="64px"
      sx={{
        /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB",
      }}
      align="center"
    >
      <Modal
        // mt={100}
        // ml={700}
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        /* overlayOpacity={0} */
        size="38vw"
        title={
          <Text fz={20} fw={600}>
            Reassign to another idea
          </Text>
        }
      >
        <Box h={445} pt={5}>
          <RequestAssignment
            idea={idea}
            item={chosenEvidence}
            setOpened={setOpened}
            navToPerspective={navToPerspective}
            TextInputW="100%"
            BoxW="38vw"
          />
        </Box>
      </Modal>
      <Modal
        centered
        opened={validateOpened}
        onClose={() => setValidateOpened(false)}
        /* overlayOpacity={0} */
        /* size="32vw" */
        title={
          <Text fz={14} /* fw={600} */>
            The submitter of this request connected it to this Idea:
          </Text>
        }
      >
        <ValidateRequestMatchPopUp
          validate={onValidate}
          setOpened={setValidateOpened}
        />
      </Modal>
      {!validation && <CommentsMenu />}
      <Flex align="center">
        {!isLoading.dismiss ?
          <Tooltip
            label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
            multiline
            maw={200}
            offset={12}

          >
            <Flex
              align="center"
              w="107px"
              h="32px"
              mr="12px"
              sx={{ 
                justifyContent: "center",
                backgroundColor: "#5C5CEB1A",
                cursor: " pointer",
                borderRadius: "4px"
              }}
              onClick={() => {
                dismissMatch(chosenEvidence?._id);
                posthog.capture('suggested_not_relevant_clicked', chosenEvidence)
              }}
            >
              <Box>
                <IconX size={16} color="#5C5CEB" />
              </Box>

              <Text ml="4px" fw={500} color="#5C5CEB" fz={12} lineClamp={1}>
            Not relevant
              </Text>
            </Flex>
          </Tooltip> 
          :
          <Tooltip
            label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
            multiline
            maw={200}
            offset={12}
          >
            <Flex
              align="center"
              w="107px"
              mr="12px"
              sx={{ cursor: "default" }}
            >
              <Box>
                <IconX size={15} color='#D8D8DB' />
              </Box>
              <Text ml="4px" color='#D8D8DB' fz={12} lineClamp={1}>
            Not relevant
              </Text>
            </Flex>
          </Tooltip> 
        }

        <Button.Group>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="135px"
              loading={loadingAssign}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  display: "flex", // Ensure content stays centered
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative", // Allow for loader overlay
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                loader: {
                  position: "absolute", // Loader overlays content
                  color: theme.white,
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={
                chosenEvidence?.match?.ideaId
                  ? () => setValidateOpened(true)
                  : onValidate
              }
            >
              {!loadingAssign && (
                <>
                  <IconPlus size={14} />
                  <Text>Assign to this Idea</Text>
                </>
              )}
            </Button>
          </Tooltip>
          <AssignActionsMenu
            actions={[
              {
                label: "Assign to another idea",
                tooltip: "Assign to another idea",
                onClick: () => setOpened(true),
              },
              {
                label: "Assign to a new idea",
                tooltip: "Assign to a new idea",
                onClick: createNew,
              },
            ]}
          />
        </Button.Group>
      </Flex>
    </Flex>
  );
};

export default RequestMatchesActions;
