// src/Api.evidences.ts
import { AuthenticationError } from "@auth0/auth0-react";
import axios from "axios";

const API_DOMAIN = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN;

const getAuthHeaders = async (auth0: any) => {
  try {
    const token = await auth0.getAccessTokenSilently();
    return { headers: { Authorization: `Bearer ${token}` } };
  } catch (e) {
    throw e;
  }
};

export interface RejectInfo {
  reason?: string;
  comment?: string;
}

export interface DismissEvidenceParams {
  ideaId?: string;
  matchId?: string; // Optional: if provided, also dismiss the match.
  rejectInfo?: RejectInfo; // Optional: not required if dismissing a match.
  isGap?: boolean // Evidence is a gap or request. 
}
// List Evidences
export const listEvidences = async (
  params: { oldestFirst: boolean; filter: { ownerIds: string[] } },
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(
      `${API_DOMAIN}/evidences/search`,
      params,
      config
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

// Get Idea Evidences
export const getIdeaEvidences = async (
  id: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/ideas/${id}/evidences`;
    const response = await axios.get(url, config);
    return response.data;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Get Evidence Matches
export const getEvidenceMatches = async (
  id: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${id}/matches`;
    const response = await axios.get(url, config);
    return response.data.matches;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Update Evidence
export const updateEvidence = async (
  id: string,
  data: any,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${id}`;
    const response = await axios.put(url, data, config); // Use PUT for full updates
    return response.data;
  } catch (e: any) {
    console.error("Error updating evidence:", e);
    throw e;
  }
};

// Save Evidence Starred
export const saveEvidenceStarred = async (
  idea: any,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/starred`;
    const response = await axios.post(url, { idea }, config);
    return response;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Save Evidence
export const saveEvidence = async (evidence: any, auth0: any): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/update`;
    const response = await axios.post(url, { evidence }, config);
    return response;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Get Single Evidence
export const getEvidence = async (
  evidenceId: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${evidenceId}`;
    const response = await axios.get(url, config);
    return response.data?.evidence;
  } catch (e) {
    throw e;
  }
};

// Get Related Text Items
export const getRelatedTextItems = async (
  itemId: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${itemId}/related-cx-items`;
    const response = await axios.get(url, config);
    return response.data;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Get Evidences Matches for an Idea
export const getEvidencesMatchesForAnIdea = async (
  id: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/ideas/${id}/evidencesMatches`;
    const response = await axios.get(url, config);
    return response.data.matches;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// Get Evidence URL
export const getEvidenceURL = async (id: string, auth0: any): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${id}/evidence`;
    const response = await axios.get(url, config);
    return response.data.evidenceFromDb;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

/**
 * Dismiss an Evidence
 *
 * @param evidenceId - The ID of the evidence to dismiss
 * @param ideaId - The ID of the idea associated with the evidence
 * @param matchId - (Optional) The ID of the match to dismiss, if applicable
 * @param rejectInfo - (Optional) Information about the rejection (may be omitted for match dismissals)
 * @param auth0 - Auth0 instance for authentication
 * @returns A promise resolving to the server's response
 */
export const dismissEvidence = async (
  evidenceId: string,
  { ideaId, matchId, rejectInfo, isGap }: DismissEvidenceParams,
  auth0?: any
): Promise<any> => {
  try {
    if (!auth0) {
      throw new AuthenticationError(
        "invalid_request", 
        "Missing Auth0 instance. Please check authentication.", 
        "" 
      );
    }

    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${evidenceId}/dismiss`;

    // Build the payload. ideaId is required.
    // Optionally include matchId and rejectInfo if provided.
    const payload: { [key: string]: any } = { ideaId };
    if (matchId) {
      payload.matchId = matchId;
    }
    if (rejectInfo) {
      payload.rejectInfo = rejectInfo;
    }

    if (isGap) {
      payload.isGap = isGap;
    }
    
    const response = await axios.post(url, payload, config);
    return response.data;
  } catch (e: any) {
    console.error("Error dismissing evidence:", e);
    throw e;
  }
};

export interface ValidateEvidencePayload {
  ideaId: string;
  sourceOfAssignment?: "request" | "suggestion" | "bagel" | "search"
  matchId?: string;
  oldIdeaId?: string;
}

/**
 * Validate an Evidence
 *
 * @param evidenceId - The ID of the evidence to validate
 * @param payload - The payload containing ideaId and optional matchId
 * @param auth0 - Auth0 instance for authentication
 * @returns A promise resolving to the server's response
 */
export const validateEvidence = async (
  evidenceId: string,
  payload: ValidateEvidencePayload,
  auth0: any
): Promise<any> => {
  try {
    if (!auth0) {
      throw new AuthenticationError(
        "invalid_request", 
        "Missing Auth0 instance. Please check authentication.", 
        "" 
      );
    }

    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/${evidenceId}/validate`;

    const response = await axios.post(url, payload, config);
    return response.data;
  } catch (e: any) {
    console.error("Error validating evidence:", e);
    throw e;
  }
};

export interface EvidenceItem {
  evidenceId: any;
  matchId?: string;
  sourceOfAssignment?: "request" | "suggestion" | "bagel" | "search";
}

export interface AssignEvidencesPayload {
  ideaId: string;
  oldIdeaId?: string;
  evidences: EvidenceItem[];
}

/**
 * Validate multiple evidences
 *
 * @param payload - The payload containing ideaId, oldIdeaId, and an array of evidences
 * @param auth0 - Auth0 instance for authentication
 * @returns A promise resolving to the server's response
 */
export const assignEvidencesToAnotherIdea = async (
  payload: AssignEvidencesPayload,
  auth0: any
): Promise<any> => {
  try {
    if (!auth0) {
      throw new AuthenticationError(
        "invalid_request", 
        "Missing Auth0 instance. Please check authentication.", 
        "" 
      );
    }

    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/evidences/validate`;

    const response = await axios.post(url, payload, config);
    return response.data;
  } catch (e: any) {
    console.error("Error validating multiple evidences:", e);
    throw e;
  }
};
