// src/components/Activities.tsx

import React, { FC, useEffect, useState } from "react";
import { Box, ActionIcon, Title } from "@mantine/core";
import { IconSend } from "@tabler/icons";
import MentionTextarea, { MentionSuggestion } from "../../../MentionTextarea";
import { searchMembers, searchUsers } from "../../../../Api"; // Import the searchMembers function
import useComments from "../../../../hooks/useComments";
import { Comments } from "./Comments";
import { useAuth0 } from "@auth0/auth0-react";
import { useMentions } from "../../../MentionTextarea/MentionContext";

interface ActivitiesProps {
  evidence: any;
}

const Activities: FC<ActivitiesProps> = ({ evidence }) => {
  const auth0 = useAuth0();

  // Initialize useComments hook with the evidence ID
  const { fetchComments, comments, loading, addComment, deleteComment, editComment } =
    useComments({ evidenceId: evidence._id });
  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();

  // Local state to manage the comment input
  const [commentValue, setCommentValue] = useState("");

  // Function to handle comment submission
  const handleSubmitComment = async () => {
    if (!commentValue.trim()) return; // Prevent submitting empty comments
    await addComment(commentValue.trim()); // Add the comment using the hook's function
    setCommentValue(""); // Reset the input field after submission
  };

  // Handle user search for mentions using searchMembers function
  const searchUser = ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    setSuggestionsLoading(true);

    return new Promise<MentionSuggestion[]>((resolve, reject) => {
      console.log({ query });

      Promise.all([searchUsers(query, auth0), searchMembers(query, auth0)])
        .then(([users, members]) => {
          console.log({ users, members });

          const userSuggestions: MentionSuggestion[] =
            users?.map((user: any) => ({
              id: user._id,
              email: user.email,
              name: user.name,
              picture: user.picture,
            })) ?? [];

          const memberSuggestions: MentionSuggestion[] =
            members
              ?.filter((member: any) => member.name)
              .map((member: any) => ({
                id: member._id,
                email: member.email,
                name: member.name,
                picture: member.picture,
              })) ?? [];

          const combinedSuggestions = [
            ...userSuggestions,
            ...memberSuggestions,
          ];
          const emailToUserMap = new Map<string, MentionSuggestion>();

          combinedSuggestions.forEach((suggestion) => {
            if (
              !emailToUserMap.has(suggestion.email) ||
              memberSuggestions.find(
                (member) => member.email === suggestion.email
              )
            ) {
              emailToUserMap.set(suggestion.email, suggestion);
            }
          });

          const uniqueSuggestions = Array.from(emailToUserMap.values());
          console.log({ emailToUserMap });

          setSuggestions(uniqueSuggestions);
          resolve(uniqueSuggestions);
        })
        .catch((error) => {
          console.error("Error searching users and members:", error);
          reject(error);
        })
        .finally(() => setSuggestionsLoading(false));
    });
  };

  useEffect(() => {
    fetchComments()
  }, [])  

  return (
    <Box
      sx={{
        borderTop: "1px solid #E5E7EB",
      }}
    >
      {/* Title Section */}
      <Title mt="md" mx="xs" order={5} mb="xs">
        Activities
      </Title>

      {/* Comments Section */}
      <Comments
        request={evidence}
        origin={evidence.origin}
        comments={comments}
        deleteComment={deleteComment}
        editComment={editComment}
        loading={{
          comment: loading.add, // Maps to adding a comment
          fetch: loading.fetch, // Maps to fetching comments
        }}
      />

      {/* Comment Input Section */}
      <Box
        sx={{
          background: "#F5F6FE",
          borderTop: "1px solid #E5E7EB",
          padding: 10,
          position: "sticky",
          bottom: 0,
        }}
      >
        {/* @ts-ignore */}
        <MentionTextarea<MentionSuggestion>
          placeholder="Your comment"
          getMentions={searchUser}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          editable
          clearOnRightSectionClick
          debounceWait={1000}
          sx={{
            background: "#fff",
            border: "1px solid #D8D8DB",
            borderRadius: 8,
            fontSize: 12,
          }}
          rightSection={
            <ActionIcon
              onClick={handleSubmitComment}
              mr={4}
              variant="filled"
              color="blue"
              radius="md"
              loading={loading.add}
              disabled={commentValue.trim() === '<p></p>' || commentValue.trim() === ''}
              aria-label="Send comment"
            >
              <IconSend size={20} />
            </ActionIcon>
          }
        />
      </Box>
    </Box>
  );
};

export default Activities;
