import {  ActionIcon, Box, Checkbox, Flex, Stack, Text, Tooltip } from "@mantine/core"
import { SourceIcon } from "../../../utils/SourceIcon";
import { IconAlertOctagon, IconBookmark, IconSearch, IconStar } from "@tabler/icons"
import moment from "moment";
import { useContext, useState, useEffect } from "react";
import IdeaContext from "../IdeaContext";
import { getBagelId, stripHtml } from "../../../utils";
import SyncContext from "../../../context/SyncContext";
import { useAuth0 } from "@auth0/auth0-react";
import { saveRequestStarred ,getCommentsForRequest} from "../../../Api";
import { useHover } from "@mantine/hooks";
import { BusinessNameNotification, FromBagelIcon, NewRequestIcon, PurpuleBagelAiIcon, RequestIcon, ConfidenceLevelIcon, FillIconNotification } from "../../../icons/x-symbol-svgrepo-com";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { OverflownText } from "../../OverflownText";
import posthog from "posthog-js";
import { formatBigMonetaryValueIdeas } from "../../ideas/ideas-table-helpers/Helpers";
import { getNestedValue } from "../Funcs";
import DiscoveryContext from "../../Discovery/DiscoveryContext";

const EvidenceCardNiv = ({
  item,
  chooseMatch,
  index,
  fromSearch,
  chosenEvidence,
  ideaPage,
  selectedEvidenceIds,
  setSelectedEvidenceIds,
}: {
  item: any;
  chooseMatch: any;
  index: number;
  fromSearch: boolean;
  chosenEvidence: any;
  ideaPage?: boolean;
  selectedEvidenceIds: any;
  setSelectedEvidenceIds: any;
}) => {

  const { requestPriorities, displayState, impactAggregationProperties } = useContext(SyncContext)
  const { setIsCommentPresent, comments, loadEvidence } = useContext(IdeaContext)

  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;
  const auth0 = useAuth0();
  
  const isChosen = chosenEvidence && chosenEvidence?._id === item?._id;

  const {
    getUnreadNotificationsCountByRequest
  } = useContext(ContextToUse);
  const [commentExist, setCommentExist] = useState(false);
  const userId = getBagelId(auth0.user)
  const [isPending, setIsPending] = useState(item?.pending?.includes(userId));
  
  useEffect(() => {
    if (comments && item?._id === comments[0]?.itemId) {
      setCommentExist(true); 
    }
  }, [comments, item?._id]);
  
  useEffect(() => {
    if (!item?._id) {
      setCommentExist(false);
      if (isChosen) {
        setIsCommentPresent([]);
      }
      return;
    }
  
    // getCommentsForRequest(item._id, auth0)
    //   .then((res: any) => {
    //     if (res.data.comments.length) {
    //       setCommentExist(true);
    //       if (isChosen) {
    //         setIsCommentPresent(res.data.comments);
    //       }
    //     } else {
    //       setCommentExist(false);
    //       if (isChosen) {
    //         setIsCommentPresent([]);
    //       }
    //     }
    //   })
    //   .catch((e: any) => {
    //     console.error("Error fetching comments:", e);
    //   });
  }, [item?._id, auth0, isChosen]);
  
  // Only change: Gather all impact items that should be displayed
  const impactsToShow = impactAggregationProperties?.filter(
    (a: any) => a?.impact?.displayInEvidenceList
  ) || [];

  const { hovered, ref } = useHover();
  const [starred, setStarred] = useState(
    item?.starred?.includes(getBagelId(auth0.user))
  );
  const isMatch: boolean = item?.match;
  const company = item?.company;
  const description = item?.title || item?.text || null;
  let priority = item?.priority || "Unassigned";

  if (typeof priority !== 'string') {
    priority = 'Unassigned';
  }
  const currentPriorityIndex = requestPriorities.findIndex((r:any)=> r?.label.toLowerCase() === priority.toLowerCase())

  const color = requestPriorities[currentPriorityIndex]?.color
  const isOppurtunity = item?.chat?.itemType === 'opportunity'

  const submitter =
    (item?.internalSubmitter || item?.cxSubmitter) ??
    item?.submitter;
  const submitterName = submitter ? submitter?.name || submitter?.displayName : item?.submitterEmail;
  const createdAt = item?.createdAt ? new Date(item?.createdAt) : undefined

  const confidenceLevel = item?.match?.level
  
  const itemOrigin =  item?.cxItem?.trueOrigin || item?.cxItem?.origin || item?.origin 

  const isSourceRequest: boolean = item.sourceOfAssignment === 'request'
  const isSourceBagel: boolean = item.sourceOfAssignment === 'bagel'
  const isSourceSuggestion: boolean = item.sourceOfAssignment === 'suggestion'
  const isSourceSearch: boolean = item.sourceOfAssignment === 'search'
  
  const toggleFavourites = () =>{
    const request = {
      id: item._id,
      push: !starred,
    };
    saveRequestStarred(request, auth0)
      .then((res) => {
        console.log(res);
        setStarred(!starred)
        loadEvidence();
      })
      .catch((e) => console.log(e));

  };

  const toggleEvidenceSelection = (prevEvidences: any[], evidence: any) => {
    const exists = prevEvidences.some((e) => e._id === evidence._id);
    
    return exists
      ? prevEvidences.filter((e) => e._id !== evidence._id)
      : [...prevEvidences, evidence];
  };
  
  const handleCheckboxChange = (event: any, evidence: any) => {
    event.stopPropagation();
    setSelectedEvidenceIds((prevEvidences:any) => toggleEvidenceSelection(prevEvidences, evidence));
  };
  
  const isChecked = selectedEvidenceIds?.some((e: any) => e._id === item._id);
  
  return (
    <Flex
      ref={ref}
      h="66px"
      fz="16px"
      my="8px"
      align="center"
      onClick={() => {
        chooseMatch(item._id, fromSearch);
        posthog.capture('suggested_clicked', item)
      }}
      sx={{
        border: (hovered || isChosen)
          ? "1px solid #5C5CEB"
          : isMatch
            ? "1px dashed #5C5CEB"
            : "1px solid #DFDEFD",
        cursor: "pointer",
        borderRadius: 12,
        overflow:'hidden',
        backgroundColor: selectedEvidenceIds?.length > 0 ? "#5C5CEB33" : "transparent", 
      }}
      justify="space-between"
    >
      <Flex h="100%">
        <Flex sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", overflow: "visible",position: "relative"}}>
          { ideaPage &&  <Checkbox
            checked={isChecked}
            onClick={(e) => e.stopPropagation()} 
            onChange={(e) => handleCheckboxChange(e, item)}
            sx={{
              position: "absolute",
              top: "8px", 
              left: "7px",
              opacity: hovered || selectedEvidenceIds?.length > 0 ? 1 : 0,
              input: {
                width: "16px",
                height: "16px",
                border: "2px solid #5C5CEB", 
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:checked": {
                  backgroundColor: "#5C5CEB", 
                  borderColor: "#5C5CEB",
                },
              },
              "& .mantine-Checkbox-icon": {
                margin: "3px",
              },
            }}
          />
          }
          {!fromSearch ? 
            <Stack
              w="52px"
              h="100%"
              bg={selectedEvidenceIds?.length > 0
                ? "#5C5CEB3"  
                : (isSourceRequest && item?.state === "inReview" && !item?.match)
                  ? "rgba(92, 92, 235, 0.8)" 
                  : "#fff"}
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "11.3px", borderBottomLeftRadius: "11.3px" }}
            >
              {((isSourceSuggestion)  || (item?.match) ) ? (
                <Tooltip label="Found by Bagel AI" withinPortal withArrow>
                  <Box>
                    <PurpuleBagelAiIcon />
                  </Box>
                </Tooltip>
              ) : (isSourceBagel || isSourceSearch) ? (
                <Tooltip label="Added by PM" withinPortal withArrow>
                  <Box>
                    <FromBagelIcon />
                  </Box>
                </Tooltip>
              ) : (isSourceRequest && item.state === "validated" ) ? (
                <Tooltip label={`Request connected by ${submitterName || 'N/A'}`} withinPortal withArrow>
                  <Box>
                    <RequestIcon />
                  </Box>
                </Tooltip>
              ) :
                (/* isSourceRequest  && */ item?.state === 'inReview' ) ? (
                  <Tooltip label={`Request connected by ${submitterName || 'N/A'}`} withinPortal withArrow>
                    <Box>
                      <NewRequestIcon />
                    </Box>
                  </Tooltip>
                ) :
                  (
                    ""
                  )}
            </Stack>
            : 
            <Stack
              w="52px"
              h="100%"
              bg="#fff"
              align="center"
              justify="center"
              sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}
            >
              <IconSearch size={18} color="#5C5CEB"/>
            </Stack>} 
  
          <Stack
            w="52px"
            bg="#F8F9FA"
            align="center"
            justify="center" // Center content vertically by default
            style={{
              height: (itemOrigin === "facebook") ? "76px" : '100%',
              backgroundColor: selectedEvidenceIds?.length > 0 ? "#5C5CEB33" : "#F8F9FA",
            }} 
          >
            <Tooltip label={capitalizeFirstLetter(itemOrigin|| "N/A")} withinPortal withArrow>
              <Box
                style={{
                  marginBottom: (itemOrigin === "facebook") ? "1px" : "-1px", // Adjust position dynamically
                }}
              >
                {(itemOrigin) && (
                  <SourceIcon
                    width={22}
                    height={22}
                    sourceName={
                      itemOrigin || "n/a"
                    }
                  />
                )}
              </Box>
            </Tooltip>
          </Stack>

        </Flex>

        <Box p="12px" w="100%">
          <Flex >
            <Text
              bg="#5C5CEB1A"
              color="#5C5CEB"
              fz="13px"
              fw={600}
              lineClamp={1}
              sx={{ lineHeight: "20px", borderRadius: '4px' }}
              px={4}
              py={2}
            >
              {item?.businessName || 'General'}
            </Text>
            {getUnreadNotificationsCountByRequest && getUnreadNotificationsCountByRequest(item?._id) > 0
            && <Box  h={16} w={16} py={2}>
              <BusinessNameNotification/>
            </Box>}
            { commentExist
            && <Box  h={16} w={16} py={2}>
              <FillIconNotification/>
            </Box>}
            {
              isPending && <IconBookmark fill="#5C5CEB" size={16} color="#5C5CEB"/>
            }
          </Flex>
          <div style={{ minWidth: "120px" }}>
            <Tooltip label={stripHtml(description)}>
              <Text 
                lineClamp={1} 
                fw={600}
                fz="13px">
                {stripHtml(description)}
              </Text>
            </Tooltip>
          </div>
        </Box>
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Box pl={8} mr={12} w="93px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text fz="12px" color="#585C68">
              Name
            </Text>
            <OverflownText fw={600} fz="12px" lineClamp={1} color="#212529">
              {company?.name || "N/A"}
            </OverflownText>            
          </Box>
          <Box pl={8} mr={12} w="65px" sx={{ borderLeft: "1px solid #D8D8DB" }}>
            <Text color="#585C68" fz="12px">
              Created
            </Text>
            <Text fw={600} fz="12px" lineClamp={1} color="#212529">
              {createdAt ? moment(createdAt).format("MM-DD-YY") : ""}
            </Text>
          </Box>

          {/* BEGIN: Dynamically Render All Impacts with displayInEvidenceList=true */}
          {impactsToShow.map((impactItem: any, idx: number) => {
            const impactValue = impactItem?.fieldPath
              ? getNestedValue(item, impactItem?.fieldPath)
              : null;
            const prefix = impactItem?.impact?.prefix || undefined;

            return (
              <Box
                key={idx}
                pl={8}
                mr={12}
                w="85px"
                sx={{ borderLeft: "1px solid #D8D8DB" }}
              >
                <Tooltip label={impactItem?.label || 'N/A'}>
                  <Text color="#585C68" fz="12px" lineClamp={1}>
                    {impactItem?.label || 'N/A'}
                  </Text>
                </Tooltip>
                <Text fw={600} fz="12px" lineClamp={1} color="#212529">
                  {impactValue
                    ? formatBigMonetaryValueIdeas(impactValue, prefix)
                    : 'N/A'}
                </Text>
              </Box>
            );
          })}
          {/* END: Dynamically Render All Impacts */}

          {displayState?.confidenceLevel && confidenceLevel &&
          <Tooltip label='Match Signal'>
            <Stack align="center">
              {confidenceLevel === 'Strong' && <ConfidenceLevelIcon line1Color="#0BAC4D" line2Color="#0BAC4D" line3Color="#0BAC4D"/>}
              {confidenceLevel === 'Medium' && <ConfidenceLevelIcon line1Color="#FF8700" line2Color="#FF8700"/>}
              {confidenceLevel === 'Weak' &&  <ConfidenceLevelIcon line1Color="#DB2525"/>}
            </Stack>
          </Tooltip>
          }
          <Tooltip label={`Priority: ${ priority || "N/A"}`}>
            <Box>
              <Stack align="center" justify="center" ml={12} fz="14px" >
                <IconAlertOctagon size={16}  color={color} />{" "}
              </Stack>
            </Box>
          </Tooltip>
        </Flex>

        {!isMatch ? (
          <Flex w="20px" mr={18} ml={12}>
            <ActionIcon
              onClick={() => {
                toggleFavourites();
              }}
            >
              <IconStar
                color={auth0.user && starred ? "#FDAE1680" : "#D1D5DB"}
                fill={auth0.user && starred ? "#FDAE1680" : "#fff"}
                size={16}
                strokeWidth={2}
              />
            </ActionIcon>
          </Flex>
        ) : (
          <Flex mr={18} ml={16} w="20px"></Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EvidenceCardNiv;