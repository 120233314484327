import React, { useState, ReactNode } from "react";
import { Menu, TextInput, Text, Box, Highlight } from "@mantine/core";
import { IconSearch } from "@tabler/icons";

interface Item {
  _id?: string;
  id?: string;
  useId?: boolean;
  name: string;
  childComponents?: Item[];
}

interface BagelAutocompleteProps {
  items: Item[];
  selected: any;
  children: ReactNode;
  onSelect: (item: Item) => void;
  disabled?: boolean;
  searchAccessor?: string;
}

const BagelAutocomplete: React.FC<BagelAutocompleteProps> = ({
  items,
  selected,
  children,
  onSelect,
  disabled,
  searchAccessor = "name"
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Function to recursively filter items and child items
  const filterItems = (items: Item[], term: string): Item[] => {
    term = term.toLowerCase();
    if (!items || items.length === 0) return [];
    return items.filter((item: any) =>
      item[searchAccessor]?.toLowerCase().includes(term) ||
      (item.childComponents && filterItems(item.childComponents, term).length > 0)
    );
  };

  const filteredItems = filterItems(items, searchTerm);

  const renderMenuItems = (items: Item[]) => {
    return items.map((item, index) => (
      <React.Fragment key={item.id || item._id || item.name || index}>
        <Menu.Item 
          py={5}
          onClick={() => onSelect(item)}
          sx={{
            backgroundColor:
              (item.useId ? item.id : item.name) === selected
                ? "#F5F6FE"
                : "transparent",
          }}
        >
          <Highlight highlight={searchTerm}>{item.name}</Highlight>
        </Menu.Item>
        {item.childComponents && item.childComponents.length > 0 && (
          <Box pl="md">
            {renderMenuItems(item.childComponents)}
          </Box>
        )}
      </React.Fragment>
    ));
  };

  return (
    <Menu shadow="md">
      <Menu.Target>{children}</Menu.Target>
      {!disabled && (
        <Menu.Dropdown maw={250}>
          <TextInput
            icon={<IconSearch size={14} />}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
          <Box
            sx={{
              overflowY: "scroll",
            }}
            mah={400}
            pt="xs"
          >
            {renderMenuItems(filteredItems)}
            {filteredItems.length === 0 && (
              <Text p="sm" color="dimmed" align="center" size="xs">
                No matches for "{searchTerm}"
              </Text>
            )}
          </Box>
        </Menu.Dropdown>
      )}
    </Menu>
  );
};

export default BagelAutocomplete;