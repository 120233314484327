import { Box, Stack, Text } from "@mantine/core"

const DiscoveryZeroState = ({text}:{text: string}) => {
  return (
    <Stack align="center" h='100%'>
      <Box  mt={80} bg='rgba(92, 92, 235, 0.1)' w={353} p={24} sx={{borderRadius: 10}}>
        <Text>
          {text}
        </Text>
      </Box>
    </Stack> 
  )
}
export default DiscoveryZeroState