import { Box, Button, Flex, Modal, Stack, Text, Tooltip, Menu, Anchor } from "@mantine/core";
import {  IconCheck, IconChevronDown, IconPlus, IconX } from "@tabler/icons";
import  { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { openModal } from "@mantine/modals";


import { showNotification } from "@mantine/notifications";
import IdeaContext from "../../../IdeaContext";
import LogoIcon from "../../../../layouts/LogoIcon";
import { DeclineModal } from "./DeclineModal";
import { removeGtmIdeaId, assignRequestToNewIdea } from "../../../../../Api";
import { RequestAssignment } from "./RequestAssignment";
import CommentsMenu from "../Comment/CommentsMenu";
import SyncContext from "../../../../../context/SyncContext";
import AssignActionsMenu from "./AssignActionsMenu";

import useEvidenceActions from "../../../../../hooks/useEvidenceActions";

const NewRequestActions = ({ 
  navToPerspective,
  componentId,
  chosenEvidence,
  validation
}
: 
{ 
  navToPerspective: any,
  componentId: string,
  chosenEvidence: any,
  validation?: boolean;
}) => {
  // FIX REASSIGN
  
  const {
    loadEvidence,
    setChosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
  } = useContext(IdeaContext);

  const { defaultPriority, defaultStatus,allComponents } = useContext(SyncContext);
  const auth0 = useAuth0();
  const { validate } = useEvidenceActions({ evidenceId: chosenEvidence._id });

  const [opened, setOpened] = useState(false);

  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModal
          request={chosenEvidence}
          idea={idea}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}
        />
      ),
    });
  };

  /* const handleDecline = () => {

    const rejectInfo = {reason: 'not relevant', comment:'not relevant'}

    dismissFeedback(item?._id,rejectInfo, auth0)
      .then((res) => {
        console.log(res)
      })
      .catch((e) => console.log(e))
  
  }; */

  const onValidate = () => {
    /* const requestObj = {...} */
    ideaId &&
    validate({ ideaId, sourceOfAssignment:"request" })
      .then((res) => {
        console.log({ res });
        if (res) {
          reCalculatPerspectiveForRequest(
            { ...res.evidence, state: "validated" },
            "add"
          );
        }
        loadEvidence();
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
        });
      })
      .then(() => setChosenEvidence(null))
      .catch((e) => console.log(e));
  };
  const item = chosenEvidence;

  const title = item?.title || item?.textItem?.title || "";
  const description = item?.text || item?.textItem?.text || '';

  const newIdeaObj = {
    title: title,
    description: description,
    componentId: idea.componentId,
    ownerId: null,
    rolloutDate: null,
    status: defaultStatus,
    priority: defaultPriority,
  };
  const evidence ={...item}

  const createNew = () => {
    if (!componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message: "Please assign a producat area to this request before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return;
    }
  
    if (item?.componentId) {
      const foundComponent = allComponents.find((c: any)=> c._id === item?.componentId)
      if(!foundComponent){
        showNotification({
          title: "Invalid Product Area",
          color: "red",
          message: "Please assign a producat area to this request before creating a new idea.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
        return;
      }
  
      if(foundComponent){
        newIdeaObj.ownerId = foundComponent.ownerId
        evidence.ownerId = foundComponent.ownerId
      }
  
      assignRequestToNewIdea(
        { idea: newIdeaObj, evidence },
        auth0
      )
        .then((response) => {
          const ideaId = response.createNewIdeaRes?.idea?._id;
          showNotification({
            title: "Assigned successfully!",
            color: "teal",
            message: <Box>
              <Text>New idea has been created</Text>
              <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
              </Anchor>
            </Box>,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          if (ideaId) {
            setTimeout(() => {
              window.open(`/idea/${ideaId}`);
            }, 1300);
          } else {
            console.log(`did not receive request id for new idea`, response);
          }
        })
        .catch(console.log)
          
    }
  };
  const moveToInbox = () => {
    if (!item?._id) {
      return
    }
  
    if (item?._id) {
  
      removeGtmIdeaId(
        item?._id,
        auth0
      )
        .then((response) => {
          showNotification({
            title: "Moved successfully!",
            color: "teal",
            message: "Moved Successfully",
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
        })
        .then(() => loadEvidence())
        .catch(console.log)
          
    }
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
    >
      {opened && (
        <Modal
          mt={100}
          opened={opened}
          onClose={() => setOpened(false)}
          size="32vw"
          title={
            <Text fz={20} fw={600}>
              Reassign to another idea
            </Text>
          }
          styles={{
            content: {
              marginLeft: "calc((100vw - 32vw) / 2) !important",
            },
          }}
        >
          <Stack h={445} pt={5} /* align="center" */ justify="center" w="100%">
            <Stack /* align="center" */ justify="center">
              <RequestAssignment
                idea={idea}
                item={chosenEvidence}
                setOpened={setOpened}
                navToPerspective={navToPerspective}
                TextInputW="100%"
              />
            </Stack>
          </Stack>
        </Modal>
      )}
      {!validation && <CommentsMenu />}
      <Flex align="center">
        <Button.Group>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="135px"
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={onValidate}
            >
              <IconPlus size={14} />
              <Text>Assign to this Idea</Text>
            </Button>
          </Tooltip>
          <AssignActionsMenu
            actions={[
              {
                label: "Assign to another idea",
                tooltip: "Assign this request to a different idea",
                onClick: () => setOpened(true),
              },
              {
                label: "Move to Inbox",
                tooltip: "Detach the Evidence from this Idea - it will show up in the Inbox for processing",
                onClick: moveToInbox,
              },
              {
                label: "Assign to a new idea",
                tooltip: "Assign to a new idea",
                onClick: createNew,
              },
            ]}
          />
        </Button.Group>
      </Flex>
    </Flex>
  );
};

export default NewRequestActions;
