import React, { useContext, useMemo, useState } from "react";
import { Flex, Button, Tooltip, Loader, Group, Text } from "@mantine/core";
import {
  IconAlertOctagon,
  IconBookmark,
  IconCategory,
  IconCoin,
  IconComponents,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import { openModal } from "@mantine/modals";
import BagelAutocomplete from "../../general/BagelAutocomplete";
import { showNotification } from "@mantine/notifications";
import { formatMonetaryValue } from "../../ideaNiv/Funcs";
import SyncContext from "../../../context/SyncContext";
import { UseListStateHandlers } from "@mantine/hooks";
import { DeclineModalContent } from "../../idea/RightBar/DeclineModalContent";
import { useAuth0 } from "@auth0/auth0-react";
import { updateEvidence } from "../../../api/evidences";
import { OverflownText } from "../../OverflownText";
import { SourceIcon } from "../../../utils/SourceIcon";
import { InfoTooltip } from "../EvidenceCard";
import { markEvidencePending } from "../../../Api";
import { getBagelId } from "../../../utils";

export function Actions({
  onAction,
  evidence,
  setEvidence,
  evidencesHandlers,
}: {
  onAction?: () => void
  evidence: any;
  setEvidence: React.Dispatch<any>;
  evidencesHandlers?: UseListStateHandlers<any[]> | undefined;
}) {
  const auth0 = useAuth0();

  const [loading, setLoading] = useState<string | null>(null);
  const { components, domains, owners, requestPriorities } =
    useContext(SyncContext);
  console.log({ requestPriorities });
  const [domain, setDomain] = useState(
    evidence?.componentObj?.domain || evidence?.domain || "Unassigned"
  );
  // const [filteredComponents, setFilteredComponents] = useState(components);
  const [productArea, setProductArea] = useState(
    evidence?.componentObj?.name || "Unassigned"
  );
  const [owner, setowner] = useState(evidence?.owner?.name || "Unassigned");
  const userId = getBagelId(auth0.user)
  const [isPending, setIsPending] = useState(evidence?.pending?.includes(userId));

  const currentPriority = requestPriorities.find(
    (p: any) => p?.key?.toLowerCase() == evidence?.priority?.toLowerCase()
  );

  const origin =
    evidence?.cxItem?.trueOrigin ||
    evidence?.cxItem?.origin ||
    evidence?.origin;

  const openDeclineModal = () => {
    openModal({
      modalId: "decline",
      title: "Decline Evidence",
      centered: true,
      children: (
        <DeclineModalContent
          evidence={evidence}
          evidencesHandlers={evidencesHandlers}
        />
      ),
    });
  };

  const updateEvidenceField = (field: string, value: any) => {
    const updatedData = { [field]: value }; // Prepare the updated field data
    setLoading(field);
    updateEvidence(evidence._id, updatedData, auth0) // Call the API function
      .then(({ evidence: updatedEvidence }) => {
        const _field = field == "componentId" ? "Product area" : field;
        showNotification({
          title: `${_field} Updated`,
          message: `${_field} successfully updated to "${
            field == "componentId" ? updatedEvidence?.componentObj?.name : value
          }".`,
          color: "teal",
        });

        setEvidence(updatedEvidence);
        setowner(updatedEvidence?.owner?.name || "Unassigned")
        // Update evidence locally in the handlers
        evidencesHandlers?.applyWhere(
          (i: any) => i._id === evidence._id,
          () => ({ ...updatedEvidence })
        );

        if (field === "domain") setDomain(value);
      })
      .catch(() =>
        showNotification({
          title: `Error Updating ${field}`,
          message: `Failed to update ${field}.`,
          color: "red",
        })
      )
      .finally(() => {
        setLoading(null)
        onAction?.()
      });
  };

  const _domain = (evidence?.componentObj?.domain || evidence?.domain)
    ?.toLowerCase()
    ?.trim();

  // Memoize the filtered components
  // const filteredComponents = useMemo(() => {
  //   if (!domain) {
  //     return components;
  //   }
  //   return components.filter(
  //     (comp: any) => comp.domain?.toLowerCase()?.trim() === _domain.domain?.toLowerCase()?.trim()
  //   );
  // }, [components, domain]);
  const filteredComponents =  components.filter(
    (comp: any) => comp.domain?.toLowerCase()?.trim() === domain?.toLowerCase()?.trim()
  );
  // Memoize the items for BagelAutocomplete
  const componentsItems =
      filteredComponents.map((comp: any) => ({
        id: comp._id,
        name: comp.name,
        childComponents: comp.childComponents
      }))

  const toggleMarkPending = () =>{
    const request = {
      id: evidence._id,
      isUserPushRequired: !isPending,
    };
    markEvidencePending(request, auth0)
      .then((res) => {
        setIsPending(!isPending)
        onAction?.()
      })
      .catch((e) => console.log(e))
  };
    
  return (
    <Flex
      align="center"
      justify="space-between"
      mih={56}
      p="sm"
      sx={{ borderBottom: "1px solid #E5E7EB" }}
    >
      <Group spacing={5}>
        {/* Priority */}
        <BagelAutocomplete
          items={requestPriorities.map((p: any) => ({
            name: p.label,
            value: p.key,
          }))}
          selected={evidence?.priority}
          onSelect={(item) => updateEvidenceField("priority", item.name)}
        >
          <Tooltip label="Select Priority">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              align="center"
              sx={{
                background: currentPriority?.color
                  ? `${currentPriority.color}10`
                  : "#F8F9FB",
                color: currentPriority?.color,
                border: `1px solid ${currentPriority?.color}35`,
                cursor: "pointer",
                borderRadius: "5px",
                transition: "background-color 0.2s",
                "&:hover": {
                  background: currentPriority?.color
                    ? `${currentPriority.color}20`
                    : "#F0F1F5",
                },
              }}
            >
              {loading == "priority" ? (
                <Loader size={18} color={currentPriority?.color} />
              ) : (
                <IconAlertOctagon
                  size={18}
                  color={currentPriority?.color || "#000"}
                />
              )}
              <OverflownText
                lineClamp={1}
                ml={8}
                color={currentPriority?.color || "#000"}
              >
                {evidence?.priority || "Unassigned"}
              </OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Domain */}
        <BagelAutocomplete
          items={domains}
          selected={domain}
          onSelect={(item: any) => {
            // updateEvidenceField("domain", item.name);
            setDomain(item.name);
            setProductArea("Unassigned");
            setowner(item?.ownerName || "Unassigned");
          }}
        >
          <Tooltip label="Select Domain">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              bg="#F8F9FB"
              align="center"
              sx={{ 
                cursor: "pointer", 
                borderRadius: "5px",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#EDEFF3", // slightly darker on hover
                },
              }}
            >
              <OverflownText lineClamp={1}>{domain}</OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Product Area */}
        <BagelAutocomplete
          items={componentsItems}
          selected={productArea}
          
          onSelect={(item: any) => {
            setowner(item?.ownerName || "Unassigned");
            updateEvidenceField("componentId", item._id || item.id)
            setProductArea(item.name)
          }
          }
          disabled={domain === "Unassigned"}
        >
          <Tooltip label="Select Product Area">
            <Flex
              h="32px"
              px="sm"
              bg="#F8F9FB"
              align="center"
              fontSize="12px"
              sx={() => ({
                cursor: domain !== "Unassigned" ? "pointer" : "not-allowed",
                borderRadius: "5px",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#EDEFF3", // slightly darker on hover
                },
                fontSize: "13px",
                color: !productArea || productArea === "Unassigned" ? "red" : "black",
                animation: productArea && productArea !== "Unassigned" ? "none" : "blinking 1s 5",
                '@keyframes blinking': {
                  '0%': {
                    opacity: 1,
                  },
                  '50%': {
                    opacity: 0,
                  },
                  '100%': {
                    opacity: 1,
                  },
                },
              })}
            >
              {loading === 'componentId' && <Loader color="gray" size={18} mr={6}/>}
              {productArea}
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Owner */}
        <BagelAutocomplete
          items={owners}
          selected={evidence?.ownerId}
          onSelect={(item) => updateEvidenceField("ownerId", item._id)}
        >
          <Tooltip label="Select Owner">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              bg="#F8F9FB"
              align="center"
              sx={{
                cursor: "pointer",
                borderRadius: "5px",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#EDEFF3", // slightly darker on hover
                },
              }}
            >
              {loading === 'ownerId' ? <Loader color="gray" size={18}/> : <IconUser stroke={1.5} color="black" size={16} />}

              <OverflownText lineClamp={1} ml={8}>
                {owner}
              </OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {evidence.cxItem?.opportunityType && (
          <InfoTooltip
            label="Opportunity Type"
            icon={<IconCategory stroke={1.5} color="black" size={14} />}
          >
            {evidence.cxItem.opportunityType}
          </InfoTooltip>
        )}

        {/* Amount */}
        {evidence?.cxItem?.amount && (
          <InfoTooltip
            label="Amount"
            icon={<IconCoin stroke={1.5} color="black" size={16} />}
          >
            {formatMonetaryValue(evidence?.cxItem?.amount)}
          </InfoTooltip>
        )}

        {/* origin */}
        {origin && (
          <InfoTooltip
            label="Origin"
            icon={<SourceIcon sourceName={origin} width={18} height={18} />}
          >
            <OverflownText tt="capitalize" lineClamp={1} ml={8} color="black">
              {origin}
            </OverflownText>
          </InfoTooltip>
        )}

        {/* company */}
        {evidence?.company?.name && (
          <InfoTooltip
            label="Account"
            icon={<IconComponents stroke={1} color="black" size={16} />}
          >
            {evidence.company?.name}
          </InfoTooltip>
        )}
      </Group>
      <Group spacing={10}>
        {isPending ? 
          <Tooltip label={"Unmark Pending"}>
            <Button
              sx={{
                backgroundColor: "#5C5CEB1A" ,
                border: "none" ,
                color: "#000000",
                padding: "8px",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#5C5CEB1A",
                  border: "none" ,
                },
              }}
              onClick={() => toggleMarkPending()}
            >
              <IconBookmark fill="#5C5CEB" size={16} color="#5C5CEB"/>
              <Text>Pending</Text> 
            </Button>
          </Tooltip> :
          <Button
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #D8D8DB",
              color: "#000000",
              padding: "8px",
              height: "35px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "1px solid #D8D8DB",
              },
            }}
            onClick={() => toggleMarkPending()}
          >
            <IconBookmark size={16}/>
            <Text>Mark pending</Text>
          </Button>
        }
        <Tooltip label="You can provide a reason in the next step">
          <Button
            compact
            color="red"
            variant="light"
            px="md"
            onClick={openDeclineModal}
            styles={() => ({
              root: {
                height: 35,
                borderRadius: "5px",
                border: "0.7px solid rgba(249, 88, 88, 0.4)",
              },
            })}
          >
            Decline
          </Button>
        </Tooltip>
      </Group>
    </Flex>
  );
}
