import React, { useState, useMemo, useContext } from "react";
import { Checkbox, Text, Box, Menu, TextInput, Highlight, Flex, UnstyledButton, Group } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons";
import DashboardContext from "../dashboard/DashboardContext";

export interface Option {
  value: string;
  label: string;
  childComponents?: Option[];
}

export interface BagelMultiSelectProps {
  parent: string;
  options: Option[];
  value: string[];
  onChange: (newSelected: string[]) => void;
  children: React.ReactNode;
  label?: string;
  searchable?: boolean;
  disabled?: boolean;
}

const BagelMultiSelect: React.FC<BagelMultiSelectProps> = ({
  parent,
  options,
  value,
  onChange,
  children,
  label,
  searchable = false,
  disabled = false,
}) => {

  const { 
    trendsActiveFilters,
    setTrendsActiveFilters,
  } = useContext(DashboardContext);
  
  const [searchTerm, setSearchTerm] = useState("");

  const findOptionByValue = (opts: Option[], val: string): Option | null => {
    for (const option of opts) {
      if (option.value?.toLowerCase() === val?.toLowerCase()) return option;
      if (option.childComponents && option.childComponents.length > 0) {
        const found = findOptionByValue(option.childComponents, val);
        if (found) return found;
      }
    }
    return null;
  };

  const getAllChildValues = (option: Option): string[] => {
    let values: string[] = [];
    if (option.childComponents && option.childComponents.length > 0) {
      for (const child of option.childComponents) {
        values.push(child.value?.toLowerCase());
        values = values.concat(getAllChildValues(child));
      }
    }
    return values;
  };
  
  const handleClearFilter = () => {
    setTrendsActiveFilters((prevFilters: any[]) => {
      const updatedFilters = prevFilters.filter((filter) => filter.key !== parent);
  
      return updatedFilters;
    });
  };

  

  const handleCheckedChange = (newSelectedValues: string[]) => {
    const addedValues = newSelectedValues.filter((val) => !value.includes(val));
    const removedValues = value.filter(
      (val) => !newSelectedValues.includes(val)
    );
    const toggledValue =
      addedValues.length > 0 ? addedValues[0] : removedValues[0];
    const isAdding = addedValues.length > 0;

    let updated = [...newSelectedValues];
    const toggledOption = findOptionByValue(options, toggledValue);
    if (toggledOption) {
      const childValues = getAllChildValues(toggledOption);
      if (isAdding) {
        childValues.forEach((childVal) => {
          if (!updated.includes(childVal)) {
            updated.push(childVal);
          }
        });
      } else {
        // Remove all child values.
        updated = updated.filter((val) => !childValues.includes(val));
      }
    }
    onChange(updated);
  };

  const filterOptions = (opts: Option[]): any[] => {
    if (!searchTerm) return opts;
    const term = searchTerm?.toLowerCase();
    return opts
      .map((option) => {
        const labelMatch = option.label?.toLowerCase().includes(term);
        let filteredChildren: Option[] = [];
        if (option.childComponents && option.childComponents.length > 0) {
          filteredChildren = filterOptions(option.childComponents);
        }
        if (labelMatch || filteredChildren.length > 0) {
          return {
            ...option,
            childComponents: filteredChildren,
          };
        }
        return null;
      })
      .filter((opt) => opt !== null);
  };

  const filteredOptions = useMemo(
    () => filterOptions(options),
    [options, searchTerm]
  );

  const renderOptions = (opts: Option[], level = 0) => {
    return opts.map((option) => {
      const hasChildren =
        option.childComponents && option.childComponents.length > 0;
      return (
        <div key={option.value} style={{ paddingLeft: level * 20 }}>
          <Checkbox
            my={5}
            sx={{
              cursor: "pointer",
              input: {
                ":checked": {
                  backgroundColor: "#5B60E3",
                  borderColor: "#5B60E3",
                },
              },
            }}
            py={3}
            px={6}
            value={option.value?.toLowerCase()}
            label={<Text><Highlight highlight={searchTerm}>{option.label}</Highlight></Text>}
          />
          {hasChildren && renderOptions(option.childComponents!, level + 1)}
        </div>
      );
    });
  };

  return (
    <Menu shadow="md" width={250} disabled={disabled} position="bottom-start">
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        {searchable && (
          <TextInput
            icon={<IconSearch size={14} />}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
        )}
        {label && (
          <Text size="xs" weight={500} mb="xs">
            {label}
          </Text>
        )}
        <Box
          sx={{
            overflowY: "scroll",
          }}
          mah={400}
          pt="xs"
        >
          <Checkbox.Group value={value} onChange={handleCheckedChange}>
            {renderOptions(filteredOptions)}
          </Checkbox.Group>
        </Box>
        <UnstyledButton  w='100%' onClick={handleClearFilter} m='sm' h={12}>
          <Group
           
            spacing={5}
            sx={{
              color: "#5C5CEB",
            }}
          >
            <IconX size={13} />
            <Text size={13}>Clear filter</Text>
          </Group>
        </UnstyledButton>
        
      </Menu.Dropdown>
    </Menu>
  );
};

export default BagelMultiSelect;
