import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from "recharts";
import { Box, Card, Flex, Stack, Text} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import SyncContext from "../../../../context/SyncContext";
import { findFilterByName } from "../../../../context/helpers";
import IdeaContext from "../../IdeaContext";
import { formatDate } from "../../../../utils"
import { isIdExist, getQuarterFromDate, getWeekFromDate, getClosestDataPoint } from "./helpers";
type CustomReferenceLabelProps = {
  viewBox?: any; 
  value: any;
  fill: any;
  date: string;
  onShowTooltip: (data: { x: any; date: any, value: any }) => void;
  onHideTooltip: () => void;
};

const CustomReferenceLabel = ({
  viewBox = {},
  value,
  fill,
  date,
  onShowTooltip,
  onHideTooltip,
}: CustomReferenceLabelProps) => {
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout); 
    }
    const timeout = setTimeout(() => {
      onShowTooltip({ x: viewBox.x, date, value });
    }, 100); 
    setTooltipTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout); 
    }
    const timeout = setTimeout(() => {
      onHideTooltip();
    }, 100); 
    setTooltipTimeout(timeout);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
        onHideTooltip();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout, onHideTooltip]);

  const x = viewBox.x;
  const y = viewBox.y;

  if (!x || !y || !value) return null;
  return (
    <g 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <rect
        x={x - 50} 
        y={y - 18} 
        width={100}
        height={17}
        fill={fill} 
        stroke={fill}
        rx={5} 
      />
      <text
        x={x}
        y={y - 5} 
        fill="white"
        fontSize="12"
        fontWeight="bold"
        textAnchor="middle"
      >
        {value.length > 15 ? value.substring(0, 12) + "..." : value}
      </text>
    </g>
  );
};


const Trend = ({
  dataArr,
  selectedValues,
  setSelectedValues,
  quarter,
  moreThanThirty
}: {
  dataArr: any[];
  selectedValues: string[],
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  quarter: boolean;
  moreThanThirty: boolean;
}) => {
  const { setActiveFilters } = useContext(FiltersContext);
  const { ideasFiltersConfig, markedDeliveryStatus }  = useContext(SyncContext);
  const { idea } = useContext(IdeaContext);
  const statusHistory = idea?.productItem?.statusHistory || [];
  const deliveryStatus = (markedDeliveryStatus || []).filter((status:any) => status.marked === true);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, date: '', value: '' });

  const showTooltip = ({ x, date, value }: any) => {
    setTooltip({ visible: true, x, date, value });
  };

  const hideTooltip = () => {
    setTooltip({ visible: false, x: 0, date: '', value: '' });
  };


  const referenceData = statusHistory
    .filter((status: any) =>
      deliveryStatus.some((ds: any) => (ds.id === status.toStatusId || (ds.id.includes('-') && isIdExist(ds.id, status.toStatusId)  )) && ds.marked == true)
    )
    .map((status: any) => {
      const deliveryStatusEntry = deliveryStatus.find(
        (ds: any) => ds.id === status.toStatusId || (ds.id.includes('-') && isIdExist(ds.id, status.toStatusId)  )
      );
      const date = new Date(status.createdAt);
      const formattedDate = quarter ? getQuarterFromDate(date) : getWeekFromDate(date);
      const closestDate = getClosestDataPoint(formattedDate, dataArr.map(d => d.name));

      return {
        id: deliveryStatusEntry.id,
        date: status.createdAt,
        formattedDate: closestDate,
        color: deliveryStatusEntry?.color,
        label: deliveryStatusEntry.label,
      };
    });
  const currentDate = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  const eighteenMonthsAgo = new Date();
  eighteenMonthsAgo.setMonth(currentDate.getMonth() - 18);

  const filteredReferenceData = referenceData.map((ref: any) => {
    const refDate = new Date(ref.date);

    if (!quarter && refDate < threeMonthsAgo) {
      return null;
    }

    if (quarter && refDate < eighteenMonthsAgo) {
      return { ...ref, formattedDate: "Older" };
    }

    return ref;
  }).filter(Boolean);

  const barWidth = 19;
  const yAxisHeight = 250; 

  const handleCheckboxChange = (selectedArray: string[]) => {
    const currentFilterConfig = findFilterByName((quarter ? "quarter" : "week"), 'evidence', ideasFiltersConfig)

    setActiveFilters &&
      setActiveFilters((prevFilters) => {
        const index = prevFilters.findIndex(
          (filter) => filter.parent === currentFilterConfig?.parent
        );
        if (index !== -1) {
          // Update the existing filter
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = {
            ...currentFilterConfig,
            ...updatedFilters[index],
            selected: selectedArray,
          };
          return updatedFilters;
        } else {
          // Add a new filter if it does not exist (optional based on your logic)
          return [
            ...prevFilters,
            {
              ...currentFilterConfig,
              selected: selectedArray,
            },
          ];
        }
      });
  };

  // Handle bar click event
  const handleBarClick = (data: any) => {
    const quarter = data?.activeLabel.toLowerCase();

    setSelectedValues((prevSelectedValues) => {
      let updatedQuarters;

      if (prevSelectedValues.includes(quarter)) {
        // If the quarter is already selected, remove it
        updatedQuarters = prevSelectedValues.filter((q) => q !== quarter);
      } else {
        // If the quarter is not selected, add it
        updatedQuarters = [...prevSelectedValues, quarter];
      }

      // Call handleCheckboxChange with the updated quarters
      handleCheckboxChange(updatedQuarters);

      // Return the updated state
      return updatedQuarters;
    });
  };

 
  const CustomBar = (props: any) => {
    const { x, y, width, height, payload } = props;
 
    const value = payload?.name?.toLowerCase()
    const isSelected = selectedValues?.includes(value)
    let color = "#5C5CEB"

    if ( selectedValues.length > 0){
      color= isSelected ? "#5C5CEB" : '#D8D8DB'
    }
 
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        style={{ cursor: "pointer", borderRadius: "10px 10px 0 0" }}
      />
    );
  };

  const CustomBar2 = (props: any) => {

    const { x, y, width, height, payload } = props;

    const value = payload?.name?.toLowerCase()
    const isSelected = selectedValues?.includes(value)

    let color = "rgba(92, 92, 235, 0.6)"

    if ( selectedValues.length > 0){
      color= isSelected ? "rgba(92, 92, 235, 0.6)" : 'rgba(216, 216, 219, 0.4)'
    }

   

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        style={{ cursor: "pointer", borderRadius: "10px 10px 0 0" }}
      />
    );
  };

  const getYAxisDomain = () => {
    const maxValue = Math.max(
      ...dataArr
        .map((item) => (item?.AssignedValue || 0) + (item?.SuggestedValue || 0))
    );
    
    if (maxValue > 30) {
      return [0, 120];  // Capped at 120 for evidence greater than 120
    } else {
      return [0, 30];  // For evidence up to 30
    }
  };

  const getYAxisTicks = () => {
    const domain = getYAxisDomain();
    if (domain[1] <= 30) {
      return [5, 10, 15, 20, 25, 30];
    } else if (domain[1] >= 30 && domain[1] <= 120) {
      return [20, 40, 60, 80, 100, 120];
    }
    return [5, 10, 15, 20, 25, 30];
  };


  return (
    <Stack
      mt={19}
      w="100%"
      justify="center"
      align="center"
      sx={{ cursor: "pointer", position: "relative" }}
    >
      {/* Chart Container */}
      <div style={{ width: "100%", height: yAxisHeight, cursor: "pointer" }}>
        <ResponsiveContainer
          width="100%"
          height={yAxisHeight}
        >
          <BarChart
            data={dataArr}
            margin={{
              top: 20,
              right: 50,
              left: 20,
              bottom: 20,
            }}
            onClick={(data) => handleBarClick(data)}
            style={{ cursor: "pointer" }}
          >
            {/* Horizontal grid lines only */}
            <CartesianGrid vertical={false} stroke="#ccc" />
            <XAxis
              /* scale='point' */
              dataKey="name"
              tick={{ fill: "#5C5CEB", dy: 7 }}
            />
            {/* Y-axis with controlled tick spacing */}
            <YAxis
              orientation="right"
              tickCount={7}
              domain={getYAxisDomain()}
              ticks={getYAxisTicks()}
              tick={{ fill: "rgba(92, 92, 235, 0.6)" }}
              tickFormatter={(value) => (value >= 120 && moreThanThirty ? "120+" : value)}
            />
            <Tooltip content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const { name, AssignedValue, SuggestedValue } = payload[0].payload; // Use original values here
                return (
                  <Card bg="#F8F8FB" p='sm' withBorder shadow="xs">
                    <Text>{name}</Text>
                    <Flex >
                      <Text color="#5C5CEB"> Assigned :</Text>
                      <Text ml={3.3} color="#5C5CEB"> {AssignedValue}</Text>
                    </Flex>
                    <Flex justify='space-between'>
                      <Text color="rgba(92, 92, 235, 0.6)"> Suggested :</Text>
                      <Text ml={3.3} color="rgba(92, 92, 235, 0.6)"> {SuggestedValue}</Text>
                    </Flex>
                  </Card>
                );
              }
              return null;
            }} />
            
            {filteredReferenceData
              .filter((ref:any) => dataArr.map(d => d.name).includes(ref.formattedDate)) 
              .map((ref:any, index:any) => (
                <ReferenceLine
                  key={index}
                  x={ref.formattedDate}
                  stroke={ref.color}
                  label={(props) => (
                    <CustomReferenceLabel
                      {...props}  
                      value={ref.label} 
                      fill={ref.color} 
                      date={formatDate(ref.date)} 
                      onShowTooltip={showTooltip}
                      onHideTooltip={hideTooltip}/>)}
                />
              ))}
            {/* <Legend /> */}
            {/* Bars with click handler */}
            <Bar
              dataKey="Assigned"
              stackId="a"
              fill="#5C5CEB"  
              radius={[2.5, 2.5, 0, 0]}
              barSize={barWidth}
              shape={<CustomBar />}
              /* background={<CustomBackground />}  */
            />
            <Bar
              dataKey="Suggested"
              stackId="a"
              fill="rgba(92, 92, 235, 0.6)"  
              shape={<CustomBar2 />}
              radius={[2.5, 2.5, 0, 0]}
              barSize={barWidth}
            >
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      {tooltip.visible && (
        <Box style={{
          position: 'absolute',
          left: tooltip.x - 50,
          top: 25,
          backgroundColor: 'black',
          color: 'white',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '12px',
          zIndex: 9999
        }}>
          <Box>{tooltip.value}</Box>
          <Box>{tooltip.date}</Box>
        </Box>
      )}
    </Stack>
  );
};

export default Trend;
