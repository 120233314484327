import { Stack, Text } from "@mantine/core"
import { ZeroState } from "../../../../../icons/x-symbol-svgrepo-com"

const ZeroStateDashboard = ( { surging } : { surging: boolean } ) => {
  return (
    <Stack align="center" justify="center" mt={18}>
      
      <ZeroState/>
      <Stack /* align="left" justify="left" */>
        <Text fw={400} color='#585c68'>
          {`No ideas have seen a significant ${surging ? 'rise' : 'fall'}`}
        </Text>
        <Text fw={400} color='#585c68'>
      in new evidence recently
        </Text>
      </Stack>
      
    </Stack>
  )
}

export default ZeroStateDashboard