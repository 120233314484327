import { FC } from "react";
import { Box, Flex, Group, Stack, Text, Tooltip } from "@mantine/core";
import {
  PlungingIcon,
  SurgingIcon,
} from "../../../../../../icons/x-symbol-svgrepo-com";

interface IdeaStatsProps {
  count: number;
  perc: number;
  label: string;
  surging: boolean;
  isCapped: boolean;
}

export const IdeaStats: FC<IdeaStatsProps> = ({
  count,
  perc,
  label,
  surging,
  isCapped,
}) => {

  const roundedCount= Math.round(count)

  return (
    <Flex h={40} align="center" justify="center" /*  w={166} */>
      <Stack h={40} w={40} mr={12} bg="#F8F9FA" align="center" justify="center">
        {surging ? <SurgingIcon /> : <PlungingIcon />}
      </Stack>
      <Stack h={40} align="center" justify="center">
        <Flex justify="space-between" h={17} align="center">
          <Tooltip
            multiline
            width={200}
            label={
              surging
                ? `The number of evidences increased by ${Math.abs(
                  roundedCount
                )} from the monthly average`
                : `The number of evidences decreased by ${Math.abs(
                  roundedCount
                )} from the monthly average`
            }
          >
            <Text size={15} weight={600} color="#212529" mr={3} w={42}>
              {roundedCount > 0 ? `+${Math.round(roundedCount)}` : Math.round(roundedCount)}
            </Text>
          </Tooltip>

          <Box px={1} bg="rgba(11, 172, 77, 0.06)" sx={{ borderRadius: 4 }}>
            <Flex align="center">
              {isCapped && (
                <Text size={10} weight={600} color="#212529" mr={2}>
                  {surging ? ">" : "<"}
                </Text>
              )}
              <Text size="xs" weight={600} color="#212529">
                {Math.round(perc)}
              </Text>
              <Text size={10} weight={600} color="#212529">
                %
              </Text>
            </Flex>
          </Box>
        </Flex>
        {/*  <Box h={9}>
          <Text size="xs" color="#585C68" mt={8}>
            {label}
          </Text>
        </Box> */}
      </Stack>
    </Flex>
  );
};
