
import { Group } from "@mantine/core";
import DomainSelect from "./DomainSelect";
import ProductAreaSelect from "./ProductAreaSelect";
import OwnerSelect from "./OwnerSelect";
import StatusSelect from "./StatusSelect";
import { TimeFrame } from "./TimeFrame";

const Panel = () => {

  return (
    <Group align='center' spacing={12}>
      <TimeFrame/>
      <DomainSelect />
      <ProductAreaSelect />
      <StatusSelect/>
      <OwnerSelect />
    </Group>
  )
}

export default Panel