import axios from "axios";
import {
  RequestPriority,
  IdeaPriority,
} from "./types/types";
import { MemberItem } from "./components/CreateNewModal/FeedbackMenu/MemberItem";
import { UserItem } from "./components/CreateNewModal/FeedbackMenu/UserItem";
import { AccountItem } from "./components/CreateNewModal/FeedbackMenu/AccountItem";
import { EvidenceItem } from "./components/CreateNewModal/FeedbackMenu/EvidenceItem";
import {IntercomProps} from "react-use-intercom";
import {useCallback, useEffect, useRef} from "react";
import {throttle} from "lodash";


const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const getDateString = (date: Date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export const capitalizeFirstLetters = (str: string) => {
  //from https://flexiple.com/javascript-capitalize-first-letter/
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

export const completeOAuth = (
  authToken: string,
  integrationType: string,
  code: string,
  subdomain?: string
) => {
  return new Promise<any>((resolve, reject) => {

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    // if ((integrationType === 'salesforce' || integrationType === 'zendesk') && !subdomain) {
    //   reject("no subdomain provided");
    //   return
    // }

    if (integrationType === "jira") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/jira",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    } else if (integrationType === "notion") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/notion",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    } else if (integrationType === "slack") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/slack",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    } else if (integrationType === "gong") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/gong",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    } else if (integrationType === "zendesk") {
      if (!subdomain) {
        reject("no subdomain provided");
      } else {
        axios
          .post(
            window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
              "/authorize/zendesk",
            { code, subdomain },
            config
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      }
    } else if (integrationType === "salesforce") {
      if (!subdomain) {
        reject("no subdomain provided");
      } else {
        axios
          .post(
            window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
              "/authorize/salesforce",
            { code, subdomain },
            config
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      }
    } else if (integrationType === "clickup") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/clickup",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    }  else if (integrationType === "jira") {
      axios
        .post(
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/authorize/jira",
          { code },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    } else if (integrationType === "productboard") {
      axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/authorize/oauth2`,
        { integrationType, code, subdomain },
        config
      ).then((response) => {
        resolve(response);
      }).catch((error) => reject(error));
    } else if (integrationType === "intercom") {
      axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/authorize/oauth2`,
        { integrationType, code },
        config
      ).then((response) => {
        resolve(response);
      }).catch((error) => reject(error));
    }else {
      console.log("intergation type not supported " + integrationType);
    }
  });
};

export const stripHtml = (html: string) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;

  let text = tmp.textContent || tmp.innerText || "";

  // Replace any literal "\n" (backslash + n) with an actual newline
  text = text.replace(/\\n/g, "\n");

  // handle "\r\n":
  text = text.replace(/\\r\\n/g, "\n");

  return text;
};

export const getDisplayRequestPriority = (priority: string) => {
  return IdeaPriority[priority] ? IdeaPriority[priority] : "Unknown";
};

export const getDisplayFeedbackPriority = (status: string) => {
  return RequestPriority[status] ? RequestPriority[status] : "Unknown";
};

export const getConnectedUserRole = (auth0: any) => {
  return auth0.user ? auth0.user["bagel/role"] : "viewer";
};

export const getConnectedUserEmail = (auth0: any) => {
  return auth0.user ? auth0.user.email : undefined;
};

export const getBagelId = (auth0User: any) => {
  return auth0User["bagel/bagelId"];
};

export const getTeamIds = (auth0User: any) => {
  return auth0User["bagel/teamsIds"];
};

export const isBagelAdmin = (auth0User: any) => {
  return auth0User?.email?.indexOf("@getbagel.com") !== -1;
};

export const getIntercomInitPropsForUser = (user?: any) => {
  const intercomInitProps: IntercomProps = {};
  if (user) {
    intercomInitProps.email = user.email;
    intercomInitProps.name = user["bagel/name"];
    intercomInitProps.customAttributes = { bagelId: user["bagel/bagelId"] };
  }
  return intercomInitProps;
};

const requestIcons: any = {
  conversation: "/evidence/Case.png",
  opportunity: "/evidence/Opportunity.png",
  call: "/evidence/Call.png",
  company: "/evidence/Account.png",
  account: "/evidence/Account.png",
  user: "/evidence/User.png",
  member: "/evidence/Member.png",
};

export const getRequestIconByType = (type: string) => {
  return requestIcons[type] ? requestIcons[type] : undefined;
};


export const itemPropsByType: any = {
  member: MemberItem,
  user: UserItem,
  account: AccountItem,
  opportunity: EvidenceItem,
  call: EvidenceItem,
  conversation: EvidenceItem,
};

export function getQuarter(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth();
  const quarter = Math.ceil((month + 1) / 3);
  let quarterWord = "";

  switch (quarter) {
  case 1:
    quarterWord = `Q1 ${year}`;
    break;
  case 2:
    quarterWord = `Q2 ${year}`;
    break;
  case 3:
    quarterWord = `Q3 ${year}`;
    break;
  case 4:
    quarterWord = `Q4 ${year}`;
    break;
  default:
    quarterWord = "Invalid quarter";
  }
  return quarterWord;
}


export const logout = async (auth0: any) => {
  localStorage.clear()
  return auth0.logout({logoutParams: { returnTo: window.location.origin  }})
};

export function useThrottle(cb: any, delay: any) {
  const options = { leading: false, trailing: true }; // add custom lodash options
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => { cbRef.current = cb; });
  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}

export function updateAccessorByState(
  accessor: string,
  wantedState: string
): string {
  const aggregationKeyword = "aggregations";
  const states = ["assigned", "suggested", "total"];

  // If the accessor does not contain the aggregation keyword, return it unchanged.
  if (!accessor?.includes(aggregationKeyword)) return accessor;

  // Split the accessor into its path segments.
  const segments = accessor.split(".");

  // Check if any of the segments is one of the states and replace it with the wanted state.
  for (let i = 0; i < segments.length; i++) {
    if (states.includes(segments[i])) {
      segments[i] = wantedState;
      break;
    }
  }

  // Reconstruct the accessor string from the segments.
  return segments.join(".");
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Formatting options for different parts
  const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date); // Monday
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date); // January
  const day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date); // 08
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date); // 2024

  // Format time parts with 12-hour clock
  const hour = new Intl.DateTimeFormat('en-US', { hour: '2-digit', hour12: true }).format(date); // 06
  const minute = new Intl.DateTimeFormat('en-US', { minute: '2-digit' }).format(date); // 08
  const second = new Intl.DateTimeFormat('en-US', { second: '2-digit' }).format(date); // 18

  // Extract AM/PM from the formatted hour
  const ampm = hour.includes('AM') ? 'AM' : 'PM';

  // Correct the hour formatting for 12-hour clock (without AM/PM part)
  const formattedHour = hour.replace(/(AM|PM)/, '').trim(); // Remove AM/PM from the hour

  // Combine into the desired format
  return `${weekday}, ${month} ${day}, ${year} at ${formattedHour}:${minute}:${second} ${ampm}`;
};

