import { SimpleGrid } from "@mantine/core";
import { useMemo } from "react";
import DashboardImpact from "./DashboardImpact";
import { calculateRanks } from "./impact-helpers/calculateRanks";


interface ImpactListDasboardProps {
  impactAggregationProperties: any[];
  idea: any;
}

const ImpactListDasboard = ({
  impactAggregationProperties,
  idea,
}: ImpactListDasboardProps) => {

  const ranksResults = idea ? calculateRanks(impactAggregationProperties, idea) : {}
 
  const sortedImpactAggregationProperties = useMemo(() => {
    return [...impactAggregationProperties].sort(
      (a: any, b: any) => (a.impact?.index || 0) - (b.impact?.index || 0)
    );
  }, [impactAggregationProperties]);

  
  return (
    <SimpleGrid
      cols={3} // Up to 3 Guage
      spacing="lg"
      style={{
        width: "100%",
        // padding: "16px",
        alignItems: "stretch",
      }}
    >
      {sortedImpactAggregationProperties.map((aggregationProperty) => (
        <DashboardImpact
          key={aggregationProperty.propertyName}
          aggregationProperty={aggregationProperty}
          impactData={ranksResults} 
          idea={idea}
          ideaRanks={idea?.ranks}
        />
      ))}
    </SimpleGrid>
  );
};

export default ImpactListDasboard;