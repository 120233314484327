import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar, Text,
  Flex,
  Group,
  Header, Menu,
  createStyles, Grid, Divider, Box,
  Button,
  ActionIcon,
  Indicator,
  UnstyledButton,
  Tooltip,
} from "@mantine/core";
import { IconLogout, IconSettings, IconX} from "@tabler/icons";
import {Link, useLocation} from "react-router-dom";
import { getInitials } from "../../../utils/getInitials";
import LogoIcon from "../LogoIcon";
import React, { useContext }  from "react";
import InviteMembersDropdown from "../InviteMembersDropdown";
import NotificationsDropdown from "../../notifications/NotificationsDropdown";
import { useEffect, useState } from "react";
import { listNotifications } from "../../../Api";
import {logout} from "../../../utils";
import { usePostHog } from "posthog-js/react";
import Search from "./Search";
import { useHover, useLocalStorage } from "@mantine/hooks";
import HeaderSearch from "./HeaderSearch";
import { MegaPhone } from "../../../icons/x-symbol-svgrepo-com";
import SyncContext from "../../../context/SyncContext";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: Number(theme.spacing.md) * 1.5, // Explicitly convert to number
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: Number(theme.spacing.md), // Explicitly convert to number
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#161832",
    padding: `${Number(theme.spacing.xs)}px 0px`, // Convert to number if needed
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      color: "#5B60E3",
      "& $linkIcon": {
        color: "#5B60E3",
      },
    },
  },

  linkIcon: {
    color: "#161832",
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      color: "#5B60E3",
      "& $linkIcon": {
        color: "#5B60E3",
      },
    },
  },
}));

export const MainHeader = () => {
  
  const auth0 = useAuth0();
  const posthog = usePostHog()

  const [notifications, setNotifications] = useState<any[]>([])
  const { showWhatsNewBanner } = useContext(SyncContext)
  const [isBannerOpened, setIsBannerOpened] = useLocalStorage<boolean | null>({
    key: "isWhatsNewOpened",
    defaultValue: showWhatsNewBanner ? true : false, 
  });
  const [isBannerRead, setIsBannerRead] = useLocalStorage<boolean | null>({
    key: "isWhatsNewRead",
    defaultValue: showWhatsNewBanner ? false : true, 
  });
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const handleCheckOut = (closeTooltip = false) => {
    window.open("https://help.getbagel.com/en/articles/10429772-2025-releases", "_blank");
    setIsBannerRead(true);
    setIsBannerOpened(false);
    if (closeTooltip) {
      setTooltipOpened(false);
    }
  }

  const getListNotifications = () =>{
    listNotifications(auth0)
      .then((noti: any) => {
        setNotifications(noti);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  useEffect(() => {
    const resources = performance.getEntriesByType("resource");
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const isSoftRefresh = resources.some((res: any) => res.transferSize === 0 && res.responseStart > 0);
    const isHardRefresh = navigation?.type === "reload" && !isSoftRefresh;

    fetch(window.location.href, { cache: "no-store" })
      .then(response => {
        if (!response.ok) throw new Error("Network error");
        if (isHardRefresh || !isSoftRefresh) {
          setIsBannerOpened(true);
          setIsBannerRead(false);
        }
      });
    getListNotifications();
  }, []);
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const {hovered, ref} = useHover();
  const [query, setQuery] = useState<string>("");
  const whatNewPosition = (hovered && isBannerOpened) || (query && isBannerOpened)

  /* console.log('hovered')
  console.log(hovered) */

  const user = auth0.user;
  const name = user ? user.name : '';
  const role = user ? user['bagel/role'] : '';
  const canInvite = role === "admin"
  const avatar = user?.picture ? (<Avatar src={user.picture} radius="xl" />) : (<Avatar color="cyan" radius="xl">
    {name && getInitials(name)}
  </Avatar>)
  return (
    <Header height={63}>
      <Flex
        h={63}
        px="xs"
        bg={"#5B60E3"}
        gap="md"
        wrap="wrap"
      >
        <Group>
          <Link to={"/"}>
            <LogoIcon height={32} style={{ marginTop: "4px" }} />
          </Link>
          
        </Group>
        <Flex 
          align="center"
          direction="row"
          wrap="nowrap"
          gap="md"
          w={"calc(100% - 50px)"}
          sx={(theme) => ({
            position: "relative",
            justifyContent: whatNewPosition ? "space-between" : "flex-end",
            ...(isBannerOpened && {
              [`@media (max-width: 1150px)`]: {
                justifyContent: "space-between",
              },
            }),
          })}
        >
          {
            isBannerOpened &&
            <Group sx={(theme) => ({
              backgroundColor: "#9CEAC2", 
              borderRadius: "26px", 
              padding: "4px 16px",
              position: whatNewPosition ? "static" : "absolute",   
              left: whatNewPosition  ? "auto" : "50%",             
              transform: whatNewPosition  ? "none" : "translateX(-50%)",
              marginLeft:  whatNewPosition  ? "10px" : "0px",
              transition: "left 0.4s ease",
              overflowX: "auto", 
              [`@media (max-width: 1150px)`]: {
                position: "static",   
                left: "auto",             
                transform: "none",
                marginLeft: "10px",
              }
            })}>
              <Group 
                sx={(theme) => ({ 
                  borderRadius: "26px", 
                  display: "flex",
                  flexWrap: "nowrap",  
                  whiteSpace: "nowrap", 
                  overflowX: "auto",  
                  scrollbarWidth: "thin", 
                  scrollbarColor: "#666 #9CEAC2",
                })}
              >
                <MegaPhone />
                <Flex gap={"5px"} mr={"1.5rem"}>
                  <Text fz={"14px"} fw={700}>What's New?</Text><Text fz={"14px"}>– Bagel's latest updates and improvements!</Text>
                </Flex>
                <Button 
                  size="xs" 
                  fz={"12px"}
                  fw={500}
                  style={{ color: "#ffffff", backgroundColor: "#000000", height: "32px", padding:"0px", width: "75px" }}
                  onClick={() => handleCheckOut()}
                >
                  Check Out
                </Button>
                <ActionIcon variant="transparent" onClick={() => setIsBannerOpened(false)}>
                  <IconX color="black" size={18} />
                </ActionIcon>  
              </Group>
              
            </Group>
          }

          <Group noWrap>
            {/* <Search /> */}
            <HeaderSearch hovered={hovered} ref={ref} ideaQuery={query} setQuery={setQuery}/>
            <Tooltip 
              label={"What's New"}
              opened={tooltipOpened} 
              onMouseEnter={() => setTooltipOpened(true)} 
              onMouseLeave={() => setTooltipOpened(false)}>
              <UnstyledButton onClick={() => {
                handleCheckOut(true);
              }}>
                {isBannerRead ? 
                  <MegaPhone fill="#363699" /> :
                  <Indicator
                    size={10}
                    color="#FF9499"
                    inline
                  >
                    <MegaPhone fill="#363699"/>
                  </Indicator>
                }
              </UnstyledButton>
            </Tooltip>
            <NotificationsDropdown notifications={notifications} getListNotifications={getListNotifications} />
            { canInvite && <InviteMembersDropdown /> }
            <Menu width={300} shadow="md" position={"bottom-end"} withArrow>
              <Menu.Target>{avatar}</Menu.Target>
              <Menu.Dropdown p={0}>
                <Grid p={"md"}>
                  <Grid.Col span={1}> </Grid.Col>
                  <Grid.Col span={2}>{avatar}</Grid.Col>
                  <Grid.Col span={5} ml={"xs"}>
                    <Text sx={{ fontWeight: 500 }} size={"sm"}>
                      {name}
                    </Text>
                    <Text sx={{ textTransform: "capitalize" }} size={"sm"}>
                      {role}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Divider />
                <Menu.Item p={0}>
                  <Box p={"md"}>
                    <Link
                      to={"/settings"}
                      className={cx(classes.link, {
                        [classes.linkActive]: "/settings" === pathname,
                      })}
                    >
                      <IconSettings className={classes.linkIcon} stroke={1.5} />
                      <span>Settings</span>
                    </Link>
                  </Box>
                </Menu.Item>
                <Divider />
                <Menu.Item p={0}>
                  <Box p={"md"}>
                    <a
                      href="#"
                      className={classes.link}
                      onClick={() => {
                        posthog?.capture('logged_out')
                        logout(auth0);
                      }}
                    >
                      <IconLogout className={classes.linkIcon} stroke={1.5} />
                      <span>Logout</span>
                    </a>
                  </Box>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Flex>
      </Flex>
    </Header>
  );
};
