import {
  Grid,
  Card,
  Group,
  Button,
  Flex,
  Box,
  Tooltip,
  Text,
  Spoiler,
  createStyles,
  ActionIcon,
  Paper,
  Anchor,
  Popover,
} from "@mantine/core";
import moment from "moment";
import { getQuarter, getDisplayRequestPriority } from "../../../../utils";
import HtmlContent from "../../../HtmlContent";
import {
  IconAlertOctagon,
  IconCalendarDue,
  IconComponents,
  IconExternalLink,
  IconUser,
  IconX,
} from "@tabler/icons";
import { ValidateEvidencePayload } from "../../../../api/evidences";
import useEvidenceActions from "../../../../hooks/useEvidenceActions";
import { OverflownText } from "../../../OverflownText";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { FC, useContext, useState } from "react";
import { EvidenceModalType } from "..";
import Property from "../RightSide/Property";
import SyncContext from "../../../../context/SyncContext";
import { ProductAreaHeaderIcon, StatusHeaderIcon } from "../../../../icons/x-symbol-svgrepo-com";

const useStyles = createStyles(() => ({
  control: {
    color: "#5C5CEA",
    fontSize: "12px",
  },
}));

export interface IdeaCardType extends EvidenceModalType {
  idea: any;
  matchId?: any;
  showRolloutDate?: boolean;
}

export const IdeaCard: FC<IdeaCardType> = ({
  onAction,
  evidence,
  idea,
  matchId,
  showRolloutDate,
  setEvidence,
  evidencesHandlers,
}) => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);

  const { validate, isLoading, error } = useEvidenceActions({
    evidenceId: evidence?._id,
  });

  const { ideaPriorities } = useContext(SyncContext);

  const currentPriority = ideaPriorities?.find(
    (p: any) => p?.label?.toLowerCase() == idea?.priority?.toLowerCase()
  );

  const handleAssign = async () => {
    setOpened(false);
    const payload: ValidateEvidencePayload = {
      ideaId: idea._id,
      matchId,
      sourceOfAssignment:'request'
    };
    const { success, evidence } = await validate(payload);
    if (success) {
      setEvidence(evidence)
      onAction()
      showNotification({
        title: "Evidence Assigned",
        message: (
          <Box>
            <Text mb="sm">
              Your evidence has been successfully assigned to the idea.
            </Text>
            <Anchor color="#5b60e3" href={`/idea/${idea._id}`} target="_blank">
              <IconExternalLink size={16} color="#5b60e3" />
              Open the idea
            </Anchor>
          </Box>
        ),
        color: "green",
        autoClose: 5000,
      });
      closeAllModals();
      evidencesHandlers?.filter((evi: any) => evi?._id !== evidence?._id);
    }
  };

  return (
    <Paper withBorder>
      <Box p="sm">
        <Group position="apart" align="flex-start" noWrap>
          <OverflownText lineClamp={1} fz={16}>
            {idea.title}
          </OverflownText>{" "}
          <Group spacing={6} noWrap>
            <Tooltip withArrow label="Open idea in new tab">
              <ActionIcon
                onClick={() => window.open(`/idea/${idea?._id}`, "_blank")}
              >
                <IconExternalLink size={18} color="#5b60e3" />
              </ActionIcon>
            </Tooltip>
            {/* Assign Button */}
            <Tooltip withArrow label={"Assign to this idea"}>
              <Popover
                width={300}
                position="left"
                withArrow
                shadow="md"
                opened={opened}
                onChange={setOpened}
              >
                <Popover.Target>
                  <Button
                    size="sm"
                    compact
                    color="indigo"
                    onClick={() => setOpened((o) => !o)}
                    loading={isLoading.validate}
                  >
                    Assign
                  </Button>
                </Popover.Target>
                <Popover.Dropdown sx={{ border: "1px solid #dee2e6" }} p={0}>
                  <Box
                    px="xs"
                    py={8}
                    sx={{
                      background: "#fcfcfc",
                      borderBottom: "1px solid #dee2e6",
                      borderRadius: "4px 4px 0px 0px",
                    }}
                  >
                    <Text size="sm" align="center" fw={600}>
                      Confirm assignment
                    </Text>
                  </Box>
                  <Box p="xs">
                    <Text size="sm">
                      The evidence will be assigned to this idea:
                      <Tooltip withArrow label="Open idea in new tab">
                        <Text
                          href={`/idea/${idea._id}`}
                          target="_blank"
                          color="indigo"
                          weight={600}
                          component="a"
                          span
                        >
                          <IconExternalLink
                            style={{ marginBottom: -3, marginLeft: 5 }}
                            size={16}
                            color="#5b60e3"
                          />
                          {idea.title}
                        </Text>
                      </Tooltip>
                    </Text>
                  </Box>
                  <Group
                    p="xs"
                    sx={{
                      borderTop: "1px solid #dee2e6",
                    }}
                    noWrap
                  >
                    <Button
                      compact
                      color="indigo"
                      fullWidth
                      onClick={handleAssign}
                    >
                      Confirm
                    </Button>
                    <Button
                      compact
                      variant="subtle"
                      color="gray"
                      onClick={() => setOpened(false)}
                    >
                      Cancel
                    </Button>
                  </Group>
                </Popover.Dropdown>
              </Popover>
            </Tooltip>
          </Group>
        </Group>
        <Spoiler
          maxHeight={24}
          showLabel="See more"
          hideLabel="See less"
          classNames={classes}
        >
          <Text
            size="xs"
            mb="sm"
            color="#3B4158"
            sx={{ p: { margin: "5px 0px" }, h2: { marginTop: 0 } }}
          >
            <HtmlContent content={idea?.description} />
          </Text>
        </Spoiler>
      </Box>
      <Box p="sm">
        <Group position="left" spacing={5}>
          {/* Owner */}
          <Property
            label="Owner"
            text={idea.owner ? idea.owner.name : "Unassigned"}
            Icon={IconUser}
            withBorder
          />
          {/* Status */}
          <Property
            label="Status"
            text={idea.status || "Unassigned"}
            withBorder
            Icon={StatusHeaderIcon}
          />
          {/* Expected Delivery Date */}
          {idea.rolloutDate && <Property
            label="Exp. delivery date"
            text={
              showRolloutDate
                ? idea.rolloutDate
                  ? moment(new Date(idea.rolloutDate)).format("D MMM YY")
                  : "Unknown"
                : idea.rolloutDate
                  ? getQuarter(new Date(idea.rolloutDate))
                  : "Unknown"
            }
            Icon={IconCalendarDue}
            withBorder
          />}
          {/* Priority */}
          <Property
            label="Priority"
            text={idea.priority  || "Unassigned"}
            Icon={() => <IconAlertOctagon size={14} color={currentPriority?.color} />}
            withBorder
          />
          {/* Product Area */}
          {idea.componentObj && (
            <Property
              label="Product area"
              text={idea.componentObj?.name || "Unassigned"}
              Icon={ProductAreaHeaderIcon}
              withBorder
            />
          )}

          {/* Companies */}
          {idea.aggregations?.accountsCount?.total && (
            <Property
              label="Companies"
              text={idea.aggregations.accountsCount.total}
              Icon={IconComponents}
              withBorder
            />
          )}
        </Group>
      </Box>
    </Paper>
  );
};
