import { useContext } from "react";
import { Box, Flex, Text, Tooltip } from "@mantine/core";
import BagelMultiSelect, { Option } from "../../../../general/BagelMultiSelect";
import DashboardContext from "../../../DashboardContext";
import { OwnerHeaderIcon } from "../../../../../icons/x-symbol-svgrepo-com";

const OwnerSelect = () => {
  const { owners, trendsActiveFilters, setTrendsActiveFilters } =
    useContext(DashboardContext);

  const optionsToRender: Option[] = owners.map((member: any) => ({
    value: member._id,
    label: member.name,
    childComponents: [],
  }));

  const currentOwnerSelection =
    (trendsActiveFilters.find((f: any) => f.key === "ownerId")
      ?.value as string[]) || [];

  const selectedLabel =
    currentOwnerSelection.length === 1
      ? optionsToRender.find(
        (option) => option.value.toLowerCase() === currentOwnerSelection[0]
      )?.label || ""
      : currentOwnerSelection.length > 1
        ? `${currentOwnerSelection.length} selected`
        : "All";

  const handleSelectionChange = (selected: string[]) => {
    setTrendsActiveFilters(
      (prev: { key: string; value: string | string[] }[]) => {
        const otherFilters = prev.filter((f) => f.key !== "ownerId");
        return [...otherFilters, { key: "ownerId", value: selected }];
      }
    );
  };

  return (
    <Flex
      align="center"
      h={32}
      sx={{
        borderRadius: "4px",
        border: "1px solid #F8e9FB",
        cursor: "pointer",
      }}
    >
      <BagelMultiSelect
        parent='ownerId'
        options={optionsToRender}
        value={currentOwnerSelection}
        onChange={handleSelectionChange}
        searchable
      >
        <Tooltip
          label={
            <Box p={0.186}>
              <Text fz={11.4} mb={1.4}>
                Owner
              </Text>
            </Box>
          }
        >
          <Flex
            h={24}
            px={8}
            py={4}
            mx="2px"
            justify="space-evenly"
            align="center"
          >
            <Flex ml="4px">
              <OwnerHeaderIcon />
              <Text fz="12px" color="#585C68" ml="6px" sx={{ lineHeight: "16px" }}>
                              Owner:
              </Text>
            </Flex>
            <Text fz="12px" lineClamp={1} maw="150px" miw="100px">
              {selectedLabel}
            </Text>
          </Flex>
        </Tooltip>
      </BagelMultiSelect>
    </Flex>
  );
};

export default OwnerSelect;
