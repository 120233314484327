import {
  Box,
  Flex,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";

import { useState } from "react";
import { SourceIcon } from "../../../../../utils/SourceIcon";
import { stripHtml } from "../../../../../utils";
import { OverflownText } from "../../../../OverflownText";

const ProductBoardTicket = ({ item }: { item: any }) => {
  const [opened, setOpened] = useState(false);
  const cxItemTitle = item?.cxItem?.title || item?.title;
  const cxItemText = item?.cxItem?.text || item?.text;
  const excerpt = item?.excerpt;
  const cxItem = item?.cxItem;
  const origin = cxItem?.trueOrigin || cxItem?.origin || item?.origin;
  const title = item?.title;
  const text = item?.text;

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          centered
          onClose={() => setOpened(false)}
          size="600"
          title={
            <Group noWrap>
              {origin && (
                <Tooltip label={origin || "N/A"}>
                  <Stack h={20} w={20} mr={4} justify="center" align="center">
                    <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
                  </Stack>
                </Tooltip>
              )}
              <OverflownText
                ml="3px"
                fz={17}
                maw={470}
                w={470}
                fw={600}
                lineClamp={1}
              >
                {cxItemTitle || "N/A"}
              </OverflownText>
            </Group>
          }
        >
          <Box h={600} px="md" w={500}>
            {cxItemText && (
              <Box py={8} px={12}>
                <Text
                  fz={14}
                  sx={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {stripHtml(cxItemText)}
                </Text>
              </Box>
            )}
          </Box>
        </Modal>
      )}
      <Box mt={15} maw="100%">
        {title && (
          <Text fz={14} fw={600} lineClamp={1} mb={14}>
            {title}
          </Text>
        )}
        <ScrollArea
          mt={15} /* h={"calc(100vh - 700px)"} mah="calc(100vh - 700px)" */
        >
          {text && (
            <Box
              py={8}
              px={12}
              bg="#F8F9FA"
              sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
            >
              <Text
                fz={12}
                fw={600}
                color="#585C68"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {stripHtml(text)}
              </Text>
            </Box>
          )}
          {excerpt && excerpt !== "" && (
            <Box
              py={8}
              px={12}
              bg="#F8F9FA"
              sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
            >
              <Box fz={12}>
                <Text
                  mb="5px"
                  fw={600}
                  style={{ color: "black", fontSize: "12.5px" }}
                >
                  Excerpt:{" "}
                </Text>
                <Text
                  sx={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {stripHtml(excerpt)}
                </Text>
              </Box>
            </Box>
          )}
        </ScrollArea>
        {
          <Box
            w="100%"
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Text
              mt={10}
              fz={13}
              color="#5C5CEB"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setOpened(true)}
            >
              View full note
            </Text>
          </Box>
        }
      </Box>
    </>
  );
};

export default ProductBoardTicket;
