import { useContext } from "react";
import DashboardContext from "../../DashboardContext";
import { Box, ScrollArea, Tabs } from "@mantine/core";
import { HeaderTab } from "./HeaderTab";
import SurgingIdeasList from "./ideas-list/SurgingIdeasList";
import PlungingIdeasList from "./ideas-list/PlungingIdeasList";
import ZeroStateDashboard from "./ideas-list/ZeroStateDashboard";

const DashboardTabs = () => {
  const { activeTab, setActiveTab, pluningIdeas, surgingIdeas, chooseIdea, filteredPluningIdeas, filteredSurgingIdeas } =
    useContext(DashboardContext);

  return (
    <Box
      p={16}
      sx={{
        overflowX: "hidden", // Prevent horizontal scrolling
     
      }}
    >
      <Tabs
        defaultValue="surging"
        value={activeTab}
        onTabChange={setActiveTab}
      >
        <Tabs.List
          h={106}
          w="100%"
          sx={{
            borderBottom: "none",
            margin: 0,
            position: "sticky",
            top: 0,
            background:
              "linear-gradient(180deg, #EFEFFD 0%, rgba(255,255,255,0.00) 100%)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(248,248,251,1)",
              display: "flex",
              margin: 0,
              overflowX: "hidden", // Prevent horizontal scrolling
            }}
            h={106}
          >
            <Tabs.Tab
              value="surging"
              onClick={() => chooseIdea(filteredSurgingIdeas[0]?._id || {})}
              sx={{
                "&[data-active]": { borderBottom: "none" },
                "&:hover": { borderBottom: "none" },
                padding: 0,
                margin: 0,
              }}
            >
              <HeaderTab
                title="Surging ideas"
                count={filteredSurgingIdeas?.length || 0}
                surging
                active={activeTab === "surging"}
              />
            </Tabs.Tab>

            <Tabs.Tab
              value="plunging"
              onClick={() => chooseIdea(filteredPluningIdeas[0]?._id || {})}
              sx={{
                "&[data-active]": { borderBottom: "none" },
                "&:hover": { borderBottom: "none" },
                padding: 0,
                margin: 0,
              }}
            >
              <HeaderTab
                title="Plunging ideas"
                count={filteredPluningIdeas?.length || 0}
                surging={false}
                active={activeTab === "plunging"}
              />
            </Tabs.Tab>
          </Box>
        </Tabs.List>

        <Tabs.Panel value="surging" pt="xs">
          <ScrollArea
            h={"calc(100vh - 327px)"}
            mah={"calc(100vh - 327px)"}
            type="never"
            sx={{
              overflowX: "hidden", // Prevent horizontal scrolling
            }}
          >
            {surgingIdeas?.length > 0 ? (
              <SurgingIdeasList />
            ) : (
              <ZeroStateDashboard surging />
            )}
          </ScrollArea>
        </Tabs.Panel>

        <Tabs.Panel value="plunging" pt="xs">
          <ScrollArea
            h={"calc(100vh - 327px)"}
            mah={"calc(100vh - 327px)"}
            type="never"
            sx={{
              overflowX: "hidden", // Prevent horizontal scrolling
            }}
          >
            {pluningIdeas?.length > 0 ? (
              <PlungingIdeasList />
            ) : (
              <ZeroStateDashboard surging={false} />
            )}
          </ScrollArea>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default DashboardTabs;
