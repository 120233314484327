import { Tooltip } from "@mantine/core";
import { SourceIcon } from "../../utils/SourceIcon";

interface MissingSourcesProps {
  missingSources: string[];
}

const MissingSources: React.FC<MissingSourcesProps> = ({ missingSources }) => {
  if (missingSources.length === 0) return null;

  return (
    <div style={styles.missingCards}>
      {missingSources.map((source, index) => (
        <div key={index} style={styles.missingCard}>
          <Tooltip label={source} position="top" withArrow>
            <div style={styles.missingIconWrapper}>
              <SourceIcon sourceName={source} width={30} height={30} />
            </div>
          </Tooltip>
          <span style={styles.missingStatus}>Not Connected Yet</span>
        </div>
      ))}
    </div>
  );
};

const styles = {
  divider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
    position: 'relative' as const,
  },
  missingCards: {
    display: 'flex',
    justifyContent: 'center' as const,
    gap: '16px',
    flexWrap: 'wrap' as const,
  },
  missingCard: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const,
    padding: '16px',
    border: '1px solid #5C5CEB66',
    borderRadius: '8px',
    backgroundColor: '#F8F8FB',
    minWidth: '170px',
    minHeight: '330px',
    textAlign: 'center' as const,
  },
  missingIconWrapper: {
    marginBottom: '8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    borderRadius: '4px',
  },
  missingCardText: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#555',
    marginBottom: '4px',
  },
  missingStatus: {
    fontSize: '15px',
    color: '#5C5CEB99',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    width: '100%',
    marginBottom: '20px',
  },
  dividerLine: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#D8D8DB',
  },
  dividerText: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#585C68',
    backgroundColor: '#F8F9FB',
    padding: '0 5px',
  },
};

export default MissingSources;
