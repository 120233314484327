import { FC, useContext } from 'react';
import { Box, Flex, HoverCard, Stack } from '@mantine/core';
import DashboardContext from '../../../../DashboardContext';
import { IdeaStats } from './IdeaStas';
import Atrributes from './Atrributes';
import Texts from './Texts';
import CardGraph from './CardGraph';
import { TimeFrame } from '../../../Header/Panel/TimeFrame';

interface IdeaCardProps {
  ideaItem: any;
  title: string;
  surging: boolean;
}

export const IdeaCard: FC<IdeaCardProps> = ({ 
  ideaItem,
  title, 
  surging
}) => {

  const { chooseIdea, chosenIdea } = useContext(DashboardContext);
  const highlighted: boolean = chosenIdea?._id === ideaItem?._id;
  const description = ideaItem?.description;
  const trendsDataWithOlder = (ideaItem?.trends?.weeks || []).slice(0, -1);
  const trendsData = trendsDataWithOlder.filter((trend: any) => trend.monday !== 'Older');
  const lastFourWeeks = trendsData.slice(-4);
  const lastFourWeeksSum = lastFourWeeks.reduce((sum: any, trend: any) => sum + (trend.total || 0), 0);

  const previousEightWeeks = trendsData.slice(0, -4);
  const previousEightWeeksSum = previousEightWeeks.reduce((sum: any, trend: any) => sum + (trend.total || 0), 0);

  const halfOfPreviousEightWeeksSum = previousEightWeeksSum / 2;
  const relevantValue = lastFourWeeksSum - halfOfPreviousEightWeeksSum;

  const percentageValue = surging ? lastFourWeeksSum / halfOfPreviousEightWeeksSum : (lastFourWeeksSum / halfOfPreviousEightWeeksSum) - 1;
  const percentage = percentageValue * 100;

  const isCapped: boolean = surging ? percentage > 900 : percentage < -900;
  const cappedPercantageSurging = percentage > 900 ? 900 : percentage;
  const cappedPercantagePlunging = percentage < -900 ? -900 : percentage;


  return (
    <Flex
      w="96.6%" // Use full width of the parent container
      justify="space-between"
      align="self-end"
      sx={(theme) => ({
        border: `1px solid ${
          highlighted ? theme.colors.blue[6] : theme.colors.gray[3]
        }`,
        backgroundColor: highlighted ? "#EFEFFD" : "white",
        borderRadius: 4,
      })}
      onClick={() => chooseIdea(ideaItem?._id)}
      h={64}
      my={8}
    >
      <Flex
        w="82.6%"
        h={64}
        align="center"
        justify="space-between"
        p={12}
      >
        <Flex>
          <Flex mx={12} w={140}>
            <IdeaStats
              count={relevantValue}
              perc={surging ? cappedPercantageSurging : cappedPercantagePlunging}
              surging={surging}
              label="new evidence"
              isCapped={isCapped}
            />
          </Flex>
          <Box /* flex={1} */ ml={12}>
            <Box h={18} mb={4}>
              <Texts title={title} description={description} />
            </Box>
            <Stack h={24} align="left" justify="center">
              <Atrributes ideaItem={ideaItem} />
            </Stack>
          </Box>
        </Flex>
      </Flex>
      <Box sx={{ position: "relative" }}>
        <HoverCard>
          <HoverCard.Target>
            <Stack mr="xs" mb="xs">
              <CardGraph dataArr={trendsData} />
            </Stack>
          </HoverCard.Target>
          <HoverCard.Dropdown
            p={0}
            sx={{
              marginLeft: "-15px",
              position: "absolute",
              zIndex: 1,
            }}
          >
            <TimeFrame />
          </HoverCard.Dropdown>
        </HoverCard>
      </Box>
    </Flex>
  );
};

export default IdeaCard;