
import Search from "./Search"
import { HeaderSearchIcon } from "../../../icons/x-symbol-svgrepo-com"
import { forwardRef } from "react";
import { Box } from "@mantine/core";
type HeaderSearchProps = {
  hovered: boolean;
  ideaQuery: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
};

const HeaderSearch = forwardRef<HTMLDivElement, HeaderSearchProps>(({ hovered, ideaQuery, setQuery }, ref) => {
  const shouldShowSearchIcon = (hovered || ideaQuery !== '') 
  return (
    <Box ref={ref}>
      { shouldShowSearchIcon ? <Search query={ideaQuery} setQuery={setQuery} /> : <HeaderSearchIcon /> }
    </Box>
  );
});

export default HeaderSearch