import {
  ActionIcon,
  Avatar,
  Box,
  Flex,
  Stack,
  Tooltip,
  Text,
} from "@mantine/core";
import { getInitials } from "../../../../utils/getInitials";

// OwnerCell Component
export const OwnerCell: React.FC<any> = ({ data }) => {
  const owner = data.owner || {};
  const userName = owner.username || "";
  const ownerName = owner.name || "";
  const userPic = owner.picture || "";
  const name = ownerName || userName;
  const pic = userPic;

  return (
    <Stack /* align="center"  */ justify="center" /* ml={1.4} */>
      <Flex align="center" /* justify="left" */>
        <Stack align="center" mb={1.2}>
          <ActionIcon>
            <Avatar src={pic} radius="xl" size={26.6}>
              {getInitials(name)}
            </Avatar>
          </ActionIcon>
        </Stack>

        {name ? (
          <Box ml={6.2}>
            <Tooltip disabled={!name} label={name}>
              <Text lineClamp={1} color="#212529">
                {name}{" "}
              </Text>
            </Tooltip>
          </Box>
        ) : (
          <Text  ml={6.2} color="dimmed">Unassigned</Text>
        )}
      </Flex>
    </Stack>
  );
};
