import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from "recharts";
import { Box, Card, Flex, Stack, Text} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import SyncContext from "../../../../context/SyncContext";
import { findFilterByName } from "../../../../context/helpers";
import { formatDate } from "../../../../utils"
import DashboardContext from "../../DashboardContext";
import { getClosestDataPoint, getQuarterFromDate, getWeekFromDate, isIdExist } from "../../../ideaNiv/right-bar/trend-graph/helpers";

  type CustomReferenceLabelProps = {
    viewBox?: any; 
    value: any;
    fill: any;
    date: string;
    onShowTooltip: (data: { x: any; date: any, value: any }) => void;
    onHideTooltip: () => void;
  };
  
const CustomReferenceLabel = ({
  viewBox = {},
  value,
  fill,
  date,
  onShowTooltip,
  onHideTooltip,
}: CustomReferenceLabelProps) => {
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(null);
  
  const handleMouseEnter = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout); 
    }
    const timeout = setTimeout(() => {
      onShowTooltip({ x: viewBox.x, date, value });
    }, 100); 
    setTooltipTimeout(timeout);
  };
  
  const handleMouseLeave = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout); 
    }
    const timeout = setTimeout(() => {
      onHideTooltip();
    }, 100); 
    setTooltipTimeout(timeout);
  };
  
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
        onHideTooltip();
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout, onHideTooltip]);
  
  const x = viewBox.x;
  const y = viewBox.y;
  
  if (!x || !y || !value) return null;
  return (
    <g 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <rect
        x={x - 45 +12} 
        y={y - 18} 
        width={80}
        height={17}
        fill={fill} 
        stroke={fill}
        rx={5}
      />
      <text
        x={x + 5}
        y={y - 5} 
        fill="white"
        fontSize="12"
        fontWeight="bold"
        textAnchor="middle"  
      >
        {value.length > 13 ? value.substring(0, 10) + "..." : value}
      </text>
    </g>
  );
};
  
  
const DashboardTrend = ({
  dataArr,
  selectedValues,
  setSelectedValues,
  quarter,
  moreThanThirty,
}: {
    dataArr: any[];
    selectedValues: string[],
    setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
    quarter: boolean;
    moreThanThirty: boolean;
  }) => {
  const { setActiveFilters } = useContext(FiltersContext);
  const { ideasFiltersConfig, markedDeliveryStatus }  = useContext(SyncContext);
  const { chosenIdea } = useContext(DashboardContext);
  const statusHistory = chosenIdea?.productItem?.statusHistory || [];
  const deliveryStatus = (markedDeliveryStatus || []).filter((status:any) => status.marked === true);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, date: '', value: '' });
  
  const showTooltip = ({ x, date, value }: any) => {
    setTooltip({ visible: true, x, date, value });
  };
  
  const hideTooltip = () => {
    setTooltip({ visible: false, x: 0, date: '', value: '' });
  };
  
  const referenceData = statusHistory
    .filter((status: any) =>
      deliveryStatus.some((ds: any) => (ds.id === status.toStatusId || (ds.id.includes('-') && isIdExist(ds.id, status.toStatusId)  )) && ds.marked == true)
    )
    .map((status: any) => {
      const deliveryStatusEntry = deliveryStatus.find(
        (ds: any) => ds.id === status.toStatusId || (ds.id.includes('-') && isIdExist(ds.id, status.toStatusId)  )
      );
      const date = new Date(status.createdAt);
      const formattedDate = quarter ? getQuarterFromDate(date) : getWeekFromDate(date);
      const closestDate = getClosestDataPoint(formattedDate, dataArr.map(d => d.monday));

      return {
        id: deliveryStatusEntry.id,
        date: status.createdAt,
        formattedDate: closestDate,
        color: deliveryStatusEntry?.color,
        label: deliveryStatusEntry.label,
      };
    });
  const currentDate = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
  
  const filteredReferenceData = referenceData.map((ref: any) => {
    const refDate = new Date(ref.date);
  
    if (refDate < threeMonthsAgo) {
      return { ...ref, formattedDate: "Older" };
    }
  
    return ref;
  }).filter(Boolean);

  const barWidth = 19;
  const yAxisHeight = 180; 
  
  const handleCheckboxChange = (selectedArray: string[]) => {
    const currentFilterConfig = findFilterByName((quarter ? "quarter" : "week"), 'evidence', ideasFiltersConfig)
  
    setActiveFilters &&
        setActiveFilters((prevFilters) => {
          const index = prevFilters.findIndex(
            (filter) => filter.parent === currentFilterConfig?.parent
          );
          if (index !== -1) {
            // Update the existing filter
            const updatedFilters = [...prevFilters];
            updatedFilters[index] = {
              ...currentFilterConfig,
              ...updatedFilters[index],
              selected: selectedArray,
            };
            return updatedFilters;
          } else {
            // Add a new filter if it does not exist (optional based on your logic)
            return [
              ...prevFilters,
              {
                ...currentFilterConfig,
                selected: selectedArray,
              },
            ];
          }
        });
  };
  
  // Handle bar click event
  const handleBarClick = (data: any) => {
    const quarter = data?.activeLabel.toLowerCase();
  
    setSelectedValues((prevSelectedValues) => {
      let updatedQuarters;
  
      if (prevSelectedValues.includes(quarter)) {
        // If the quarter is already selected, remove it
        updatedQuarters = prevSelectedValues.filter((q) => q !== quarter);
      } else {
        // If the quarter is not selected, add it
        updatedQuarters = [...prevSelectedValues, quarter];
      }
  
      // Call handleCheckboxChange with the updated quarters
      handleCheckboxChange(updatedQuarters);
  
      // Return the updated state
      return updatedQuarters;
    });
  };
  
   
  const CustomBar = (props: any) => {
    const { x, y, width, height, payload, index } = props;
  
    const value = payload?.name?.toLowerCase();
    const isSelected = selectedValues?.includes(value);
    let color = "#5C5CEB";
  
    if (selectedValues.length > 0) {
      color = isSelected ? "#5C5CEB" : "#5C5CEB";
    }
  
    // Apply a different color to the last 4 weeks
    const isLastFourWeeks = index >= dataArr.length - 4;
    if (isLastFourWeeks) {
      color = "#5C5CEB"; // Change this to your desired color for the last 4 weeks
    }
  
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        style={{ cursor: "pointer", borderRadius: "10px 10px 0 0" }}
      />
    );
  };
  
  const CustomBar2 = (props: any) => {
  
    const { x, y, width, height, payload } = props;
  
    const value = payload?.name?.toLowerCase()
    const isSelected = selectedValues?.includes(value)
  
    let color = "#5C5CEB"
  
    if ( selectedValues.length > 0){
      color= isSelected ? "#5C5CEB" : 'rgba(216, 216, 219, 0.4)'
    }
  
     
  
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        style={{ cursor: "pointer", borderRadius: "10px 10px 0 0" }}
      />
    );
  };
  
  const getYAxisDomain = () => {
    const maxValue = Math.max(
      ...dataArr
        .map((item) => (item?.assigned || 0) + (item?.suggested || 0))
    );
      
    if (maxValue > 30) {
      return [0, 120];  // Capped at 120 for evidence greater than 
      
    } else {
      return [0, 30];  // For evidence up to 30
    }
  };
  
  const getYAxisTicks = () => {
    const domain = getYAxisDomain();
    if (domain[1] <= 30) {
      return [5, 10, 15, 20, 25, 30];
    } else if (domain[1] >= 30 && domain[1] <= 120) {
      return [20, 40, 60, 80, 100, 120];
    }
    return [5, 10, 15, 20, 25, 30];
  };
  
  return (
    <Stack
      h={180} 
      w="100%"
      justify="center"
      align="center"
      sx={{ position: "relative" }}
    >
      {/* Chart Container */}

      <Flex align='left' w='100%'>
        <Text fz={14} fw={600} mr={5} >Creation date:</Text>
        <Text fz={14}>Last 3 months</Text>
      </Flex>
      
      <div style={{ width: "100%", height: yAxisHeight, cursor: "default" }}>
        <ResponsiveContainer
          width="100%"
          height={yAxisHeight}
          
        >
          <BarChart
            data={dataArr}
            margin={{
              top: 20,
              right: 0,
              left: 25,
              bottom: 20,
            }}
            onClick={(data) => handleBarClick(data)}
            style={{ cursor: "default" }}
          >
            {/* Horizontal grid lines only */}
            <CartesianGrid vertical={false} stroke="#ccc" />
            <XAxis
              /* scale='point' */
              dataKey="monday"
              tick={{ fill: "#5C5CEB", dy: 7 }}
              tickFormatter={(value, index)=>{
                const parts = value.split('-')
                if(parts.length>=3){
                  return `${parts[0]}-${parts[1]}`
                }
                return value}}
            />
            {/* Y-axis with controlled tick spacing */}
            <YAxis
              orientation="right"
              tickCount={7}
              domain={getYAxisDomain()}
              ticks={getYAxisTicks()}
              tick={{ fill: "#5C5CEB" }}
              tickFormatter={(value) => (value >= 120 ? "120+" : value)}
            />
            <Tooltip content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const {monday, total} = payload[0].payload; // Use original values here
                const formattedMonday = monday.split('-').slice(0,2).join('-')
                return (
                  <Card bg="#F8F8FB" p='sm' withBorder shadow="xs">
                    <Text>{formattedMonday}</Text>
                    <Flex >
                      <Text color="#5C5CEB"> Evidence :</Text>
                      <Text ml={3.3} color="#5C5CEB"> {total}</Text>
                    </Flex>
                  </Card>
                );
              }
              return null;
            }} />
              
            {filteredReferenceData
              .filter((ref:any) => dataArr.map(d => d.monday).includes(ref.formattedDate)) 
              .map((ref:any, index:any) => (
                <ReferenceLine
                  key={index}
                  x={ref.formattedDate}
                  stroke={ref.color}
                  label={(props) => (
                    <CustomReferenceLabel
                      {...props}  
                      value={ref.label} 
                      fill={ref.color} 
                      date={formatDate(ref.date)} 
                      onShowTooltip={showTooltip}
                      onHideTooltip={hideTooltip}/>)}
                />
              ))}
            {/* <Legend /> */}
            {/* Bars with click handler */}
            <Bar
              dataKey="total"
              stackId="a"
              fill="#5C5CEB"  
              radius={[2.5, 2.5, 0, 0]}
              barSize={barWidth}
              shape={<CustomBar />}
              /* background={<CustomBackground />}  */
            />
            {/* <Bar
              dataKey="suggested"
              stackId="a"
              fill="rgba(92, 92, 235, 0.6)"  
              shape={<CustomBar2 />}
              radius={[2.5, 2.5, 0, 0]}
              barSize={barWidth}
            >
            </Bar> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {tooltip.visible && (
        <Box style={{
          position: 'absolute',
          left: tooltip.x - 50,
          top: 70,
          backgroundColor: 'black',
          color: 'white',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '12px',
          zIndex: 9999
        }}>
          <Box>{tooltip.value}</Box>
          <Box>{tooltip.date}</Box>
        </Box>
      )}
    </Stack>
  );
};
  
export default DashboardTrend;

