import { Flex, Text } from "@mantine/core";
import { DomainHeaderIcon } from "../../../../../icons/x-symbol-svgrepo-com";
import { useContext } from "react";
import DashboardContext from "../../../DashboardContext";
import BagelMultiSelect, { Option } from "../../../../general/BagelMultiSelect";

const DomainSelect = () => {
  const {
    domains,
    displayState,
    filterComponentByDomain,
    setTrendsActiveFilters,
    trendsActiveFilters
  } = useContext(DashboardContext);

  const optionsToRender: Option[] = domains.map((d: any) => ({
    value: d.name,
    label: d.name,
  }));

  const currentSelection =
    (trendsActiveFilters.find((f: any) => f.key === "domain")?.value as string[]) || [];

  const selectedLabel =
    currentSelection.length === 1
      ? currentSelection[0]
      : currentSelection.length > 1
        ? `${currentSelection.length} selected`
        : "All";

  const handleSelectionChange = (selected: string[]) => {
    setTrendsActiveFilters((prev: { key: string; value: string | string[] }[]) => {
      const otherFilters = prev.filter((f) => f.key !== "domain");
      return [...otherFilters, { key: "domain", value: selected }];
    });
    filterComponentByDomain(selected);
  };

  return (
    <>
      {displayState?.domain && (
        <Flex
          align="center"
          sx={{ borderRadius: "4px", border: "0.8px solid #F8e9FB", cursor: "pointer" }}
        >
          <BagelMultiSelect
            parent="domain"
            options={optionsToRender}
            value={currentSelection}
            onChange={handleSelectionChange}
            searchable
          >
            <Flex px={8} justify="space-evenly" align="center" h={32}>
              <Flex ml="4px">
                <DomainHeaderIcon />
                <Text fz="12px" color="#585C68" ml="6px" sx={{ lineHeight: "16px" }}>
                  Domain:
                </Text>
              </Flex>
              <Text fz="12px" lineClamp={1} maw="100px" miw="60px" mx={5}>
                {selectedLabel}
              </Text>
            </Flex>
          </BagelMultiSelect>
        </Flex>
      )}
    </>
  );
};

export default DomainSelect;