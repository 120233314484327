import React, { useContext } from "react";
import { Box, Text, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import ChildMenu from "./ChildMenu";
import FiltersContext from "./context/FiltersContext";
import FilterFooter from "./FilterFooter";
import { evidenceParents } from "./FiltersSpec";
import IdeasContext from "../IdeasContext";
import { useLocation } from "react-router-dom";
import SyncContext from "../../../context/SyncContext";

interface MenuFirstStepProps {
  initialfilters: any;
  MenuType: "idea" | "evidence";
  componentType: 'ideas-table' | 'idea-page' | 'discovery';
  handleParentFilterClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  
}

const MenuFirstStep: React.FC<MenuFirstStepProps> = ({
  initialfilters,
  MenuType,
  componentType
}) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _selectedView = queryParams.get("selectedView");
  
  const { selectedParentFilter, parentQuery, setParentQuery, setActiveFilters, setCheckedComponents } =
    useContext(FiltersContext);

  const { ideasFiltersConfig } = useContext(SyncContext);

  const filteredFiltersKeys = Object.keys(ideasFiltersConfig[MenuType]).filter((name) =>
    parentQuery ? name.toLowerCase().includes(parentQuery) : true
  );

  const resetFilters = (MenuType: "idea" | "evidence", selectedParentFilter?: string | null) => {
    console.log("resetFilters",{ MenuType, selectedParentFilter});

    // Update checked components based on the new filters
    setCheckedComponents((current: {[key: string]: any[]}) => {    
      console.log("resetFilters",{current})
      const newState = Object.keys(current).reduce((acc, key) => {
        const isParentInEvidence = evidenceParents.some(parent => key.startsWith(parent));
    
        if (selectedParentFilter) {
          const hasDot = selectedParentFilter.includes('.');

          if(!hasDot && selectedParentFilter == key.split('.')[0]){
            acc[key] = []
          }else if (hasDot &&  selectedParentFilter == key){
            acc[key] = []
          }else{
            // Otherwise, keep this entry
            acc[key] = current[key];
          }
        } else {
          // Depending on the MenuType, keep or discard entries
          if ((MenuType === "evidence" && !isParentInEvidence) || (MenuType === "idea" && isParentInEvidence)) {
            acc[key] = current[key];
          }
        }
    
        return acc;
      }, {} as {[key: string]: any[]});
    
      return newState;
    });
    

    setActiveFilters((currentFilters) =>
      currentFilters.filter((filter:any) => {

        if (selectedParentFilter) {
          return filter.parent.split('.')[0] !== selectedParentFilter;
        } else {
          return MenuType !== filter?.filterObject 
        }
      })
    );
  };

  return (
    <>
      <TextInput
        mt={5}
        mx={5}
        radius="md"
        placeholder="Search"
        value={parentQuery}
        onChange={(event: any) => setParentQuery(event.currentTarget.value)}
        icon={<IconSearch size={14.5} />}
        sx={{
          input: {
            backgroundColor: "#F9FAFB",
            ":focus": {
              borderColor: "#b1b1b1;",
            },
          },
        }}
      />
      <Box
        sx={{
          maxHeight: 275,
          overflowY: selectedParentFilter ? "hidden" : "scroll",
        }}
      >
        {filteredFiltersKeys.length > 0 ? (
          filteredFiltersKeys
            .filter((name: string) => {
              const filter = ideasFiltersConfig[MenuType][name];
              console.log({ filter });
              if (_selectedView?.toLowerCase() === 'bagel ai') {
                return filter.generatedEnabled;
              }
              return !filter.hidden;
            })
            .map((name, index) => (
              <Box key={index} mt={index === 0 ? 5 : 0} w={"100%"}>
                {/* Use ChildMenu component instead of UnstyledButton */}
                <ChildMenu
                  label={ideasFiltersConfig[MenuType][name].label}
                  initialfilters={initialfilters}
                  filter={ideasFiltersConfig[MenuType][name]}
                  MenuType={MenuType}
                  resetFilters={resetFilters}
                />
              </Box>
            ))
        ) : (
          <Text p="sm" align="center">
            Filter "{parentQuery}" not found.
          </Text>
        )}
      </Box>
      <FilterFooter onClickClear={() => resetFilters(MenuType, selectedParentFilter)} />
    </>
  );
};

export default MenuFirstStep;
