import { useAuth0 } from "@auth0/auth0-react";
import {
  Text,
  TextInput,
  Box,
  ScrollArea,
  createStyles,
  Button,
  Anchor,
  Popover,
  Stack,
  Flex,
  Card,
  Loader,
  Checkbox,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSearch, IconCheck, IconX, IconPlus } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { assignRequestToNewIdea, deleteIdea, getMatchesForRequests, getSuggestionsForIdea, searchIdeas } from "../../../../../Api";
import { getBagelId, useThrottle } from "../../../../../utils";
import { SearchResultIdeaItem } from "../SearchResultIdeaItem";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";


const useStyles = createStyles((theme) => ({
  item: {
    border: "1px solid #000",
    "&[data-hovered]": {
      backgroundColor: "#f5f5f5",
      color: theme.white,
    },
  },
  tabs: {
    "&[data-active]": {
      borderColor: "#5B60E3",
      color: "#5B60E3",
      "&:hover": {
        borderColor: "#5B60E3",
      },
    },
  },
}));

export function RequestAssignment({
  item,
  idea,
  navToPerspective,
  setOpened,
  TextInputW,
  TextInputPH,
  BoxW,
  multiSelect,
  selectedEvidenceIds,
  setSelectedEvidenceIds
}: {
  item: any;
  idea: any;
  navToPerspective: any;
  setOpened: any;
  TextInputW?: string;
  TextInputPH?: string;
  BoxW?: string;
  multiSelect?: boolean;
  selectedEvidenceIds?: any;
  setSelectedEvidenceIds?: any;
}) {
  const { defaultPriority, defaultStatus, allComponents } =
    useContext(SyncContext);
  const { loadEvidence, ideaId: oldIdeaId } =
    useContext(IdeaContext);
  
  const { validate, validateMultiple } = useEvidenceActions({ evidenceId: item?._id });

  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [ideaMatches, setIdeaMatches] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [chosenIdea, setChosenIdea] = useState();
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showMandatoryPAMSG, setShowMandatoryPAMSG] = useState(false);
  const [onlyMyIdeas, setOnlyMyIdeas] = useState(false);

  const userId = getBagelId(auth0.user);
  
  const openDeleteRequestPopup = () =>
    openConfirmModal({
      title: "Delete idea",
      centered: true,
      children: (
        <Text size="sm">
          This idea has no evidence. do you want to delete it?
        </Text>
      ),
      labels: { confirm: "Delete idea", cancel: "No, keep it for now" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteIdea(item?._id, auth0)
          .then((response) => {
            navigate("/requests");
          })
          .catch((e: any) => console.log(e));
      },
    });

  const handleAssign = (idea: any) => {
    if(multiSelect){
      const evidences = selectedEvidenceIds.map((evidence: any) => ({
        evidenceId: evidence._id,
        ...(evidence.match ? { matchId: evidence.match._id, sourceOfAssignment: "suggestion" } : {})
      }));
      setLoading(true);
      validateMultiple({ ideaId: idea?._id, oldIdeaId, evidences })
        .then(() => {
          setLoading(false);
          showNotification({
            title: "Evidences have been re-assigned successfully",
            color: "teal",
            message: "",
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200,
          });
          setOpened(false);
          loadEvidence();
          navToPerspective();
          setSelectedEvidenceIds([]);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          showNotification({
            title: "Error re-assigning requests",
            color: "red",
            message: "Please try again...",
            icon: <IconX size="1.1rem" />,
            autoClose: 1200,
          });
          setSelectedEvidenceIds([]);
        });
    } else {
      oldIdeaId &&
      validate({ideaId: idea?._id, oldIdeaId})
        .then(() => {
          showNotification({
            title: "Eviedence has been re-assigned successfuly",
            color: "teal",
            message: "",
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          setOpened(false);
  
          /* closeAllModals() */
          loadEvidence();
          navToPerspective();
        })
        .catch((e) => {
          console.log(e);
          showNotification({
            title: "Error re-assigning request",
            color: "red",
            message: "please try again...",
            icon: <IconX size="1.1rem" />,
            autoClose: 1200
          });
        });
    }
    
  };

  const chooseIdea = (ideaObj: any) => {
    setChosenIdea(ideaObj);
  };

  const doSearch = async () => {
    searchIdeas({ query, onlyMyIdeas: onlyMyIdeas ? userId : undefined}, auth0)
      .then((results: any[]) => {
        if(results.length > 0){
          
          setSearchResults(results);
          setShowMandatoryPAMSG(false)
          setShowSearchResults(true)
        }
        else{
          setSearchResults([])
        }
      })
      .catch((e) => console.log(e));
  };

  const throttledSearch = useThrottle(() => doSearch(), 1000);

  const getSuggestions = () => {
    const title =  item?.title || "";
    const text = item?.description || "";
    getSuggestionsForIdea(title, text, auth0)
      .then((matches: any) => {
        const filtered =
          matches?.ideaMatches?.filter((m: any) => m.ideaId !== idea?._id) ||
          [];
        setIdeaMatches(filtered);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingMatches(false));
  };

  const getIdeasMatches = () => {
    setLoadingMatches(true);
    let evidenceIds;
    if(multiSelect) {
      evidenceIds = selectedEvidenceIds.map((obj: any) => obj._id);
    } else {
      evidenceIds = [item?._id];
    }
    getMatchesForRequests(evidenceIds, auth0)
      .then((matches: any) => {
        const filtered =
          matches?.filter((m: any) => m.ideaId !== idea?._id) || [];

        if (filtered.length === 0) {
          getSuggestions();
        } else {
          setIdeaMatches(filtered);
          setLoadingMatches(false);
        }
      })
      .catch((e) => console.log(e));
  };


  const title = item?.title ||  "";
  const description = item?.text || '';

  const newIdeaObj = {
    title: title,
    description: description,
    componentId: item?.componentId, 
    ownerId: null,
    rolloutDate: null,
    status: defaultStatus,
    priority: defaultPriority,
  };


  const createObj = { idea: newIdeaObj, evidence: {...item} }

  const createNew = () => { 
    if (!item?.componentId) {
      setShowMandatoryPAMSG(true);
      return
    } 

    else if (item?.componentId) { 
  
      const foundComponent = allComponents.find((c: any)=> c._id === item?.componentId)

      if(!foundComponent){
        setShowMandatoryPAMSG(true);
        return
      } 

      if(foundComponent){
        createObj.idea.ownerId = foundComponent.ownerId
        createObj.evidence.ownerId = foundComponent.ownerId
      } 

      assignRequestToNewIdea(
        createObj,
        auth0
      )
        .then((response) => {
          const ideaId = response.createNewIdeaRes?.idea?._id;
          showNotification({
            title: "Assigned successfully!",
            color: "teal",
            message: <Box>
              <Text>New idea has been created</Text>
              <Anchor href={`/idea/${ideaId}`} target="_blank">
                Go to the new idea
              </Anchor>
            </Box>,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
          if (ideaId) {
            setTimeout(() => {
              window.open(`/idea/${ideaId}`);
            }, 1300);
          } else {
            console.log(`did not receive request id for new idea`, response);
          }
        })
        .catch(console.log)
        
    } 
  };


  useEffect(() => {
    query !== "" ? throttledSearch() : setShowSearchResults(false);

    
  }, [query,onlyMyIdeas]);

  useEffect(() => {
    
    getIdeasMatches();

  }, []);

  return (
    <Stack justify="space-between" h={400} pt={5}>
      <Box>
        <div
          onFocusCapture={() => setOpen(true)}
          onBlurCapture={() => setOpen(false)}
        >
          <TextInput
            w={TextInputW || "28vw"}
            placeholder={
              TextInputPH ? TextInputPH : "Search and assign idea..."
            }
            icon={<IconSearch size={14} />}
            radius="md"
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
          <Checkbox
            sx={{ margin: "5px 0"}}
            label="Only search Ideas I own"
            color="indigo"
            checked={onlyMyIdeas}
            onChange={(event) => setOnlyMyIdeas(event.currentTarget.checked)}
            mb="xs"
          />
        </div>
        <Box>{ 
          showSearchResults ?
            <Box>

            
              {searchResults.length > 0 && (
                <Box
                  sx={{
                    height: 304,
                    overflowY: 'auto', // Enables vertical scrolling
                    overflowX: 'hidden', // Hides horizontal scrollbar
                    border: '1px solid #D8D8DB',
                    borderRadius: 6,
                    backgroundColor: '#F8F9FA',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap', // Ensures no wrapping of content
                  }}
                >
                  {searchResults.length > 0 && (
                    <Flex justify='space-between' pl="sm"  w='97%'>
                      <Box
                        
                        py="md"
                        sx={{
                          borderTop: "1px solid #e5e7eb",
                        }}
                      >
                        <Text size="sm">{searchResults.length} results</Text>
                      </Box>
                      <Flex 
                        px="sm"
                        align='center' 
                        ml='md'
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={()=>{
                          setQuery('')
                          setOnlyMyIdeas(false)
                          setShowSearchResults(false)
                        } }
                      >
                        <Box mr='3px' mt='2px'>
                          <IconX size={12} color='#5C5CEB'/>
                        </Box>
          
                        <Text fz="12px" color='#5C5CEB'> Clear</Text>
                      </Flex> 
                    </Flex>
                    
                  )}{" "}
                  <Box w={500} h={350} px="xs">
                    {searchResults.length > 0 &&
              searchResults.map((idea: any) => {
                return (
                  <SearchResultIdeaItem
                    key={idea._id}
                    reAssign
                    loading={false}
                    ideaItem={idea}
                    chooseIdea={chooseIdea}
                    chosenIdea={chosenIdea}
                  />
                );
              })}
                  </Box>
                  <>
                    {" "}
            
            
                  </>
                </Box>
              )}
            </Box>
            : showMandatoryPAMSG ? 
              <Box bg='rgba(92, 92, 235, 0.1)' py='lg' px ={50}  mx ={50} mt={60} sx={{borderRadius: 8}}>
Please assign a producat area to this request before creating a new idea
              </Box>
              :
              <Box>
                <Text size="xs" mb="xs" px={16} fw={500}my='sm'>
              Suggested by Bagel
                </Text>
                <Box px={16}>
                  {ideaMatches.length > 0 ? (
                    ideaMatches
                      .slice(0, 2)
                      ?.map((ideaMatch) => (
                        <SearchResultIdeaItem
                          key={ideaMatch?.idea?._id}
                          reAssign
                          loading={false}
                          ideaItem={ideaMatch?.idea}
                          chooseIdea={chooseIdea}
                          chosenIdea={chosenIdea}
                        />
                      ))
                  ) : loadingMatches ? (
                    <Box mb={28}>
                      <Loader />
                    </Box>
                  ) : (
                    <Card
                      withBorder
                      radius="md"
                      sx={{ borderColor: "#EAEAFE" }}
                      mb="sm"
                    >
                      <Text>No suggestions found</Text>
                    </Card>
                  )}
                </Box>
                
              </Box>
       
        
        }
        
        </Box>
      </Box>
      

      <Flex justify="space-between" mr="md">
        
        <Button
          loading={loading}
          fz="12px"
          color="indigo"
          w="75px"
          mr={4}
          disabled={!chosenIdea}
          styles={(theme) => ({
            root: {
              height: "32px",
              backgroundColor: "#5C5CEB",
              border: 0,
              fontWeight: 400,
              padding: 0,
              "&:hover": {
                backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
              },
            },

            leftIcon: {
              marginRight: 15,
            },
          })}
          onClick={() => {
            handleAssign(chosenIdea);
          }}
        >
          <IconPlus size={14} />
          <Text>assign</Text>
        </Button>
      </Flex>
    </Stack>
  );
}