import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useContext, useState } from "react";
import { IconX } from "@tabler/icons";
import { useHover } from "@mantine/hooks";
import { Tag } from "../../Tags-old/Tag";
import { IdeaTags } from "../../Tags-old/IdeaTags";
import SyncContext from "../../../context/SyncContext";
import IdeaContext from "../IdeaContext";

const OverView = ({
  reviewMode,
  setReviewMode,
  description,
  setDescription,
  updateIdeaDescription,
  longDescriptionCheck,
  idea,
}: {
  reviewMode: boolean;
  setReviewMode: any;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  updateIdeaDescription: any;
  longDescriptionCheck: any;
  idea: any;
}) => {
  const { hovered, ref } = useHover();
  const [editing, setEditing] = useState(false);

  const { addedAttributes } = useContext(SyncContext);
  const { isGenerated } = useContext(IdeaContext);

  return (
    <Box
      mah="386px"
      pt={16}
      pl={24}
      pr={24}
      pb={24}
      bg={"#ffffff"}
      sx={{ borderBottom: "1px solid #D8D8DB" }}
    >
      <Flex justify="space-between" ml={4} mb={12}>
        <Box>
          <Text color="#212529" fw={600}>
            Description
          </Text>
        </Box>
        <Flex
          sx={{ cursor: "pointer" }}
          onClick={() => setReviewMode(!reviewMode)}
        >
          <IconX size={16} color="#5C5CEB" />
          <Text ml="3px" color="#5C5CEB">
            Close
          </Text>
        </Flex>
      </Flex>
      <Stack>
        {!isGenerated && addedAttributes.map((attr) =>
          attr.fieldPath == "tags" ? (
            <IdeaTags idea={idea} showAddBtn/>
          ) : (
            <></>
          )
        )}
      </Stack>
      <Tooltip label="Edit description" disabled={editing || isGenerated}>
        <Box ref={ref}>
          <Textarea
            autosize
            onClick={() => {

              if (!editing && !isGenerated) {
                setEditing(true);
              }
            }}
            placeholder="Enter description"
            minRows={2}
            value={description}
            onChange={(event) => {
              if(!isGenerated){
                setDescription(event.currentTarget.value);
              }
              
              /* longDescriptionCheck() */
            }}
            onBlur={(event) => {
              longDescriptionCheck();
              updateIdeaDescription(event.currentTarget.value);
              setEditing(false);
            }}
            sx={{
              cursor: isGenerated ? "not-allowed" : "text", // Disable cursor if `isGenerated` is true
              whiteSpace: "pre-line",
            }}
            styles={() => ({
              wrapper: {
                maxHeight: 290,
                borderRadius: "6px",
              },
              root: {
                
                border: (editing && !isGenerated)
                  ? "1.6px solid rgba(92, 92, 235, 0.3)"
                  : (hovered && !isGenerated)
                    ? "1.6px solid #D8D8DB"
                    : "1.6px solid transparent",
                borderRadius: "6px",
              },
              input: {
                cursor: isGenerated ? "default" : "text",
                border: "none",
                width: "100%",
                fontSize: "14px",
                backgroundColor: (hovered && ! isGenerated) ? "#F8F9FA" : "#fff",
                margin: "0px",
                padding: "4px",
                whiteSpace: "pre-line",
              },
            })}
            
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default OverView;
