import * as React from "react";

const ZoomInfoIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={props.width || 30}
    height={props.height || 30}
    {...props}
  >
    <path
      d="M0,0H150V150H0V0Z"
      fill="none"
    />

    <path
      fill="#f44238"
      d="M128.87,13.57H21.69c-4.66,0-8.44,3.78-8.44,8.44v107.19c0,4.66,3.78,8.44,8.44,8.44h107.19
        c4.66,0,8.44-3.78,8.44-8.44V22.01c0-4.66-3.78-8.44-8.44-8.44ZM44.44,45.86c0-.6,.49-1.09,1.09-1.09h34.52
        c.6,0,1.09,.49,1.09,1.09v4.15c0,.6-.49,1.09-1.09,1.09h-29.09v9.56c0,.6-.49,1.08-1.09,1.09h-4.33
        c-.6,0-1.09-.49-1.09-1.09v-14.8Zm62.4,59.49c0,.6-.49,1.09-1.09,1.09h-31.74c-.6,0-1.09-.49-1.09-1.09v-4.32
        c0-.6,.49-1.09,1.09-1.09h26.32v-9.86c0-.6,.49-1.09,1.09-1.09h4.33c.6,0,1.09,.49,1.09,1.09v15.26Zm0-44.87
        c0,.6-.48,1.09-1.08,1.1h-4.34c-.6,0-1.09-.49-1.09-1.09v-4.52l-46.17,43.97h9.5c.6,0,1.09,.49,1.09,1.09v4.32
        c0,.6-.49,1.09-1.09,1.09h-18.11c-.6,0-1.09-.49-1.09-1.09v-5.39l51.18-48.71h-5.19c-.6,0-1.09-.49-1.09-1.09v-4.31
        c0-.6,.49-1.09,1.09-1.09h15.32c.6,0,1.09,.49,1.09,1.09v14.62Z"
    />
  </svg>
);

export default ZoomInfoIcon;
